const formatTracks = (tracks) => {
	if (tracks.length === 1) {
		return `Sur la voie ${tracks[0]}`;
	} else if (tracks.length === 2) {
		return `Sur les voies ${tracks[0]} et ${tracks[1]}`;
	} else if (tracks.length > 2) {
		const lastTrack = tracks.pop();
		return `Sur les voies ${tracks.join(', ')} et ${lastTrack}`;
	}
};

const formatDirection = (trafficDirection) => {
	if (!trafficDirection) return '';

	if (trafficDirection === 'Contresens') {
		return 'à contresens';
	}
	if (trafficDirection === 'Normal') {
		return `en sens ${trafficDirection.toLowerCase()}`;
	}
	return `dans ${trafficDirection.toLowerCase()} de circulation`;
};

export { formatDirection, formatTracks };
