import React, { useContext, useEffect, useState } from 'react';
import { ConsultantPage, DateUtils, LoadMore, SearchBar } from '../../../../core';
import { useTranslation } from 'react-i18next';
import { ChangeAttachmentBanner } from '../../../../attachment';
import { getConsultationAttachments } from '../../../../attachment/utils/attachments-localstorage-utils';
import SearchDriveLogsByDates from '../../../components/search-drive-logs-by-dates/search-drive-logs-by-dates';
import DriveLogTable from '../../../components/drive-log-table/drive-log-table';
import useDrivelogList from '../../../../core/custom-hooks/use-drive-log-list';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import './dl-list-read-only.scss';

const DlListReadOnly = () => {
	const { t } = useTranslation('dl');
	const { user = {} } = useContext(AuthContext);

	const [selectedAttachments, setSelectedAttachments] = useState();
	const attachmentsNamesList = selectedAttachments?.map((attachment) => attachment.name);
	const columnsToSearch = ['name', 'service', 'id', 'mission', 'train-number'];
	const storageName = 'consultantReadDl';

	useEffect(() => {
		getConsultationAttachments(user)
			.then((attachmentInfo) => {
				setSelectedAttachments(attachmentInfo);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [user]);

	const {
		driveLogs,
		loadMore,
		handleResetDriveLogs,
		handleSearchDriveLogs,
		handleDateFilter,
		totalResultCount,
		filter,
	} = useDrivelogList({
		status: ['validated', 'archived'],
		storageName,
		attachmentsNamesList,
		columnsToSearch: columnsToSearch[0],
		numberResults: 40,
		mandatoryForRequest: ['attachmentsNamesList'],
		dateSortOrder: 'desc',
		originView: 'consultant',
	});

	const { searchDate: filterDate } = filter;
	const formattedDate = DateUtils.formatFullDate(filterDate, 'dd/MM/yyyy');

	const attachmentsName = {
		name: selectedAttachments
			?.map((attachment) => attachment.name)
			.sort()
			.join(', '),
	};

	return (
		<ConsultantPage className="consultant-dl-list-page">
			{selectedAttachments && (
				<ChangeAttachmentBanner
					attachment={attachmentsName}
					className="consultant-dl-list-page__attachment-banner"
					originPage="/consultant/drive-logs"
					redirectUrl="/modify-attachment-for-consultation"
					text="change-attachment-banner.drive-log-from"
				/>
			)}
			<div className="consultant-dl-list-page__filters">
				<SearchBar
					handleSearch={handleSearchDriveLogs}
					handleReset={handleResetDriveLogs}
					columnsToSearch={columnsToSearch}
					filter={filter}
				/>
				<SearchDriveLogsByDates handleDateFilter={handleDateFilter} date={filterDate} />
			</div>
			<h1 className="consultant-dl-list-page__title">
				{formattedDate
					? `${t('consultant-dl-list-page.title')} du ${formattedDate}`
					: t('consultant-dl-list-page.title')}
			</h1>
			<DriveLogTable driveLogs={driveLogs} consultantView={true} displayDriveLogStatus={true} />
			<div className="consultant-dl-list-page__controls">
				{driveLogs?.length < totalResultCount && <LoadMore onClick={() => loadMore(40)} />}
			</div>
		</ConsultantPage>
	);
};

export default DlListReadOnly;
