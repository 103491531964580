import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../core';

/*
	Modal to display when the 'No sheet line approved' link is clicked
	The user is either routed to daily drive-log if the answer is yes
	or stay on the add-sheet-line page if the answer is no
 */
const ServiceNumberModal = (props) => {
	const { t } = useTranslation();
	const { showModal, toggleModal, navigate } = props;

	const modalText = t('dl:service-number.no-fiche-service');

	const confirmCallback = () => {
		navigate({ serviceNumber: '-1' });
	};

	const cancelCallback = () => {
		toggleModal();
	};

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={confirmCallback}
				cancelCallback={cancelCallback}
				closeCallback={cancelCallback}
			/>
		)
	);
};

export default ServiceNumberModal;
