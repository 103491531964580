/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../../core/components';
import { MultiInputCells, Button, Icon, arrayHasEmptyValues } from '../../../core';
import './multi-input-field.scss';

const acceptedCharacters = /^[a-z0-9]?$/i;

const MultiInputField = (fieldProps) => {
	const {
		translation = '',
		fieldName = '',
		maxItems = 5,
		inputLength = 6,
		values = [],
		handleChange,
		validator,
		className = '',
		disabled = false,
	} = fieldProps;
	const voidArray = Array(inputLength).fill('');

	const { t } = useTranslation(translation);

	const [fieldValues, setFieldValues] = useState(values || []);
	const [item, setItem] = useState(voidArray);
	const [isFormValid, setIsFormValid] = useState(false);
	const [isItemValid, setIsItemValid] = useState(true);

	// Whenever props.values changes, update the state
	useEffect(() => {
		if (values != null) {
			setFieldValues(values);
		}
	}, [values]);

	// Whenever a new item is completely put, run validation
	useEffect(() => {
		if (item.length >= inputLength && !arrayHasEmptyValues(item)) {
			setIsItemValid(validator(item));
		} else {
			setIsItemValid(true);
		}
	}, [item]);

	// Handle change only when a valid item is added
	useEffect(() => {
		handleChange(fieldName, fieldValues);
	}, [fieldValues]);

	const addItem = () => {
		if (fieldValues.length < maxItems) {
			setFieldValues([...fieldValues, item.join('').toUpperCase()]);
			setItem(voidArray);
		}
	};

	const removeItem = (idx) => {
		if (disabled) {
			return;
		}

		let list = fieldValues;
		list.splice(idx, 1);
		setFieldValues([...list]);
	};

	const selectedItems = (id, idx) => {
		return (
			<div key={`multi-input-selected-item-${idx}`} className="multi-input__field__selected-item">
				{id}
				<Icon
					aria-label="Close"
					className="multi-input__field__selected-item__icon"
					name="close"
					role="button"
					onClick={() => removeItem(idx)}
				/>
			</div>
		);
	};

	return (
		<div className={`multi-input__field ${className}`}>
			<div className={`multi-input__field__label ${className}`}>
				{t(`${translation}.forms.fields.labels.${fieldName}`)}
			</div>

			{!disabled && (
				<div className={`multi-input__field__container ${className}`}>
					<div className="multi-input__field__input">
						<MultiInputCells
							size={inputLength}
							cellTypes="text"
							valueArr={item}
							setValueArr={(valueArr) => setItem(valueArr)}
							setFormValid={(formValid) => setIsFormValid(formValid)}
							cellRegex={acceptedCharacters}
							hideClearButtonWhenEmpty={true}
						/>

						<Button
							className="button--submit button"
							type="submit"
							disabled={!isFormValid || !isItemValid || fieldValues.length >= maxItems}
							onClick={() => addItem()}
						>
							<>
								<Icon
									aria-label="add-item"
									className="multi-input__field__item__add-icon"
									name="add-plus"
								/>
								{t(`${translation}.forms.buttons.add`)}
							</>
						</Button>
					</div>
				</div>
			)}

			{!isItemValid && <ErrorMessage message={t('special-notice.forms.errors.missions.format')} />}

			<div className="multi-input__field__list">{fieldValues.map(selectedItems)}</div>
		</div>
	);
};

export default MultiInputField;
