import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DriverPage } from '../../../../core';
import { getDriveLogById } from '../../../drive-log.services';
import getStatusRedirection from '../../../utils/get-status-redirection';
import DelaysTable from './components/delays-table/delays-table';
import ValidateDelayBtn from './components/validate-delay-btn/validate-delay-btn';
import './delay-list-page.scss';

const DelayListPage = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;
	const { id: driveLogId } = useParams();
	const [dlToUpdate, setDlToUpdate] = useState({ id: driveLogId });
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [currentDriveLog, setCurrentDriveLog] = useState({});

	const loadDriveLog = useCallback(() => {
		getDriveLogById(driveLogId)
			.then((payload) => {
				const driveLog = payload.data;
				getStatusRedirection(driveLog, navigate);
				setCurrentDriveLog(driveLog);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId, navigate]);

	useEffect(loadDriveLog, [loadDriveLog]);

	return (
		<DriverPage selectedLink={redirectUrl === '/drive-log' ? 0 : 1} className="delay-list-page">
			<div className="delay-list-page__content">
				<h1 className="delay-list-page__title">{t('dl:delay-list-page.title')}</h1>
				<DelaysTable
					loading={loading}
					driveLog={currentDriveLog}
					loadDriveLog={loadDriveLog}
					redirectUrl={redirectUrl}
					dlToUpdate={dlToUpdate}
					setDlToUpdate={setDlToUpdate}
				/>
				<div className="delay-list-page__controls">
					<ValidateDelayBtn driveLog={currentDriveLog} redirectUrl={redirectUrl} />
				</div>
			</div>
		</DriverPage>
	);
};

export default DelayListPage;
