import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchStationReferential } from '../../../../../core';
import './observation-search.scss';

/**
 * Renders an <ObservationSearch /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.referential - the referential's name
 * @param {object} props.selectedStation - display the selectedStation
 * @param {function} props.setSelectedStation - set the setSelectedStation
 */
const ObservationSearch = (props) => {
	const { className = '', referential, searchLabel, selectedStation, setSelectedStation } = props;
	const { t } = useTranslation();

	return (
		<div className={`observation-search ${className}`}>
			{searchLabel && <label className="observation-search__label">{searchLabel}</label>}
			{!selectedStation?.key && (
				<label className="observation-search__subLabel">
					{t('observation:observation-fields.search-label')}
				</label>
			)}
			<SearchStationReferential
				className="field__input"
				referential={referential}
				selectedStation={selectedStation}
				setSelectedStation={setSelectedStation}
			/>
		</div>
	);
};

ObservationSearch.propTypes = {
	className: PropTypes.string,
	referential: PropTypes.string,
	selectedStation: PropTypes.object,
	setSelectedStation: PropTypes.func,
};

export default ObservationSearch;
