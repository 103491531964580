import { setDateTimeWithService } from '../../observation.services';
import {
	regexValidator,
	validateReason,
} from '../../components/observation-form/observation-validators';
import { RegexUtils } from '../../../core';

const epe = [
	{
		fieldName: 'cabinAndCassette',
		mandatory: true,
		group: [
			{
				fieldName: 'cabin',
				validator: regexValidator(RegexUtils.fourDigitNumber),
				mandatory: true,
			},
			{
				fieldName: 'cassette',
				validator: regexValidator(RegexUtils.twentyDigitNumber),
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
	{
		fieldName: 'comment',
		validator: validateReason,
	},
];

export default epe;
