import React from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-component.scss';
import CustomDatePickerInput from './custom-date-picker-input/custom-date-picker-input';
registerLocale('fr', fr);

/**
 * Renders a <DatePickerComponent /> component from react-datepicker library
 */
const DatePickerComponent = ({
	date,
	dateFormat = 'dd/MM',
	isError = false,
	large = false,
	maxDate = () => {},
	minDate = () => {},
	onChange,
	disabled = false,
}) => {
	const ref = React.createRef();

	return (
		<DatePicker
			dateFormat={dateFormat}
			selected={date}
			maxDate={maxDate}
			minDate={minDate}
			onChange={onChange}
			customInput={<CustomDatePickerInput ref={ref} date={date} large={large} isError={isError} />}
			locale="fr"
			disabled={disabled}
		/>
	);
};

DatePickerComponent.propTypes = {
	onChange: PropTypes.func,
	date: PropTypes.instanceOf(Date),
};

export default DatePickerComponent;
