import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import FeedbackMenuButton from '../../feedback/components/feedback-menu-button/menu-feedback-button';
import DriverHeader from '../../headers/driver-header/driver-header';
import PageWrapper from '../page-wrapper/page-wrapper';
import './driver-page.scss';

import { Button, Icon } from '../../../../core';

/**
 * Renders a <Page /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {Object} props.children - the content to render in the page
 * @param {boolean} props.selectedLink - display or not the footer
 * @param {boolean} props.hideNav - display or not the nav buttons
 */
const DriverPage = (props) => {
	const {
		className = '',
		children,
		selectedLink = 0,
		hideNav = false,
		overrideRoles,
		style,
		hideButton = false,
	} = props;
	const { user } = useContext(AuthContext);
	const { REACT_APP_URBAN_GROUP_LINK: urbanLink = '' } = process.env;

	const allowedRoles = overrideRoles || ['driver', 'amp', 'admin', 'localAdmin'];
	const navigate = useNavigate();

	if (!user?.role || !allowedRoles.includes(user.role)) {
		return navigate('/error-access');
	}

	return (
		<PageWrapper className="driver-page">
			<DriverHeader selectedLink={selectedLink} hideNav={hideNav} />

			<div className={`driver-page__content ${className}`} style={style}>
				{children}
			</div>
			<FeedbackMenuButton hideButton={hideButton} />
			{!hideButton && (
				<Button className="driver-page__button" onClick={() => window.open(urbanLink, '_blank')}>
					<Icon name="info" className="driver-page__button__icon" />
				</Button>
			)}
		</PageWrapper>
	);
};

DriverPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
	hideButton: PropTypes.bool,
};

export default DriverPage;
