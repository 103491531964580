import csrf from '../csrf';

const { REACT_APP_API_BASE_URL = '' } = process.env;
const authRootUrl = REACT_APP_API_BASE_URL + 'auth';

/**
 * Axios interceptor - handle timeout or network errors and log error message and id received from API
 *
 * @param {AxiosInstance} axiosInstance - the custom axios instance
 */
const setupInterceptor = (axiosInstance) => {
	axiosInstance.interceptors.request.use((reqConfig) => {
		const csrfToken = csrf.getCSRFToken();
		if (csrfToken) {
			reqConfig.headers[csrf.csrfRequestHeader] = csrfToken;
		}
		return reqConfig;
	});

	axiosInstance.interceptors.response.use(
		(res) => res,
		(error) => {
			// dispatch event for unauthorized
			if (
				error.response?.status === 401 &&
				error.response?.headers['error-type'] === 'TOKEN_INVALID'
			) {
				localStorage.clear();
				window.location = `${authRootUrl}/login?redirectPath=${window.location.pathname}`;
			}

			// dispatch event for server timeout or network error
			if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
				const networkStatusEvent = new CustomEvent('serverError');
				window.dispatchEvent(networkStatusEvent);
				return;
			}

			if (process.env.NODE_ENV !== 'production' && error.response) {
				const { id, error: errorMessage } = error.response.data;
				console.error(
					`Error: ${errorMessage}\nMore detail about this error can be found in API log by searching the following id: "${id}"`,
				);
			}
			return Promise.reject(error);
		},
	);
};

export default setupInterceptor;
