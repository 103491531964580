import i18next from '../../config/i18next';
import i18n from './i18n/fr.json';

import AddSurvey from './pages/add-survey/add-survey';
import EditSurvey from './pages/edit-survey/edit-survey';
import ReadSurvey from './pages/read-survey/read-survey';

i18next.addResourceBundle('fr', 'survey', i18n);

export { AddSurvey, EditSurvey, ReadSurvey };
