import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PopupFullPage } from '../../../core';
import ObservationForm from '../../components/observation-form/observation-form';
import observationTypes from '../../forms/observation-types';

const AddObservation = (props) => {
	const { missionId, typeUrl } = useParams();
	const { params = {}, observationData } = props;
	const { redirectUrl = '' } = params;

	const { t } = useTranslation();

	const linkedEntity = missionId ? 'mission' : 'drive-log';

	const observationMatch = (observation) => {
		if (observationData) {
			return observation.name === observationData.observation_type;
		}
		return observation.url === typeUrl;
	};
	const findObservation = observationTypes.find(observationMatch);
	const { fields, name } = findObservation;

	return (
		<PopupFullPage title={t(`observation:observation-titles.${name}`)}>
			<ObservationForm
				observationFields={fields}
				observationType={name}
				observationData={observationData}
				linkedEntity={linkedEntity}
				redirectUrl={redirectUrl}
			/>
		</PopupFullPage>
	);
};

export default AddObservation;
