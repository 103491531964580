const filterListStationsMission = (sortedStationList, station_start, station_end) => {
	const startIndex = sortedStationList.findIndex(
		(station) => station.station_name === station_start,
	);
	const endIndex = sortedStationList.findIndex((station) => station.station_name === station_end);

	if (startIndex === -1 || endIndex === -1 || startIndex < endIndex) {
		return sortedStationList;
	}

	const extractStationsBetween = sortedStationList.slice(endIndex, startIndex + 1);
	return sortedStationList.map((station) => ({
		...station,
		is_disabled: !extractStationsBetween.map((s) => s.station_name).includes(station.station_name),
	}));
};

export { filterListStationsMission };
