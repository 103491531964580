import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './user-table-header.scss';

const UserTableHeader = (props) => {
	const { className } = props;
	const { t } = useTranslation('user');

	return (
		<li className={`${className} user-table-row--header`}>
			<div className="user-row--cell user__id">{t('users-list.user-row.id')}</div>
			<div className="user-row--cell user__names">{t('users-list.user-row.names')}</div>
			<div className="user-row--cell user__role">{t('users-list.user-row.role')}</div>
			<div className="user-row--cell user__line">{t('users-list.user-row.line')}</div>
			<div className="user-row--cell user__digiplan">{t('users-list.user-row.digiplan')}</div>
			<div className="user-row--cell user__status">{t('users-list.user-row.status')}</div>
			<div className="user-row--cell user__action">{t('users-list.user-row.action')}</div>
		</li>
	);
};

UserTableHeader.propTypes = {
	user: PropTypes.object,
	key: PropTypes.string,
};

export default UserTableHeader;
