import differenceInMinutes from 'date-fns/differenceInMinutes';

/**
 * Compute compensatory time elements
 * @param {Date} shiftStartDate - DateTime the shift started
 * @param {Date} shiftEndDate - DateTime the shift ended
 * @param {number} endOfShiftDelay - Delay at the end of the shift
 * @param {number} dailyLegalTimeExceed - Amount of time exceeding the regulatory time (usually 6h30 per day)
 * @param {number} restTimeReduction - Amount of time lost by the driver for his rest
 * @param {number} diffServicePlannedPerformedTime - Difference service planned / performed time
 * @param {number} otherTcTime - Other TC time
 * @param {object} driveLog - Drive Log
 */
const computeTime = (
	{
		shiftStartDate,
		shiftEndDate,
		endOfShiftDelay,
		dailyLegalTimeExceed,
		restTimeReduction,
		diffServicePlannedPerformedTime,
		otherTcTime,
	},
	driveLog = {},
) => {
	const result = {
		endOfServiceDelay: endOfShiftDelay,
		superiorToTenMinutes: 0,
		superiorToSixHoursThirty: 0,
		restTimeReduction: 0,
		diffServicePlannedPerformedTime: 0,
		otherTcTime: 0,
		total: 0,
		t3tc: 0,
	};

	const realizedDateStart = new Date(shiftStartDate);
	const realizedDateEnd = new Date(shiftEndDate);
	const isAdditionalService =
		driveLog?.service_type === 'special_operation' || driveLog?.service_type === 'reserve';

	const serviceDuration = differenceInMinutes(realizedDateEnd, realizedDateStart);

	if (endOfShiftDelay > 10) {
		result.superiorToTenMinutes = endOfShiftDelay - 10;
	}

	// If daily legal time is not defined and is not an additional service type
	if (!dailyLegalTimeExceed && !isAdditionalService) {
		const delaySuperiorToSixHoursThirty = serviceDuration + endOfShiftDelay - 390;
		if (delaySuperiorToSixHoursThirty > 0) {
			result.superiorToSixHoursThirty = 0;
		}
	} else {
		result.superiorToSixHoursThirty = dailyLegalTimeExceed;
	}

	// If rest time reduction is superior to 0
	if (restTimeReduction > 0) {
		result.restTimeReduction = restTimeReduction;
	}

	// If difference service planned / performed time is superior to 0
	if (diffServicePlannedPerformedTime > 0) {
		result.diffServicePlannedPerformedTime = diffServicePlannedPerformedTime;
	}

	// If other TC time is superior to 0
	if (otherTcTime > 0) {
		result.otherTcTime = otherTcTime;
	}

	result.t3tc =
		result.superiorToTenMinutes +
		result.superiorToSixHoursThirty +
		result.restTimeReduction +
		result.diffServicePlannedPerformedTime +
		result.otherTcTime;

	result.total = result.t3tc + (result.endOfServiceDelay || 0);

	return result;
};

export default computeTime;
