/**
 * validate precision comment
 * @param precision
 * @return {boolean}
 */
const validatePrecisionComment = (precision) => {
	return precision?.length > 0 && precision?.length < 240;
};

/**
 *
 * @param radio
 * @returns {boolean}
 */
const validateRadioButton = (radio) => {
	return ['yes', 'no', 'idk'].includes(radio);
};

/**
 *
 * @param value
 * @returns {boolean|*}
 */
const validateSurveyChecklist = (value = {}) => value.checkbox;

export { validatePrecisionComment, validateRadioButton, validateSurveyChecklist };
