/**
 * check if child field should be shown or not
 * @param groupData
 * @param {string} fieldName
 * @param {Object} childSchema
 */
const isChildShown = (groupData = {}, fieldName, childSchema = {}) => {
	const { groupValue = {} } = childSchema;
	const { value, name } = groupValue;
	const targetStateValue = groupData[name];
	return targetStateValue === value;
};

/**
 * check if child field is valid
 * @return {boolean}
 */
const isChildFieldValid = (groupValue = {}, fieldName, childrenSchema) => {
	const { fieldName: childName, mandatory, validator, childrenName } = childrenSchema;

	let childValue = groupValue[childName];
	if (childrenName) {
		childValue = groupValue[childrenName];
	}

	// child valid if not shown
	if (!isChildShown(groupValue, fieldName, childrenSchema)) {
		return true;
	}

	// if child shown child valid if not mandatory and empty
	if (!isChildShown(groupValue, fieldName, childrenSchema) || (!mandatory && !childValue)) {
		return true;
	}

	// if no validator, child valid if not mandatory or mandatory and has a value
	if (!validator) {
		return !mandatory || childValue;
	}

	return validator(childValue);
};

/**
 * Check if all children from groupData are valid depending of type of childrenValidation from form Structure
 * @param groupValue
 * @param schemaStructure
 * @returns {boolean}
 */
const isChildrenGroupValid = (groupValue, schemaStructure) => {
	const { fieldName, children, childrenValidatorType } = schemaStructure;
	let childrenNotValid = false;
	if (childrenValidatorType === 'all') {
		childrenNotValid =
			children &&
			children.some((childSchema) => !isChildFieldValid(groupValue, fieldName, childSchema));
	} else if (childrenValidatorType === 'one') {
		childrenNotValid =
			children &&
			!children.some((childSchema) => isChildFieldValid(groupValue, fieldName, childSchema));
	}
	return childrenNotValid;
};

/**
 * Check if groupData is valid
 * @param groupData
 * @param fieldName
 * @param content
 */
const isFieldValid = (groupData = {}, fieldName, content) => {
	const { fieldName: groupFieldName, mandatory, validator } = content;
	// field valid if not shown and if not mandatory and empty
	const data = groupData[groupFieldName];

	if (!mandatory && !data) {
		return true;
	}
	// if no validator, field valid if not mandatory or if mandatory and has a value
	if (!validator) {
		return !mandatory || data;
	}
	// If mandatory, check if not undefined or empty
	if (mandatory && (data === undefined || data === '')) {
		return false;
	}

	return validator(data);
};

export { isFieldValid, isChildFieldValid, isChildShown, isChildrenGroupValid };
