import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDriveLogById } from '../../drive-log/drive-log.services';
import { getMissionInfo } from '../../mission/mission.services';
import { getObservationById } from '../observation.services';

/**
 * Line number hook
 * @return {Array} line number, loading flag
 */
const useLine = () => {
	const { id: driveLogId } = useParams();
	const [line, setLine] = useState();
	const [lineLoading, setLineLoading] = useState(false);

	const getDLine = () => {
		if (driveLogId) {
			setLineLoading(true);
			getDriveLogById(driveLogId)
				.then((res) => {
					const { line_number, attachment_bigram } = res.data;
					if (attachment_bigram === 'TN') {
						setLine('-1');
					} else {
						setLine(line_number);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setLineLoading(false);
				});
		}
	};

	useEffect(getDLine, [driveLogId]);

	return [line, lineLoading];
};

/**
 * Mission code hook
 * @return {Array} mission code, loading flag
 */
const useMissionCode = () => {
	const { id: driveLogId, missionId } = useParams();
	const [missionCode, setMissionCode] = useState('');
	const [missionCodeLoading, setMissionCodeLoading] = useState(true);

	const getMissionCode = () => {
		if (missionId) {
			getMissionInfo(driveLogId, missionId)
				.then((res) => {
					setMissionCode(res?.data?.code);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setMissionCodeLoading(false);
				});
		}
	};

	useEffect(getMissionCode, [missionId, driveLogId]);

	return [missionCode, missionCodeLoading];
};

const useObservation = () => {
	const { id: driveLogId, observationId } = useParams();

	const [observation, setObservation] = useState({});
	const [loadingObservation, setLoadingObservation] = useState(true);

	useEffect(() => {
		if (driveLogId && observationId) {
			getObservationById(driveLogId, observationId)
				.then((res) => {
					setObservation(res?.data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setLoadingObservation(false);
				});
		}
	}, [driveLogId, observationId]);

	return [observation, loadingObservation];
};

export { useLine, useMissionCode, useObservation };
