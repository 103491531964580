/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
	createAttentionAlert,
	getAttentionAlertById,
	updateAttentionAlert,
} from '../../../attention-alert/attention-alert.services';
import { Button, InputField, Loader } from '../../../core';
import {
	ErrorMessage,
	MultiCheckboxField,
	MultiRadioField,
	MultiSearchField,
} from '../../../core/components';
import { getReferentialList } from '../../../core/core.services';
import { AuthContext } from '../../../user/auth/context/auth-context';
import { Card } from '../../components';
import attentionAlertFormConfig from './attention-alert-form.json';
import './attention-alert-form.scss';

const translationEntity = 'attention-alert';

const {
	station: stationConfig,
	tracks: tracksConfig,
	isAllServices: isAllServicesConfig,
	type: typeConfig,
	otherType: otherTypeConfig,
} = attentionAlertFormConfig;

const AttentionAlertForm = (props) => {
	const { t } = useTranslation(translationEntity);
	const navigate = useNavigate();
	const { id: attentionAlertId } = useParams();

	const { mode } = props;
	const editMode = mode === 'update';

	const { user = {} } = useContext(AuthContext);
	const [stations, setStations] = useState([]);
	// The state of the form components
	const [values, setValues] = useState({
		is_all_services: true,
		type: ['manualDrive'],
		tracks: [1, 2],
	});
	// An invalid property has a true value
	const [validator, setValidator] = useState({});
	const [isFormValid, setIsFormValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isReady, setIsReady] = useState(false);

	const fillForm = () => {
		getLineStations().then((lineStations) => {
			setStations(lineStations);

			if (editMode) {
				// Get the attention-alert entity and populate state values
				getAttentionAlertById(attentionAlertId).then((res) => {
					const { station_trigram, tracks, is_all_services, type, other_type } = res.data;

					setValues((prev) => ({
						...prev,
						tracks,
						is_all_services,
						type,
						other_type,
					}));

					if (station_trigram != null) {
						setValues((prev) => ({
							...prev,
							station: getStationByTrigram(lineStations, station_trigram),
						}));
					}

					setIsReady(true);
				});
			} else {
				setIsReady(true);
			}
		});
	};

	const getLineStations = () => {
		return getReferentialList('station').then((res) => {
			return res.data.filter((station) => {
				const { line: stationLine } = station;
				return stationLine === user.line;
			});
		});
	};

	const getStationByTrigram = (data, trigram) => {
		return data.find((station) => {
			const { trigram: stationTrigram } = station;
			return stationTrigram === trigram;
		});
	};

	const handleValidation = () => {
		setValidator((prev) => ({
			...prev,
			station: values.station == null,
			tracks: values.tracks == null || values.tracks?.length === 0,
			otherType:
				values.type[0] === 'other' && (values.other_type == null || values.other_type === ''),
		}));
	};

	const handleChange = (fieldName, value) => {
		const newValue = fieldName === 'type' ? [value] : value;
		setValues((prev) => {
			const updatedValues = { ...prev, [fieldName]: newValue };
			if (fieldName === 'type' && value !== 'other') {
				updatedValues.other_type = '';
			}
			return updatedValues;
		});
	};

	// Get data from form state & handle it before submit
	const handleSubmit = () => {
		const formData = {
			station: values.station.name,
			station_trigram: values.station.trigram,
			tracks: values.tracks,
			is_all_services: values.is_all_services,
			type: values.type,
			is_permanent: true,
		};

		if (values.type[0] === 'other') {
			formData.other_type = values.other_type;
		}

		setIsLoading(true);

		const handleData = editMode
			? () => updateAttentionAlert(attentionAlertId, formData)
			: () => createAttentionAlert(formData);

		handleData()
			.then((res) => {
				setIsLoading(false);
				navigate('/attention-alerts');
			})
			.catch(() => {
				// TODO: Display an error message
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fillForm();
	}, []);

	useEffect(() => {
		handleValidation();
	}, [values]);

	// Check if there are any invalid fields
	useEffect(() => {
		setIsFormValid(Object.keys(validator).filter((key) => validator[key]).length === 0);
	}, [validator]);

	return (
		<div className="attention-alert__form__container">
			<Loader isLoading={!isReady}>
				<Card index={1} title={t('attention-alert.forms.cards.broadcast-zone')}>
					<>
						<MultiSearchField
							className="attention-alert__form"
							translation={translationEntity}
							placeholders="attention-alert.forms.fields.placeholders"
							groupName="station"
							fieldNames={[stationConfig.fieldName]}
							options={stations}
							values={{
								station: values.station,
							}}
							handleChange={handleChange}
						/>

						{validator.station && (
							<ErrorMessage message={t('attention-alert.forms.errors.station.empty')} />
						)}
					</>

					<MultiCheckboxField
						className="attention-alert__form"
						translation={translationEntity}
						fieldName={tracksConfig.fieldName}
						options={user.line === 'B' ? tracksConfig.options_b : tracksConfig.options}
						values={values.tracks}
						handleChange={handleChange}
					/>
					{validator.tracks && (
						<ErrorMessage message={t('attention-alert.forms.errors.tracks.empty')} />
					)}
				</Card>

				<Card index={2} title={t('attention-alert.forms.cards.type')}>
					<MultiRadioField
						className="attention-alert__form"
						translation={translationEntity}
						fieldName={typeConfig.fieldName}
						options={typeConfig.options}
						value={values.type.length !== 1 ? false : values.type[0]}
						handleChange={handleChange}
					/>

					{values.type[0] === 'other' && (
						<>
							<div className="attention-alert__form__field__container">
								<InputField
									className="attention-alert__form__field__input"
									value={values.other_type}
									onChange={(value) => handleChange(otherTypeConfig.fieldName, value)}
									maxLength={otherTypeConfig.maxLength}
									placeholder={t('attention-alert.forms.fields.placeholders.other_type')}
									title={t('attention-alert.forms.fields.labels.other_type')}
								/>
							</div>

							{validator.otherType && (
								<ErrorMessage message={t('attention-alert.forms.errors.other_type.empty')} />
							)}
						</>
					)}
				</Card>

				<Card index={3} title={t('attention-alert.forms.cards.service')}>
					<MultiRadioField
						className="attention-alert__form"
						translation={translationEntity}
						fieldName={isAllServicesConfig.fieldName}
						options={isAllServicesConfig.options}
						value={values.is_all_services == null ? false : values.is_all_services}
						handleChange={handleChange}
					/>
				</Card>

				<div className="attention-alert__form__submit-block">
					<Button
						className="attention-alert__form__button--cancel button button--outline"
						onClick={() => navigate(-1)}
					>
						{t('attention-alert.forms.buttons.cancel')}
					</Button>
					<Button
						className="attention-alert__form__button--submit button"
						type="submit"
						disabled={!isFormValid || isLoading}
						onClick={() => handleSubmit()}
					>
						{editMode
							? t(`attention-alert.forms.buttons.${mode}`)
							: t('attention-alert.forms.buttons.submit')}
					</Button>
				</div>
			</Loader>
		</div>
	);
};

export default AttentionAlertForm;
