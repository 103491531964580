import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../auth/context/auth-context';
import {
	Button,
	Modal,
	Dropdown,
	Icon,
	ToggleSwitch,
	TwoChoicesButtons,
} from '../../../../../../core';
import linesIconName from '../../../../../utils/lines-icon-names.json';
import { updateUser } from '../../../../../user.services';
import './update-user-modal.scss';

/**
	Modal to display when an user row is clicked
	Only an admin connected-user can update an user via this modal
 **/
const UpdateUserModal = ({
	showModal,
	setShowModal,
	user,
	attachmentsList,
	refreshUsersList,
	adminRolesList,
	localAdminRolesList,
	getLineFromAttachment,
}) => {
	const { t } = useTranslation();

	const { user: currentUser } = useContext(AuthContext);
	const { role: currentUserRole, user: currentUserLine } = currentUser || {};
	const isLocalAdminRole = currentUserRole === 'localAdmin';

	const {
		active,
		id,
		line,
		role,
		attachment_code,
		first_name: firstName,
		last_name: lastName,
		digiplan_access,
		excel_access,
		mobile_access,
	} = user;
	const [userToUpdate, setUserToUpdate] = useState({});
	const [formValid, setFormValid] = useState(false);

	const toggleAccessList = [
		{ label: 'digiplan', userProperty: 'digiplan_access', display: true },
		{ label: 'excel', userProperty: 'excel_access', display: true },
		{ label: 'mobile', userProperty: 'mobile_access', display: true },
		{
			label: 'special-notice',
			userProperty: 'special_notice_access',
			display: userToUpdate.role === 'amp',
		},
	];

	useEffect(() => {
		setUserToUpdate({
			active,
			line,
			role,
			attachment_code,
			digiplan_access,
			excel_access,
			mobile_access,
		});
	}, [active, line, role, attachment_code, digiplan_access, excel_access, mobile_access]);

	const validateForm = (updatedUser) => {
		const { role: updatedRole, line: updatedLine, attachment_code: attachmentCode } = updatedUser;
		// attachment_code becomes empty if an user is hr, only the line is kept
		const fieldAttachmentValid = () => {
			if (updatedRole === 'hr' || updatedRole === 'localAdmin') {
				return updatedLine;
			} else {
				return attachmentCode;
			}
		};

		//if the user is amp psg then having a line equal to null is valid else it has to be A or B
		const fieldLineValid = attachmentCode === 'BPSG' && updatedLine === null ? true : updatedLine;
		return updatedRole && fieldAttachmentValid() && fieldLineValid;
	};

	const formValidation = () => {
		const isValid = validateForm(userToUpdate);
		setFormValid(isValid);
	};

	useEffect(formValidation, [userToUpdate, validateForm, formValidation]);

	const handleValidate = () => {
		updateUser(id, userToUpdate)
			.then(() => {
				setShowModal(false);
				refreshUsersList();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const closeCallback = () => {
		setShowModal(false);
		setUserToUpdate({
			active,
			line,
			role,
			attachment_code,
			digiplan_access,
			excel_access,
			mobile_access,
		});
	};

	const handleRoleChange = (newUserData) => {
		const isAdminRole = newUserData === 'admin' || newUserData === 'localAdmin';
		let next_digiplan_access = false;

		if (userToUpdate.active && (newUserData === 'driver' || newUserData === 'amp' || isAdminRole)) {
			next_digiplan_access = true;
		}

		if (newUserData === 'hr') {
			let userData = {
				...userToUpdate,
				role: newUserData,
				attachment_code: '',
				digiplan_access: next_digiplan_access,
			};
			if (isLocalAdminRole) {
				userData = { ...userData, line: currentUserLine };
			}
			setUserToUpdate({ ...userData });
		} else if (newUserData === 'localAdmin') {
			setUserToUpdate({
				...userToUpdate,
				role: newUserData,
				digiplan_access: next_digiplan_access,
				attachment_code: '',
			});
		} else {
			setUserToUpdate({
				...userToUpdate,
				role: newUserData,
				digiplan_access: next_digiplan_access,
			});
		}
	};

	const handleAttachmentChange = (newUserData) => {
		const newUserLine = getLineFromAttachment(newUserData);
		setUserToUpdate({ ...userToUpdate, attachment_code: newUserData, line: newUserLine });
	};

	const handleLineChange = (field) => (value) => {
		setUserToUpdate({ ...userToUpdate, [field]: value });
	};

	const handleActiveChange = (e) => {
		const { checked } = e.target;

		let next_digiplan_access;

		// Disabled access to dpln if user is blocked
		if (checked) {
			next_digiplan_access = false;
		} else {
			// Else enable access to dpln when user is enabled for specific role
			const isUserAdmin = userToUpdate?.role === 'admin' || userToUpdate?.role === 'localAdmin';
			next_digiplan_access =
				userToUpdate?.role === 'driver' || userToUpdate?.role === 'amp' || isUserAdmin;
		}

		setUserToUpdate({ ...userToUpdate, active: !checked, digiplan_access: next_digiplan_access });
	};

	const handleChange = (e) => {
		const { checked, name } = e.target;
		setUserToUpdate({ ...userToUpdate, [name]: !checked });
	};

	const displayLine = (lineChoice) => (
		<>
			<span className="two-choices__line--label">{t('user:modals.update-user.line')}</span>
			<Icon className="two-choices__line--icon" name={linesIconName[lineChoice]} />
		</>
	);

	const displayOptions = (selectedRole) => {
		if (
			selectedRole &&
			(selectedRole === 'hr' || selectedRole === 'localAdmin') &&
			!isLocalAdminRole
		) {
			return (
				<TwoChoicesButtons
					className="update-user-modal__content__block__two-choices"
					name="line"
					value={userToUpdate.line}
					onChange={handleLineChange('line')}
					label={t('user:modals.update-user.line')}
					firstOptionLabel={displayLine('A')}
					firstOptionValue="A"
					secondOptionLabel={displayLine('B')}
					secondOptionValue="B"
				/>
			);
		} else if (selectedRole && selectedRole !== 'hr' && selectedRole !== 'localAdmin') {
			//if the role of the updated user is not AMP, we shouldn't be able to select the PSG attachment
			const { BPSG, ...attachmentCodeWithoutPSG } = attachmentsList;
			const attachmentListByRole =
				userToUpdate?.role === 'amp' ? attachmentsList : attachmentCodeWithoutPSG;
			return (
				<Dropdown
					className={'update-user-modal__content__block__dropdown'}
					value={userToUpdate.attachment_code}
					onChange={handleAttachmentChange}
					label={t('user:modals.update-user.attachment')}
					dropdownList={attachmentListByRole}
					placeholder={t('user:modals.add-user.placeholder')}
				/>
			);
		}
	};

	return (
		showModal && (
			<Modal type="custom" className="update-user-modal">
				<div className="update-user-modal__content">
					<div onClick={closeCallback}>
						<Icon name="close" className="update-user-modal__content__close" />
					</div>
					<div className="update-user-modal__content__block">
						<div className="update-user-modal__content__block__title">
							{t('user:modals.update-user.title')}
						</div>
						<div className="update-user-modal__content_block__user-names">
							<span className="user-names_last">{lastName}</span>
							<span className="user-names_first">{firstName}</span>
						</div>
						<div
							className={
								'update-user-modal__content__sub-block update-user-modal__content__sub-block--underlined'
							}
						>
							<Dropdown
								className={'update-user-modal__content__block__dropdown'}
								value={userToUpdate.role}
								onChange={handleRoleChange}
								label={t('user:modals.update-user.role')}
								dropdownList={isLocalAdminRole ? localAdminRolesList : adminRolesList}
							/>

							{displayOptions(userToUpdate.role)}
						</div>

						<div className={'update-user-modal__content__sub-block'}>
							<div className="update-user-modal__content__block__toggle-status">
								<div className="toggle-status__label">{t('user:modals.update-user.status')}</div>
								<span className="toggle-status__value">
									{userToUpdate.active
										? t('user:modals.update-user.status-active')
										: t('user:modals.update-user.status-blocked')}
								</span>
								<ToggleSwitch
									className="toggle-status"
									isChecked={!userToUpdate.active}
									sliderClassName="toggle-status__slider"
									handleOnChange={handleActiveChange}
								/>
							</div>

							{toggleAccessList.map((toggle) => {
								const { label, userProperty, display } = toggle;
								return (
									<>
										{display && (
											<div className="update-user-modal__content__block__toggle">
												<div className="toggle-status__label">
													{t(`user:modals.update-user.${label}`)}
												</div>
												<span className="toggle-status__value">
													{userToUpdate[userProperty]
														? t('user:modals.update-user.access-granted')
														: t('user:modals.update-user.access-denied')}
												</span>
												<ToggleSwitch
													className="toggle-status"
													isChecked={!userToUpdate[userProperty]}
													sliderClassName="toggle-status__slider"
													handleOnChange={handleChange}
													name={userProperty}
												/>
											</div>
										)}
									</>
								);
							})}
						</div>
					</div>
					<div className="update-user-modal__content__btn">
						<Button
							className="update-user-modal__content__validate-btn"
							onClick={handleValidate}
							disabled={!formValid}
						>
							{t('user:modals.update-user.update-btn')}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
};

UpdateUserModal.propTypes = {
	showModal: PropTypes.bool,
	attachmentsList: PropTypes.object,
	getLineFromAttachment: PropTypes.func,
	refreshUsersList: PropTypes.func,
	adminRolesList: PropTypes.object,
	localAdminRolesList: PropTypes.object,
};

export default UpdateUserModal;
