import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../core';

const ValidateSimpleDlsButton = (props) => {
	const { validateAllSimpleDriveLogs, resultCount } = props;
	const { t } = useTranslation('dl');
	const [showModal, setShowModal] = useState(false);

	const modalText = t('dl:supervisor-dl-list-page.validate-simple-drivelogs', {
		count: resultCount,
	});

	const validateDrivelogs = () => {
		setShowModal(false);
		validateAllSimpleDriveLogs();
	};

	const cancelCallback = () => {
		setShowModal(false);
	};

	return (
		<>
			{showModal && (
				<Modal
					type="confirm"
					title={modalText}
					confirmCallback={validateDrivelogs}
					cancelCallback={cancelCallback}
					closeCallback={cancelCallback}
				/>
			)}
			<button className="button" onClick={() => setShowModal(true)}>
				{t('supervisor-dl-list-page.validate-number-dl', { count: resultCount })}
			</button>
		</>
	);
};

ValidateSimpleDlsButton.propTypes = {
	validateAllSimpleDriveLogs: PropTypes.func,
	resultCount: PropTypes.number,
};

export default ValidateSimpleDlsButton;
