import { useLocation, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppEnv } from '../../../../../../../config';
import { getDataFromLocalStorage } from '../../../../../../../config/offline/dispatch-get-request';
import { Loader, PopupFullPage } from '../../../../../../core';
import EmptySplash from '../../../../../../core/assets/images/empty-splash.svg';
import { APP_NAME } from '../../../../../../core/global-app/global-app-constant';
import { GlobalAppContext } from '../../../../../../core/global-app/global-app-context';
import 'pdfjs-dist/web/pdf_viewer.css';
import './document-pdf-screen.scss';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

const DocumentPdfScreen = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const env = useAppEnv();
	const { fromApp, setFromApp } = useContext(GlobalAppContext);

	const [isLoading, setIsLoading] = useState(true);
	const [pdfDocument, setPdfDocument] = useState(null);
	const containerRef = useRef(null);
	const isMounted = useRef(true);
	const [isMobileDevice, setIsMobileDevice] = useState(false);

	const { id: driveLogId, key: keyFile } = useParams();
	const queryParams = new URLSearchParams(location.search);
	const fromApplication = queryParams.get('fromApplication');
	const isTechnicalManual = /technical_manual/.test(keyFile);

	const titlePdfFile = useMemo(() => {
		if (/roadbook/.test(keyFile)) {
			return t('dl:dl-documents.roadbook');
		} else if (/driving_guide/.test(keyFile)) {
			return t('dl:dl-documents.driving-guide');
		}
		return t('dl:dl-documents.technical-manual.title');
	}, [keyFile, t]);

	const getBackLink = () => {
		if (fromApp === APP_NAME.DIGIPLAN) {
			return `${env?.DIGIPLAN_APP_SCHEME}://Drive`;
		}
		return `/drive-log/${driveLogId}/`;
	};

	const getUserDevice = () => {
		const userAgent = navigator.userAgent.toLowerCase();
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			userAgent,
		);
		setIsMobileDevice(isMobile);
	};

	const getPdfDocumentUrl = useCallback(async () => {
		try {
			const docPdfImage = await getDataFromLocalStorage(keyFile);
			const { data } = docPdfImage;

			if (data?.size > 0) {
				const arrayBuffer = await data.arrayBuffer();
				const loadingTask = pdfjsLib.getDocument({ data: arrayBuffer });
				const pdf = await loadingTask.promise;
				if (isMounted.current) {
					setPdfDocument(pdf);
					return pdf;
				}
			}
		} catch (error) {
			console.error('Error fetching PDF document:', error);
		} finally {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	}, [keyFile]);

	const renderPdfPages = useCallback(
		async (pdf) => {
			const PdfContainer = containerRef.current;
			if (!PdfContainer || !pdf) return;

			PdfContainer.innerHTML = '';
			const pdfPagesNumber = isTechnicalManual ? 1 : pdf.numPages;
			for (let i = 1; i <= pdfPagesNumber; i++) {
				try {
					const page = await pdf.getPage(i);
					const scale = isTechnicalManual ? 2 : 1;
					const viewport = page.getViewport({ scale });

					const canvas = document.createElement('canvas');
					const context = canvas.getContext('2d');
					canvas.width = viewport.width;
					canvas.height = viewport.height;
					PdfContainer.appendChild(canvas);

					await page.render({ canvasContext: context, viewport }).promise;
				} catch (error) {
					console.error(`Error rendering page ${i}:`, error);
				}
			}
		},
		[isTechnicalManual],
	);

	useEffect(() => {
		isMounted.current = true;
		const PdfContainer = containerRef.current;

		getPdfDocumentUrl().then(async (pdf) => {
			if (pdf && isMounted.current) {
				await renderPdfPages(pdf);
				setIsLoading(false);
			}
		});
		return () => {
			isMounted.current = false;
			if (PdfContainer) {
				while (PdfContainer.firstChild) {
					PdfContainer.removeChild(PdfContainer.firstChild);
				}
			}
		};
	}, [getPdfDocumentUrl, renderPdfPages]);

	useEffect(() => {
		if (fromApplication === APP_NAME.DIGIPLAN) {
			setFromApp(APP_NAME.DIGIPLAN);
		}
	}, [fromApplication, setFromApp]);

	useEffect(getUserDevice, []);

	const renderPdfDocument = () => {
		if (!pdfDocument) {
			return (
				<div className="document-pdf-screen__content__no-result">
					<h1 className="no-result__title">{t('dl:dl-documents.no-assigned-pdf-document')}</h1>
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			);
		}

		return (
			<div
				ref={containerRef}
				className={`document-pdf-screen__content__result document-pdf-screen__content__result__${
					isTechnicalManual || (isMobileDevice && isTechnicalManual)
						? 'row-layout'
						: 'column-layout'
				}`}
			/>
		);
	};

	return (
		<PopupFullPage className="document-pdf-screen" title={titlePdfFile} backLink={getBackLink()}>
			<div className="document-pdf-screen__content">
				<Loader isLoading={isLoading}>{renderPdfDocument()}</Loader>
			</div>
		</PopupFullPage>
	);
};

export default DocumentPdfScreen;
