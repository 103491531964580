import http from '../axios';
import localForage from '../local-forage';
import checkNetwork from './check-network';
import { storePostPending } from './synchronize-storage';

/**
 * Posts data to API and remove from local storage
 * @param {string} url - url to call
 * @param {Object} data - data to post
 * @return {Promise<Object>}
 */
const postDataToApi = async (url, data) => await http.post(url, data);

/**
 * Saves data to local storage
 * @param {string} key - local storage key
 * @param {string} url - request url
 * @param {Object} data - data to post
 * @param {string} [itemKey] - item key if it exists
 * @param {string} [id] - item id if it exists
 * @return {Promise<void>}
 */
const saveDataToLocalStorage = async (key, url, data) => {
	const newData = { ...data, dblCache: true, dblUrl: url, dblMethod: 'POST' };
	// update local storage
	const cache = await localForage.getItem(key);
	if (cache) {
		const { data: storedData, cacheDate } = cache;
		const postArray = storedData?.length > 0 ? [...storedData, newData] : [newData];
		postArray.sort((a, b) => new Date(a.hour_start) - new Date(b.hour_start));
		await localForage.setItem(key, { data: postArray, cacheDate });
	} else {
		await localForage.setItem(key, { data: [newData], cacheDate: new Date() });
	}

	// mark the data as pending
	await storePostPending(key);
};

/**
 * Dispatch post request to either local storage or to API
 * @param {string} key
 * @param {string} url
 * @param {Object} data
 * @param {string} [itemKey]
 * @param {string} [id]
 * @return {Promise<Object|void>}
 */
const dispatchPostRequest = async (key, url, data) => {
	const online = await checkNetwork();

	if (online) {
		const storedData = await localForage.getItem(key);
		if (!storedData || !storedData.data) {
			throw new Error('No data found in localForage');
		}
		const updatedData = [...storedData.data, data];
		updatedData.sort((a, b) => new Date(a.hour_start) - new Date(b.hour_start));
		await localForage.setItem(key, { ...storedData, data: updatedData });
		return await postDataToApi(url, data);
	} else return saveDataToLocalStorage(key, url, data);
};

export { postDataToApi, saveDataToLocalStorage };
export default dispatchPostRequest;
