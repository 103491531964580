import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, DriverPage, Loader } from '../../../core';
import EmptySplash from '../../../core/assets/images/empty-splash.svg';
import useStoredFilter from '../../../core/custom-hooks/use-stored-filter';
import { getAttentionAlerts } from '../../attention-alert.services';
import { AttentionAlertListHeader, AttentionAlertListRow } from '../../components';
import './attention-alerts-page.scss';

const translationEntity = 'attention-alert';

const AttentionAlertsPage = () => {
	const { t } = useTranslation(translationEntity);

	const attentionAlertPageUrl = '/attention-alert';

	const {
		data: attentionAlerts,
		refresh: refreshAttentionAlerts,
		isLoading,
	} = useStoredFilter({
		storageName: 'ampAttentionAlerts',
		getData: () => getAttentionAlerts(),
	});

	const renderList = () => {
		if (
			!Array.isArray(attentionAlerts) ||
			(Array.isArray(attentionAlerts) && attentionAlerts?.length === 0)
		) {
			return (
				<div className="empty-dl-list__content">
					<h1 className="empty-dl-list__content__title">
						{t('attention-alert.list.no-attention-alert')}
					</h1>
					<div
						className="empty-dl-list__empty-splash"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			);
		}

		return (
			<div className="attention-alerts-page__list">
				<AttentionAlertListHeader />
				{attentionAlerts && attentionAlerts.map(renderRows)}
			</div>
		);
	};

	const renderRows = (attentionAlert) => (
		<AttentionAlertListRow
			attentionAlert={attentionAlert}
			key={attentionAlert.id}
			refreshAttentionAlerts={refreshAttentionAlerts}
		/>
	);

	return (
		<DriverPage selectedLink={3} className="attention-alerts-page">
			<div className="attention-alerts-page__header">
				<div className="attention-alerts-page__header__title">Alertes d'attention permanentes</div>
				<Link className="attention-alerts-page__header__link" to={`${attentionAlertPageUrl}/add`}>
					<Button className="attention-alerts-page__header__button button">
						{t('attention-alert.list.create-btn')}
					</Button>
				</Link>
			</div>

			<Loader isLoading={isLoading}>{renderList()}</Loader>
		</DriverPage>
	);
};

export default AttentionAlertsPage;
