import { http } from '../../../config';

/**
 * get all nudges
 * @param {object} config Configuration for the get nudges request
 * @returns {Promise} response
 */
const getNudges = async (config) => {
	const { status, search, searchOptions, sortBy } = config;
	const params = { status, search, sortBy, searchOptions: searchOptions };
	return await http.get('/nudge', { params });
};

/**
 * get a nudge by its id
 * @param {String} id the id of the nudge to get
 * @returns {Promise} response
 */
const getNudgeById = async (id) => {
	return await http.get(`/nudge/${id}`);
};

/**
 * create a new nudge
 * @param {object} nudge the nudge object
 * @returns {Promise} response
 */
const createNudge = async (nudge) => {
	return await http.post('/nudge', nudge);
};

/**
 * update the nudge of the given id
 * @param {string} id the id of nudge to update
 * @param {object} nudge the nudge object
 * @param {object} params parameters
 * @returns {Promise} response
 */
const updateNudge = async (id, nudge, params = {}) => {
	return await http.put(`/nudge/${id}`, nudge, { params });
};

export { createNudge, getNudgeById, getNudges, updateNudge };
