import React, { createContext, useEffect, useState } from 'react';
import { checkFilterValidity } from '../utils/stored-filter/get-stored-filter';
import { subscribeNotificationPush } from './notification-utils';
import { useAppEnv } from '../../../config';
import {
	pushNotificationSubscription,
	removeNotificationSubscription,
} from './notification.services';

const NotificationContext = createContext({
	isNotificationActive: false,
	notificationConfig: {},
	saveNotificationConfig: () => null,
	isServiceDL: false,
	checkDriveLogType: () => null,
	activateAlert: () => null,
	removeAlert: () => null,
});

const notificationStorageName = 'notification-alert';

/**
 * Notification provider to keep all information relative to notification inside this context
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationProvider = (props) => {
	const { children } = props;
	const { NOTIFICATION_PUBLIC_KEY } = useAppEnv();

	// notification variables
	const [notificationConfig, setNotificationConfig] = useState(
		JSON.parse(localStorage.getItem(notificationStorageName)) || {},
	);
	const [isNotificationActive, setNotificationActive] = useState(false);
	const [driveLogInfo, setDriveLogInfo] = useState({});
	const { isServiceDl = false } = driveLogInfo;

	// update notification Config and save it in local storage for retrieve the information on refresh
	const saveNotificationConfig = (newConfig) => {
		localStorage.setItem(notificationStorageName, JSON.stringify(newConfig));
		setNotificationConfig(newConfig);
	};

	// check if drive log is automatic or manuel one
	const checkDriveLogType = (driveLog) => {
		const { is_empty: isEmpty = true, id } = driveLog;
		setDriveLogInfo({ id, isServiceDl: !isEmpty });
	};

	const activateAlert = async (params) => {
		const { releve, degarage } = params;
		try {
			const subscription = await subscribeNotificationPush(NOTIFICATION_PUBLIC_KEY);
			if (subscription) {
				await pushNotificationSubscription(driveLogInfo?.id, subscription, { releve, degarage });
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	const removeAlert = async () => {
		await removeNotificationSubscription();
	};

	// when config change, check if notification is still active depending on user's choice and date change
	const checkNotificationActive = () => {
		const { active = false, date } = notificationConfig;
		const dateValid = !checkFilterValidity(date);
		setNotificationActive(active && dateValid);
	};

	useEffect(checkNotificationActive, [notificationConfig]);

	return (
		<NotificationContext.Provider
			value={{
				isNotificationActive,
				notificationConfig,
				saveNotificationConfig,
				isServiceDl,
				checkDriveLogType,
				activateAlert,
				removeAlert,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export { NotificationContext, NotificationProvider };
