import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../core';
import './feedbacks-filters.scss';
import { format } from 'date-fns';
import FeedbacksExportBtn from '../feedbacks-export-btn/feedbacks-export-btn';
import PropTypes from 'prop-types';

const FeedbacksFilters = ({
	filteredFeedbacks,
	onDateChange,
	feedbackFilters,
	setFeedbackFilters,
}) => {
	const { t } = useTranslation('users-feedback');
	const { startDate, endDate } = feedbackFilters;
	const [isOpenStartDate, setIsOpenStartDate] = useState(false);
	const [isOpenEndDate, setIsOpenEndDate] = useState(false);

	const handleDateChange = (name, value) => {
		setFeedbackFilters((previousState) => ({ ...previousState, [name]: value }));
		onDateChange(name, value);
	};

	const formattedFeedbacks = filteredFeedbacks?.map((feedback) => ({
		...feedback,
		created_at: format(new Date(feedback.created_at), 'dd/MM/yyyy HH:mm'),
	}));

	return (
		<div className="feedbacks-filters__container">
			<div className="feedbacks-filters__container__dates">
				<div className="feedbacks-filters_dates">
					<div className="feedbacks-filters__wrapper">
						<span className="feedbacks-filters__wrapper__title">
							{t('feedback-page.filter.start-date')}
						</span>
						<div className="feedbacks-filters__date">
							<DatePicker
								selected={startDate}
								onChange={(date) => {
									handleDateChange('startDate', date);
									setIsOpenStartDate(false);
								}}
								placeholderText={t('feedback-page.filter.start-date')}
								dateFormat="dd/MM/yyyy"
								className="feedbacks-filters__date-picker"
								locale="fr"
								onClickOutside={() => setIsOpenStartDate(false)}
								open={isOpenStartDate}
								onFocus={() => setIsOpenStartDate(true)}
								popperPlacement="top-start"
							/>
							{feedbackFilters.startDate && (
								<Icon
									className="custom-date-picker-filter__close_icon"
									name="close"
									onClick={() => handleDateChange('startDate', null)}
								/>
							)}
							<Icon
								className="custom-date-picker-filter__calendar-icon"
								name="calendar"
								onClick={() => setIsOpenStartDate(true)}
							/>
						</div>
					</div>
					<div className="feedbacks-filters__wrapper">
						<span className="feedbacks-filters__wrapper__title">
							{t('feedback-page.filter.end-date')}
						</span>
						<div className="feedbacks-filters__date">
							<DatePicker
								selected={endDate}
								onChange={(date) => {
									handleDateChange('endDate', date);
									setIsOpenEndDate(false);
								}}
								placeholderText={t('feedback-page.filter.end-date')}
								dateFormat="dd/MM/yyyy"
								className="feedbacks-filters__date-picker"
								locale="fr"
								onClickOutside={() => setIsOpenEndDate(false)}
								open={isOpenEndDate}
								onFocus={() => setIsOpenEndDate(true)}
								minDate={startDate}
								popperPlacement="top-start"
							/>
							{feedbackFilters.endDate && (
								<Icon
									className="custom-date-picker-filter__close_icon"
									name="close"
									onClick={() => handleDateChange('endDate', null)}
								/>
							)}
							<Icon
								className="custom-date-picker-filter__calendar-icon"
								name="calendar"
								onClick={() => setIsOpenEndDate(true)}
							/>
						</div>
					</div>
				</div>

				{formattedFeedbacks.length > 0 && (
					<FeedbacksExportBtn filteredFeedbacks={filteredFeedbacks} />
				)}
			</div>
		</div>
	);
};

FeedbacksFilters.propTypes = {
	filteredFeedbacks: PropTypes.object.isRequired,
	onDateChange: PropTypes.func.isRequired,
	feedbackFilters: PropTypes.object.isRequired,
	setFeedbackFilters: PropTypes.func.isRequired,
};

export default FeedbacksFilters;
