import { http } from '../../config';

/**
 * get all feedbacks
 * @returns {Promise} response
 */
const getFeedbacks = async () => {
	return http.get('/feedback');
};

export { getFeedbacks };
