import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from '../../../core';
import Icon from '../../../core/components/icon-svg/icon';
import './special-notice-details-modal.scss';

const broadcastTypes = {
	Station: 'station',
	Zone: 'zone',
	Line: 'line',
};

const SpecialNoticeDetailsModal = (props) => {
	const { showModal, setShowModal, values, enclosed } = props;
	const { t } = useTranslation('special-notice');

	const cancelCallback = () => {
		setShowModal(false);
	};

	const renderBroadcastZone = () => {
		return (
			<p>
				{values.broadcast_type === broadcastTypes.Station && (
					<span className="special-notice-details-modal__container__content__block--bold">
						{values.broadcast_station}
					</span>
				)}
				{values.broadcast_type === broadcastTypes.Zone && (
					<span className="special-notice-details-modal__container__content__block">
						<Trans
							i18nKey="special-notice:special-notice.modals.details.between-stations"
							parent="p"
						>
							<span className="special-notice-details-modal__container__content__block--bold">
								{{ start: values.broadcast_station_start }}
							</span>
							<span className="special-notice-details-modal__container__content__block--bold">
								{{ end: values.broadcast_station_end }}
							</span>
						</Trans>
					</span>
				)}
				{values.broadcast_type === broadcastTypes.Line && (
					<span className="special-notice-details-modal__container__content__block--bold">
						{t('special-notice.modals.details.whole-line')}
					</span>
				)}
			</p>
		);
	};

	const renderTracks = () => {
		const { tracks = [] } = values;

		return (
			<p className="special-notice-details-modal__container__content__block__sub-content">
				{tracks.length === 2 && <span>{t('special-notice.modals.details.tracks')}</span>}
				{tracks.length === 1 && (
					<Trans i18nKey="special-notice:special-notice.modals.details.track" parent="p">
						{{ track: tracks[0] }}
					</Trans>
				)}
			</p>
		);
	};

	const renderMissions = () => {
		const { is_all_missions = true, missions = [] } = values;

		if (is_all_missions || !missions) {
			return <span>{t('special-notice.modals.details.all-missions')}</span>;
		}

		return (
			<span className="special-notice-details-modal__container__content__block__missions">
				{missions.map((mission) => (
					<span className="special-notice-details-modal__container__content__block__missions__item">
						{mission}
					</span>
				))}
			</span>
		);
	};

	const renderSchedule = () => {
		const { is_not_scheduled = true, schedule_start, schedule_end } = values;

		if (is_not_scheduled) {
			return null;
		}

		const start = new Date(schedule_start);
		const end = new Date(schedule_end);

		return renderScheduleData(start, end);
	};

	const renderScheduleData = (start, end) => {
		return (
			<span className="special-notice-details-modal__container__content__block__schedule">
				<span className="special-notice-details-modal__container__content__block__schedule__start">
					{start.toLocaleString([], { dateStyle: 'short' })} à{' '}
					{start.toLocaleString([], { timeStyle: 'short' })}
				</span>
				<Icon
					name="arrow-right"
					className="special-notice-details-modal__container__content__block__schedule__icon"
				/>
				<span className="special-notice-details-modal__container__content__block__schedule__start">
					{end.toLocaleString([], { dateStyle: 'short' })} à{' '}
					{end.toLocaleString([], { timeStyle: 'short' })}
				</span>
			</span>
		);
	};

	return (
		showModal && (
			<Modal type="custom" className="special-notice-details-modal">
				<button className="special-notice-details-modal__icon" onClick={cancelCallback}>
					<Icon name="close" />
				</button>

				<div className="special-notice-details-modal__container">
					<div className="special-notice-details-modal__container__header">
						<p className="special-notice-details-modal__container__header__title">
							{t('special-notice.modals.details.title')}
						</p>
						<Icon className="special-notice-details-modal__container__header__icon" name="notice" />
					</div>

					<div className="special-notice-details-modal__container__content">
						<p className="special-notice-details-modal__container__content--row">
							<div className="special-notice-details-modal__container__content--left">
								<p className="special-notice-details-modal__container__content__title special-notice-details-modal__container__content__title--no-margin">
									{t('special-notice.modals.details.broadcast-zone')}
								</p>
								<p className="special-notice-details-modal__container__content__block">
									{renderBroadcastZone()}
									{renderTracks()}
								</p>
							</div>

							{!enclosed && (
								<div className="special-notice-details-modal__container__content--right">
									<Link to={`/special-notice/${values.id}/edit`}>
										<Button className="special-notice__form__button--cancel button button--outline">
											{t('special-notice.modals.details.edit')}
										</Button>
									</Link>
								</div>
							)}
						</p>

						<p className="special-notice-details-modal__container__content__title">
							{t('special-notice.modals.details.missions')}
						</p>
						<p className="special-notice-details-modal__container__content__block">
							{renderMissions()}
						</p>

						{!values.is_not_scheduled && (
							<p className="special-notice-details-modal__container__content__title">
								{t('special-notice.modals.details.schedule')}
							</p>
						)}
						<p className="special-notice-details-modal__container__content__block">
							{renderSchedule()}
						</p>

						<p className="special-notice-details-modal__container__content__title">
							{t('special-notice.modals.details.message')}
						</p>
						<p className="special-notice-details-modal__container__content__block">
							<p className="special-notice-details-modal__container__content__block__title">
								{values.title}
							</p>
							<p className="special-notice-details-modal__container__content__block__message">
								{values.message}
							</p>
						</p>
					</div>
				</div>
			</Modal>
		)
	);
};

export default SpecialNoticeDetailsModal;
