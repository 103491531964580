import PropTypes from 'prop-types';
import React from 'react';
import DriveLogTableHeaders from './drive-log-table-headers/drive-log-table-headers';
import DriveLogTableRow from './drive-log-table-row/drive-log-table-row';
import './drive-log-table.scss';

const DriveLogTable = (props) => {
	const {
		driveLogs,
		type = '',
		driverView = false,
		consultantView = false,
		ampValidatedView = false,
		displayDriveLogStatus = false,
		isSimpleDL = false,
		className = '',
		refresh,
	} = props;

	const generateRows = (driveLog) => (
		<DriveLogTableRow
			className="drive-log-list__row"
			driveLog={driveLog}
			type={type}
			key={driveLog.id}
			driverView={driverView}
			consultantView={consultantView}
			ampValidatedView={ampValidatedView}
			displayDriveLogStatus={displayDriveLogStatus}
			refresh={refresh}
			isSimpleDL={isSimpleDL}
		/>
	);

	return (
		<ul className={`dl-table ${className}`}>
			<DriveLogTableHeaders
				type={type}
				driverView={driverView}
				consultantView={consultantView}
				ampValidatedView={ampValidatedView}
				displayDriveLogStatus={displayDriveLogStatus}
			/>
			{Array.isArray(driveLogs) && driveLogs?.length > 0 && driveLogs.map(generateRows)}
		</ul>
	);
};

DriveLogTable.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	driveLogs: PropTypes.array,
	driverView: PropTypes.bool,
	consultantView: PropTypes.bool,
	ampValidatedView: PropTypes.bool,
	refresh: PropTypes.func,
	displayDriveLogStatus: PropTypes.bool,
};

export default DriveLogTable;
