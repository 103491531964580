import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '../../index';
import './two-choices-buttons.scss';

/**
 * Renders an <TwoChoicesButtons /> component
 * @param {Object} props
 * @param {string} props.className
 * @param {string} props.label
 * @param {string} props.value
 * @param {string} props.firstOptionLabel
 * @param {string} props.firstOptionValue
 * @param {string} props.secondOptionLabel
 * @param {string} props.secondOptionValue
 * @param {function} props.onChange
 */
const TwoChoicesButtons = (props) => {
	const {
		className = '',
		label,
		value,
		onChange,
		name,
		firstOptionLabel,
		firstOptionValue,
		secondOptionLabel,
		secondOptionValue,
	} = props;

	return (
		<div className={`two-choices-buttons ${className}`}>
			<label className="two-choices-buttons__label">{label}</label>
			<div className="two-choices-buttons__choices">
				<RadioButton
					name={name}
					labelId={firstOptionValue}
					className="two-choices-buttons__choices__radio"
					checked={value === firstOptionValue}
					onChange={() => onChange(firstOptionValue)}
					label={firstOptionLabel}
					showRadioTick
				/>
				<RadioButton
					name={name}
					labelId={secondOptionValue}
					className="two-choices-buttons__choices__radio"
					checked={value === secondOptionValue}
					onChange={() => onChange(secondOptionValue)}
					label={secondOptionLabel}
					showRadioTick
				/>
			</div>
		</div>
	);
};

TwoChoicesButtons.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,
	firstOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	firstOptionValue: PropTypes.string,
	secondOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	secondOptionValue: PropTypes.string,
	onChange: PropTypes.func,
};

export default TwoChoicesButtons;
