import { useContext, useEffect } from 'react';
import { AuthContext } from '../../user/auth/context/auth-context';
import { MATOMO_CATEGORIES, MATOMO_ACTIONS } from './matomo-constants';
import useMatomoTracker from './matomo-tracker';

/**
 * Retrieve the last authentication date and check if it expired
 * @returns {boolean}
 */
const isTrackerExpired = () => {
	const currentDate = new Date();
	const lastAuth = localStorage.getItem('matomo-last-authentication');

	// If no last authentication record, enable tracking
	if (!lastAuth) {
		return true;
	}

	// If the last authentication happened between 00:00 and 03:00, it expires at 03:00
	// If it happened after 03:00, it should expire the next day at 03:00
	let lastAuthExpiresAt = new Date(lastAuth);
	lastAuthExpiresAt.setHours(3, 0, 0, 0);
	if (currentDate.getHours() > 3) {
		lastAuthExpiresAt.setDate(currentDate.getDate() + 1);
	}

	// If the expiration date has passed, enable tracking
	return currentDate > lastAuthExpiresAt;
};

const MatomoContainer = () => {
	const { user = {} } = useContext(AuthContext);
	const { initMatomoTracker, trackEvent } = useMatomoTracker();

	// Initialize Matomo tracker
	useEffect(() => {
		initMatomoTracker();
	}, [initMatomoTracker]);

	// Track Login event if expired
	useEffect(() => {
		const trackerExpired = isTrackerExpired();
		const { attachment_code: attachmentCode } = user;
		const isNotPSG = attachmentCode !== 'BPSG';

		// whether the last authentication tracker has expired
		// exclude PSG users
		if (trackerExpired && isNotPSG) {
			trackEvent({ category: MATOMO_CATEGORIES.AUTH, action: MATOMO_ACTIONS.LOGIN, value: 1 });
			localStorage.setItem('matomo-last-authentication', new Date());
		}
	}, [initMatomoTracker, trackEvent, user]);

	return null;
};

export default MatomoContainer;
