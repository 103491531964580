import http from '../axios';

/**
 * Check if application is online, display an offline message in console otherwise
 * @return boolean
 */
const checkNetwork = async () => {
	try {
		const ping = await http.get('/status', { timeout: 1000 });
		const connection =
			navigator.connection || navigator.mozConnection || navigator.webkitConnection;
		if (connection) {
			const { downlink, effectiveType } = connection;
			console.log('downlink', downlink);
			// Check if connection is slow
			if (effectiveType === '2g' || effectiveType === 'slow-2g') {
				console.log('Connection is slow');
				return false;
			}
		}
		return ping?.status === 200 ? true : false;
	} catch (err) {
		console.info('Application is offline');
		return false;
	}
};

export default checkNetwork;
