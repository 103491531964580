import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../icon-svg/icon';
import PropTypes from 'prop-types';

const ErrorModal = (props) => {
	const { t } = useTranslation();
	const { text = '', closeCallback = null, btnName = t('core:modal.ok') } = props;

	return (
		<div className="confirm-modal">
			<div onClick={closeCallback}>
				<Icon name="close" className="confirm-modal__close-icon" />
			</div>
			<p className="confirm-modal__title">{text}</p>

			<div className="confirm-modal__controls">
				<button className="confirm-modal__confirm-btn button" onClick={closeCallback}>
					{t(btnName)}
				</button>
			</div>
		</div>
	);
};

ErrorModal.propTypes = {
	text: PropTypes.string,
	closeCallback: PropTypes.func,
	btnName: PropTypes.string,
};

export default ErrorModal;
