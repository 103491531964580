import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../core';
import SurveyForm from '../../components/survey-form/survey-form';
import SurveyHeader from '../../components/survey-form/survey-header/survey-header';
import { getSurveyById } from '../../survey.services';

const ReadSurvey = () => {
	const { driveLogId, surveyId } = useParams();

	const [survey, setSurvey] = useState({});
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const getBackLink = () => {
		return `/drive-log/${driveLogId}/details`;
	};

	const retrieveSurveyInfo = useCallback(() => {
		getSurveyById(driveLogId, surveyId)
			.then((res) => {
				setSurvey(res?.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId, surveyId]);

	useEffect(retrieveSurveyInfo, [retrieveSurveyInfo]);

	return (
		<PopupFullPage title={t('survey:survey.title')} backLink={getBackLink()}>
			<Loader isLoading={loading}>
				<SurveyHeader
					observationType={survey.observation_type}
					observationContent={survey.observation_content}
					missionCode={survey.mission_code}
				/>
				<SurveyForm
					handleCancelForm={() => navigate(-1)}
					readOnly
					action="back-to-drivelog"
					surveyToEdit={survey}
				/>
			</Loader>
		</PopupFullPage>
	);
};

export default ReadSurvey;
