import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '../../../../core';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../core/components/icon-svg/icon';
import './survey-modal.scss';

const SurveyModal = (props) => {
	const {
		showModal,
		toggleModal,
		confirmCallback,
		cancelCallback = () => null,
		modalText,
		modalSubText = '',
	} = props;

	const { t } = useTranslation();

	const onConfirmCallback = () => {
		toggleModal(!showModal);
		confirmCallback();
	};

	const onCancelCallback = () => {
		toggleModal(!showModal);
		cancelCallback();
	};

	return (
		showModal && (
			<Modal type="custom">
				<div>
					<button className="survey-modal__icon" onClick={onCancelCallback}>
						<Icon name="close" />
					</button>
					<div className="survey-modal__content__container">
						<p className="survey-modal__content">{modalText}</p>
						<p className="survey-modal__sub-content">{modalSubText}</p>
					</div>
					<div className="survey-modal__button__container">
						<Button className="button--outline" onClick={onCancelCallback}>
							{t('survey:survey-modal.button.Cancel')}
						</Button>
						<Button className="button" onClick={onConfirmCallback}>
							{t('survey:survey-modal.button.Pass')}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
};

SurveyModal.propTypes = {
	modalText: PropTypes.string,
	modalSubText: PropTypes.string,
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
};

export default SurveyModal;
