import {
	validateDelay,
	validateReason,
} from '../../components/observation-form/observation-validators';

const cleanliness = [
	{
		fieldName: 'car',
		mandatory: true,
	},
	{
		fieldName: 'signalDetail',
		mandatory: true,
		group: [
			{
				fieldName: 'signalDetailComment',
				validator: validateReason,
				mandatory: true,
			},
			{
				fieldName: 'signalDetailFiles',
				mandatory: false,
			},
		],
	},
	{
		fieldName: 'signalTransmission',
		mandatory: true,
		children: [
			{
				fieldName: 'pcc',
				parentValue: 'yes',
				lines: ['B', '-1'],
			},
		],
	},
	{
		fieldName: 'dateTime',
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
	},
];

export default cleanliness;
