import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from '../../icon-svg/icon';
import './popup-full-page-header.scss';

/**
 * Renders a <PopupFullPageHeader /> component
 * @param {object} props
 * @param {string} props.title - the title of the header derived from provided props
 * @param {string} props.backLink - the url to return to when the close button is clicked
 */
const PopupFullPageHeader = (props) => {
	const { title, subHeader, backLink = -1 } = props;
	const navigate = useNavigate();
	// Detect if external protocol
	const isExternalBackLink = /.+:\/\/.+/.test(backLink);

	return (
		<header className="pfp__header">
			{isExternalBackLink
				? title !== 'Ma fiche ligne' && (
						<a href={backLink} className="pfp__header__link">
							<Icon aria-label="Close" className="pfp__header__link__icon-close" name="close" />
						</a>
				  )
				: title !== 'Ma fiche ligne' && (
						<button onClick={() => navigate(backLink)} className="pfp__header__link">
							<Icon aria-label="Close" className="pfp__header__link__icon-close" name="close" />
						</button>
				  )}
			<div
				className="pfp__subheader"
				style={title !== 'Ma fiche ligne' ? { marginRight: 'Auto' } : {}}
			>
				<h2 className="pfp__header__title">{title}</h2>
				{subHeader}
			</div>
		</header>
	);
};

PopupFullPageHeader.propTypes = {
	title: PropTypes.string,
	subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default PopupFullPageHeader;
