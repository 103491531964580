import React from 'react';
import PropTypes from 'prop-types';

import './overlay.scss';

const Overlay = (props) => {
	const { children, className = '' } = props;
	return <div className={`overlay__background ${className}`}>{children}</div>;
};

Overlay.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Overlay;
