import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import Icon from '../../icon-svg/icon';
import UserInfos from '../user-infos/user-infos';

import classNames from 'classnames';
import { getDriveLogs } from '../../../../drive-log/drive-log.services';
import './generic-header.scss';

const GenericHeader = (props) => {
	const { className = '', selectedLink = 0, links = [], hideNav = false, hideUser = false } = props;
	const { t } = useTranslation();

	const [hasReturnedDls, setHasReturnedDls] = useState(false);

	useEffect(() => {
		getDriveLogs({
			dateSortOrder: 'desc',
			onlyMe: true,
			numberResults: 40,
			status: ['returned'],
		})
			.then((response) => {
				const driveLogs = response.data;
				if (driveLogs?.data?.length > 0) {
					setHasReturnedDls(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const generateLink = (linkData, index) => {
		const { target = '/', text = '', isReturned = false } = linkData;

		const isActive = selectedLink === index;
		const showBadge = isReturned && hasReturnedDls;
		const linkClassNames = classNames('dbl-header__link', {
			'dbl-header__link--active': isActive,
			'dbl-header__link--with-badge': showBadge,
		});

		return (
			<Link to={target} key={uuid()} className={linkClassNames}>
				{text}
				{showBadge && <Icon className="dbl-header__badge" aria-label="badge" name="badge" />}
			</Link>
		);
	};

	return (
		<header className={`dbl-header ${className}`}>
			<Link className="dbl-header__logo-wrapper" to="/">
				<Icon className="dbl-header__icon-logo" name="db-logo" />
				<div className="dbl-header__title"> {t('core:header.app-name')} </div>
			</Link>

			{!hideNav && <div className="dbl-header__links">{links.map(generateLink)}</div>}

			{!hideUser && <UserInfos />}
		</header>
	);
};

GenericHeader.propTypes = {
	className: PropTypes.string,
	selectedLink: PropTypes.number,
	links: PropTypes.array,
};

export default GenericHeader;
