import { useCallback, useContext, useEffect } from 'react';
import { AuthContext } from '../../domains/user/auth/context/auth-context';
import checkNetwork from './check-network';
import { getExpiredDataFromApi } from './dispatch-get-request';
import { getExpiredLineSheetDataFromApi } from './dispatch-line-sheet-get-request';
import { purgeStorage } from './purge-storage';
import { synchronizeAllStorage } from './synchronize-storage';

const offlineLineSheetData = [
	{
		key: 'line-sheet-A-published',
		url: '/line-sheet',
		interval: 1,
		config: { responseType: 'blob', params: { line: 'A', status: 'published' } },
	},
	{
		key: 'line-sheet-B-published',
		url: '/line-sheet',
		interval: 1,
		config: { responseType: 'blob', params: { line: 'B', status: 'published' } },
	},
	{
		key: 'line-sheet-A-coming',
		url: '/line-sheet',
		interval: 1,
		config: { responseType: 'blob', params: { line: 'A', status: 'coming' } },
	},
	{
		key: 'line-sheet-B-coming',
		url: '/line-sheet',
		interval: 1,
		config: { responseType: 'blob', params: { line: 'B', status: 'coming' } },
	},
];

// const offlineDocumentData = [
// 	// roadbook
// 	{
// 		key: 'roadbook_lb',
// 		url: '/document/LEONAR_LB_ROADBOOK.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'roadbook_la',
// 		url: '/document/LEONAR_LA_ROADBOOK.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	// Notices techniques
// 	{
// 		key: 'technical_manual_la_ger_vin',
// 		url: '/document/LEONAR_LA_NT_OUEST.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'technical_manual_la_vin_che_boi',
// 		url: '/document/LEONAR_LA_NT_EST.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'driving_guide_la',
// 		url: '/document/LEONAR_LA_GUIDE_CONDUITE.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'driving_guide_lb',
// 		url: '/document/LEONAR_LB_GUIDE_CONDUITE.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'technical_manual_lb_nor_rem_rob',
// 		url: '/document/LEONAR_LB_NT_SUD.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// 	{
// 		key: 'technical_manual_lb_nor_ro2_mit',
// 		url: '/document/LEONAR_LB_NT_NORD.pdf',
// 		interval: 1,
// 		config: { responseType: 'blob' },
// 	},
// ];

/**
 * Loads offline data and synchronizes pending data from local storage
 * @return {null}
 */
const LoadOffline = () => {
	const { user } = useContext(AuthContext);
	/**
	 * Fetch data for offline use
	 * @param {string} key
	 * @param {string} url
	 * @param {number} [interval]
	 * @param config
	 * @return {Promise<void>}
	 */
	const fetchExpiredDataFromApi = async ({ key, url, interval, config }) =>
		await getExpiredDataFromApi(key, url, interval, config);

	/**
	 * Fetch sheet line data for offline use
	 * @param {string} key
	 * @param {string} url
	 * @param {number} [interval]
	 * @param config
	 * @return {Promise<void>}
	 */
	const fetchExpiredLineSheetDataFromApi = async ({ key, url, interval, config }) =>
		await getExpiredLineSheetDataFromApi(key, url, interval, config);

	/**
	 * Fetch  documents data for offline use
	 * @param {string} key
	 * @param {string} url
	 * @param {number} [interval]
	 * @return {Promise<void>}
	 */
	// const fetchExpiredDocumentDataFromApi = async ({ key, url }) =>
	// 	await getExpiredDocumentDataFromApi(key, url);

	/**
	 * store daily drive log (if it exists) for offline use
	 * @return {Promise<void>}
	 */
	const getDailyDLOffline = useCallback(async () => {
		const dailyDL = { key: 'daily-drive-log', url: '/drive-log?type=daily' };
		const dailyDLData = await fetchExpiredDataFromApi(dailyDL);

		const driveLogId = dailyDLData?.data?.id;

		if (driveLogId) {
			const dailyMissionsData = await fetchExpiredDataFromApi({
				key: `dl-${driveLogId}-missions`,
				url: `/drive-log/${driveLogId}/mission`,
			});

			// this function is needed, otherwise it breaks eslint (issue with react-scripts 4.0.3
			const getOfflineKeys = (dlId, mId) => {
				return { key: `dl-${dlId}-mission-${mId}`, url: `/drive-log/${dlId}/mission/${mId}` };
			};

			const offlineDailyMissionData = dailyMissionsData?.data?.map((mission) => {
				const { id } = mission;
				return getOfflineKeys(driveLogId, id);
			});

			const offlineDailyDL = [
				{ key: `dl-${driveLogId}`, url: `/drive-log/${driveLogId}` },
				{ key: `dl-${driveLogId}-missions`, url: `/drive-log/${driveLogId}/mission` },
				{
					key: `dl-${driveLogId}-worksheet`,
					url: `/drive-log/${driveLogId}/work-sheet/image`,
					config: { responseType: 'blob' },
					interval: 1,
				},
			];
			await Promise.all(offlineDailyDL.map(fetchExpiredDataFromApi));
			await Promise.all(offlineDailyMissionData.map(fetchExpiredDataFromApi));
		}
	}, []);

	/**
	 * Synchronize local storage data with API data
	 * @type {function(): Promise<void>}
	 */
	const synchronizeOfflineData = useCallback(async () => {
		const online = await checkNetwork();

		if (!online) {
			throw Error('Application is offline');
		}
		// purge expired or old local storage
		await purgeStorage();

		const offlineData = [
			{ key: 'missions', url: '/mission', interval: 1 },
			{ key: 'station-codes', url: '/referential/station-code', interval: 1 },
			{ key: 'station', url: '/referential/station', interval: 1 },
			{ key: 'train-storage', url: '/referential/train-storage', interval: 1 },
			{ key: 'office-station', url: '/referential/office-station', interval: 1 },
		];

		const filteredOfflineLineSheetData = user?.line
			? offlineLineSheetData.filter((item) => item.config.params.line === user.line)
			: offlineLineSheetData;
		// const filteredOfflineDocumentData = user.line
		// 	? offlineDocumentData.filter((item) => {
		// 			if (user.line === 'A') {
		// 				return item.key.includes('la');
		// 			} else if (user.line === 'B') {
		// 				return item.key.includes('lb');
		// 			}
		// 			return true;
		// 	  })
		// 	: offlineDocumentData;
		await getDailyDLOffline();

		await Promise.all(filteredOfflineLineSheetData.map(fetchExpiredLineSheetDataFromApi));
		// await Promise.all(filteredOfflineDocumentData.map(fetchExpiredDocumentDataFromApi));
		await Promise.all(offlineData.map(fetchExpiredDataFromApi));

		await synchronizeAllStorage();
	}, [getDailyDLOffline, user.line]);

	useEffect(() => {
		synchronizeOfflineData()
			.then(() => console.info('Application synchronized'))
			.catch(() => console.info('Application not synchronized'));
	}, [synchronizeOfflineData]);

	return null;
};

export default LoadOffline;
