import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Card, Icon } from '../../../core';
import './drive-log-card.scss';

/**
 * DriveLogCard Props
 * @typedef {object} DriveLogCardProps
 */

/**
 * DriveLogCard component
 * @param {string} props.className
 * @param {string} props.textContent
 * @param {string} props.iconName
 * @param {string} props.cta
 * @param {string} props.action
 * @param {boolean} props.isSelected
 * @returns {JSX.Element}
 */

const DriveLogCard = (props) => {
	const {
		textContent = '',
		iconName = '',
		cta = '',
		action = () => {},
		isSelected = false,
	} = props;

	const driveLogCardClasses = classnames('drive-log-card__container', {
		'drive-log-card__container--selected': isSelected,
	});

	return (
		<Card className={driveLogCardClasses}>
			<div className="drive-log-card__left">
				<div className="drive-log-card__container-icon">
					<Icon name={iconName} className="drive-log-card__icon" />
				</div>
				<span className="drive-log-card__text">{textContent}</span>
			</div>
			<div className="drive-log-card__container-cta">
				<Button light className="drive-log-card__cta" handleClick={action}>
					<span>{cta}</span>
				</Button>
			</div>
		</Card>
	);
};

DriveLogCard.propTypes = {
	textContent: PropTypes.string,
	iconName: PropTypes.string,
	cta: PropTypes.string,
	action: PropTypes.func,
	isSelected: PropTypes.bool,
};

export default DriveLogCard;
