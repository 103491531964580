import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMissionsQueries } from '../../../../../../../../../config/react-query/missions.query';
import { Button, Icon, ResponsiveText } from '../../../../../../../../core';
import DeleteMissionModal from '../modals/delete-mission-modal/delete-mission-modal';
import DeleteMissionWithObservationsModal from '../modals/delete-mission-with-observations/delete-mission-with-observations';
import './dl-delete-mission.scss';

/**
 * Renders delete mission button and popup confirmation
 * @param {Object} props
 * @param {String} props.className
 * @param {String} props.driveLogId
 * @param {String} props.missionCode
 * @param {String} props.updateDriveLogMissions
 */
const DlDeleteMission = (props) => {
	const {
		className = '',
		hasObservations = false,
		driveLogId = '',
		missionCode = '',
		missionId = '',
	} = props;

	const { t } = useTranslation();

	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [showDeletePopupWithObservations, setShowDeletePopupWithObservations] = useState(false);
	const [missionToDelete, setMissionToDelete] = useState();
	const { deleteMission } = useMissionsQueries();

	const handleShowDeletePopup = (id, code) => {
		if (hasObservations) setShowDeletePopupWithObservations(true);
		else setShowDeletePopup(true);
		setMissionToDelete({ id, code });
	};

	const handleDeleteMission = () => {
		deleteMission.mutate({ id: missionToDelete.id, driveLogId: driveLogId });
	};

	const handleCancelDeleteMission = () => {
		setShowDeletePopup(false);
		setMissionToDelete(undefined);
	};

	return (
		<div className={`dl-mission-list__mission__delete ${className}`}>
			<Button
				onClick={() => handleShowDeletePopup(missionId, missionCode)}
				className="dl-mission-list__mission__delete__btn"
			>
				<Icon name="close" className="dl-mission-list__mission__delete__icon" />
				<ResponsiveText
					text=""
					mobileText={t('dl:dl-detail-page.delete')}
					className="dl-mission-list__mission__delete__text"
				/>
			</Button>

			<DeleteMissionModal
				showModal={showDeletePopup}
				setShowModal={setShowDeletePopup}
				handleDeleteMission={handleDeleteMission}
				handleCancelDeleteMission={handleCancelDeleteMission}
			/>
			<DeleteMissionWithObservationsModal
				showModal={showDeletePopupWithObservations}
				setShowModal={setShowDeletePopupWithObservations}
			/>
		</div>
	);
};

DlDeleteMission.propTypes = {
	className: PropTypes.string,
	updateDriveLogMissions: PropTypes.func,
	driveLogId: PropTypes.string,
	missionCode: PropTypes.string,
	missionId: PropTypes.string,
};

export default DlDeleteMission;
