import classNames from 'classnames';
import 'pdfjs-dist/web/pdf_viewer.css';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import queryString from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import localForage from '../../../../../config/local-forage';
import { Button, Loader, PopupFullPage } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import Icon from '../../../../core/components/icon-svg/icon';
import AddSheetLine from '../../../components/add-sheet-line/add-sheet-line';
import { getTodayDisabledLineSheet } from '../../../disable-sheets-line.service';
import { getDriveLogById, updateDriveLog } from '../../../drive-log.services';
import { getLineSheet } from '../../../sheets-line.service';
import './dl-line-sheet-page.scss';

const DlLineSheetPage = (props) => {
	const location = useLocation();
	const { t } = useTranslation();
	const queryParams = queryString.parse(location.search);
	const { status } = props.params;
	const { driveLogId } = queryParams;
	const navigate = useNavigate();

	// states declaration
	const [publishedLineSheetUrl, setPublishedLineSheetUrl] = useState();
	const [comingLineSheetUrl, setComingLineSheetUrl] = useState();
	const [disabledPublishedSheet, setDisabledPublishedSheet] = useState(false);
	const [selectedTab, setSelectedTab] = useState('published');
	const [isLoading, setIsLoading] = useState(true);
	const [number, setNumber] = useState('-1');
	const [line, setLine] = useState();
	const pdfContainerRef = useRef(null);

	// defines the text of the backButton based on the fromApp's value
	const backButtonText = 'back-to-daily-dl';
	const pageTitle = status === 'all' ? 'line-sheets' : 'my-line-sheet';

	const publishedLineSheetClass = classNames('line-sheet-page__tab__button', {
		'line-sheet-page__tab__button--selected': selectedTab === 'published',
	});
	const comingLineSheetClass = classNames('line-sheet-page__tab__button', {
		'line-sheet-page__tab__button--selected': selectedTab === 'coming',
	});
	const displayAddSheetLine = disabledPublishedSheet && selectedTab === 'published';

	const retrieveDisabledLineSheets = () => {
		if (line) {
			getTodayDisabledLineSheet(line)
				.then((res) => {
					if (res?.data.length > 0) {
						setDisabledPublishedSheet(true);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const handleTabChange = (tab) => {
		if (
			(tab === 'published' && !publishedLineSheetUrl) ||
			(tab === 'coming' && !comingLineSheetUrl)
		) {
			setIsLoading(true);
		}
		setSelectedTab(tab);
	};

	const renderPdfLineSheetPages = useCallback(async (lineSheetPdf) => {
		const renderPdfPages = async () => {
			const PdfContainer = pdfContainerRef.current;
			if (!PdfContainer) return;
			if (PdfContainer.hasChildNodes() && !(PdfContainer.childNodes[0].nodeName === 'DIV')) {
				PdfContainer.innerHTML = '';
			}
			for (let i = 1; i <= lineSheetPdf.numPages; i++) {
				lineSheetPdf
					.getPage(i)
					.then((page) => {
						const scale = 1.1;
						const viewportPdf = page.getViewport({ scale });
						const canvasId = `pdf-page-${i}`;
						let canvas = document.getElementById(canvasId);

						// create new canvas for each page if it doesn't exist
						if (!canvas) {
							canvas = document.createElement('canvas');
							canvas.id = canvasId;
							PdfContainer.appendChild(canvas);
						}

						const context = canvas.getContext('2d');

						const outputScale = window.devicePixelRatio || 1;
						canvas.width = Math.floor(viewportPdf.width * outputScale);
						canvas.height = Math.floor(viewportPdf.height * outputScale);
						canvas.style.width = `${viewportPdf.width}px`;
						canvas.style.height = `${viewportPdf.height}px`;

						const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

						const renderParams = { canvasContext: context, viewport: viewportPdf, transform };
						page.render(renderParams);
					})
					.catch((error) => {
						console.error(`Error rendering page ${i}:`, error);
					});
			}
		};

		if (lineSheetPdf) {
			await renderPdfPages();
		}
	}, []);

	const displayActionButton = () => {
		if (status === 'all') {
			return (
				<Button className="line-sheet-page__actions__button button" type="button">
					<Link to={getBackLink()}>{t(`dl:dl-line-sheet-page.${backButtonText}`)}</Link>
				</Button>
			);
		} else if (status !== 'all' && !disabledPublishedSheet) {
			return (
				<Button
					className="line-sheet-page__actions__button button"
					type="button"
					onClick={() => {
						localForage.setItem('daily-lineSheet-seen', driveLogId);
						handleNavigation({ sheetLineNumber: number });
					}}
				>
					{t('dl:dl-line-sheet-page.open-daily-dl')}
				</Button>
			);
		}
	};

	const getBackLink = () => {
		return `/drive-log/${driveLogId}/`;
	};

	const getDriveLogLine = () => {
		getDriveLogById(driveLogId).then((response) => {
			const { data: driveLog } = response || {};
			const { line_number: lineNumber } = driveLog || {};
			setLine(lineNumber);
		});
	};

	const handleNavigation = ({ sheetLineNumber }) => {
		updateDriveLog(
			{ id: driveLogId, sheet_line_number: sheetLineNumber },
			{ action: 'add-sheet-line' },
		)
			.then(() => {
				navigate(`/drive-log/${driveLogId}`);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(getDriveLogLine, [driveLogId]);
	useEffect(retrieveDisabledLineSheets, [line]);

	useEffect(() => {
		const renderPdf = async () => {
			const url = selectedTab === 'published' ? publishedLineSheetUrl : comingLineSheetUrl;
			if (url) {
				renderPdfLineSheetPages(url).then(() => {
					setIsLoading(false);
				});
			} else setIsLoading(false);
		};

		renderPdf();
	}, [publishedLineSheetUrl, comingLineSheetUrl, selectedTab, renderPdfLineSheetPages]);

	useEffect(() => {
		const getPublishedAndComingLineSheet = () => {
			if (!line || line === undefined) return;
			if (status === 'all') {
				getLineSheet({ line, status: 'coming' }).then(async (lineSheet) => {
					const { data: comingLineSheetData } = lineSheet;
					if (comingLineSheetData?.size > 0) {
						const arrayBuffer = await comingLineSheetData.arrayBuffer();
						pdfjsLib
							.getDocument(arrayBuffer)
							.promise.then((pdf) => setComingLineSheetUrl(pdf))
							.catch((error) => console.log('Error : ', error));
					}
				});
			}

			getLineSheet({ line, status: 'published' }).then(async (lineSheet) => {
				const { data: publishedLineSheetData, fileName } = lineSheet;
				if (publishedLineSheetData?.size > 0) {
					if (fileName) setNumber(fileName);
					const arrayBuffer = await publishedLineSheetData.arrayBuffer();
					pdfjsLib
						.getDocument(arrayBuffer)
						.promise.then((pdf) => setPublishedLineSheetUrl(pdf))
						.catch((error) => console.log('Error : ', error));
				}
			});
		};

		getPublishedAndComingLineSheet();
	}, [line, status]);

	return (
		<PopupFullPage title={t(`dl:dl-line-sheet-page.${pageTitle}`)}>
			<Loader isLoading={isLoading}>
				<div className="line-sheet-page">
					{status === 'all' && (
						<div className={'line-sheet-page__tab__button_container'}>
							<Button
								className={publishedLineSheetClass}
								onClick={() => handleTabChange('published')}
							>
								{t('dl:dl-line-sheet-page.published-line-sheet')}
								{selectedTab === 'published' && (
									<Icon name="checkMark" className="line-sheet-page__tab__icon" />
								)}
							</Button>
							<Button className={comingLineSheetClass} onClick={() => handleTabChange('coming')}>
								{t('dl:dl-line-sheet-page.coming-line-sheet')}
								{selectedTab === 'coming' && (
									<Icon name="checkMark" className="line-sheet-page__tab__icon" />
								)}
							</Button>
						</div>
					)}
					{displayAddSheetLine && <AddSheetLine navigate={handleNavigation} disabledLineSheet />}
					{!displayAddSheetLine && (
						<>
							<div ref={pdfContainerRef} className="line-sheet-page__content__result">
								{(selectedTab === 'published'
									? publishedLineSheetUrl === undefined
									: comingLineSheetUrl === undefined) && (
									<div className={'line-sheet-page__content__no-result'}>
										<h1 className="no-result__title">
											{t(`dl:dl-line-sheet-page.no-${selectedTab}-line-sheet`)}
										</h1>
										<div
											className="no-result__searching-mouse-img"
											style={{ backgroundImage: `url(${EmptySplash})` }}
										/>
									</div>
								)}
							</div>
							<div className="line-sheet-page__actions">{displayActionButton()}</div>
						</>
					)}
				</div>
			</Loader>
		</PopupFullPage>
	);
};

export default DlLineSheetPage;
