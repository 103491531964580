import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { updateDriveLog } from '../../../drive-log.services';
import { AddSheetLine } from '../../../index';

const ModifySheetLineNumber = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;

	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { id: driveLogId } = useParams();

	const redirectionPage = location.state?.from ?? `${redirectUrl}/${driveLogId}`;

	const handleUpdateDL = ({ sheetLineNumber }) => {
		//TODO refactor to delete action parameter

		updateDriveLog(
			{ id: driveLogId, sheet_line_number: sheetLineNumber },
			{ action: 'add-sheet-line' },
		)
			.then(() => {
				navigate(redirectionPage);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<>
			<AddSheetLine
				navigate={handleUpdateDL}
				modifyDlTitle={t('dl:add-sheet-line-page.modify-sheet-line')}
				modifyDl
			/>
		</>
	);
};

export default ModifySheetLineNumber;
