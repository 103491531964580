import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import FeedbackMenuButton from '../../feedback/components/feedback-menu-button/menu-feedback-button';
import HrHeader from '../../headers/hr-header/hr-header';
import PageWrapper from '../page-wrapper/page-wrapper';
import './hr-page.scss';

/**
 * Renders a <HrPage /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {Object} props.children - the content to render in the page
 * @param {boolean} props.selectedLink - display or not the footer
 * @param {boolean} props.hideNav - display or not the nav buttons
 */
const HrPage = (props) => {
	const { className = '', children, selectedLink = 0, hideNav = false, overrideRoles } = props;
	const { user } = useContext(AuthContext);

	const allowedRoles = overrideRoles || ['hr', 'admin', 'localAdmin'];
	const navigate = useNavigate();

	if (!user?.role || !allowedRoles.includes(user.role)) {
		return navigate('/error-access');
	}

	return (
		<PageWrapper className="hr-page">
			<HrHeader selectedLink={selectedLink} hideNav={hideNav} />

			<main className={`hr-page__content ${className}`}>{children}</main>
			<FeedbackMenuButton />
		</PageWrapper>
	);
};

HrPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
};

export default HrPage;
