import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './multi-checkbox-field.scss';

const MultiCheckboxField = (fieldProps) => {
	const {
		translation = '',
		fieldName = '',
		options = [],
		values = [],
		handleChange,
		className = '',
		disabled = false,
		showLabel = true,
        listDirection = 'row',
	} = fieldProps;
	const { t } = useTranslation(translation);

	const listClassName = classnames('multi-checkbox__field__list', {
        'multi-checkbox__field__list--column': listDirection === 'column',
    });

	const handleClick = (value) => {
		let checkedBoxes = [...values];
		if (values.includes(value)) {
			checkedBoxes = values.filter((v) => v !== value);
		} else {
			checkedBoxes = [...values, value];
		}

		handleChange(fieldName, checkedBoxes);
	};

	const renderCheckboxes = (option, idx) => {
		const isChecked = values.includes(option.value);

		const checkboxClassName = classnames('multi-checkbox__field__item', {
			'multi-checkbox__field__item--checked': isChecked,
		});

		return (
			<li
				key={`multi-checkbox__field-${idx}`}
				className={checkboxClassName}
				onClick={() => (disabled ? {} : handleClick(option.value))}
			>
				<input type="checkbox" name={fieldName} onChange={() => {}} checked={isChecked} />
				<span className="multi-checkbox__field__text">{option.label}</span>
			</li>
		);
	};

	return (
		<div className={`multi-checkbox__field ${className}`}>
			{showLabel && <div className={`multi-checkbox__field__label ${className}`}>
				{t(`${translation}.forms.fields.labels.${fieldName}`)}
			</div>}

			<div className={`multi-checkbox__field__container ${className}`}>
				<ul className={listClassName}>{options.map(renderCheckboxes)}</ul>
			</div>
		</div>
	);
};

export default MultiCheckboxField;
