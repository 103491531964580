import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChangeAttachmentBanner } from '../../../../attachment';
import { useAttachmentList } from '../../../../attachment/attachment.services';
import { Button, Loader, PopupFullPage } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import { getWorkSheetList, saveTemporaryWorksheet } from '../../../work-sheet.service';
import ScheduleDocumentList from './components/schedule-document-list/schedule-document-list';
import SimpleSearchBar from './components/simple-search-bar/simple-search-bar';
import './dl-schedule-document-list-page.scss';

const DlScheduleDocumentListPage = () => {
	const { t } = useTranslation();
	const { id: driveLogId = '' } = useParams();
	const { attachments } = useAttachmentList();
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = queryString.parse(location.search);
	const { attachment: attachmentBigram, service, action } = queryParams;

	const [isLoading, setIsLoading] = useState(true);
	const [initialScheduleDocumentList, setInitialScheduleDocumentList] = useState([]);
	const [scheduleDocumentList, setScheduleDocumentList] = useState([]);
	const [selectedScheduleDocument, setSelectedScheduleDocument] = useState();

	//get all the scheduleDocuments related to the the line of the daily driveLog and the attachment in query param
	const getScheduleDocuments = useCallback((bigram, id) => {
		getWorkSheetList(bigram, id)
			.then((payload) => {
				const scheduleDocumentsList = payload?.data;
				setScheduleDocumentList(scheduleDocumentsList);
				setInitialScheduleDocumentList(scheduleDocumentsList);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	//get the attachment name of the attachment in query param
	const getAttachmentName = (attachmentList) => {
		if (attachmentList) {
			return attachmentList?.filter((attachment) => attachment.bigram === attachmentBigram)[0];
		}
		return {};
	};

	const attachmentName = getAttachmentName(attachments);

	const handleSearchScheduleDocuments = (search) => {
		if (search) {
			const scheduleDocumentSearchResult = initialScheduleDocumentList.filter((scheduleDocument) =>
				scheduleDocument.service.includes(search.toUpperCase()),
			);
			setScheduleDocumentList(scheduleDocumentSearchResult);
		} else {
			setScheduleDocumentList(initialScheduleDocumentList);
		}
	};

	const displayScheduleDocumentsResult = () => {
		if (scheduleDocumentList?.length > 0) {
			return (
				<ScheduleDocumentList
					scheduleDocuments={scheduleDocumentList}
					attachment={attachmentName}
					setSelectedScheduleDocument={setSelectedScheduleDocument}
					selectedScheduleDocument={selectedScheduleDocument}
				/>
			);
		} else {
			return (
				<div className="dl-work-sheets-page__no-result">
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
					<h1 className="no-result__text"> {t('dl:dl-documents.no-result')} </h1>
				</div>
			);
		}
	};

	const validateNewScheduleDocument = (scheduleDocument) => {
		if (scheduleDocument) {
			saveTemporaryWorksheet(scheduleDocument, driveLogId).then(() => {
				navigate(`/drive-log/${driveLogId}/schedule-document/daily`);
			});
		}
	};

	useEffect(() => {
		getScheduleDocuments(attachmentBigram, driveLogId);
	}, [getScheduleDocuments, attachmentBigram, driveLogId]);

	useEffect(() => {
		const initialSelectedScheduleDocument = initialScheduleDocumentList.filter(
			(scheduleDocument) =>
				scheduleDocument.service === service?.toUpperCase() && scheduleDocument.attachment_bigram,
		)[0];
		setSelectedScheduleDocument(initialSelectedScheduleDocument);
	}, [initialScheduleDocumentList, service]);

	return (
		<PopupFullPage
			className="dl-schedule-document-list-page"
			title={t(`dl:dl-documents.${action}-schedule-documents`)}
		>
			<ChangeAttachmentBanner
				attachment={attachmentName}
				className="dl-schedule-document-list-page__attachment-banner"
				originPage={`/drive-log/${driveLogId}/schedule-document?attachment=${attachmentBigram}&action=${action}`}
				text="change-attachment-banner.schedule-documents-from"
				redirectUrl={`/drive-log/${driveLogId}/schedule-document/modify-attachment`}
				query={`attachment=${attachmentBigram}&action=${action}`}
			/>
			<SimpleSearchBar handleSearch={handleSearchScheduleDocuments} />
			<Loader isLoading={isLoading}>
				{displayScheduleDocumentsResult()}
				{scheduleDocumentList?.length > 0 && (
					<Button
						onClick={() => validateNewScheduleDocument(selectedScheduleDocument)}
						className="dl-schedule-document-list-page__button--fixed"
					>
						{t('dl:dl-documents.validate')}
					</Button>
				)}
			</Loader>
		</PopupFullPage>
	);
};

export default DlScheduleDocumentListPage;
