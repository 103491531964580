import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../../../core';

const ObservationModal = (props) => {
	const { showModal, toggleModal, confirmCallback, cancelCallback, modalText } = props;

	const onConfirmCallback = () => {
		toggleModal(!showModal);
		confirmCallback();
	};

	const onCancelCallback = () => {
		toggleModal(!showModal);
		cancelCallback();
	};

	// handle click on modal without handling form
	const closeModal = () => toggleModal(!showModal);

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={onConfirmCallback}
				cancelCallback={onCancelCallback}
				closeCallback={closeModal}
			/>
		)
	);
};

ObservationModal.propTypes = {
	modalText: PropTypes.string,
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
};

export default ObservationModal;
