import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMissionCode } from '../../../../custom-hooks/observation-custom-hooks';

import './observation-checkbox.scss';

const ObservationCheckbox = (props) => {
	const { handleChange, className = '', value } = props;
	const [missionCode] = useMissionCode();
	const { t } = useTranslation();

	const handleClick = (event) => {
		const { checked } = event.target;
		handleChange(checked ? missionCode : '');
	};

	return (
		<div className={`observation-checkbox-wrapper ${className}`}>
			<input
				className={'observation-checkbox-input'}
				type="checkbox"
				checked={value === missionCode}
				onChange={(e) => handleClick(e)}
			></input>
			<div className={'observation-checkbox-text'}>
				<span>{t('observation:observation-fields.manual-drive-declaration-1')}</span>
				<div className={'observation-checkbox-text__code'}>{missionCode}</div>
				<span>{t('observation:observation-fields.manual-drive-declaration-2')}</span>
			</div>
		</div>
	);
};

ObservationCheckbox.propTypes = {
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	value: PropTypes.string,
};

export default ObservationCheckbox;
