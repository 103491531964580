import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../../../core/components/icon-svg/icon';
import './footer-dl-details.scss';

/**
 * FooterDlDetails component - Mention which amp has validated the drive log
 * @param props
 * @param {string} props.className
 * @param {object} props.currentDriveLog
 * @return {JSX.Element}
 * @constructor
 */

const FooterDlDetails = (props) => {
	const { className = '', currentDriveLog } = props;
	const { t } = useTranslation();

	const isDlValidated =
		currentDriveLog?.status === 'validated' || currentDriveLog?.status === 'archived';
	const footerMessage = isDlValidated
		? t('dl:dl-details.footer.dl-validated')
		: t('dl:dl-details.footer.dl-validation-waiting');
	const footerIconName = isDlValidated ? 'validate' : 'time';

	return (
		<footer className={`${className} footer-dl-details`}>
			<Icon className={`footer-dl-details__icon--${footerIconName}`} name={footerIconName} />
			<div className="footer-dl-details__text">
				<span className="footer-dl-details__text__fix">{footerMessage}</span>
				<span className="footer-dl-details__text__amp">
					{isDlValidated && currentDriveLog?.amp_firstname}
				</span>
				<span className="footer-dl-details__text__amp">
					{isDlValidated && currentDriveLog?.amp_lastname}
				</span>
			</div>
		</footer>
	);
};

FooterDlDetails.propTypes = {
	className: PropTypes.string,
	currentDriveLog: PropTypes.object,
};

export default FooterDlDetails;
