import React from 'react';
import { useTranslation } from 'react-i18next';

import { PopupFullPage } from '../../../core';
import NudgeForm from '../../forms/nudge-form/nudge-form';

const CreateNudgePage = (props) => {
	const { t } = useTranslation('nudges');

	return (
		<PopupFullPage title={t('nudges.forms.titles.create')} backLink={'/nudges'}>
			<NudgeForm />
		</PopupFullPage>
	);
};

export default CreateNudgePage;
