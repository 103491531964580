import i18next from '../../config/i18next';
import i18nUser from './i18n/fr.json';

// Pages Nudges
import NudgesDistributedPage from './pages/nudges-distributed-page/nudges-distributed-page';
import CreateNudgePage from './pages/create-nudge-page/create-nudge-page';
import UpdateNudgePage from './pages/update-nudge-page/update-nudge-page';

i18next.addResourceBundle('fr', 'nudges', i18nUser);

export { NudgesDistributedPage, CreateNudgePage, UpdateNudgePage };
