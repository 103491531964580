import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDirection, formatTracks } from '../../../../utils/pcc-notice';
import './pcc-notice-card.scss';

const PccNoticeCard = ({ pccNotice }) => {
	const { t } = useTranslation();
	const {
		broadcast_date,
		pk_end,
		pk_start,
		traffic_direction,
		tracks,
		station_start_1,
		station_end_1,
		station_start_2,
		station_end_2,
		description,
		title,
		id,
	} = pccNotice;

	const parsedDate = parseISO(broadcast_date);
	const formattedDate = format(parsedDate, 'EEEE d MMMM yyyy', { locale: fr });
	const formattedTime = format(parsedDate, "HH'h'mm");

	const formatStationText = (stationStart, stationEnd) => {
		return (
			<span className="pcc-notice-card__footer-text pcc-notice-card__footer-text__bold">
				{stationEnd
					? `Entre les gares de ${stationStart} et ${stationEnd}`
					: `À la gare de ${stationStart}`}
			</span>
		);
	};

	return (
		<div className="pcc-notice-card__container">
			<div className="pcc-notice-card__container__title">
				<div className="pcc-notice-card__sub-title">
					<span className="pcc-notice-card__main">Avis PCC</span>
					<span className="pcc-notice-card__secondary">{id}</span>
				</div>
				<Trans
					parent="div"
					t={t}
					className="pcc-notice-card__date"
					i18nKey="dl:dl-documents.pcc-notice.declared-at"
					values={{
						date: formattedDate,
						time: formattedTime,
					}}
				></Trans>
			</div>

			{title && <div className="pcc-notice-card__middle-text">{title}</div>}
			<div className="pcc-notice-card__container-footer">
				{formatStationText(station_start_1, station_end_1)}
				{station_start_2 && formatStationText(station_start_2, station_end_2)}
				<span className="pcc-notice-card__footer-text pcc-notice-card__footer-text__bold">
					{formatTracks(tracks)} {formatDirection(traffic_direction)}
				</span>
				{pk_start && pk_end && (
					<span className="pcc-notice-card__footer-text">
						Entre les pk {pk_start} et {pk_end}
					</span>
				)}
				{description && <div className="pcc-notice-card__footer-text">{description}</div>}
			</div>
		</div>
	);
};

PccNoticeCard.propTypes = {
	pccNotice: PropTypes.object.isRequired,
};
export default PccNoticeCard;
