/**
 * Get the status of the driveLog and redirect if the driveLog is signed
 * @param {object} driveLog - the driveLog object
 * @param {object} navigate - the object to navigate to
 **/
const getStatusRedirection = (driveLog, navigate, redirectUrl) => {
	if (driveLog.status === 'signed') {
		navigate(`${redirectUrl}/${driveLog.id}/details`);
	}
};

export default getStatusRedirection;
