import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminPage, Button, Icon, SearchBar } from '../../../core';
import useStoredFilter from '../../../core/custom-hooks/use-stored-filter';
import { getAttachments } from '../../../drive-log/drive-log.services';
import { PurgeInfoBanner } from '../../../purge';
import { AuthContext } from '../../auth/context/auth-context';
import { CreateUserModal, UserTableHeader, UserTableRow } from '../../index';
import { getUsers } from '../../user.services';
import './users-list.scss';

const UsersList = () => {
	const { t } = useTranslation('user');
	const { user: currentUser = {} } = useContext(AuthContext);

	const [attachmentsList, setAttachmentsList] = useState([]);
	const [showCreateUserModal, setShowCreateUserModal] = useState(false);

	const localAdminRolesList = {
		amp: t('user:users-list.roles.amp'),
		driver: t('user:users-list.roles.driver'),
		hr: t('user:users-list.roles.hr'),
		consultant: t('user:users-list.roles.consultant'),
	};

	const adminRolesList = {
		...localAdminRolesList,
		admin: t('user:users-list.roles.admin'),
		localAdmin: t('user:users-list.roles.localAdmin'),
	};

	const attachments = attachmentsList.map((attachmentObj) => [
		attachmentObj.code,
		attachmentObj.name,
	]);
	const attachmentCodesAndNames = Object.fromEntries(attachments);
	const storageName = 'adminUsersList';

	const getLineFromAttachment = (attachmentSelected) => {
		const attachmentInfos = attachmentsList.find(
			(attachmentObj) => attachmentObj.code === attachmentSelected,
		);
		return attachmentInfos.line;
	};

	const columnsToSearch = ['name', 'id'];

	const {
		data: usersList,
		refresh: refreshUsers,
		handleResetData: handleResetUser,
		handleSearchData: handleSearchUser,
		filter,
		numberResults,
		loadMore,
	} = useStoredFilter({
		storageName,
		columnsToSearch: columnsToSearch[0],
		getData: () => getUsers({ ...filter, numberResults, currentUser }),
	});

	useEffect(() => {
		getAttachments()
			.then((payload) => {
				setAttachmentsList(payload.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [setAttachmentsList]);

	const generateRows = (user) => (
		<UserTableRow
			className="user-list-page__table--row"
			user={user}
			key={user.id}
			attachmentsList={attachmentCodesAndNames}
			getLineFromAttachment={getLineFromAttachment}
			refreshUsersList={refreshUsers}
			localAdminRolesList={localAdminRolesList}
			adminRolesList={adminRolesList}
		/>
	);

	const handleShowModal = () => setShowCreateUserModal(!showCreateUserModal);

	return (
		<AdminPage selectedLink={0} className="users-list-page">
			<div className="users-list-page__top-options">
				<SearchBar
					handleSearch={handleSearchUser}
					handleReset={handleResetUser}
					columnsToSearch={columnsToSearch}
					className="top-options__search-bar"
					filter={filter}
				/>
				<Button className="top-options__add-user-btn" onClick={handleShowModal}>
					<Icon className="top-options__add-user-btn__icon" name="plus" />
					<span className="top-options__add-user-btn__label">{t('users-list.add-user-btn')}</span>
				</Button>
				<CreateUserModal
					showModal={showCreateUserModal}
					setShowModal={setShowCreateUserModal}
					attachmentsList={attachmentCodesAndNames}
					getLineFromAttachment={getLineFromAttachment}
					refreshUsersList={refreshUsers}
					localAdminRolesList={localAdminRolesList}
					adminRolesList={adminRolesList}
				/>
			</div>

			<h1 className="users-list-page__title"> {t('users-list.title')} </h1>

			<ul className="user-list-page__table">
				<UserTableHeader className="user-list-page__table--row" />
				{Array.isArray(usersList) && usersList.length > 0 && usersList.map(generateRows)}
			</ul>

			<div className="users-list-page__load-more">
				<Button className="users-list-page__load-more--cta" onClick={() => loadMore(40)}>
					{t('users-list.load-more-btn')}
				</Button>
			</div>

			<PurgeInfoBanner className="users-list-page__purge-banner" />
		</AdminPage>
	);
};

export default UsersList;
