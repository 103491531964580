import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import subMonths from 'date-fns/subMonths';
import { getDriveLogs } from '../../../../drive-log.services';
import './dl-month.scss';
import DriveLogTable from '../../../../components/drive-log-table/drive-log-table';
import { useTranslation } from 'react-i18next';
import { checkArrayEmpty } from '../../../../../core';
import properCase from '../../../../../core/utils/proper-case/proper-case';

const DlMonth = (props) => {
	const { monthIndex } = props;
	const { t } = useTranslation('dl');

	const [monthDriveLogs, setMonthDriveLogs] = useState([]);

	const monthDate = subMonths(new Date(), monthIndex);
	const month = monthDate.toLocaleString('fr-FR', { month: 'long' });
	const formattedMonth = properCase(month);

	const year = monthDate.getFullYear();

	useEffect(() => {
		getDriveLogs({
			monthToLoad: monthIndex,
			dateSortOrder: 'desc',
			onlyMe: true,
			numberResults: 40,
			status: ['signed', 'validated', 'archived', 'signed-not-transmitted'],
		})
			.then((response) => {
				const driveLogs = response.data;
				setMonthDriveLogs(driveLogs?.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [monthIndex]);

	const generateLogTable = () => {
		const driveLogListEmpty = checkArrayEmpty(monthDriveLogs);
		if (driveLogListEmpty) {
			return (
				<div className="dl-month__empty-table-warning">
					{t('dl-list-page.month-without-drive-log', { month: formattedMonth })}
				</div>
			);
		}
		return (
			<DriveLogTable driveLogs={monthDriveLogs} driverView={true} replaceCommentWithStatus={true} />
		);
	};

	return (
		<section className="dl-month">
			<h2 className="dl-month__title">{`${month} ${year}`}</h2>
			{generateLogTable()}
		</section>
	);
};

DlMonth.propTypes = {
	monthIndex: PropTypes.number.isRequired,
};

export default DlMonth;
