import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveText } from '../../../core';
import './export-section-title.scss';

const ExportSectionTitle = (props) => {
	const { sectionNumber = '', sectionTitle = '' } = props;

	return (
		<div className="section__header">
			<ResponsiveText
				text={sectionNumber}
				mobileText={sectionNumber}
				className="section__header__title section__header__title--bold"
			/>
			<ResponsiveText
				text={sectionTitle}
				mobileText={sectionTitle}
				className="section__header__title"
			/>
		</div>
	);
};

ExportSectionTitle.propTypes = {
	list: PropTypes.array,
	subtitle: PropTypes.string,
	type: PropTypes.string,
};

export default ExportSectionTitle;
