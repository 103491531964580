import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import { PageWrapper } from '../../../index';
import FeedbackMenuButton from '../../feedback/components/feedback-menu-button/menu-feedback-button';
import GenericHeader from '../../headers/generic-header/generic-header';
import './consultant-page.scss';

const ConsultantPage = (props) => {
	const {
		className = '',
		children,
		selectedLink = 0,
		hideNav = false,
		overrideRoles,
		style,
	} = props;
	const { user } = useContext(AuthContext);
	const { role, attachment } = user;

	const allowedRoles = overrideRoles || ['consultant', 'admin', 'localAdmin'];
	const isPSGRole = role === 'amp' && attachment === 'PSG';
	const navigate = useNavigate();

	if (!user?.role || (!allowedRoles.includes(user.role) && !isPSGRole)) {
		return navigate('/error-access');
	}

	return (
		<PageWrapper className="consultant-page">
			<GenericHeader selectedLink={selectedLink} hideNav={hideNav} />

			<div className={`consultant-page__content ${className}`} style={style}>
				{children}
			</div>
			<FeedbackMenuButton className="consultant-page__menu__feedback-button" />
		</PageWrapper>
	);
};

ConsultantPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
};

export default ConsultantPage;
