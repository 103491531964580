import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommentCell from '../columns/comment-cell/comment-cell';
import HoursCell from '../columns/hours-cell/hours-cell';
import ReturnDateCell from '../columns/return-date-cell/return-date-cell';
import ServiceNumberCell from '../columns/service-number-cell/service-number-cell';
import ServiceStartCell from '../columns/service-start-cell/service-start-cell';
import SheetLineNumberCell from '../columns/sheet-line-number-cell/sheet-line-number-cell';
import './returned-drive-log-table-row.scss';

const ReturnedDriveLogTableRow = (props) => {
	const { driveLog = {} } = props;
	const { comment = '' } = driveLog;

	const { t } = useTranslation();

	const generateCells = () => {
		return (
			<>
				<ServiceStartCell driveLog={driveLog} />
				<ServiceNumberCell driveLog={driveLog} />
				<SheetLineNumberCell driveLog={driveLog} />
				<HoursCell driveLog={driveLog} />
				<CommentCell driveLog={driveLog} />
				<ReturnDateCell driveLog={driveLog} />
			</>
		);
	};

	return (
		<li className="returned-drive-log-row-container">
			<Link to={`/drive-log/returned/${driveLog.id}`}>
				<div className="returned-drive-log-row">{driveLog && driveLog.id && generateCells()}</div>
				{comment && (
					<div className="returned-drive-log-row__comment-container">
						<div className="returned-drive-log-row__comment">
							<h3 className="returned-drive-log-row__comment-title">
								{t('dl:dl-details.comment.title-extended')}
							</h3>
							<div className="returned-drive-log-row__comment-text">{driveLog.comment}</div>
						</div>
					</div>
				)}
			</Link>
		</li>
	);
};

ReturnedDriveLogTableRow.propTypes = {
	driveLog: PropTypes.object.isRequired,
};

export default ReturnedDriveLogTableRow;
