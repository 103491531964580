import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorMessage from '../../../../../../core/components/error-message/error-message';
import computeTime from '../../../../../../core/utils/compute-time/compute-time';
import retrieveNextObservationWithoutSurvey from '../../../../../../survey/utils/retrieve-next-observation-id-for-survey';
import { updateDriveLog } from '../../../../../drive-log.services';
import './validate-delay-btn.scss';

const ValidateDelayBtn = ({ driveLog = {}, redirectUrl = '' }) => {
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const navigate = useNavigate();

	const [hasError, setHasError] = useState(false);

	const validate = () => {
		const driverComputePayload = {
			shiftStartDate: driveLog.realized_date_start,
			shiftEndDate: driveLog.realized_date_end,
			endOfShiftDelay: driveLog.end_of_service_delay,
			dailyLegalTimeExceed: driveLog.daily_legal_time_exceed,
			restTimeReduction: driveLog.rest_time_reduction,
			diffServicePlannedPerformedTime: driveLog.diff_service_planned_performed_time,
			otherTcTime: driveLog.other_tc_time,
		};
		const {
			endOfServiceDelay,
			restTimeReduction,
			superiorToSixHoursThirty,
			diffServicePlannedPerformedTime,
			otherTcTime,
			total,
		} = computeTime(driverComputePayload, driveLog);
		const payload = {
			id: driveLogId,
			end_of_service_delay: endOfServiceDelay,
			daily_legal_time_exceed: superiorToSixHoursThirty,
			rest_time_reduction: restTimeReduction,
			diff_service_planned_performed_time: diffServicePlannedPerformedTime,
			other_tc_time: otherTcTime,
			total_additional_time: total,
		};

		updateDriveLog(payload, { action: 'modifyDelay' })
			.then(async () => {
				const observationId = await retrieveNextObservationWithoutSurvey(driveLogId);
				if (observationId) {
					navigate(`${redirectUrl}/${driveLogId}/observation/${observationId}/add-survey`);
				} else {
					navigate(`${redirectUrl}/${driveLogId}/sign`);
				}
			})
			.catch(() => {
				setHasError(true);
			});
	};

	return (
		<div className="validate-delay">
			{hasError && <ErrorMessage />}
			<button className="validate-delay__button button" onClick={validate}>
				{t('dl:delay-list-page.validate-additionnal-time')}
			</button>
		</div>
	);
};

export default ValidateDelayBtn;
