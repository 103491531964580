import { http } from '../../../config';

const pushNotificationSubscription = (driveLogId, subscription, params) => {
	const query = { id: driveLogId };
	const body = { subscription, filter: params };
	return http.post('/notification', body, { params: query });
};

const removeNotificationSubscription = () => {
	return http.delete('/notification');
};

export { pushNotificationSubscription, removeNotificationSubscription };
