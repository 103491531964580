import { http } from '../../config';

/**
 * get all permanent attention alerts
 * @returns {Promise} response
 */
const getAttentionAlerts = async () => {
	return http.get('/attention-alert');
};

/**
 * get a permanent attention alert by its id
 * @param {String} id the id of the permanent attention alert to get
 * @returns {Promise} response
 */
const getAttentionAlertById = async (id) => {
	return http.get(`/attention-alert/${id}`);
};

/**
 * create a new permanent attention alert
 * @param {object} attentionAlert the attention-alert object
 * @returns {Promise} response
 */
const createAttentionAlert = async (attentionAlert) => http.post('/attention-alert', attentionAlert);


/**
 * update the permanent attention alert of the given id
 * @param {string} id the id of the permanent attention alert to update
 * @param {object} attentionAlert the attention-alert object
 * @returns {Promise} response
 */
const updateAttentionAlert = async (id, attentionAlert) => http.put(`/attention-alert/${id}`, attentionAlert);

/**
 * delete multiple attention alers
 * @returns {Promise} response
 */
const deleteMultipleAttentionAlerts = async (id) => {
	return http.delete('/attention-alert');
};

/**
 * delete a permanent attention alert by its id
 * @param {String} id the id of the permanent attention alert to delete
 * @returns {Promise} response
 */
const deleteAttentionAlertById = async (id) => {
	return http.delete(`/attention-alert/${id}`);
};

export {
	getAttentionAlerts,
	getAttentionAlertById,
	createAttentionAlert,
	updateAttentionAlert,
	deleteAttentionAlertById,
	deleteMultipleAttentionAlerts,
};
