import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { cloneElement, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import './app.scss';
import { LoadOffline } from './config';
import { queryClient } from './config/react-query/query-client';
import {
	ModifyAttachmentConsultationPage,
	ModifyAttachmentGlobalScopePage,
	ModifyAttachmentScheduleDocumentPage,
} from './domains/attachment';
import {
	AttentionAlertsPage,
	CreateAttentionAlertPage,
	UpdateAttentionAlertPage,
} from './domains/attention-alert';
import { ConsistencyReportPage } from './domains/consistency-report';
import { GlobalAppProvider } from './domains/core/global-app/global-app-context';
import MatomoContainer from './domains/core/matomo/matomo-container';
import { DisableLineSheetsPage } from './domains/disable-line-sheets';
import {
	ArchivedDlPage,
	BeyondSixHourThirty,
	CreateEmptyDl,
	DailyDlPage,
	DelayListPage,
	DiffServicePlannedPerformedDelayPage,
	DlDetailsDriver,
	DlDetailsSupervisor,
	DlLineSheetPage,
	DlListPage,
	DlListReadOnly,
	DlPage,
	DlScheduleDocumentListPage,
	DlScheduleDocumentPage,
	DlToBeArchivedPage,
	EndOfServiceDelayPage,
	ModifyEndOfServiceDelayPage,
	ModifyServiceNumber,
	ModifySheetLineNumber,
	OtherTcDelayPage,
	RestShortenedPage,
	ReturnedDlListPage,
	SignDlPage,
	SignedDl,
	SupervisorDriveLogList,
	ValidatedDriveLogsPage,
} from './domains/drive-log';
import DocumentPdfScreen from './domains/drive-log/pages/driver/dl-page/components/document-pdf-screen/document-pdf-screen';
import DlPccNoticePage from './domains/drive-log/pages/driver/dl-pcc-notice-page/dl-pcc-notice-page';
import { ErrorBoundary, ErrorPage } from './domains/error';
import { ExportPage } from './domains/export';
import {
	AddMission,
	AddNoMissionCode,
	AddTrainNumber,
	MissionDetails,
	ModifyStations,
} from './domains/mission';
import { CreateNudgePage, NudgesDistributedPage, UpdateNudgePage } from './domains/nudges';
import {
	AddObservation,
	EditObservation,
	ObservationList,
	ObservationRedirect,
} from './domains/observation';
import {
	CreateSpecialNoticePage,
	SpecialNoticesPage,
	UpdateSpecialNoticePage,
} from './domains/special-notice';
import { AddSurvey, ReadSurvey } from './domains/survey';
import EditSurvey from './domains/survey/pages/edit-survey/edit-survey';
import { TermsOfService } from './domains/terms-of-service';
import { AuthProvider, UserHome, UsersList } from './domains/user';
import { FeedbackPage } from './domains/users-feedback';

const persister = createSyncStoragePersister({
	storage: window.localStorage,
});

const protectedRoutesList = [
	// Drive log routes
	{ path: '/daily-drive-log', element: <DailyDlPage /> },
	{ path: '/drive-logs', element: <DlListPage /> },
	{ path: '/drive-logs/returned', element: <ReturnedDlListPage /> },
	{ path: '/drive-log/create', element: <CreateEmptyDl /> },
	{ path: '/drive-log/choose-service', element: <ModifyServiceNumber /> },
	{
		path: '/drive-log/:id',
		element: <DlPage />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id',
		element: <DlPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{ path: '/drive-log/:id/sign', element: <SignDlPage />, params: { redirectUrl: '/drive-log' } },
	{
		path: '/drive-log/returned/:id/sign',
		element: <SignDlPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/add-delay',
		element: <EndOfServiceDelayPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/add-delay',
		element: <EndOfServiceDelayPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/modify-delay',
		element: <ModifyEndOfServiceDelayPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-delay',
		element: <ModifyEndOfServiceDelayPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{ path: '/drive-log/:id/modify-service', element: <ModifyServiceNumber /> },
	{
		path: '/drive-log/:id/modify-sheet-line-number',
		element: <ModifySheetLineNumber />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-sheet-line-number',
		element: <ModifySheetLineNumber />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/modify-rest-shortened',
		element: <RestShortenedPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-rest-shortened',
		element: <RestShortenedPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/modify-difference-service-planned-performed',
		element: <DiffServicePlannedPerformedDelayPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-difference-service-planned-performed',
		element: <DiffServicePlannedPerformedDelayPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/modify-other-tc',
		element: <OtherTcDelayPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-other-tc',
		element: <OtherTcDelayPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/modify-daily-time',
		element: <BeyondSixHourThirty />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/modify-daily-time',
		element: <BeyondSixHourThirty />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/delays',
		element: <DelayListPage />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/delays',
		element: <DelayListPage />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/signed-dl',
		element: <SignedDl />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/signed-dl',
		element: <SignedDl />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/details',
		element: <DlDetailsDriver />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
		params: { redirectUrl: '/drive-log', domain: 'driver' },
	},
	{
		path: '/drive-log/returned/:id/details',
		element: <DlDetailsDriver />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
		params: { redirectUrl: '/drive-log/returned', domain: 'driver' },
	},
	{
		path: '/drive-log/:id/pcc-notice',
		element: <DlPccNoticePage />,
	},
	{ path: '/drive-log/:id/schedule-document/daily', element: <DlScheduleDocumentPage /> },
	{ path: '/drive-log/:id/schedule-document', element: <DlScheduleDocumentListPage /> },
	{
		path: '/drive-log/:id/schedule-document/modify-attachment',
		element: <ModifyAttachmentScheduleDocumentPage />,
	},
	{
		path: '/drive-log/:id/pdf/:key',
		element: <DocumentPdfScreen />,
		params: { redirectUrl: '/drive-log', customProp: 'myCustomValue' },
	},
	// Mission routes
	{
		path: '/drive-log/:id/mission/add',
		element: <AddMission />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/mission/add',
		element: <AddMission />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{ path: '/drive-log/:id/mission/add/no-code', element: <AddNoMissionCode /> },
	{
		path: '/drive-log/:id/mission/:missionId/train-number',
		element: <AddTrainNumber />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/mission/:missionId/train-number',
		element: <AddTrainNumber />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/:station/edit',
		element: <ModifyStations />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/mission/:missionId/:station/edit',
		element: <ModifyStations />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/details',
		element: <MissionDetails />,
		params: { redirectUrl: '/drive-log' },
	},

	// Observation list pages (with or without mission
	{
		path: '/drive-log/:id/add-observation',
		element: <ObservationList />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/add-observation',
		element: <ObservationList />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/add-observation/:code',
		element: <ObservationList />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/add-observation',
		element: <ObservationRedirect />,
	},
	{
		path: '/drive-log/returned/:id/mission/:missionId/add-observation/:code',
		element: <ObservationList />,
		params: { redirectUrl: '/drive-log/returned' },
	},

	// Survey page
	{
		path: '/drive-log/:driveLogId/observation/:observationId/add-survey',
		element: <AddSurvey />,
	}, // TODO: returned ?
	{
		path: '/drive-log/:driveLogId/observation/:observationId/survey/:surveyId/edit',
		element: <EditSurvey />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:driveLogId/observation/:observationId/survey/:surveyId/edit',
		element: <EditSurvey />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:driveLogId/observation/:observationId/survey/:surveyId',
		element: <ReadSurvey />,
	},

	// Add observations with and without missions
	{
		path: '/drive-log/:id/observation/:typeUrl',
		element: <AddObservation />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/observation/:typeUrl',
		element: <AddObservation />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/observation/:typeUrl',
		element: <AddObservation />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/mission/:missionId/observation/:typeUrl',
		element: <AddObservation />,
		params: { redirectUrl: '/drive-log/returned' },
	},

	// Edit observations with and without missions
	{
		path: '/drive-log/:id/observation/:observationId/edit',
		element: <EditObservation />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/observation/:observationId/edit',
		element: <EditObservation />,
		params: { redirectUrl: '/drive-log/returned' },
	},
	{
		path: '/drive-log/:id/mission/:missionId/observation/:observationId/edit',
		element: <EditObservation />,
		params: { redirectUrl: '/drive-log' },
	},
	{
		path: '/drive-log/returned/:id/mission/:missionId/observation/:observationId/edit',
		element: <EditObservation />,
		params: { redirectUrl: '/drive-log/returned' },
	},

	// Line sheet routes
	{ path: '/line-sheet', element: <DlLineSheetPage />, params: { status: 'single' } },
	{ path: '/line-sheets', element: <DlLineSheetPage />, params: { status: 'all' } },

	// Export routes
	{ path: '/export', element: <ExportPage /> },

	// Supervisor drive log routes
	{ path: '/supervisor/drive-log-list', element: <SupervisorDriveLogList /> },
	{ path: '/supervisor/validated-drive-logs', element: <ValidatedDriveLogsPage /> },
	{
		path: '/supervisor/drive-log/:id/details',
		element: <DlDetailsSupervisor />,
		params: { redirectUrl: '/drive-log' },
	},
	{ path: '/supervisor/consistency-report', element: <ConsistencyReportPage /> },

	// Multi-role Pages
	{ path: '/modify-attachment', element: <ModifyAttachmentGlobalScopePage /> },
	{ path: '/modify-attachment-for-consultation', element: <ModifyAttachmentConsultationPage /> },
	{
		path: '/terms-of-service',
		element: <TermsOfService />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
	},
	{ path: '/disable-line-sheets', element: <DisableLineSheetsPage /> },

	// Human Resources drive log routes
	{ path: '/hr/drive-log-list', element: <DlToBeArchivedPage /> },
	{ path: '/hr/archived-drive-logs', element: <ArchivedDlPage /> },
	{
		path: '/hr/drive-log/:id/details',
		element: <DlDetailsSupervisor />,
		params: { domain: 'hr' },
	},
	{
		path: '/hr/drive-log/archived/:id/details',
		element: <DlDetailsDriver />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
		params: { redirectUrl: '/drive-log', domain: 'hr' },
	},
	// Consultant drive log routes
	{ path: '/consultant/drive-logs', element: <DlListReadOnly /> },
	{
		path: '/consultant/drive-log/:id/details',
		element: <DlDetailsSupervisor />,
		params: { domain: 'consultant' },
	},

	// Back-office page
	{ path: '/admin/users-list', element: <UsersList /> },

	//feedback
	{ path: '/admin/feedback', element: <FeedbackPage /> },

	// Special notices page
	{ path: '/special-notices', element: <SpecialNoticesPage />, params: { enclosed: false } },
	{
		path: '/special-notices/enclosed',
		element: <SpecialNoticesPage />,
		params: { enclosed: true },
	},
	{ path: '/special-notice/add', element: <CreateSpecialNoticePage /> },
	{
		path: '/special-notice/:id/edit',
		element: <UpdateSpecialNoticePage />,
		params: { action: 'edit' },
	},
	{
		path: '/special-notice/:id/republish',
		element: <UpdateSpecialNoticePage />,
		params: { action: 'republish' },
	},

	//Nudges page
	{ path: '/nudges', element: <NudgesDistributedPage />, params: { enclosed: false } },
	{ path: '/nudges/enclosed', element: <NudgesDistributedPage />, params: { enclosed: true } },
	{ path: '/nudges/add', element: <CreateNudgePage /> },
	{ path: '/nudges/:id/edit', element: <UpdateNudgePage />, params: { action: 'edit' } },
	{
		path: '/nudges/:id/republish',
		element: <UpdateNudgePage />,
		params: { action: 'republish' },
	},

	// Attention alerts page
	{ path: '/attention-alerts', element: <AttentionAlertsPage /> },
	{ path: '/attention-alert/add', element: <CreateAttentionAlertPage /> },
	{ path: '/attention-alert/:id/edit', element: <UpdateAttentionAlertPage /> },

	// Homepage
	{
		path: '/',
		element: <UserHome />,
		overrideRoles: ['driver', 'amp', 'hr', 'admin', 'consultant', 'localAdmin'],
	},
];

const App = () => {
	const renderProtectedRoutes = (route) => {
		const { element, path, overrideRoles, params = {} } = route;
		return (
			<Route
				key={path}
				path={path}
				element={
					<AuthProvider>
						<MatomoContainer />
						<LoadOffline />
						{cloneElement(element, { overrideRoles, params })}
					</AuthProvider>
				}
			/>
		);
	};

	const protectedRoutes = useMemo(() => protectedRoutesList.map(renderProtectedRoutes), []);

	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{ persister }}
			onSuccess={() => {
				// resume mutations after initial restore from localStorage was successful
				queryClient.resumePausedMutations().then(() => {
					queryClient.invalidateQueries();
				});
			}}
		>
			<ErrorBoundary>
				<div className="digibul">
					<GlobalAppProvider>
						<Routes>
							<Route path="/error-access" element={<ErrorPage />} />
							{protectedRoutes}
							{/* {protectedRoutesList.map(renderProtectedRoutes)} */}
						</Routes>
					</GlobalAppProvider>
				</div>
			</ErrorBoundary>
			<ReactQueryDevtools initialIsOpen={false} />
		</PersistQueryClientProvider>
	);
};

export default App;
