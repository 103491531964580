import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import FeedbackMenuButton from '../../feedback/components/feedback-menu-button/menu-feedback-button';
import SupervisorHeader from '../../headers/supervisor-header/supervisor-header';
import PageWrapper from '../page-wrapper/page-wrapper';
import './supervisor-page.scss';

/**
 * Renders a <SupervisorPage /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {object} props.children - the content to render in the page
 * @param {boolean} props.selectedLink - display or not the footer
 */
const SupervisorPage = (props) => {
	const { className = '', children, selectedLink = 0, hideNav = false, overrideRoles } = props;
	const { user } = useContext(AuthContext);

	const allowedRoles = overrideRoles || ['amp', 'admin', 'localAdmin'];
	const navigate = useNavigate();

	if (!user?.role || !allowedRoles.includes(user.role)) {
		return navigate('/error-access');
	}

	return (
		<PageWrapper className="supervisor-page">
			<SupervisorHeader selectedLink={selectedLink} hideNav={hideNav} />

			<main className={`supervisor-page__content ${className}`}>{children}</main>
			<FeedbackMenuButton className="supervisor-page__menu__feedback-button" />
		</PageWrapper>
	);
};

SupervisorPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
	overrideRoles: PropTypes.array,
};

export default SupervisorPage;
