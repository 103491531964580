import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DateUtils } from '../../../../core';
import displayStationNameFromObservation from '../../../../core/utils/display-station-name-from-observation/display-station-name-from-observation';
import SurveyModal from '../../../../survey/components/survey-form/survey-modal/survey-modal';
import { deleteSurvey } from '../../../../survey/survey.services';
import './survey-list-item.scss';

/**
 * Component for display one survey in a row
 * @component
 * @param {Object} props
 * @param {Object} props.survey - the survey object data
 * @param {boolean} props.readOnly - the boolean that defines if the observation is on readonly mode
 * @param {function} props.retrieveSurveysList - function to retrieve the survey list
 */
const SurveyListItem = (props) => {
	const { survey, showUpdateButton = false, retrieveSurveysList, redirectUrl = '' } = props;
	const {
		observation_creation_date,
		drive_log_id,
		observation_id,
		id,
		mission_code,
		observation_content,
	} = survey;
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);
	const { formatFullDate, formatHourMinutes } = DateUtils;

	const displayFullDate = () => {
		const creationDate = formatFullDate(observation_creation_date, 'dd MMM yyyy');
		const creationTime = formatHourMinutes(observation_creation_date);

		return (
			<div className="survey__row__full-date">
				<Trans
					parent="span"
					className="full-date__d-y"
					i18nKey="dl:dl-detail-page.obs-raw.date"
					values={{ day: creationDate, time: creationTime }}
				></Trans>
			</div>
		);
	};

	const redirection = (action) => {
		if (action === 'edit') {
			return `${redirectUrl}/${drive_log_id}/observation/${observation_id}/survey/${id}/edit`;
		} else {
			return `${redirectUrl}/${drive_log_id}/observation/${observation_id}/survey/${id}`;
		}
	};

	const displayConfirmationModal = () => {
		setShowModal(true);
	};

	const handleDelete = () => {
		deleteSurvey(drive_log_id, observation_id, id)
			.then(() => {
				retrieveSurveysList();
			})
			.catch((err) => console.error(err));
	};

	return (
		<>
			<li key={id} className="survey__row">
				<div className="survey__mission_code">{mission_code}</div>
				{displayFullDate()}
				<div className="survey__row__station-and-type">
					<span>{displayStationNameFromObservation(survey.observation_content)}</span>
					<span>{`. ${observation_content?.pattern.value}`}</span>
				</div>
				<div className="survey__row__actions">
					{showUpdateButton && (
						<>
							<Link to={redirection('edit')} className="survey__row__actions__update">
								{t('dl:dl-detail-page.edit')}
							</Link>
							<div
								className="survey__row__actions__delete"
								onClick={() => displayConfirmationModal()}
							>
								{t('dl:dl-detail-page.delete')}
							</div>
							<SurveyModal
								showModal={showModal}
								toggleModal={setShowModal}
								confirmCallback={() => handleDelete()}
								modalText={t('survey:survey-modal.content')}
							/>
						</>
					)}
					{!showUpdateButton && (
						<Link to={redirection('read')} className="survey__row__actions__read">
							{t('dl:dl-detail-page.see-detail')}
						</Link>
					)}
				</div>
			</li>
			<div className="survey__separator" />
		</>
	);
};

SurveyListItem.propTypes = {
	survey: PropTypes.object.isRequired,
	readOnly: PropTypes.bool,
	retrieveSurveysList: PropTypes.func,
};

export default SurveyListItem;
