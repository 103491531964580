import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Button } from '../../../../../../core';
import EmptySplash from '../../../../../../core/assets/images/empty-splash.svg';
import DlMissionRow from './components/mission-row/dl-mission-row';
import './dl-mission-list.scss';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 */
const DlMissionList = (props) => {
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState('list-mission');
	const tabClass = 'dl-mission-list__tab__button';
	const {
		className = '',
		observations,
		retrieveObservationsList,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
		setUncompletedDl,
		emptyTrainComposition,
		setEmptyTrainComposition,
		missionsList = [],
		showHeader = true,
		dailyDriveLog = {},
		worksheetImageUrl,
	} = props;
	const handleTabChange = (tab) => {
		setSelectedTab(tab);
	};

	const displayWorkSheetImage = () => {
		if (worksheetImageUrl) {
			return (
				<div className={'dl-mission-list__content__result'}>
					<img src={worksheetImageUrl} alt="Assigned work-sheet" />
				</div>
			);
		} else {
			return (
				<div className={'dl-mission-list__content__no-result'}>
					<h1 className="no-result__title">{t('dl:dl-documents.no-assigned-schedule-document')}</h1>
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			);
		}
	};

	const generateMissionHeaders = () => {
		return (
			<li key={uuid()} className="dl-mission-list__mission dl-mission-list__mission--header">
				<div className="dl-mission-list__mission__code dl-mission-list__mission__code--header">
					{t('dl:dl-detail-page.mission')}
				</div>
				<div className="dl-mission-list__mission__composition dl-mission-list__mission__composition--header">
					{t('dl:dl-detail-page.composition')}
				</div>
				<div className="dl-mission-list__mission__train-number dl-mission-list__mission__train-number--header">
					{t('dl:dl-detail-page.motor_number')}
				</div>
				<div className="dl-mission-list__mission__start dl-mission-list__mission__start--header">
					{t('dl:dl-detail-page.start')}
				</div>
				<div className="dl-mission-list__mission__end dl-mission-list__mission__end--header">
					{t('dl:dl-detail-page.end')}
				</div>

				{!readOnly && (
					<div className="dl-mission-list__mission__actions dl-mission-list__mission__actions--header">
						{t('dl:dl-detail-page.actions')}
					</div>
				)}
			</li>
		);
	};

	const generateMissionLine = (missionData) => {
		return (
			<DlMissionRow
				dailyDriveLog={dailyDriveLog}
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				missionData={missionData}
				key={uuid()}
				readOnly={readOnly}
				uncompletedDl={uncompletedDl}
				setUncompletedDl={setUncompletedDl}
				emptyTrainComposition={emptyTrainComposition}
				setEmptyTrainComposition={setEmptyTrainComposition}
				redirectUrl={redirectUrl}
			/>
		);
	};

	return (
		<div className={`dl-mission-list ${className}`}>
			{showHeader && (
				<div className="dl-mission-list__tab__button_container">
					<Button
						className={`${
							selectedTab === 'list-mission' ? `${tabClass}--selected` : `${tabClass}`
						}`}
						onClick={() => handleTabChange('list-mission')}
					>
						{t('dl:dl-detail-page.list-mission')}
					</Button>
					<Button
						className={`${selectedTab === 'work-sheet' ? `${tabClass}--selected` : `${tabClass}`}`}
						onClick={() => handleTabChange('work-sheet')}
					>
						{t('dl:dl-documents.work-sheet')}
					</Button>
				</div>
			)}
			<div className="dl-mission-list__missions">
				<ul>
					{selectedTab === 'list-mission' && generateMissionHeaders()}
					{selectedTab === 'list-mission' &&
						Array.isArray(missionsList) &&
						missionsList.map(generateMissionLine)}
					{selectedTab === 'work-sheet' && displayWorkSheetImage()}
				</ul>
			</div>
		</div>
	);
};

DlMissionList.propTypes = {
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	setUncompletedDl: PropTypes.func,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlMissionList;
