import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ResponsiveText } from '../../../../../core';
import './service-number-cell.scss';

const ServiceNumberCell = (props) => {
	const { driveLog, header = false, consultantView = false } = props;
	const { t } = useTranslation('dl');

	const getServiceNumber = (dl) => {
		// serviceNumberCell should display service number and bigram attachment for consultant view
		const text = consultantView
			? `${dl.realized_service_number} - ${dl.attachment_bigram}`
			: `${dl.realized_service_number}`;
		if (dl?.realized_service_number && dl?.realized_service_number !== '-1') {
			return (
				<ResponsiveText
					text={text}
					mobileText={t('dl-table.service-number', { service_number: dl.realized_service_number })}
				/>
			);
		}
		return 'PV';
	};

	const buildHeader = () => (
		<div className="service-number-cell">{t('dl-table.headers.service')}</div>
	);

	const buildCell = () => <div className="service-number-cell">{getServiceNumber(driveLog)}</div>;

	return header ? buildHeader() : buildCell();
};

ServiceNumberCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool,
};

export default ServiceNumberCell;
