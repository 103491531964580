/**
 * Update a single element of an array with a new value
 * @param {Array} arr - the array to update
 * @param {String} newValue - the new value
 * @param {Number} index - the index of the element to update
 * @returns {Array} new array with updated value
 */
const updateArrayElement = (arr, newValue, index) => {
	return arr.map((value, i) => (i === index ? newValue : value));
};

export default updateArrayElement;
