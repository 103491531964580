import React, { useState } from 'react';
import { getHours, getMinutes, getSeconds, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
	dateTime,
	radio,
	stationSearch,
	trainStorageSearch,
	yesNo,
	fileAttachment,
	delay,
} from '../observation-form/observation-fields/observation-fields-list';
import observationTypes from '../../forms/observation-types';
import ObservationWithFileAttachment from '../observation-form/observation-fields/observation-with-file-attachment/observation-with-file-attachment';
import './observation-details.scss';

/**
 * Renders observation content details
 * @param props
 * @param {string} props.className custom class name for css purpose
 * @param {Object} props.observation
 * @return {JSX.Element}
 * @constructor
 */
const ObservationDetails = (props) => {
	const { className = '', observation } = props;
	const { t } = useTranslation();
	const { id: observationId, drive_log_id: driveLogId } = observation;
	const [observationFiles, setObservationFiles] = useState([]);

	const displayValue = (fieldName, fieldValue) => {
		if ([...yesNo, ...radio].includes(fieldName)) {
			return t(`observation:observation-fields.radio-button.${fieldValue}`);
		}

		if (stationSearch.includes(fieldName) || trainStorageSearch.includes(fieldName)) {
			return fieldValue?.name;
		}

		if (dateTime.includes(fieldName)) {
			return `${getHours(parseISO(fieldValue))}:${getMinutes(parseISO(fieldValue))
				.toString()
				.padStart(2, '0')}`;
		}

		if (fileAttachment.includes(fieldName)) {
			return (
				<>
					<ObservationWithFileAttachment
						driveLogId={driveLogId}
						observationId={observationId}
						readOnly={true}
						observationFiles={observationFiles}
						setObservationFiles={setObservationFiles}
					/>
				</>
			);
		}

		if (delay.includes(fieldName)) {
			// handle previous retention observation without seconds
			if (fieldValue.length <= 4) {
				return `${fieldValue} minute(s)`;
			}

			if (typeof fieldValue === 'string' && !isNaN(Date.parse(fieldValue))) {
				const minutes = getMinutes(parseISO(fieldValue));
				const seconds = getSeconds(parseISO(fieldValue));

				if (minutes !== 0 && seconds !== 0) {
					return `${minutes} minute(s) et ${seconds} seconde(s)`;
				}

				if (minutes === 0) {
					return `${seconds} seconde(s)`;
				}

				if (seconds === 0) {
					return `${minutes} minute(s)`;
				}
			}
		}

		return fieldValue;
	};

	const getRow = (fieldName, fieldValue) => {
		return (
			<div className="content__row">
				<div className="content__row__field-name">
					{t(`observation:observation-fields.${fieldName}`)}
				</div>
				<div className="content__row__field-value">{displayValue(fieldName, fieldValue)}</div>
			</div>
		);
	};

	const getChildField = (fieldName) => (childSchema) => {
		const { fieldName: childName } = childSchema;
		const fieldValue = observation.content[fieldName]?.children[childName];

		if (!fieldValue) {
			return null;
		}

		return (
			<React.Fragment key={`${fieldName}-${childName}`}>
				{getRow(childName, fieldValue)}
			</React.Fragment>
		);
	};

	const getGroupField = (groupSchema) => {
		const { fieldName: groupedField } = groupSchema;
		let fieldValue;
		fieldValue = observation.content[groupedField];

		// specific case for all observations (with label signalling) created before the possibility to attach file to observation
		if (!fieldValue && groupedField === 'signalDetailComment') {
			fieldValue = observation.content['signalDetail'];
		}

		// if the field is signalDetailFiles related to observation files then we have to get the files from the api
		const isObservationFileField = groupedField === 'signalDetailFiles';
		/* if (isObservationFileField) {
			return getObservationFiles(groupedField)
		} */
		if (!fieldValue && !isObservationFileField) {
			return null;
		}
		return <React.Fragment key={groupedField}>{getRow(groupedField, fieldValue)}</React.Fragment>;
	};

	const getRepeatField = (fieldName) => (fieldValue, index) => {
		return (
			<React.Fragment key={`${fieldName}${index}`}>{getRow(fieldName, fieldValue)}</React.Fragment>
		);
	};

	const getParentField = (fieldSchema) => {
		const { fieldName, children, group, repeat } = fieldSchema;

		const displayChildrenValue = children
			? observation.content[fieldName]?.value
			: observation.content[fieldName];

		if (group) {
			return <React.Fragment key={fieldName}>{group.map(getGroupField)}</React.Fragment>;
		}

		if (repeat && displayChildrenValue?.length > 0) {
			return (
				<React.Fragment key={fieldName}>
					{displayChildrenValue.map(getRepeatField(fieldName))}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment key={fieldName}>
				{displayChildrenValue && getRow(fieldName, displayChildrenValue)}
				{children && children.map(getChildField(fieldName))}
			</React.Fragment>
		);
	};

	const findObservation = observationTypes.find(
		(obsType) => obsType.name === observation?.observation_type,
	);

	const { fields } = findObservation;

	return <div className={`observation-details ${className}`}>{fields.map(getParentField)}</div>;
};

ObservationDetails.propTypes = {
	className: PropTypes.string,
	observation: PropTypes.object,
};

export default ObservationDetails;
