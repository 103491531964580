import React from 'react';
import PropTypes from 'prop-types';
import SurveyHeader from '../../survey-form/survey-header/survey-header';
import SurveyForm from '../../survey-form/survey-form';
import './survey-details-list.scss';

/**
 * Surveys list with full survey content
 * @param props
 * @param {Array<Object>} props.surveyList
 * @constructor
 */
const SurveyDetailsList = (props) => {
	const { surveyList, displayButton } = props;

	const getSurveyDetails = (survey) => {
		const { id } = survey;
		return (
			<div key={id} className="survey-details-list__item">
				<SurveyHeader
					observationType={survey.observation_type}
					observationContent={survey.observation_content}
					missionCode={survey.mission_code}
				/>
				<SurveyForm
					readOnly
					action="back-to-drivelog"
					surveyToEdit={survey}
					displayButton={displayButton}
				/>
			</div>
		);
	};

	return <div className="survey-details-list">{surveyList.map(getSurveyDetails)}</div>;
};

SurveyDetailsList.propTypes = {
	surveyList: PropTypes.array,
};

export default SurveyDetailsList;
