import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../../core';

/*
	Modal to display when the 'Delete' button is clicked on a mission Item
 */
const DeleteMissionWithObservationsModal = ({ showModal, setShowModal }) => {
	const { t } = useTranslation();

	const modalText = t('dl:dl-detail-page.delete-mission-with-observation-popup');

	const closeCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && (
			<Modal
				type="error"
				text={modalText}
				closeCallback={closeCallback}
				btnName={t('core:modal.understand')}
			/>
		)
	);
};

DeleteMissionWithObservationsModal.propTypes = {
	showModal: PropTypes.bool,
};

export default DeleteMissionWithObservationsModal;
