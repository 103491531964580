import { useState, useEffect } from 'react';

/**
 * Debounce hook can be used to retrieve a value with a set specific delay.
 * @param {*} value - value that needs to be debounced
 * @param {number} delay - time delay in ms, default 1000ms
 * @returns {*} debounced value
 */
const useDebounce = (value, delay = 1000) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		// Set debouncedValue to value (passed in) after the specified delay
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		// Return a cleanup function that will be called every time useEffect is re-called
		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
