export const getColorByFeedbackType = (feedbackType) => {
	let type;
	if (feedbackType === 'Problème technique') {
		type = 'technicalProblem';
	} else if (feedbackType === 'Suggestion') {
		type = 'suggestion';
	} else if (feedbackType === "J'aime / Je n'aime pas") {
		type = 'appreciation';
	}
	return type;
};
