/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setHours, setMinutes, setSeconds, getHours, getMinutes, formatISO } from 'date-fns';
import { TimePicker, DatePickerComponent } from '../../../core';
import './schedule-field.scss';

const ScheduleField = (fieldProps) => {
	const {
		translation = '',
		fieldNames = [],
		values = {},
		handleChange,
		className = '',
		disabled = false,
	} = fieldProps;
	const { t } = useTranslation(translation);

	const [fieldValues, setFieldValues] = useState({});

	// Set props.values to the state (only once) & handleChange with validDate
	useEffect(() => {
		fieldNames.map((fieldName) => {
			setFieldValues((prev) => ({ ...prev, [fieldName]: validDate(values[fieldName]) }));
			return handleChange(fieldName, formatISO(validDate(values[fieldName])));
		});
	}, []);

	// Whenever props.values changes, update the state
	useEffect(() => {
		fieldNames.map((fieldName) =>
			setFieldValues((prev) => ({ ...prev, [fieldName]: validDate(values[fieldName]) })),
		);
	}, [values]);

	const handleDateChange = (fieldName, newValue) => {
		setFieldValues({ ...fieldValues, [fieldName]: newValue });
		handleChange(fieldName, formatISO(newValue));
	};

	const handleTimeChange = (fieldName, isHour = true, newValue) => {
		const newTime = setSeconds(
			isHour
				? setHours(fieldValues[fieldName], newValue)
				: setMinutes(fieldValues[fieldName], newValue),
			0,
		);
		setFieldValues({ ...fieldValues, [fieldName]: newTime });
		handleChange(fieldName, formatISO(newTime));
	};

	const renderFullDateField = (fieldName, idx) => (
		<div key={`schedule__field__input-${idx}`} className="schedule__field__input">
			<div>
				<div className="schedule__field__label">
					{t(`${translation}.forms.fields.labels.${fieldName}`)}
				</div>
				<div className="schedule__field__block">
					<DatePickerComponent
						date={fieldValues[fieldName]}
						onChange={(value) => handleDateChange(fieldName, value)}
						disabled={disabled}
					/>
					<TimePicker
						className="schedule__field__time__cells"
						hours={getHours(fieldValues[fieldName]) || 0}
						setHours={(value) => handleTimeChange(fieldName, true, value)}
						minutes={getMinutes(fieldValues[fieldName]) || 0}
						setMinutes={(value) => handleTimeChange(fieldName, false, value)}
						setTimeError={() => {}}
						disabled={disabled}
					/>
				</div>
			</div>
		</div>
	);

	return (
		<div className={`schedule__field ${className}`}>
			<div className={`schedule__field__container ${className}`}>
				{fieldNames.map((fieldName, idx) => renderFullDateField(fieldName, idx))}
			</div>
		</div>
	);
};

const validDate = (date) => {
	if (date == null) {
		return new Date();
	}

	if (date instanceof Date && !isNaN(date)) {
		return date;
	}

	if (new Date(date) instanceof Date && !isNaN(new Date(date))) {
		return new Date(date);
	}

	return new Date();
};

export default ScheduleField;
