import React from 'react';
import PropTypes from 'prop-types';
import iconRef from '../../assets/icons/symbol-defs.svg';
import './icon.scss';

/**
 * Renders an <Icon /> component
 * @param {object} props
 * @param {string} props.name - icon name
 * @param {string} props.className - the className of the icon
 */
const Icon = (props) => {
	const { name = '', className = '', onClick } = props;
	return (
		<span className={`icon ${className}`} onClick={onClick}>
			<svg role="img" aria-hidden="true" focusable="false">
				<use xlinkHref={`${iconRef}#${name}`} />
			</svg>
		</span>
	);
};

Icon.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
};

export default Icon;
