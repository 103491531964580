import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../core';
import AttachmentCard from '../../../drive-log/components/attachment-list/attachment-card/attachment-card';
import { useAttachmentList } from '../../attachment.services';
import './modify-attachment-schedule-document-page.scss';

const ModifyAttachmentScheduleDocumentPage = () => {
	const { state: stateLocation, search } = useLocation();
	const navigate = useNavigate();
	const { attachmentsWithoutService, loading } = useAttachmentList();
	const { t } = useTranslation('at');
	const { id: driveLogId } = useParams();

	const [currentAttachment, setCurrentAttachment] = useState({});

	const queryParams = queryString.parse(search);
	const { attachment: currentAttachmentBigram, action } = queryParams;

	const getCurrentAttachment = () => {
		const currentAttachmentRetrieved = attachmentsWithoutService?.filter(
			({ bigram = '' }) => bigram === currentAttachmentBigram,
		)[0];
		setCurrentAttachment(currentAttachmentRetrieved);
	};

	const saveNewAttachment = (newAttachmentCode) => {
		const newAttachmentInfo = attachmentsWithoutService.filter(
			({ code = '' }) => code === newAttachmentCode,
		)[0];
		setCurrentAttachment(newAttachmentInfo);
	};

	const generateAttachment = (attachment) => {
		const { code, name, bigram } = attachment;
		const attachmentSelected = currentAttachment?.bigram === bigram;
		return (
			<AttachmentCard
				isSelected={attachmentSelected}
				handleClick={() => saveNewAttachment(code)}
				key={code}
				name={name}
			/>
		);
	};

	const generateAttachmentList = (attachmentList) => {
		if (Array.isArray(attachmentList) && attachmentList.length > 0) {
			return (
				<ul className="modify-attachment-schedule-document-page__attachment-list">
					{attachmentList.map(generateAttachment)}
				</ul>
			);
		}
		return <ul className="modify-attachment-schedule-document-page__attachment-list" />;
	};

	useEffect(getCurrentAttachment, [currentAttachmentBigram, attachmentsWithoutService]);

	return (
		<PopupFullPage
			title={t(`modify-attachment-page.${action}-schedule-documents`)}
			backLink={stateLocation?.originPage}
			className="modify-attachment-schedule-document-page"
		>
			<div className="modify-attachment-schedule-document-page__content">
				<h2 className="modify-attachment-schedule-document-page__title">
					{t('modify-attachment-page.sub-title')}
				</h2>
				<Loader isLoading={!!loading}>{generateAttachmentList(attachmentsWithoutService)}</Loader>
				<button
					className="button"
					onClick={() =>
						navigate({
							pathname: `/drive-log/${driveLogId}/schedule-document`,
							search: `attachment=${currentAttachment?.bigram}&action=${action}`,
						})
					}
				>
					{t('modify-attachment-page.validate')}
				</button>
			</div>
		</PopupFullPage>
	);
};

export default ModifyAttachmentScheduleDocumentPage;
