import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DateUtils } from '../../../../../core';
import './return-date-cell.scss';

const ReturnDateCell = (props) => {
	const { driveLog } = props;

	const { t } = useTranslation('dl');

	const getReturnDate = (dl) => {
		const generateI18nParams = (date) => {
			return {
				date: DateUtils.formatFullDate(date, 'dd/MM/yyyy'),
			};
		};

		return <>{t('dl-table.return-date', generateI18nParams(dl.updated_at))}</>;
	};

	return <div className="return-date-cell">{getReturnDate(driveLog)}</div>;
};

ReturnDateCell.propTypes = {
	driveLog: PropTypes.object,
};

export default ReturnDateCell;
