import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkNetwork } from '../../../config';
import { AdminPage, Loader } from '../../core';
import EmptySplash from '../../core/assets/images/empty-splash.svg';
import { FeedbacksListHeader, FeedbacksListRow } from '../components';
import FeedbackFilters from '../components/feedbacks-filters/feedbacks-filters';
import { getFeedbacks } from '../feedback.service';
import './feedback-page.scss';

const FeedbackPage = () => {
	const { t } = useTranslation('users-feedback');

	const [feedbackFilters, setFeedbackFilters] = useState({
		startDate: null,
		endDate: null,
	});

	const [feedbacks, setFeedbacks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);

	const fetchData = () => {
		setIsLoading(true);
		const online = checkNetwork();
		if (online) {
			getFeedbacks()
				.then((res) => {
					setFeedbacks(res.data || []);
				})
				.catch((error) => {
					console.error('Erreur lors de la récupération des feedbacks', error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const formatDate = (date) => {
		return format(new Date(date), 'yyyy-MM-dd');
	};

	const getFeedbacksByFilters = useCallback(() => {
		let filtered = feedbacks;

		if (feedbackFilters.startDate) {
			filtered = filtered.filter((feedback) => {
				const feedbackDate = formatDate(feedback.created_at);
				const startDate = formatDate(feedbackFilters.startDate);
				return feedbackDate >= startDate;
			});
		}

		if (feedbackFilters.endDate) {
			filtered = filtered.filter((feedback) => {
				const feedbackDate = formatDate(feedback.created_at);
				const endDate = formatDate(feedbackFilters.endDate);
				return feedbackDate <= endDate;
			});
		}

		setFilteredFeedbacks(filtered);
	}, [feedbacks, feedbackFilters]);

	useEffect(() => {
		getFeedbacksByFilters();
	}, [feedbacks, feedbackFilters, getFeedbacksByFilters]);

	const handleChangeDate = (name, value) => {
		setFeedbackFilters((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const renderList = () => {
		return filteredFeedbacks.length > 0 ? (
			<div className="feedbacks-list">
				<FeedbacksListHeader />
				<div className="feedbacks-list__rows">{filteredFeedbacks.map(renderRows)}</div>
			</div>
		) : (
			<div className="feedbacks-list__empty">
				<div className="no-feedbacks__image" style={{ backgroundImage: `url(${EmptySplash})` }} />
				<p className="no-feedbacks__text">{t('feedback-page.no-feedbacks')}</p>
			</div>
		);
	};

	const renderRows = (feedback) => <FeedbacksListRow key={feedback.id} feedback={feedback} />;

	return (
		<AdminPage selectedLink={1}>
			<div className="feedback-page">
				<div className="feedback-page__content">
					<h1>{t('feedback-page.title')}</h1>
					<FeedbackFilters
						onDateChange={handleChangeDate}
						feedbackFilters={feedbackFilters}
						setFeedbackFilters={setFeedbackFilters}
						filteredFeedbacks={filteredFeedbacks}
					/>
					<Loader isLoading={isLoading}>{renderList()}</Loader>
				</div>
			</div>
		</AdminPage>
	);
};

export default FeedbackPage;
