import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { http } from '../../../../config';
import { getAllObservationsInDl } from '../../../observation/observation.services';
import DlMissionList from '../../pages/driver/dl-page/components/dl-mission-list/dl-mission-list';
import NoMissionObservations from '../../pages/driver/dl-page/components/no-mission-observations/no-mission-observations';
import './mission-list-all-obs.scss';

const MissionListAllObs = (props) => {
	const { setLoading, redirectUrl = '', showHeader = true } = props;
	const { t } = useTranslation();
	const { id: driveLogId } = useParams();
	const [observations, setObservations] = useState([]);
	const { data: missions } = useQuery({
		queryKey: ['dlMissions', driveLogId],
		queryFn: async () => {
			if (!driveLogId) return [];
			const response = await http.get(`/drive-log/${driveLogId}/mission`);
			return response.data;
		},
	});

	const retrieveObservationsList = useCallback(() => {
		getAllObservationsInDl(driveLogId)
			.then((res) => {
				setObservations(res?.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId, setLoading]);

	useEffect(retrieveObservationsList, [driveLogId, retrieveObservationsList]);

	return (
		<>
			<div>
				<h2 className="mission-list-and-obs__title">
					<span>{t('dl:dl-details.missions-done.title')}</span>
				</h2>
				<DlMissionList
					className="mission-list-and-obs__missions"
					missionsList={missions}
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					readOnly={true}
					redirectUrl={redirectUrl}
					showHeader={showHeader}
				/>
				<NoMissionObservations
					className="mission-list-and-obs__no-mission-obs"
					observations={observations}
					retrieveObservationsList={retrieveObservationsList}
					readOnly={true}
					redirectUrl={redirectUrl}
				/>
			</div>
		</>
	);
};

export default MissionListAllObs;
