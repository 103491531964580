import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import { useLine } from '../../../../observation/custom-hooks/observation-custom-hooks';
import { getPccNotices } from '../../../services';
import PccNoticeCard from './components/pcc-notice-card';
import './dl-pcc-notice-page.scss';

const REFRESH_PCC_NOTICE = 30000;

const DlPccNoticePage = () => {
	const { t } = useTranslation();
	const { id: driveLogId = '' } = useParams();
	const [line] = useLine();
	const [isLoading, setIsLoading] = useState(true);
	const [pccNoticesList, setPccNoticesList] = useState([]);
	const [hasFetched, setHasFetched] = useState(false);

	useEffect(() => {
		let timeoutId;
		if (line) {
			const fetchPccNotices = async () => {
				getPccNotices({ line })
					.then((response) => {
						setPccNoticesList(response?.data);
					})
					.catch((err) => {
						console.error(err);
					})
					.finally(() => {
						setIsLoading(false);
						setHasFetched(true);
					});

				timeoutId = setTimeout(fetchPccNotices, REFRESH_PCC_NOTICE);
			};

			fetchPccNotices();
		} else {
			setPccNoticesList([]);
			setIsLoading(false);
			setHasFetched(false);
		}

		return () => {
			if (timeoutId) clearTimeout(timeoutId);
		};
	}, [line]);

	const displayPccNoticeCard = (pccNotice) => {
		return <PccNoticeCard key={pccNotice.id} pccNotice={pccNotice} />;
	};

	const displayPccNoticesResult = () => {
		if (pccNoticesList?.length > 0) {
			return (
				<div className="dl-pcc-notice-page__pcc-notices">
					{pccNoticesList.map(displayPccNoticeCard)}
				</div>
			);
		}
		if (hasFetched && !isLoading) {
			return (
				<div className="dl-pcc-notice-page__no-result">
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
					<h1 className="no-result__text"> {t('dl:dl-documents.pcc-notice.no-result')} </h1>
				</div>
			);
		}
		return null;
	};

	return (
		<PopupFullPage
			className="dl-pcc-notice-page"
			title={t('dl:dl-documents.pcc-notice.title')}
			backLink={`/drive-log/${driveLogId}`}
		>
			<Loader isLoading={isLoading}>{displayPccNoticesResult()}</Loader>
		</PopupFullPage>
	);
};

export default DlPccNoticePage;
