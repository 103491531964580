import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon, InputCheckbox } from '../../../../core';
import './export-attachment-line-selector.scss';
import linesIconsName from '../../../utils/user-lines-icons.json';

const ExportAttachmentLineSelector = (props) => {
	const {
		displayLineSelector = false,
		line = '',
		onChange = () => {},
		lineSelectedHandler = () => {},
	} = props;

	const [isChecked, setIsChecked] = useState(false);

	const handleCheckBoxState = (checked, element) => {
		setIsChecked(checked);
		onChange({ target: { name: 'lines', value: checked ? [element] : [] } });
		lineSelectedHandler(checked, element);
	};

	const { t } = useTranslation('exp');

	useEffect(() => {
		if (!displayLineSelector) {
			onChange({ target: { name: 'lines', value: [line] } });
		}
	}, [displayLineSelector, line, onChange]);

	// rendered when line selection is available
	const renderLineSelector = () => {
		return (
			<InputCheckbox
				type="checkbox"
				name={line}
				handleClick={handleCheckBoxState}
				isChecked={isChecked}
			/>
		);
	};

	const lineSelectorStyle = classNames('attachment-picker__section__line__selector', {
		'attachment-picker__section__line__selector--multiple': displayLineSelector,
	});

	return (
		<div className={lineSelectorStyle}>
			{displayLineSelector && renderLineSelector()}
			<span className="attachment-picker__section__line__selector__text">
				{t('exp:sections.attachments-picker.line')}
			</span>
			<Icon
				className="attachment-picker__section__line__selector__icon"
				name={line ? linesIconsName[line] : ''}
			/>
		</div>
	);
};

export default ExportAttachmentLineSelector;
