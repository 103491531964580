import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GenericHeader from '../generic-header/generic-header';

/**
 * Renders an <AdminHeader /> component
 * @param {object} props
 * @param {string} props.className - className to add to the Admin header Component
 * @param {number} props.selectedLink - the index of the active link (Utilisateur = 0, Feedback = 1)
 * @param {boolean} props.hideNav - whether to hide navigation links
 */
const AdminHeader = (props) => {
	const { className = '', selectedLink = 0, hideNav = false } = props;
	const { t } = useTranslation();

	const links = [
		{ target: '/admin/users-list', text: t('core:header.users') },
		{ target: '/admin/feedback', text: t('core:header.feedback') },
	];

	return (
		<GenericHeader
			className={className}
			links={links}
			selectedLink={selectedLink}
			hideNav={hideNav}
		/>
	);
};

AdminHeader.propTypes = {
	className: PropTypes.string,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
};

export default AdminHeader;
