import React from 'react';
import { useTranslation } from 'react-i18next';
import './feedbacks-list-header.scss';

const FeedbacksListHeader = () => {
	const { t } = useTranslation('users-feedback');

	return (
		<div className="feedbacks-list-header">
			<div className="feedbacks-list-header__cell">
				{t('feedback-page.feedback-form.thead.category')}
			</div>
			<div className="feedbacks-list-header__cell">
				{t('feedback-page.feedback-form.thead.date')}
			</div>
			<div className="feedbacks-list-header__cell">
				{t('feedback-page.feedback-form.thead.comment')}
			</div>
			<div className="feedbacks-list-header__cell">
				{t('feedback-page.feedback-form.thead.user-id')}
			</div>
		</div>
	);
};

export default FeedbacksListHeader;
