/**
 * Check if file is a pdf
 * @param fileType
 * @param isObservationFile
 */
const checkFileType = (fileType, isObservationFile) => {
	if (isObservationFile && fileType !== 'image/jpeg') {
		throw Error('dl:signature-page.file-attachment.error-message.file-type');
	} else if (!isObservationFile && fileType !== 'application/pdf') {
		throw Error('dl:signature-page.file-attachment.error-message.file-type');
	}
	return true;
};

/**
 * Check if file size is under 5Mo
 * @param fileSize
 */
const checkFileSize = (fileSize) => {
	const fileAttachmentMaxSize = 5000000;
	if (fileSize > fileAttachmentMaxSize) {
		throw Error('dl:signature-page.file-attachment.error-message.file-size');
	}
	return true;
};

/**
 * Check is file can be added
 * @param fileType
 * @param fileSize
 * @param isObservationFile
 */
const checkAddFile = (fileType, fileSize, isObservationFile = false) => {
	checkFileSize(fileSize);
	checkFileType(fileType, isObservationFile);
	return true;
};

export default checkAddFile;
