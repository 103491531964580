import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from '../../../../index';
import './thanks-feedback-modal.scss';

const ThanksFeedbackModal = (props) => {
	const { showModal, toggleModal } = props;
	const { t } = useTranslation();

	const handleClick = () => {
		toggleModal(!showModal);
	};

	return (
		showModal && (
			<Modal type="custom">
				<div>
					<button className="thanks-modal__close-icon" onClick={handleClick}>
						<Icon name="close" />
					</button>
					<div className="thanks-modal__content">
						<div>
							<p className="thanks-modal__content__title">
								{t('core:feedback.thanks-modal.title')}
							</p>
						</div>
						<div className="thanks-modal__content__icon">
							<Icon name="thumb" className="thanks-modal__content__thumb-icon" />
						</div>
						<div>
							<p className="thanks-modal__content__text">{t('core:feedback.thanks-modal.text')}</p>
						</div>
					</div>
					<div className="thanks-modal__button">
						<Button className="button" onClick={handleClick}>
							{t('core:feedback.thanks-modal.back')}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
};

ThanksFeedbackModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
};

export default ThanksFeedbackModal;
