import React from 'react';
import { Assets, HrPage, LoadMore } from '../../../../core';
import { useTranslation } from 'react-i18next';
import useDrivelogList from '../../../../core/custom-hooks/use-drive-log-list';
import DriveLogDelayDetailsTable from '../../../components/drive-log-delay-details-table/drive-log-delay-details-table';
import './dl-to-be-archived.scss';

const DlToBeArchived = () => {
	const { t } = useTranslation('dl');

	const { driveLogs, loadMore, refresh, totalResultCount } = useDrivelogList({
		status: ['validated'],
		sortBy: ['attachment_name'],
		restrictedLine: true,
	});

	const generateDriveLogList = () => {
		return (
			<>
				<h2 className="dl-to-be-archived-page__title">
					{t('drive-log-to-archive-page.title', { count: totalResultCount })}
				</h2>
				<DriveLogDelayDetailsTable driveLogs={driveLogs} hrToArchiveView={true} refresh={refresh} />
				<div className="archived-dl-page__controls">
					{driveLogs?.length < totalResultCount && <LoadMore onClick={() => loadMore(40)} />}
				</div>
			</>
		);
	};

	const generateEmptyDls = () => {
		return (
			<div className="dl-to-be-archived-page__empty-dls">
				<img
					className="dl-to-be-archived-page__searching-mouse-img"
					src={Assets.SearchingMouse}
					alt="No DriveLog to validate"
				/>
				<div className="dl-to-be-archived-page__empty-dls-label">
					{t('drive-log-to-archive-page.no-drive-log-to-archive')}
				</div>
			</div>
		);
	};

	return (
		<HrPage className="dl-to-be-archived-page">
			{totalResultCount === 0 ? generateEmptyDls() : generateDriveLogList()}
		</HrPage>
	);
};

export default DlToBeArchived;
