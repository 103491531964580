/**
 * Simple string hash function
 *
 * source: https://stackoverflow.com/a/52171480
 * source: https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
 */
export const hash = (key, seed = 0) => {
	const A = 2654435761;
	const B = 1597334677;
	const C = 2246822507;
	const D = 3266489909;

	let h1 = 0xdeadbeef ^ seed;
	let h2 = 0x41c6ce57 ^ seed;

	for (let index = 0, char; index < key.length; index++) {
		char = key.charCodeAt(index);

		h1 = Math.imul(h1 ^ char, A);
		h2 = Math.imul(h2 ^ char, B);
	}

	h1 = Math.imul(h1 ^ (h1 >>> 16), C) ^ Math.imul(h2 ^ (h2 >>> 13), D);
	h2 = Math.imul(h2 ^ (h2 >>> 16), C) ^ Math.imul(h1 ^ (h1 >>> 13), D);

	return (h2 >>> 0).toString(16).padStart(8, '0') + (h1 >>> 0).toString(16).padStart(8, '0');
};
