import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { InputCheckbox, ResponsiveText } from '../../../core';
import './export-list-container.scss';

const ExportListContainer = (props) => {
	const {
		list = [],
		subtitle = '',
		type = '',
		updateItemCheckStateHandler = () => {},
		updateAllCheckStateHandler = () => {},
		atLeastOneChecked = false,
	} = props;
	const { t } = useTranslation('exp');

	const exportContainerClass = classNames('export-list-container', {
		'export-list-container--vertical': type === 'attachments' || type === 'observations',
		'export-list-container--horizontal': type === 'resources',
	});

	const generateListItems = (listItems) => {
		if (Array.isArray(listItems) && listItems.length > 0) {
			return (
				<ul className={`export-list-container__${type}__list`}>
					{listItems.map(generateListItem)}
				</ul>
			);
		}
		return <ul className={'export-list-container__list'} />;
	};

	const generateListItem = (item, index) => {
		const spanContent =
			type === 'observations' ? t(`observation:observation-list.types.${item.name}`) : item.name;

		const listItemStyle = classNames(`export-list-container__${type}__list__item`, {
			[`export-list-container__${type}__list__item--checked`]: item.isChecked,
		});

		return (
			<li key={item.name} className={listItemStyle}>
				<InputCheckbox
					type="checkbox"
					name={item.name}
					handleClick={() => updateItemCheckStateHandler(index)}
					isChecked={item.isChecked}
				/>
				<span className={`export-list-container__${type}__list__item__text`}>{spanContent}</span>
			</li>
		);
	};

	return (
		<div className={exportContainerClass}>
			{(type === 'attachments' || type === 'observations') && (
				<div className="export-list-container__header">
					<ResponsiveText
						text={subtitle}
						mobileText={subtitle}
						className="export-list-container__header__subtitle"
					/>
					<button
						type="button"
						className="export-list-container__header__button"
						onClick={() => updateAllCheckStateHandler(atLeastOneChecked)}
					>
						<span>
							{atLeastOneChecked ? t('exp:sections.deselect-all') : t('exp:sections.select-all')}
						</span>
					</button>
				</div>
			)}
			{generateListItems(list)}
		</div>
	);
};

ExportListContainer.propTypes = {
	list: PropTypes.array,
	subtitle: PropTypes.string,
	type: PropTypes.string,
};

export default ExportListContainer;
