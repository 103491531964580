import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../user/auth/context/auth-context';
import { useAttachmentList } from '../../../attachment/attachment.services';
import { Loader } from '../../../core';
import { ExportAttachmentLineSelector, ExportListContainer, ExportSectionTitle } from '../../';
import './export-attachment-picker.scss';

const ExportAttachmentPicker = (props) => {
	const { onChange = () => {} } = props;
	const { attachments, loading } = useAttachmentList(true);
	const [updatedAttachmentsList, setUpdatedAttachmentsList] = useState([]);
	const [isItemChecked, setIsItemChecked] = useState(false);

	const [linesSelected, setLineSelected] = useState([]);
	const [filteredAttachmentsList, setFilteredAttachmentsList] = useState([]);

	const { user = {} } = useContext(AuthContext);
	const { role = '', line = '', attachment_code = '' } = user;

	const { t } = useTranslation('exp');

	//keeps track of lines selected when line selection is available
	const handleLineSelected = (checked, value) => {
		if (checked) {
			setLineSelected((prev) => [...prev, value]);
		} else {
			setLineSelected((prev) => prev.filter((lineAvailable) => lineAvailable !== value));
		}
	};

	// verify if list item state is checked
	const isChecked = (listEl) => !!listEl.isChecked;

	// valid if you can choose between lines
	const verifyLinesSelection = (userAttachmentCode, userRole) => {
		const isAdminRole = userRole === 'admin' || userRole === 'localAdmin';
		return isAdminRole || (userRole === 'amp' && userAttachmentCode === 'BPSG');
	};
	const linesSelectionAvailable = verifyLinesSelection(attachment_code, role);

	const updateAllCheckState = (checkedState) => {
		const checkAllState = filteredAttachmentsList.map((listItem) => ({
			...listItem,
			isChecked: !checkedState,
		}));
		setUpdatedAttachmentsList(checkAllState);
	};

	// update checked state of element
	const updateItemCheckState = (index) => {
		const newData = filteredAttachmentsList.map((listItem, currentIndex) => {
			if (currentIndex === index) {
				return { ...listItem, isChecked: !listItem.isChecked };
			}
			return listItem;
		});
		setFilteredAttachmentsList(newData);
	};

	// handler to update formData base on attachments selected
	// TODO: to improve ! not satisfied with logic
	const handleFormData = useCallback(() => {
		const atLeastOneChecked = filteredAttachmentsList.some(isChecked);
		setIsItemChecked(atLeastOneChecked);
		if (atLeastOneChecked) {
			const attachmentsChecked = filteredAttachmentsList
				.filter((attachment) => attachment.isChecked)
				.map((attachment) => attachment.name);
			onChange({ target: { name: 'attachments', value: [...attachmentsChecked] } });
		} else {
			onChange({ target: { name: 'attachments', value: null } });
		}
	}, [filteredAttachmentsList, onChange]);

	// handle filtered attachments list state
	useEffect(() => {
		if (linesSelected.length === 1) {
			const data = [...updatedAttachmentsList];
			setFilteredAttachmentsList(
				data.filter((attachment) => attachment.line.includes(linesSelected)),
			);
		} else {
			setFilteredAttachmentsList(updatedAttachmentsList);
		}
	}, [linesSelected, updatedAttachmentsList]);

	// TODO: to improve not satisfied with logic
	// not a single source of truth
	useEffect(() => {
		if (attachments) {
			const updatedList = attachments
				.filter((attachment) => attachment.name !== 'PSG')
				.map((element) => ({ ...element, isChecked: false }));
			setUpdatedAttachmentsList(updatedList);
		}
	}, [attachments]);

	useEffect(() => {
		handleFormData();
	}, [handleFormData]);

	return (
		<div className="attachment-picker__section">
			<ExportSectionTitle
				sectionNumber={t('exp:sections.attachments-picker.section-number')}
				sectionTitle={
					linesSelectionAvailable
						? t('exp:sections.attachments-picker.section-title.two-lines')
						: t('exp:sections.attachments-picker.section-title.one-line')
				}
			/>
			<div className="attachment-picker__section__line">
				{linesSelectionAvailable ? (
					<>
						<ExportAttachmentLineSelector
							displayLineSelector={linesSelectionAvailable}
							line="A"
							lineSelectedHandler={handleLineSelected}
							onChange={onChange}
						/>
						<ExportAttachmentLineSelector
							displayLineSelector={linesSelectionAvailable}
							line="B"
							lineSelectedHandler={handleLineSelected}
							onChange={onChange}
						/>
					</>
				) : (
					<ExportAttachmentLineSelector line={line} onChange={onChange} />
				)}
			</div>
			<Loader isLoading={!!loading}>
				<ExportListContainer
					list={filteredAttachmentsList}
					subtitle={t('exp:sections.attachments-picker.section-content-header.subtitle')}
					type="attachments"
					updateItemCheckStateHandler={updateItemCheckState}
					updateAllCheckStateHandler={updateAllCheckState}
					atLeastOneChecked={isItemChecked}
				/>
			</Loader>
		</div>
	);
};

export default ExportAttachmentPicker;
