import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DriveLogTable from '../../../../../components/drive-log-table/drive-log-table';
import driveLogServices from '../../../../../drive-log.services';
import { checkArrayEmpty, LoadMore } from '../../../../../../core';
import { MATOMO_ACTIONS, MATOMO_CATEGORIES } from '../../../../../../core/matomo/matomo-constants';
import ValidateSimpleDlsButton from '../validate-simple-dls-button/validate-simple-dls-button';
import useMatomoTracker from '../../../../../../core/matomo/matomo-tracker';
import './simple-drive-logs.scss';

const SimpleDriveLogs = (props) => {
	const { driveLogs, refresh, loadMore, totalResultCount, currentAttachment } = props;
	const { t } = useTranslation('dl');
	const { trackEvent } = useMatomoTracker();

	const driveLogsId = driveLogs?.map((dl) => dl.id);

	const validateAllSimpleDriveLogs = () => {
		driveLogServices
			.batchUpdateDriveLog('validateSimpleDriveLogs', currentAttachment?.code, driveLogsId)
			.then(() => {
				if (currentAttachment?.code !== 'BPSG') {
					trackEvent({
						category: MATOMO_CATEGORIES.DRIVELOG,
						action: MATOMO_ACTIONS.VALIDATE_MULTI,
						value: driveLogs?.length,
					});
				}
				refresh();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const renderDriveLogsList = () => {
		const simpleDriveLogsEmpty = checkArrayEmpty(driveLogs);
		if (!driveLogs || simpleDriveLogsEmpty) {
			return null;
		}

		return (
			<>
				<div className="simple-dls__header">
					<h2 className="simple-dls__title">
						{t('supervisor-dl-list-page.number-dl-to-not-be-validated', {
							count: driveLogs?.length,
						})}
					</h2>
					{driveLogs.length > 0 && (
						<ValidateSimpleDlsButton
							validateAllSimpleDriveLogs={validateAllSimpleDriveLogs}
							resultCount={driveLogs?.length}
						/>
					)}
				</div>
				<DriveLogTable driveLogs={driveLogs} isSimpleDL={true} />
				<div className="simple-dls__controls">
					{driveLogs.length < totalResultCount && <LoadMore onClick={() => loadMore(40)} />}
				</div>
			</>
		);
	};

	return <section className="simples-dls">{renderDriveLogsList()}</section>;
};

SimpleDriveLogs.props = {
	driveLogs: PropTypes.array,
	refresh: PropTypes.func,
	loadMore: PropTypes.func,
	totalResultCount: PropTypes.number,
	currentAttachment: PropTypes.string,
};

export default SimpleDriveLogs;
