import React, { Component } from 'react';
import { ErrorPage } from '../../index';

// ErrorBoundary is not a hooks component because error boundary feature is only available as class component.
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, errorInfo: null };
		this.t = props.t;
	}

	componentDidCatch(error, errorInfo) {
		console.error(error, errorInfo);
		this.setState({ hasError: true, errorInfo: error?.message || null });
	}

	render() {
		if (this.state.hasError) {
			return (
				<ErrorPage
					handleOnExit={() => this.setState({ hasError: false })}
					errorMessage={this.state.errorInfo}
				/>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
