import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PopupFullPage } from '../../../core';
import { DriveLogSubHeader } from '../../../drive-log';
import { getDriveLogById } from '../../../drive-log/drive-log.services';
import observationTypes from '../../forms/observation-types';
import ObservationCard from './observation-card/observation-card';
import './observation-list.scss';

const ObservationList = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;

	const { t } = useTranslation();
	const { id: driveLogId, missionId, code } = useParams();
	const [line, setLine] = useState();

	const linkedEntity = missionId ? 'mission' : 'drive-log';

	useEffect(() => {
		getDriveLogById(driveLogId)
			.then((res) => {
				if (res.data) setLine(res?.data?.line_number);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [driveLogId]);

	const observationSubHeader = () => {
		if (missionId) {
			return (
				<>
					{t('observation:observation-list.heading.mission-related')}
					{code && <div className="observation-list__subheader__code">{code}</div>}
				</>
			);
		}
		return t('observation:observation-list.heading.drive-log-related');
	};

	const displayObservationCard = (observationType) => {
		return (
			<ObservationCard
				driveLogId={driveLogId}
				key={observationType.name}
				type={observationType}
				missionId={missionId}
				redirectUrl={redirectUrl}
			/>
		);
	};

	const getBackLink = () => {
		return `${redirectUrl}/${driveLogId}`;
	};

	/**
	 * get the observation list cards filtered by entity and by line
	 * @returns {Object} Returns <ObservationCard> filtered list
	 */
	const getObsListFilteredByEntityAndLine = () => {
		const obsFiltered = observationTypes.filter(({ linkedEntities, linkedLines }) => {
			const matchEntity = linkedEntities.includes(linkedEntity);
			const matchLine = linkedLines.includes(line);
			return matchEntity && matchLine;
		});

		return obsFiltered.map(displayObservationCard);
	};

	return (
		<PopupFullPage
			className="observation-list"
			title={t('observation:observation-list.title')}
			backLink={getBackLink()}
		>
			<DriveLogSubHeader className="observation-list__subheader">
				{observationSubHeader()}
			</DriveLogSubHeader>
			<ul className="observation-list__wrapper">{line && getObsListFilteredByEntityAndLine()}</ul>
		</PopupFullPage>
	);
};

export default ObservationList;
