import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon, Button, Modal } from '../../../core';
import SpecialNoticeDetailsModal from '../special-notice-details-modal/special-notice-details-modal';
import { updateSpecialNotice } from '../../special-notice.services';
import './special-notice-list-row.scss';

const broadcastTypes = {
	Station: 'station',
	Zone: 'zone',
	Line: 'line',
};

/**
 * SpecialNoticeListRow component display a row for each special-notice
 * @param props
 * @param {object} props.specialNotice
 * @param {string} props.className
 * @return {JSX.Element}
 * @constructor
 */
const SpecialNoticeListRow = (props) => {
	const { specialNotice, className = '', enclosed = false, refreshSpecialNotices } = props;

	const { t } = useTranslation('special-notice');
	const [showDetailsModal, setShowDetailsModal] = useState(false);
	const [showEncloseNoticeModal, setShowEncloseNoticeModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const {
		id,
		broadcast_type,
		broadcast_station,
		broadcast_station_start,
		broadcast_station_end,
		is_all_missions,
		missions,
		title,
		is_not_scheduled,
		schedule_start,
		schedule_end,
		created_at,
		created_by,
		enclosed_at,
	} = specialNotice;

	const handleRead = () => {
		setShowDetailsModal(!showDetailsModal);
	};

	const handleEnclose = (e) => {
		e.stopPropagation();
		setShowEncloseNoticeModal(!showEncloseNoticeModal);
	};

	const updateNoticeStatus = (action) => {
		const params = { action: action };
		setIsLoading(true);
		updateSpecialNotice(id, specialNotice, params)
			.then((res) => {
				setShowEncloseNoticeModal(false);
				refreshSpecialNotices();
			})
			.catch((error) => console.error(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const renderBroadcastZone = () => {
		if (broadcast_type === broadcastTypes.Station) {
			return <span className="special-notice-list-row__cell-station">{broadcast_station}</span>;
		} else if (broadcast_type === broadcastTypes.Zone) {
			return (
				<span>
					{t('special-notice.list.cells.between')}{' '}
					<span className="special-notice-list-row__cell-station">{broadcast_station_start}</span>{' '}
					{t('special-notice.list.cells.and')}{' '}
					<span className="special-notice-list-row__cell-station">{broadcast_station_end}</span>
				</span>
			);
		} else if (broadcast_type === broadcastTypes.Line) {
			return (
				<span className="special-notice-list-row__cell-station">
					{t('special-notice.list.cells.whole-line')}
				</span>
			);
		}
	};

	const renderMissions = () => {
		if (is_all_missions || !missions) {
			return (
				<div className="special-notice-list-row__cell-mission-container">
					<span className="special-notice-list-row__cell-mission special-notice-list-row__cell-mission--all">
						{t('special-notice.list.cells.all-missions')}
					</span>
				</div>
			);
		}

		return missions.map((mission) => (
			<div className="special-notice-list-row__cell-mission-container">
				<span className="special-notice-list-row__cell-mission">{mission}</span>
			</div>
		));
	};

	const renderCreatedBy = () => {
		// Remove the initials from the id
		return created_by.slice(2);
	};

	const renderScheduleIfNotEnclosed = () => {
		if (enclosed) {
			return null;
		}

		if (is_not_scheduled) {
			return (
				<div className="special-notice-list-row__cell">
					<span className="special-notice-list-row__cell-schedule">
						{t('special-notice.list.cells.indefinitely')}
					</span>
				</div>
			);
		}

		const start = new Date(schedule_start);
		const end = new Date(schedule_end);

		return renderScheduleData(start, end);
	};

	const renderScheduleData = (start, end) => {
		return (
			<div className="special-notice-list-row__cell">
				<span className="special-notice-list-row__cell-schedule">
					<div>{start.toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}</div>
					<Icon name="arrow-right" className="special-notice-list-row__cell-schedule__arrow" />
					<div>{end.toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })}</div>
				</span>
			</div>
		);
	};

	const renderAction = () => {
		if (enclosed) {
			return (
				<Link to={`/special-notice/${id}/republish`}>
					<Button className="button--outline special-notice-list-row__cell-action">
						{t('special-notice.forms.buttons.republish')}
					</Button>
				</Link>
			);
		}

		return (
			<Button
				className="button--outline special-notice-list-row__cell-action"
				onClick={(e) => handleEnclose(e)}
			>
				{t('special-notice.forms.buttons.enclose')}
			</Button>
		);
	};

	const renderDatesIfEnclosed = () => {
		if (!enclosed) {
			return null;
		}

		const start = is_not_scheduled ? new Date(created_at) : new Date(schedule_start);
		const end = is_not_scheduled ? new Date(enclosed_at) : new Date(schedule_end);

		return (
			<>
				<div className="special-notice-list-row__cell">
					{start.toLocaleString([], { dateStyle: 'short' })}
				</div>
				<div className="special-notice-list-row__cell">
					{end.toLocaleString([], { dateStyle: 'short' })}
				</div>
			</>
		);
	};

	return (
		<>
			<div
				className={`${className} special-notice-list-row  ${enclosed ? 'special-notice-list-row--enclosed' : ''}`}
				onClick={() => handleRead()}
			>
				{renderDatesIfEnclosed()}
				<div className="special-notice-list-row__cell">{renderBroadcastZone()}</div>
				<div className="special-notice-list-row__cell">{renderMissions()}</div>
				<div className="special-notice-list-row__cell">{title}</div>
				<div className="special-notice-list-row__cell">{renderCreatedBy()}</div>
				{renderScheduleIfNotEnclosed()}
				<div className="special-notice-list-row__cell">{renderAction()}</div>
			</div>

			<SpecialNoticeDetailsModal
				showModal={showDetailsModal}
				setShowModal={setShowDetailsModal}
				values={specialNotice}
				enclosed={enclosed}
			/>
			{showEncloseNoticeModal && (
				<Modal
					type="confirm"
					title={t('special-notice.modals.enclose.title')}
					text="special-notice:special-notice.modals.enclose.content"
					cancelText={t('special-notice.modals.enclose.cancel')}
					confirmText={t('special-notice.modals.enclose.validate')}
					confirmCallback={() => updateNoticeStatus('enclose')}
					cancelCallback={() => setShowEncloseNoticeModal(false)}
					closeCallback={() => setShowEncloseNoticeModal(false)}
					isLoading={isLoading}
					iconName="warning-triangle"
				/>
			)}
		</>
	);
};

export default SpecialNoticeListRow;
