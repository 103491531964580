import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../core/components/icon-svg/icon';
import { getFileAttachmentByIdAndDl } from '../../../file-attachment.services';
import classNames from 'classnames';
import './file-attachment-card.scss';

/**
 * Renders FileAttachment card component
 */
const FileAttachmentCard = (props) => {
	const { handleDeleteFile, file, readOnly, driveLogId, observationId } = props;
	const { file_name: fileName, id: fileAttachmentId } = file;

	const downloadFileAttachment = () => {
		if (!observationId) {
			getFileAttachmentByIdAndDl(driveLogId, fileAttachmentId)
				.then((response) => {
					const fileURL = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.download = fileName;
					link.href = fileURL;
					link.click();
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	return (
		<div
			className={classNames('file-attachment-card', {
				'file-attachment-card--pointer': !observationId,
			})}
		>
			<div
				className="file-attachment-card__content"
				onClick={observationId ? () => {} : downloadFileAttachment}
			>
				<Icon
					className="file-attachment-card__content__trombone-icon"
					aria-label="Trombone"
					name="trombone"
				/>
				<span
					className={classNames('file-attachment-card__content__label', {
						'file-attachment-card__content__label--pointer': !observationId,
					})}
				>
					{fileName}
				</span>
			</div>
			{!readOnly && (
				<div className="file-attachment-card__close-icon">
					<button
						className="file-attachment-card__close-icon__button"
						onClick={() => handleDeleteFile(file)}
					>
						<Icon name="close" />
					</button>
				</div>
			)}
		</div>
	);
};

FileAttachmentCard.propTypes = {
	files: PropTypes.object,
	handleDeleteFile: PropTypes.func,
	readOnly: PropTypes.bool,
	driveLogId: PropTypes.string,
};

export default FileAttachmentCard;
