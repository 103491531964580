import { addHours, isBefore, startOfDay } from 'date-fns';

/**
 * Check if stored filters are still valid
 * @param {Date} date - object filters
 * @return {boolean} - return true if filters have been stored before current day 3am
 */
const checkFilterValidity = (date) => {
	// format storage date of the filter
	const storedDate = new Date(date);
	// define expiredDate (current day at 3am)
	const expiredDate = addHours(startOfDay(new Date()), 3);
	//check if filter is expired
	return isBefore(storedDate, expiredDate);
};

/**
 * Retrieve stored filter in the locale storage base on the key
 * @param {string} storageName - the key in the locale storage
 * @return {object} - object filter
 */
const getStoredFilter = (storageName) => {
	//retrieve stored filter base on key
	const storedFilter = localStorage.getItem(storageName);
	let parsedStoredFilter = JSON.parse(storedFilter);
	const { dateStored } = storedFilter || {};
	const filterIsExpired = checkFilterValidity(dateStored);
	// if filter is expired, remove th filter from the local storage
	if (filterIsExpired) {
		localStorage.removeItem(storageName);
		return {};
	}
	return parsedStoredFilter;
};

export { getStoredFilter, checkFilterValidity };
