import { useQuery } from '@tanstack/react-query';
import base64 from 'base-64';
import { formatISO, parseISO } from 'date-fns';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { http, useAppEnv } from '../../../../config';
import localForage from '../../../../config/local-forage/index';
import { getExpiredDataFromApi } from '../../../../config/offline/dispatch-get-request';
import { useMissionsQueries } from '../../../../config/react-query/missions.query';
import { Button, Loader, Modal } from '../../../core';
import Icon from '../../../core/components/icon-svg/icon';
import PopupFullPage from '../../../core/components/page-wrappers/popup-full-page/popup-full-page';
import { getReferentialList } from '../../../core/core.services';
import { getDriveLogById } from '../../../drive-log/drive-log.services';
import ObservationSearch from '../../../observation/components/observation-form/observation-fields/observation-search/observation-search';
import ObservationTime from '../../../observation/components/observation-form/observation-fields/observation-time/observation-time';
import { useLine } from '../../../observation/custom-hooks/observation-custom-hooks';
import { getAllObservationsInDl } from '../../../observation/observation.services';
import './modify-stations.scss';

const ModifyStations = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const param = queryString.parse(location.search);
	const { updateMission } = useMissionsQueries();

	const env = useAppEnv();

	const { id: driveLogId, missionId, station } = useParams();
	// redirect to the previous page according to the redirectUrl param
	const previousPage = `${redirectUrl}/${driveLogId}`;

	const [stationsList, setStationsList] = useState();

	const [timeError, setTimeError] = useState(true);

	const [line] = useLine();

	const [observations, setObservations] = useState([]);
	const [driveLog, setDriveLog] = useState({});
	const [rfnStation, setRfnStation] = useState([]);
	const [showRfnConfirmModal, setShowRfnConfirmModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingMsg, setLoadingMsg] = useState('');

	const isStationStart = station && station === 'station-start';
	const { data: mission } = useQuery({
		queryKey: ['dlMission', missionId],
		queryFn: async () => {
			if (!missionId) return [];
			const response = await http.get(`/drive-log/${driveLogId}/mission/${missionId}`);
			return response.data;
		},
	});
	const {
		id: currentMissionId,
		code: missionCode,
		train_number: trainNumber,
		train_composition: trainComposition,
		station_start: missionStationStart,
		station_end: missionStationEnd,
	} = mission || {};

	const [fields, setFields] = useState({
		station: {},
	});

	const launchMission = param.start === 'launch-mission';
	// get "fromApp" & "station" params from Digiplan redirection when stopping the mission
	const stopMission = param.fromApp === 'DigiPlan' && !!param.position;
	// redirect to the previous page according to the redirectUrl param

	useEffect(() => {
		if (mission && stationsList) {
			const findStation = (trigramStation) =>
				stationsList?.find(({ trigram }) => trigram === trigramStation);

			const getTrigram = () => {
				if (isStationStart) {
					return mission?.station_start_trigram;
				}
				if (stopMission) {
					const position = !!param?.position ? JSON.parse(base64.decode(param?.position)) : {};
					// Get first trigram if 2 provided
					const positionTrigram = position?.trigram?.split('-');
					return positionTrigram?.length > 0 ? positionTrigram[0] : '';
				}
				return mission?.station_end_trigram;
			};

			const foundStation = findStation(getTrigram());

			const missionDateTime = () => {
				if (isStationStart) {
					setTimeError(!mission?.hour_start);
					return mission?.hour_start ? parseISO(mission?.hour_start) : 0;
				}
				if (stopMission) {
					setTimeError(false);
					return new Date();
				}
				setTimeError(!mission?.hour_end);
				return mission?.hour_end ? parseISO(mission?.hour_end) : 0;
			};

			const updateFields = (old) => {
				return { ...old, station: foundStation, dateTime: missionDateTime() };
			};
			setFields(updateFields);
		}
	}, [isStationStart, mission, stationsList, param.station, stopMission, param?.position]);

	useEffect(() => {
		getReferentialList('station').then((res) => {
			if (!res)
				getExpiredDataFromApi('station', '/referential/station').then((res) => {
					setStationsList(res.data);
				});
			else setStationsList(res?.data);
		});
	}, []);

	useEffect(() => {
		getAllObservationsInDl(driveLogId)
			.then((res) => {
				setObservations(res?.data);
			})
			.catch((err) => {
				console.error(err);
			});
		getDriveLogById(driveLogId)
			.then((res) => {
				setDriveLog(res?.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [driveLogId]);

	const handleChange = (fieldToUpdate) => (value) => {
		setFields({ ...fields, [fieldToUpdate]: value });
	};

	const action = () => {
		setLoadingMsg('4/5 - Mise à jour de la mission');
		if (launchMission) {
			setLoadingMsg('5/5 - Démarrage de la mission');
			// If observation have one rfn station
			if (rfnStation && rfnStation.length > 0) {
				setShowRfnConfirmModal(true);
			} else {
				startDigiPlan(false);
			}
		} else {
			setLoadingMsg('5/5 - Navigation vers le bulletin de conduite');
			setLoading(false);
			navigate(previousPage);
		}
	};

	const handleSubmit = (event) => {
		setLoading(true);
		event.preventDefault();
		let values;
		setLoadingMsg('1/5 - Récupération de la gare');
		if (isStationStart) {
			values = {
				station_start: fields?.station.name,
				hour_start: formatISO(fields?.dateTime),
				station_start_trigram: fields?.station?.trigram,
			};
		} else {
			setLoadingMsg("2/5 - Récupération de l'heure");
			values = {
				station_end: fields?.station.name,
				hour_end: formatISO(fields?.dateTime),
				station_end_trigram: fields?.station?.trigram,
			};
		}
		setLoadingMsg('3/5 - Vérification des données');
		if (!driveLogId) setLoadingMsg('Erreur : Bulletin de conduite non trouvé');
		if (!missionId) setLoadingMsg('Erreur : Mission non trouvée');
		updateMission.mutate({ driveLogId, missionId, values, action });
	};

	const subHeader = () => {
		return (
			<>
				<h2 className="subheader__code">{mission?.code}</h2>
			</>
		);
	};

	const getTitlePage = () => {
		if (launchMission) {
			return t('mission:modify-stations.launch-mission');
		}
		if (stopMission) {
			return t('mission:modify-stations.stop-mission');
		}
		return t('mission:modify-stations.title');
	};

	useEffect(() => {
		if (
			observations &&
			observations.length &&
			!/W[A-Za-z]W[A-Za-z][0-9][0-9]/.test(mission?.code)
		) {
			setRfnStation(
				observations
					.filter((e) => e?.observation_type === 'rfn' && e.draft === false)
					.map((e) => {
						return {
							trigramList: e?.content?.rfnStation.map((ee) => ee?.trigram),
							dateTimeStart: e?.content?.dateTimeStart,
							dateTimeEnd: e?.content?.dateTimeEnd,
						};
					}),
			);
		}
	}, [observations, mission?.code]);

	const startDigiPlan = async (withRfnStation) => {
		setShowRfnConfirmModal(false);

		let currentLine = line;

		// For TN attachment
		if (currentLine === '-1') {
			currentLine = driveLog.line_number;
		}

		// Only if user accept to send to digiplan rfn station
		const rfnStationEncoded = withRfnStation
			? base64.encode(JSON.stringify(rfnStation))
			: base64.encode(JSON.stringify([]));

		// Récupérer les données de localForage
		const savedStationsState = (await localForage.getItem(`stationsState-${missionCode}`)) || {};
		// Filter stations with value true
		const addedStationList = Object.keys(savedStationsState).filter(
			(station) => savedStationsState[station] === true,
		);
		// Filter stations with value false
		const deletedStationList = Object.keys(savedStationsState).filter(
			(station) => savedStationsState[station] === false,
		);

		const urlValues = {
			fromApp: 'DigiBulletin',
			driveLogId,
			missionId: currentMissionId,
			missionCode,
			missionStationStart,
			missionStationEnd,
			line: currentLine,
			activeItem: 'drive',
			rfnStation: rfnStationEncoded,
			trainNumber: trainNumber ?? null,
			trainComposition,
			addedStationList: JSON.stringify(addedStationList),
			deletedStationList: JSON.stringify(deletedStationList),
		};
		const queryParams = queryString.stringify(urlValues);

		setLoading(false);
		window.location.href = `${env?.DIGIPLAN_APP_SCHEME}://Drive?${queryParams}`;
		window.close();
	};

	return (
		<>
			<PopupFullPage title={getTitlePage()} backLink={previousPage} subHeader={subHeader()}>
				<Loader isLoading={!mission || !stationsList}>
					<form className="modify-stations" onSubmit={handleSubmit}>
						<ObservationSearch
							searchLabel={
								station === 'station-start'
									? t('mission:modify-stations.departure.label')
									: t('mission:modify-stations.arrival.label')
							}
							referential="station"
							selectedStation={fields?.station}
							setSelectedStation={handleChange('station')}
						/>
						<ObservationTime
							className="modify-stations__time"
							label={
								station === 'station-start'
									? t('mission:modify-stations.departure.time')
									: t('mission:modify-stations.arrival.time')
							}
							dateTime={fields?.dateTime}
							handleChange={handleChange('dateTime')}
							startServiceDateTime={driveLog?.realized_date_start}
							hasResetToCurrentTime
							setTimeError={setTimeError}
						/>
						<Button
							className="modify-stations__button"
							disabled={(!fields?.dateTime && timeError) || !fields?.station || loading}
							type="submit"
						>
							{t('mission:modify-stations.button')}
							{loading && <div className={'spinner'} />}
						</Button>
						{loadingMsg && loadingMsg.length > 0 && (
							<span className="loading-message">{loadingMsg}</span>
						)}
					</form>
				</Loader>
			</PopupFullPage>
			{showRfnConfirmModal && (
				<Modal type="custom">
					<div>
						<div onClick={() => setShowRfnConfirmModal(false)}>
							<Icon name="close" className="confirm-modal__icon" />
						</div>
						<div className="confirm-modal__text">
							<p>{t('mission:modify-stations.modal-rfn-station.text-1')}</p>
							<p className="modify-stations__rfn-station-station__text-alert">
								{t('mission:modify-stations.modal-rfn-station.text-2')}
							</p>
							<p>{t('mission:modify-stations.modal-rfn-station.text-3')}</p>
						</div>

						<div className="confirm-modal__controls">
							<button
								className={'confirm-modal__cancel-btn button button--outline'}
								onClick={() => startDigiPlan(false)}
							>
								{t('core:modal.no')}
							</button>
							<button
								className={'confirm-modal__confirm-btn button'}
								onClick={() => startDigiPlan(true)}
							>
								{t('core:modal.yes')}
							</button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default ModifyStations;
