import HappyMouse from './images/happy-mouse.svg';
import SearchingMouse from './images/searching-mouse.svg';
import TabletMouse from './images/tablet-mouse.svg';

const Assets = {
	HappyMouse,
	SearchingMouse,
	TabletMouse,
};

export default Assets;
