import {
	validateDelay,
	validateReason,
} from '../../components/observation-form/observation-validators';

const pmr = [
	{
		fieldName: 'stationOn',
		mandatory: true,
	},
	{
		fieldName: 'stationOff',
		mandatory: true,
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
	},
	{
		fieldName: 'reason',
		validator: validateReason,
	},
];

export default pmr;
