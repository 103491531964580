import { dispatchGetRequest, http } from '../../../config';

const { REACT_APP_API_BASE_URL = '' } = process.env;
const authRootUrl = REACT_APP_API_BASE_URL + 'auth';
const userRootUrl = '/user';

/**
 * logout service
 * @return {Promise<void>}
 */
const logout = async () => {
	localStorage.clear();
	window.location.assign(`${authRootUrl}/logout`);
};

/**
 * set CSRF protection
 * @return {Promise<any>}
 */
const setupCsrfProtection = async () => http.get('/auth/csrf');

/**
 * get api status
 * @return {Promise<any>}
 */
const getStatus = async () => http.get('/status');

/**
 * get current user
 * @return {Promise<Object>}
 */
const getCurrentUser = async () => {
	return dispatchGetRequest('current-user', `${userRootUrl}/current`);
};

export { logout, getCurrentUser, getStatus, setupCsrfProtection };
