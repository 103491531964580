import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Icon, Modal } from '../../../core';
import './consistency-overlaps-info-modal.scss';

/**
 * ConsistencyOverlapsInfoModal modal component
 * @param props
 * @param {boolean} props.displayModal show/hide modal
 * @param {function} props.closeModal callback when closing modal
 * @return {JSX.Element}
 * @constructor
 */
const ConsistencyOverlapsInfoModal = (props) => {
	const { t } = useTranslation('cr');

	const { displayModal, closeModal } = props;

	const handleOk = () => {
		closeModal();
	};

	return (
		displayModal && (
			<Modal type="custom" className="consistency-overlaps-info-modal">
				<div className="consistency-overlaps-info-modal__header">
					<button
						className="consistency-overlaps-info-modal__header__close-icon"
						onClick={handleOk}
					>
						<Icon name="close" />
					</button>
				</div>
				<div className="consistency-overlaps-info-modal__content">
					<div className="consistency-overlaps-info-modal__content__text">
						{t('consistency-report.consistency-overlaps.modals.info.text')}
					</div>

					<Button className="consistency-overlaps-info-modal__button" onClick={handleOk}>
						{t('consistency-report.consistency-overlaps.modals.info.refresh')}
					</Button>
				</div>
			</Modal>
		)
	);
};

ConsistencyOverlapsInfoModal.propTypes = {
	displayModal: PropTypes.bool,
	closeModal: PropTypes.func,
};

export default ConsistencyOverlapsInfoModal;
