/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchField as CoreSearchField } from '../..';
import './multi-search-field.scss';

const MultiSearchField = (fieldProps) => {
	const {
		translation = '',
		placeholders = '',
		groupName = '',
		fieldNames = [],
		options = [],
		values = {},
		handleChange,
		className = '',
		disabled = false,
	} = fieldProps;
	const { t } = useTranslation(translation);

	const [fieldValues, setFieldValues] = useState({});

	// Whenever props.values changes, update the state
	useEffect(() => {
		fieldNames.map((fieldName) =>
			setFieldValues((prev) => ({ ...prev, [fieldName]: values[fieldName] })),
		);
	}, [values]);

	const onSelect = (fieldName, value) => {
		setFieldValues({ ...fieldValues, [fieldName]: value });
		handleChange(fieldName, value);
	};

	const renderSearchField = (fieldName, idx) => (
		<CoreSearchField
			key={`multi-search__field__input-${idx}`}
			className="multi-search__field__input"
			placeholder={t(`${placeholders}.${fieldName}`)}
			searchList={options}
			line="-1"
			selectedOption={fieldValues[fieldName]}
			onSelect={(value) => onSelect(fieldName, value)}
			disabled={disabled}
		/>
	);

	return (
		<div className={`multi-search__field ${className}`}>
			<div className={`multi-search__field__label ${className}`}>
				{t(`${translation}.forms.fields.labels.${groupName}`)}
			</div>
			<div className={`multi-search__field__container ${className}`}>
				{fieldNames.map((fieldName, idx) => renderSearchField(fieldName, idx))}
			</div>
		</div>
	);
};

export default MultiSearchField;
