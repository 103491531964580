import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './input-textarea.scss';

/**
 * Renders an <InputTextarea /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.placeholder - textarea placeholder
 * @param {string} props.name - input name
 * @param {boolean} props.value - textarea value
 * @param {number} props.rows - number of rows to display
 * @param {number} props.maxLength - textarea maximum length
 * @param {function} props.onChange - function to handle input events
 * @param {boolean} props.hasError - boolean if form has error
 */
const InputTextarea = (props) => {
	const {
		className = '',
		placeholder,
		name,
		value = '',
		rows,
		maxLength,
		onChange,
		hasError,
		onBlur,
		disabled = false,
	} = props;

	const inputTextareaClassNames = classNames(className, { 'input-textarea--error': hasError });

	const handleChange = (e) => {
		e.stopPropagation();
		onChange(e.target.value);
	};

	return (
		<label className={`input-textarea ${inputTextareaClassNames}`}>
			<textarea
				className="input-textarea__field"
				placeholder={placeholder}
				name={name}
				value={value}
				rows={rows}
				onBlur={onBlur}
				maxLength={maxLength}
				onChange={handleChange}
				readOnly={disabled}
			/>
		</label>
	);
};

InputTextarea.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	rows: PropTypes.number,
	maxLength: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	hasError: PropTypes.bool,
};

export default InputTextarea;
