import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EmptySplash from '../../../core/assets/images/empty-splash.svg';

import { Button, Loader, NudgesPage, SearchBar } from '../../../core';
import useStoredFilter from '../../../core/custom-hooks/use-stored-filter';
import { NudgesDistributedListHeader, NudgesListRow } from '../../components';
import { getNudges } from '../../services';
import './nudges-distributed-page.scss';

const translationEntity = 'nudges';

const NudgesDistributedPage = (props) => {
	const { t } = useTranslation(translationEntity);

	const { enclosed } = props.params;
	const columnsToSearch = ['station', 'title'];
	const storageName = enclosed ? 'EnclosedNudges' : 'ActiveNudges';
	const status = enclosed ? 'enclosed' : 'active';
	const sortBy = enclosed ? 'enclosed_at' : 'created_at';

	const {
		data: nudgesList,
		refresh: refreshNudges,
		handleResetData: handleResetNudges,
		handleSearchData: handleSearchNudges,
		filter,
		isLoading,
	} = useStoredFilter({
		storageName,
		columnsToSearch: columnsToSearch[0],
		getData: () => getNudges({ status, sortBy, ...filter }),
		status,
	});

	const renderList = () => {
		if (!Array.isArray(nudgesList) || (Array.isArray(nudgesList) && nudgesList?.length === 0)) {
			return (
				<div className="empty-dl-list__content">
					<h1 className="empty-dl-list__content__title">
						{enclosed ? t('nudges.list.no-enclosed-nudges') : t('nudges.list.no-ongoing-nudges')}
					</h1>
					<div
						className="empty-dl-list__empty-splash"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			);
		}

		return (
			<div className="nudges-distributed-page__list">
				<NudgesDistributedListHeader enclosed={enclosed} />
				{nudgesList?.map(renderRows)}
			</div>
		);
	};

	const renderRows = (nudge) => (
		<NudgesListRow nudge={nudge} key={nudge.id} enclosed={enclosed} refreshNudges={refreshNudges} />
	);

	return (
		<NudgesPage selectedLink={enclosed ? 1 : 0} className="nudges-distributed-page">
			<div className="nudges-distributed-page__header">
				<SearchBar
					handleSearch={handleSearchNudges}
					handleReset={handleResetNudges}
					columnsToSearch={columnsToSearch}
					filter={filter}
				/>
				{!enclosed && (
					<>
						<span className="nudges-distributed-page__header__separator" />
						<Link to={'/nudges/add'}>
							<Button className="nudges-distributed-page__header__button button">
								{t('nudges.list.create-btn')}
							</Button>
						</Link>
					</>
				)}
			</div>

			<Loader isLoading={isLoading}>{renderList()}</Loader>
		</NudgesPage>
	);
};

export default NudgesDistributedPage;
