import localForage from '../../../config/local-forage';
import { http } from '../../../config';

/**
 * get file documents
 * @param {String} documentName - the file id
 * @returns {Promise} response
 */
const getDocumentByName = async (documentName) => {
	return localForage.getItem(documentName);
};

/**
 * get all nudges
 * @param line (A or B)
 * @returns {Promise} response
 */
const getPccNotices = async (line) => {
	return http.get('nf2/pcc-notice', { params: line });
};

export { getPccNotices, getDocumentByName };
