import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../../../core';
import useOnClickOutside from '../../../../../../core/custom-hooks/use-on-click-outside';
import { getDriveLogById } from '../../../../../drive-log.services';
import { getLineSheet } from '../../../../../sheets-line.service';
import DocumentCard from '../document-card/document-card';
import './pop-up-documents.scss';

/**
 * Renders a <PopUpDocuments /> component
 * @param {Object} props
 * @param {boolean} props.showPopUp - the boolean that defines if the PopUpDocuments is opened
 * @param {function} props.setShowPopUp - set the setShowPopUp
 * @param {string} props.driveLogId - the drive log id
 */
const PopUpDocuments = ({ showPopUp, setShowPopUp, driveLogId }) => {
	const { t } = useTranslation();
	const modalRef = useRef();

	const [line, setLine] = useState('');
	const [dlAttachment, setDlAttachment] = useState('');
	const [publishedLineSheet, setPublishedLineSheet] = useState({});
	const [comingLineSheet, setComingLineSheet] = useState({});
	const noPublishedLineSheet =
		Object.keys(publishedLineSheet).length === 0 && Object.keys(comingLineSheet).length === 0;
	const [documentsElements, setDocumentsElements] = useState([]);

	const closePopUp = () => {
		setShowPopUp(!showPopUp);
	};

	useOnClickOutside(modalRef, closePopUp);

	const getDriveLogLine = () => {
		getDriveLogById(driveLogId).then((response) => {
			const { data: driveLog } = response || {};
			const { line_number: lineNumber, attachment_name: attachmentName } = driveLog || {};
			setLine(lineNumber);
			setDlAttachment(attachmentName);
		});
	};

	//retrieve published and coming line sheets
	const getPublishedAndComingLineSheet = () => {
		if (line) {
			getLineSheet({ line, status: 'published' }).then((res) => {
				if (res) {
					setPublishedLineSheet(res);
				}
			});
			getLineSheet({ line, status: 'coming' }).then((res) => {
				if (res) {
					setComingLineSheet(res);
				}
			});
		}
	};

	const splitArrayByCount = (array, itemCount) => {
		let arr = [];
		for (let i = 0, j = array.length; i < j; i += itemCount) {
			arr.push(array.slice(i, i + itemCount));
		}
		return arr;
	};
	const getDocumentsPdf = useCallback(() => {
		const docs = [];
		//linesheet
		docs.push({
			disabled: noPublishedLineSheet,
			title: t('dl:dl-documents.line-sheet'),
			url: `/line-sheets?driveLogId=${driveLogId}`,
		});
		//driveLog
		docs.push({
			title: t('dl:dl-documents.schedule-documents'),
			secondaryText: dlAttachment,
			url: `/drive-log/${driveLogId}/schedule-document/daily`,
		});
		//Avis PCC
		docs.push({
			title: t('dl:dl-documents.pcc-notice.title'),
			secondaryText: dlAttachment,
			url: `/drive-log/${driveLogId}/pcc-notice`,
		});
		// if (line === 'A') {
		// 	// Notices techniques
		// 	docs.push({
		// 		title: t('dl:dl-documents.technical-manual:title'),
		// 		secondaryText: t('dl:dl-documents.technical-manual:la_ger_vin'),
		// 		key: 'technical_manual_la_ger_vin',
		// 		fileName: 'LEONAR_LA_NT_OUEST.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/technical_manual_la_ger_vin`,
		// 	});
		// 	docs.push({
		// 		title: t('dl:dl-documents.technical-manual:title'),
		// 		secondaryText: t('dl:dl-documents.technical-manual:la_vin_che_boi'),
		// 		key: 'technical_manual_la_vin_che_boi',
		// 		fileName: 'LEONAR_LA_NT_EST.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/technical_manual_la_vin_che_boi`,
		// 	});
		// 	// Guide de conduite
		// 	docs.push({
		// 		title: t('dl:dl-documents.driving-guide'),
		// 		key: 'driving_guide_la',
		// 		fileName: 'LEONAR_LA_GUIDE_CONDUITE.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/driving_guide_la`,
		// 	});
		// 	// Roadbook
		// 	docs.push({
		// 		title: t('dl:dl-documents.roadbook'),
		// 		key: 'roadbook_la',
		// 		fileName: 'LEONAR_LA_ROADBOOK.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/roadbook_la`,
		// 	});
		// } else if (line === 'B') {
		// 	// Notices techniques
		// 	docs.push({
		// 		title: t('dl:dl-documents.technical-manual:title'),
		// 		secondaryText: t('dl:dl-documents.technical-manual:lb_nor_rem_rob'),
		// 		key: 'technical_manual_lb_nor_rem_rob',
		// 		fileName: 'LEONAR_LB_NT_SUD.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/technical_manual_lb_nor_rem_rob`,
		// 	});
		// 	docs.push({
		// 		title: t('dl:dl-documents.technical-manual:title'),
		// 		secondaryText: t('dl:dl-documents.technical-manual:lb_nor_ro2_mit'),
		// 		key: 'technical_manual_lb_nor_ro2_mit',
		// 		fileName: 'LEONAR_LB_NT_NORD.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/technical_manual_lb_nor_ro2_mit`,
		// 	});
		// 	// Guide de conduite
		// 	docs.push({
		// 		title: t('dl:dl-documents.driving-guide'),
		// 		key: 'driving_guide_lb',
		// 		fileName: 'LEONAR_LB_GUIDE_CONDUITE.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/driving_guide_lb`,
		// 	});
		// 	// Roadbook
		// 	docs.push({
		// 		title: t('dl:dl-documents.roadbook'),
		// 		key: 'roadbook_lb',
		// 		fileName: 'LEONAR_LB_ROADBOOK.pdf',
		// 		url: `/drive-log/${driveLogId}/pdf/roadbook_lb`,
		// 	});
		// }

		return docs;
	}, [driveLogId, noPublishedLineSheet, dlAttachment, t]);

	useEffect(getDriveLogLine, [driveLogId]);
	useEffect(getPublishedAndComingLineSheet, [driveLogId, line]);
	useEffect(() => {
		const docs = getDocumentsPdf();
		const docsByRows = splitArrayByCount(docs, 4);
		setDocumentsElements(docsByRows);
	}, [driveLogId, line, getDocumentsPdf]);

	return (
		showPopUp && (
			<div className="dl-popup-documents">
				<div className="dl-popup-documents__card" ref={modalRef}>
					<div className="dl-popup-documents__header">
						<h3 className={'dl-popup-documents__title'}>{t('dl:dl-documents.documents')}</h3>
						<button className="dl-popup-documents__icon-container" onClick={closePopUp}>
							<Icon name="close" className="dl-popup-documents__icon" />
						</button>
					</div>

					{documentsElements.length > 0 &&
						documentsElements.map((documentRow, i) => {
							return (
								<div className={'dl-popup-documents__core'} key={`document-${i}`}>
									{documentRow.map(({ title, disabled, url, secondaryText }) => (
										<DocumentCard
											mainText={title}
											secondaryText={secondaryText || ''}
											url={url}
											disabled={disabled}
											key={url}
										/>
									))}
								</div>
							);
						})}
				</div>
			</div>
		)
	);
};

PopUpDocuments.propTypes = {
	showPopUp: PropTypes.bool.isRequired,
	setShowPopUp: PropTypes.func.isRequired,
	driveLogId: PropTypes.string.isRequired,
};

export default PopUpDocuments;
