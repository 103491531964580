import React from 'react';
import SurveyFields from './survey-fields/survey-fields';
import PropTypes from 'prop-types';
import { isChildShown } from '../survey-utils';
import { useTranslation } from 'react-i18next';
import MandatoryStar from '../../../../core/components/mandatory-star/mandatory-star';

/**
 * render Survey Structure form depending on their schema structure
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const SurveyGroupedField = (props) => {
	const { t } = useTranslation();
	const { fieldSchema, fields = {}, setFields, readOnly = false } = props;
	const { fieldName, group, children = [], childrenMandatory } = fieldSchema;
	/**
	 * handler for grouped form fields change
	 * @param {string} fieldToUpdate for childrenName to update
	 */
	const handleChange = (fieldToUpdate) => (newValue) => {
		setFields(newValue, fieldName, fieldToUpdate);
	};

	/**
	 * get child survey field
	 * @param {Object} childSchema
	 */
	const getChildField = (childSchema) => {
		const {
			fieldName: fieldChildName,
			mandatory: childMandatory,
			validator: childValidator,
			childrenName,
			type,
		} = childSchema;
		if (!isChildShown(fields, fieldName, childSchema)) {
			return null;
		}

		// if childSchema has childrenName, it means values has to be packed in the childrenName
		const state = childrenName ? fields[childrenName] : fields[fieldChildName];
		const attributeName = childrenName ? childrenName : fieldChildName;

		return (
			<div>
				<SurveyFields
					key={`${fieldName}-${fieldChildName}-${childrenName}`}
					fieldName={attributeName}
					valueName={fieldChildName}
					type={type}
					state={state}
					handleChange={handleChange(attributeName)}
					mandatory={childMandatory}
					validator={childValidator}
					readOnly={readOnly}
				/>
			</div>
		);
	};

	/**
	 * get group survey field
	 */
	const getGroupedField = (groupContentSchema) => {
		const { fieldName: groupContentName, validator, mandatory, type } = groupContentSchema;

		const state = fields[groupContentName];

		return (
			<React.Fragment key={`${fieldName}-${groupContentName}`}>
				<SurveyFields
					fieldName={fieldName}
					valueName={groupContentName}
					type={type}
					state={state}
					handleChange={handleChange(groupContentName)}
					mandatory={mandatory}
					validator={validator}
					readOnly={readOnly}
				/>
			</React.Fragment>
		);
	};

	const renderChildren = children.map(getChildField).filter((field) => field);
	const labelBase = t(`survey:survey-fields.${fieldName}-children-title`);

	return (
		<div className="survey__group">
			<div className="survey__group__container">
				<span className="survey__group__title">
					{t(`survey:survey-fields.${fieldName}-group-title`)}
				</span>
				{group.map(getGroupedField)}
				<div className="survey__group__children-container">
					{renderChildren?.length > 0 && (
						<span>{childrenMandatory ? <MandatoryStar label={labelBase} /> : labelBase}</span>
					)}
					{renderChildren}
				</div>
			</div>
		</div>
	);
};

SurveyGroupedField.propTypes = {
	fieldSchema: PropTypes.object.isRequired,
	fields: PropTypes.object,
	setFields: PropTypes.func.isRequired,
};

export default SurveyGroupedField;
