import React from 'react';
import PropTypes from 'prop-types';
import './drive-log-error-message.scss';

/**
 * Renders a <DriveLogErrorMessage /> component to display form error messages
 * @param {object} props
 * @param {string} props.message - the message string
 * @param {boolean} props.showError - conditional that determines whether error message is displayed or not
 */
const DriveLogErrorMessage = (props) => {
	const { message, showError } = props;

	if (showError) {
		return <div className="dl-error-message">{message}</div>;
	}

	return <></>;
};

DriveLogErrorMessage.propTypes = {
	message: PropTypes.string,
	showError: PropTypes.bool,
};

export default DriveLogErrorMessage;
