import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../../../auth/context/auth-context';
import {
	Button,
	Modal,
	Dropdown,
	Icon,
	TwoChoicesButtons,
	InputField,
	ValidateUtils,
	RegexUtils,
	ToggleSwitch,
} from '../../../../../../core';
import linesIconName from '../../../../../utils/lines-icon-names.json';
import { createUser, getUserById } from '../../../../../user.services';
import './create-user-modal.scss';

/*
	Modal to display when button "add an user" is clicked
	Only an admin connected-user can add an user via this modal
 */
const CreateUserModal = ({
	showModal,
	setShowModal,
	attachmentsList,
	refreshUsersList,
	adminRolesList,
	localAdminRolesList,
	getLineFromAttachment,
}) => {
	const { t } = useTranslation();
	const [newUser, setNewUser] = useState({
		id: '',
		role: '',
		attachment_code: '',
		line: '',
		digiplan_access: false,
		excel_access: false,
	});

	const { user: currentUser } = useContext(AuthContext);
	const { role: currentUserRole, line: currentUserLine } = currentUser || {};
	const isLocalAdminRole = currentUserRole === 'localAdmin';

	const [idHasError, setIdHasError] = useState('');

	const [formValid, setFormValid] = useState(false);

	const handleSubmit = () => {
		createUser(newUser)
			.then(() => {
				setShowModal(false);
				setNewUser({ id: '', role: '', attachment_code: '', line: '' });
				refreshUsersList();
			})
			.catch((err) => console.error(err));
	};

	const closeCallback = () => {
		setShowModal(false);
		setNewUser({ id: '', role: '', attachment_code: '', line: '' });
		setIdHasError('');
	};

	const handleIdChange = (newUserData) => {
		setNewUser({ ...newUser, id: newUserData });
	};
	const validateId = (val) => ValidateUtils.validateInput(val, RegexUtils.alphaNumeric);

	const handleBlur = (e) => {
		const userId = e.target.value;
		getUserById(userId, { extendedSearch: true })
			.then((res) => {
				const { found_in: userFound } = res.data;
				if (userFound === 'digibul') {
					setIdHasError(t('user:modals.add-user.id-already-exist'));
				} else if (userFound === 'digiref') {
					setIdHasError('');
				} else {
					setIdHasError(t('user:modals.add-user.error-id'));
				}
			})
			.catch(() => {
				setIdHasError(t('user:modals.add-user.error-id'));
			});
	};

	const handleRoleChange = (newUserData) => {
		let digiplan_access = false;
		const isNewUserAdmin = newUserData === 'admin' || newUserData === 'localAdmin';
		if (newUserData === 'driver' || newUserData === 'amp' || isNewUserAdmin) {
			digiplan_access = true;
		}

		if (newUserData === 'hr') {
			let userData = { ...newUser, role: newUserData, attachment_code: '', digiplan_access };
			if (isLocalAdminRole) {
				userData = { ...userData, line: currentUserLine };
			}
			setNewUser({ ...userData });
		} else {
			setNewUser({ ...newUser, role: newUserData, digiplan_access });
		}
	};

	const handleAttachmentChange = (newUserData) => {
		const newUserLine = getLineFromAttachment(newUserData);
		setNewUser({ ...newUser, attachment_code: newUserData, line: newUserLine });
	};

	const handleLineChange = (field) => (value) => {
		setNewUser({ ...newUser, [field]: value });
	};

	const handleChange = (e) => {
		const { checked, name } = e.target;
		setNewUser({ ...newUser, [name]: !checked });
	};

	const validateForm = (newFormUser) => {
		const fieldIdValid = newFormUser.id && validateId(newFormUser.id);
		const fieldRoleValid = newFormUser.role;
		// attachment_code becomes empty if an user is hr, only the line is kept
		const fieldAttachmentValid = () => {
			if (newFormUser.role === 'hr') {
				return newFormUser.line;
			} else {
				return newFormUser.attachment_code;
			}
		};
		//if the user is amp psg then having a line equal to null is valid else it has to be A or B
		const fieldLineValid =
			newFormUser.attachment_code === 'BPSG' && newFormUser.line === null ? true : newFormUser.line;
		return fieldIdValid && fieldRoleValid && fieldAttachmentValid() && fieldLineValid;
	};

	const formValidation = () => {
		const isValid = validateForm(newUser);
		setFormValid(isValid);
	};

	const displayLine = (lineChoice) => (
		<>
			<span className="two-choices__line--label">{t('user:modals.add-user.line')}</span>
			<Icon className="two-choices__line--icon" name={linesIconName[lineChoice]} />
		</>
	);

	useEffect(formValidation, [newUser, validateForm, formValidation]);

	const displayOptions = (selectedRole) => {
		if (
			selectedRole &&
			(selectedRole === 'hr' || selectedRole === 'localAdmin') &&
			!isLocalAdminRole
		) {
			return (
				<TwoChoicesButtons
					className="create-user-modal__content__two-choices"
					name="line"
					value={newUser.line}
					onChange={handleLineChange('line')}
					label={t('user:modals.add-user.line')}
					firstOptionLabel={displayLine('A')}
					firstOptionValue="A"
					secondOptionLabel={displayLine('B')}
					secondOptionValue="B"
				/>
			);
		} else if (selectedRole && selectedRole !== 'hr' && selectedRole !== 'localAdmin') {
			//if the role of the new user is not AMP, we shouldn't be able to select the PSG attachment
			const { BPSG, ...attachmentCodeWithoutPSG } = attachmentsList;
			const attachmentListByRole =
				newUser?.role === 'amp' ? attachmentsList : attachmentCodeWithoutPSG;
			return (
				<Dropdown
					className={'create-user-modal__content__dropdown'}
					value={newUser.attachment_code}
					onChange={handleAttachmentChange}
					label={t('user:modals.add-user.attachment')}
					dropdownList={attachmentListByRole}
					placeholder={t('user:modals.add-user.placeholder')}
				/>
			);
		}
	};

	return (
		showModal && (
			<Modal type="custom" className="create-user-modal">
				<div className="create-user-modal__content">
					<div onClick={closeCallback}>
						<Icon name="close" className="create-user-modal__content__close" />
					</div>
					<div className="create-user-modal__content__block">
						<div className="create-user-modal__content__block__title">
							{t('user:modals.add-user.title')}
						</div>

						<div className="create-user-modal__content__block__id-field">
							<label className="create-user-modal__content__block__id-field__label">
								{t('user:modals.add-user.id')}
							</label>
							<InputField
								className="create-user-modal__content__block__id-field__input"
								placeholder={t('user:modals.add-user.enter-id')}
								name={'id'}
								value={newUser.id || ''}
								onChange={handleIdChange}
								validation={validateId}
								hasError={!!idHasError}
								onBlur={handleBlur}
							/>
							<span className="create-user-modal__content__block__id-field__error">
								{idHasError}
							</span>
						</div>

						<Dropdown
							className={'create-user-modal__content__dropdown'}
							value={newUser.role || ''}
							onChange={handleRoleChange}
							label={t('user:modals.add-user.role')}
							dropdownList={isLocalAdminRole ? localAdminRolesList : adminRolesList}
							placeholder={t('user:modals.add-user.placeholder')}
						/>

						{displayOptions(newUser.role)}

						<div className="create-user-modal__content__toggle">
							<div className="toggle__label">{t('user:modals.add-user.digiplan')}</div>
							<span className="toggle__value">
								{newUser.digiplan_access
									? t('user:modals.add-user.access-granted')
									: t('user:modals.add-user.access-denied')}
							</span>
							<ToggleSwitch
								className="toggle-status"
								isChecked={!newUser.digiplan_access}
								sliderClassName="toggle-status__slider"
								handleOnChange={handleChange}
								name={'digiplan_access'}
							/>
						</div>
						<div className="create-user-modal__content__toggle">
							<div className="toggle__label">{t('user:modals.add-user.excel')}</div>
							<span className="toggle__value">
								{newUser.excel_access
									? t('user:modals.add-user.access-granted')
									: t('user:modals.add-user.access-denied')}
							</span>
							<ToggleSwitch
								className="toggle-status"
								isChecked={!newUser.excel_access}
								sliderClassName="toggle-status__slider"
								handleOnChange={handleChange}
								name={'excel_access'}
							/>
						</div>
					</div>
					<div className="create-user-modal__content__btn">
						<Button
							className="create-user-modal__content__validate-btn"
							onClick={handleSubmit}
							disabled={!formValid || idHasError}
						>
							{t('user:modals.add-user.add-btn')}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
};

CreateUserModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	attachmentsList: PropTypes.object,
	getLineFromAttachment: PropTypes.func,
	refreshUsersList: PropTypes.func,
	adminRolesList: PropTypes.object,
	localAdminRolesList: PropTypes.object,
};

export default CreateUserModal;
