import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupFullPage } from '../../../core';
import { AttentionAlertForm } from '../../forms';
import './update-attention-alert-page.scss';

const UpdateAttentionAlertPage = (props) => {
	const { t } = useTranslation('attention-alert');

	return (
		<PopupFullPage title={t('attention-alert.forms.titles.update')} backLink={'/attention-alerts'}>
			<AttentionAlertForm mode="update" />
		</PopupFullPage>
	);
};

export default UpdateAttentionAlertPage;
