import { useTranslation } from 'react-i18next';
import { useAppEnv } from '../../../../config';
import { PopupFullPage } from '../../../core';
import './observation-redirect.scss';

const ObservationRedirect = () => {
	const env = useAppEnv();

	const { t } = useTranslation();

	return (
		<PopupFullPage
			title={t('observation:observation-list.title')}
			backLink={`${env?.DIGIPLAN_APP_SCHEME}://Drive`}
		>
			<div className="observation-redirect-message">
				Votre version de Digiplan n'est pas à jour.
				<br />
				Veuillez mettre à jour votre application pour pouvoir saisir des observations depuis
				Digiplan.
			</div>
		</PopupFullPage>
	);
};

export default ObservationRedirect;
