import {
	regexValidator,
	validateDelay,
	validateReason,
} from '../../components/observation-form/observation-validators';
import { RegexUtils } from '../../../core';
import { setDateTimeWithService } from '../../observation.services';

const installationSignal = [
	{
		fieldName: 'location',
		mandatory: true,
		children: [
			{
				fieldName: 'station',
				mandatory: true,
				parentValue: 'station',
				parentTranslated: true,
			},
			{
				fieldName: 'interStationStart',
				mandatory: true,
				parentValue: 'inter-station',
				parentTranslated: true,
			},
			{
				fieldName: 'interStationEnd',
				mandatory: true,
				parentValue: 'inter-station',
				parentTranslated: true,
			},
			{
				fieldName: 'trainStorageChoice',
				mandatory: true,
				parentValue: 'train-storage',
				parentTranslated: true,
			},
		],
	},
	{
		fieldName: 'lane',
		mandatory: true,
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
	{
		fieldName: 'installation',
		mandatory: true,
		validator: regexValidator(RegexUtils.alphaNumericSpecialCharactersWithTwenty),
	},
	{
		fieldName: 'signalDetail',
		mandatory: true,
		group: [
			{
				fieldName: 'signalDetailComment',
				validator: validateReason,
				mandatory: true,
			},
			{
				fieldName: 'signalDetailFiles',
				mandatory: false,
			},
		],
	},
	{
		fieldName: 'signalTransmission',
		mandatory: true,
		children: [
			{
				fieldName: 'pcc',
				parentValue: 'yes',
				lines: ['B', '-1'],
			},
		],
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
	},
];

export default installationSignal;
