import React, { useState } from 'react';
import { DriverPage } from '../../../../core';
import DlMonth from './dl-month/dl-month';
import { useTranslation } from 'react-i18next';
import './dl-list-page.scss';

const DlListPage = () => {
	const { t } = useTranslation('dl');
	const [numberMonthsToLoad, setNumberMonthsToLoad] = useState([0, 1]);

	const generateDlMonth = (monthIndex) => <DlMonth key={monthIndex} monthIndex={monthIndex} />;

	const handleLoadMore = () => {
		setNumberMonthsToLoad([...numberMonthsToLoad, numberMonthsToLoad.length]);
	};

	return (
		<DriverPage selectedLink={2} className="dl-list-page">
			<h1 className="dl-list-page__title"> {t('dl-list-page.title')} </h1>

			{numberMonthsToLoad.map(generateDlMonth)}

			<div className="dl-list-page__controls">
				<button className="button" onClick={handleLoadMore}>
					{t('dl-list-page.load-more')}
				</button>
			</div>
		</DriverPage>
	);
};

export default DlListPage;
