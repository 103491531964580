import localForage from 'localforage';

const getItem = async (key) => {
	try {
		return localForage.getItem(key);
	} catch (error) {
		console.error(error);
	}
};

const setItem = async (key, value) => {
	try {
		return localForage.setItem(key, value);
	} catch (error) {
		console.error(error);
	}
};

const removeItem = async (key) => {
	try {
		return localForage.removeItem(key);
	} catch (error) {
		console.error(error);
	}
};

const localforageOption = {
	name: 'digibul',
	storeName: 'digibul_storage',
	description: 'Storage for digibul offline mode',
};

localForage.config(localforageOption);

export default localForage;
export { getItem, setItem, removeItem };
