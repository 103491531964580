import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupFullPage } from '../../../core';
import { NudgeForm } from '../../forms';
import { ModesNudge } from '../../utils/constants';

const UpdateNudgePage = (props) => {
	const { t } = useTranslation('nudges');
	const { action } = props.params;
	const backLink = action === 'republish' ? '/nudges/enclosed' : '/nudges';
	const mode = action === 'republish' ? 'Republish' : 'Update';

	return (
		<PopupFullPage title={t(`nudges.forms.titles.${action}`)} backLink={backLink}>
			<NudgeForm mode={ModesNudge[mode]} />
		</PopupFullPage>
	);
};

export default UpdateNudgePage;
