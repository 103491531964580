/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, InputCell, ResponsiveText, updateArrayElement } from '../../../index';
import Icon from '../../icon-svg/icon';
import './multi-input-cells.scss';

/**
 * Renders a <MultiInputCell /> component with several single character input cells
 * @param {Object} props
 * @param {number} props.size - number of input cells to render
 * @param {Array} props.valueArr - array of values for the input cells
 * @param {function} props.setValueArr - setter for the input cells value
 * @param {function} props.setFormValid - setter for the form validation
 * @param {regex} props.cellRegex - regex to check cell validity
 * @param {string} props.cellType - input type
 */
const MultiInputCells = (props) => {
	const {
		size = 1,
		valueArr = [],
		setValueArr,
		setFormValid,
		cellRegex,
		cellTypes,
		hideClearButtonWhenEmpty = false,
	} = props;
	const { t } = useTranslation();

	const keys = [...Array(size).keys()];
	const htmlElRef = useRef([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);

	const changeCell = useCallback(
		(index) => {
			if (index >= 0 && index <= size - 1) {
				htmlElRef?.current[index].focus();
			}
		},
		[size],
	);

	useEffect(() => {
		// skip to next cell
		const skipToNextInput = currentIndex < size - 1 && valueArr[currentIndex] !== '';
		setIsEmpty(JSON.stringify(valueArr) === JSON.stringify(Array(size).fill('')));

		if (skipToNextInput) {
			changeCell(currentIndex + 1);
		}

		// if there are any values that don't match the regex, then form is not valid
		const findInvalidValue = valueArr.some((value) => !cellRegex.test(value) || !value);

		setFormValid(valueArr && valueArr.length === size && !findInvalidValue);
	}, [currentIndex, size, valueArr, cellRegex, changeCell]);

	const handleChange = (index, newValue) => {
		// new array with input cell at position index updated to the new value
		const updatedArr = updateArrayElement(valueArr, newValue, index);

		// update state with new array
		setValueArr(updatedArr);
		setCurrentIndex(index);
	};

	const clearInputs = () => {
		const emptyArray = Array(size).fill('');
		setValueArr(emptyArray);
		setCurrentIndex(0);

		// get the reference to the first input to focus to it after reset
		const firstInputRef = htmlElRef?.current[0];
		firstInputRef.focus();
	};

	const generateInputCell = (char, index) => {
		const getRef = (el) => (htmlElRef.current[index] = el);
		return (
			<InputCell
				key={keys[index]}
				type={cellTypes}
				maxLength={1}
				onChange={(newValue) => handleChange(index, newValue)}
				onClick={() => handleChange(index, '')}
				value={char}
				ref={getRef}
				regex={cellRegex}
				goPrevious={() => changeCell(index - 1)}
			/>
		);
	};

	const CloseButton = () => {
		if (!hideClearButtonWhenEmpty || !isEmpty) {
			return (
				<Button className="multi-input-cells__close" type="button" onClick={clearInputs}>
					<ResponsiveText text={<Icon name="close" />} mobileText={t('core:input-cell.delete')} />
				</Button>
			);
		}

		return <span className="close-button-placeholder"></span>;
	};

	return (
		<div className="multi-input-cells">
			<div className="multi-input-cells__container">
				{valueArr.map((char, index) => generateInputCell(char, index))}
			</div>
			<CloseButton />
		</div>
	);
};

MultiInputCells.propTypes = {
	size: PropTypes.number,
	valueArr: PropTypes.array,
	setValueArr: PropTypes.func,
	setFormValid: PropTypes.func,
	cellRegex: PropTypes.instanceOf(RegExp),
	cellTypes: PropTypes.string,
};

export default MultiInputCells;
