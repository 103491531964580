import {
	validatePrecisionComment,
	validateRadioButton,
	validateSurveyChecklist,
} from '../../components/survey-form/survey-validators';

const surveySfStructure = [
	{
		fieldName: 'sequenceBreak',
		group: [
			{
				type: 'radio',
				fieldName: 'value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
			},
			{
				type: 'text',
				fieldName: 'precision',
				validator: validatePrecisionComment,
			},
		],
	},
	{
		fieldName: 'doubleTask',
		group: [
			{
				type: 'radio',
				fieldName: 'value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
			},
		],
		childrenValidatorType: 'all',
		childrenMandatory: false,
		children: [
			{
				type: 'text',
				fieldName: 'precision',
				validator: validatePrecisionComment,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
			{
				type: 'radio',
				fieldName: 'second_value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
			{
				type: 'text',
				fieldName: 'second_precision',
				validator: validatePrecisionComment,
				mandatory: true,
				groupValue: {
					name: 'second_value_radio_button',
					value: 'yes',
				},
			},
		],
	},
	{
		fieldName: 'confusedPerception',
		group: [
			{
				type: 'radio',
				fieldName: 'value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
			},
			{
				type: 'text',
				fieldName: 'precision',
				validator: validatePrecisionComment,
			},
		],
	},
	{
		fieldName: 'externalDisruptor',
		group: [
			{
				type: 'radio',
				fieldName: 'value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
			},
		],
		childrenValidatorType: 'one',
		childrenMandatory: true,
		children: [
			{
				type: 'survey-checkbox',
				fieldName: 'value_checklist',
				childrenName: 'people',
				validator: validateSurveyChecklist,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
			{
				type: 'survey-checkbox',
				fieldName: 'value_checklist',
				childrenName: 'element',
				validator: validateSurveyChecklist,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
			{
				type: 'survey-checkbox',
				fieldName: 'value_checklist',
				childrenName: 'communication',
				validator: validateSurveyChecklist,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
			{
				type: 'survey-checkbox',
				fieldName: 'value_checklist',
				childrenName: 'other',
				validator: validateSurveyChecklist,
				mandatory: true,
				groupValue: {
					name: 'value_radio_button',
					value: 'yes',
				},
			},
		],
	},
	{
		fieldName: 'internalDisruptor',
		group: [
			{
				type: 'radio',
				fieldName: 'value_radio_button',
				validator: validateRadioButton,
				mandatory: true,
			},
			{
				type: 'text',
				fieldName: 'precision',
				validator: validatePrecisionComment,
			},
		],
	},
];

export default surveySfStructure;
