const manualDrive = [
	{
		fieldName: 'exploitationNote',
		visibleOnSide: ['mission'],
	},
	{
		fieldName: 'manualDrive',
		mandatory: true,
		visibleOnSide: ['mission'],
	},
];

export default manualDrive;
