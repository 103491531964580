import { formatISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import localForage from '../../../../config/local-forage';
import { useMissionsQueries } from '../../../../config/react-query/missions.query';
import { Button, MultiInputCells, PopupFullPage } from '../../../core';
import { DriveLogErrorMessage, DriveLogSubHeader } from '../../../drive-log';
import { getDriveLogById } from '../../../drive-log/drive-log.services';
import { useLine } from '../../../observation/custom-hooks/observation-custom-hooks';
import { getMissionPassageFromRef } from '../../mission.services';
import checkMission from '../../utils/check-mission';
import './add-mission.scss';

/**
 * Renders an <AddMission /> full page
 */
const AddMission = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;

	const { id: driveLogId } = useParams();

	const navigate = useNavigate();
	const { t } = useTranslation();

	// accept only alphanumeric characters for the input cells
	const acceptedCharacters = /^[a-z0-9]?$/i;

	const [line, lineLoading] = useLine();
	const [missionId, setMissionId] = useState(Array(6).fill(''));

	const [formValid, setFormValid] = useState(false);
	const [hasError, setHasError] = useState();
	const [loading, setLoading] = useState(false);
	const [displayNoMissionCode, setDisplayNoMissionCode] = useState(false);
	const { addMission } = useMissionsQueries();

	const action = async (missionCode) => {
		const params = {
			line,
			missionCode,
		};
		const stationResponse = await getMissionPassageFromRef('/mission/passage', { params });
		if (stationResponse && stationResponse[missionCode]) {
			localForage
				.getItem('mission-details')
				.then((e) => {
					e.data = {
						...e.data,
						[missionCode]: stationResponse[missionCode],
					};
					localForage.setItem('mission-details', e);
				})
				.finally(() => {
					navigate(-1);
				});
		} else navigate(-1);
	};

	const sendMissionInfo = (mission) => {
		const missionPayload = { ...mission };
		if (mission?.hour_start) {
			missionPayload.hour_start = formatISO(mission.hour_start);
		}
		if (mission?.hour_end) {
			missionPayload.hour_end = formatISO(mission.hour_end);
		}
		const generateId = uuid();
		const values = {
			...missionPayload,
			id: generateId,
			drive_log_id: driveLogId,
			train_number: null,
		};
		addMission.mutate({
			driveLogId,
			missionId,
			values,
			action,
		});
	};

	const handleSubmit = () => {
		if (!lineLoading && missionId && missionId.length === 6) {
			setLoading(true);
			checkMission(missionId, line)
				.then((res) => {
					const { data } = res;
					sendMissionInfo(data);
				})
				.catch((err) => {
					console.log('err : ', err);
					setLoading(false);
					setHasError('error-invalid-code');
				});
		}
	};

	useEffect(() => {
		getDriveLogById(driveLogId)
			.then((res) => {
				const driveLog = res?.data;
				if (driveLog?.attachment_bigram === 'TN') {
					setDisplayNoMissionCode(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [driveLogId]);

	return (
		<PopupFullPage title={t('mission:add-mission.title')}>
			<div className="add-mission">
				<DriveLogSubHeader subHeader={t('mission:add-mission.heading')} />

				<MultiInputCells
					size={6}
					cellTypes="text"
					valueArr={missionId}
					setValueArr={setMissionId}
					setFormValid={setFormValid}
					cellRegex={acceptedCharacters}
				/>

				<DriveLogErrorMessage
					message={t(`mission:add-mission.${hasError}`)}
					showError={!!hasError}
				/>
				<Button
					disabled={!formValid || lineLoading || loading}
					className="add-mission__button"
					onClick={handleSubmit}
				>
					{t('core:popup-full-page.button.validate')}
				</Button>
				{displayNoMissionCode && (
					<div
						className={'add-mission__no-mission-code-link'}
						onClick={() => navigate(`${redirectUrl}/${driveLogId}/mission/add/no-code`)}
					>
						{t('mission:add-mission.no-mission-code')}
					</div>
				)}
			</div>
		</PopupFullPage>
	);
};

export default AddMission;
