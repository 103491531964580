import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import localForage from '../../../../../config/local-forage';
import { Modal } from '../../../../core';
import { getMissionsFromRef } from '../../../../mission/mission.services';
import ServiceNumber from '../../../components/service-number/service-number';
import { createNewDriveLog, getDriveLogById, updateDriveLog } from '../../../drive-log.services';
import { AttachmentList } from '../../../index';

const ModifyServiceNumber = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id: driveLogId } = useParams();
	const [searchParams] = useSearchParams();

	const action = searchParams.get('action');

	const [activeStep, setActiveStep] = useState(0);
	const [driveLogData, setDriveLogData] = useState();

	const [driveLog, setDriveLog] = useState();
	const [showDLExistsModal, setShowDLExistsModal] = useState(false);
	const [showAttachmentNotValidModal, setShowAttachmentNotValidModal] = useState(false);
	const [buttonValidated, setButtonValidated] = useState(false);

	const [loading, setLoading] = useState(true);

	const getExistingDL = useCallback(() => {
		getDriveLogById(driveLogId)
			.then((payload) => {
				setDriveLog(payload.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId]);

	const handleUpdateDL = async (newService) => {
		const { serviceNumber, line, attachmentBigram, attachmentName, code } = driveLogData;
		const serviceToUpdate =
			action === 'signDl' ? 'planned_service_number' : 'realized_service_number';
		// remove null values
		const formattedNewDl = {
			attachment_name: attachmentName,
			attachment_bigram: attachmentBigram,
			[serviceToUpdate]: newService.serviceNumber ? newService.serviceNumber : serviceNumber,
			line_number: line,
		};
		try {
			if (action === 'dailyDl') {
				const dlRes = await createNewDriveLog(formattedNewDl, 'full');
				if (dlRes.data?.id) {
					await localForage.setItem('daily-drive-log', { data: dlRes.data });
					const params = {
						attachmentBigram: attachmentBigram,
						line: line,
						service: serviceNumber,
					};
					const stationResponse = await getMissionsFromRef(
						'missions-schedule-info',
						'/mission/schedule-info',
						{ params },
					);
					if (stationResponse && stationResponse.data) {
						localForage
							.setItem('mission-details', {
								data: stationResponse.data,
								id: dlRes.data.id,
							})
							.then(() => navigate(-1));
					} else navigate(-1);
				} else {
					setShowDLExistsModal(false);
					setShowAttachmentNotValidModal(true);
				}
			} else if (action === 'signDl') {
				const updatedDrivelog = { id: driveLogId, ...formattedNewDl, attachment_code: code };
				const updatedDrivelogRes = await updateDriveLog(updatedDrivelog, {
					action: 'modify-service',
				});
				updatedDrivelogRes.data[0] && navigate(-1);
			}
		} catch (e) {
			console.error(e);
			setShowDLExistsModal(false);
			setShowAttachmentNotValidModal(true);
		}
	};

	const handleAttachmentSubmit = (data) => {
		setActiveStep(1);
		setDriveLogData({ ...driveLogData, ...data });
	};

	const handleServiceSubmit = (newService) => {
		// trigger showDLExistsModal only when the user wants to modify service of an existing drivelog and from the daily drivelog page
		if (!loading && driveLog && action === 'dailyDl') {
			setShowDLExistsModal(true);
		} else if (!loading && (!driveLog || (driveLog && action === 'signDl'))) {
			handleUpdateDL(newService);
		}
		setDriveLogData({ ...driveLogData, ...newService });
	};

	const handleCancelModal = () => {
		setShowDLExistsModal(false);
		setButtonValidated(!buttonValidated);
	};

	const handleRestartForm = () => {
		if (driveLogId) {
			getExistingDL();
		}
		setActiveStep(0);
		setShowDLExistsModal(false);
		setShowAttachmentNotValidModal(false);
	};

	useEffect(() => {
		if (driveLogId) {
			getExistingDL();
		}
		setLoading(false);
	}, [driveLogId, getExistingDL]);

	return (
		<>
			{activeStep === 0 && (
				<AttachmentList
					navigate={handleAttachmentSubmit}
					title={t('dl:modify-service-number.title')}
				/>
			)}
			{activeStep === 1 && (
				<ServiceNumber
					navigate={handleServiceSubmit}
					title={t('dl:modify-service-number.title')}
					noServiceNumberOption={false}
					showDLExistsModal={showDLExistsModal}
					showAttachmentNotValidModal={showAttachmentNotValidModal}
					buttonValidated={buttonValidated}
				/>
			)}
			{showDLExistsModal && (
				<Modal
					type="confirm"
					title={t('dl:add-sheet-line-page.replace-existing-dl-modal')}
					confirmCallback={handleUpdateDL}
					cancelCallback={handleCancelModal}
					closeCallback={handleCancelModal}
				/>
			)}
			{showAttachmentNotValidModal && (
				<Modal
					type="error"
					text={t('dl:add-sheet-line-page.attachment-not-valid')}
					closeCallback={handleRestartForm}
				/>
			)}
		</>
	);
};

export default ModifyServiceNumber;
