import React from 'react';
import PropTypes from 'prop-types';
import ObservationReadOnly from '../observation-read-only/observation-read-only';
import ObservationRepeatField from '../observation-repeat-field/observation-repeat-field';
import ObservationGroupedField from '../observation-grouped-field/observation-grouped-field';
import ObservationFields from '../observation-fields/observation-fields';

/**
 * Root field can be
 * 	read only information
 * 	grouped fields (several observation fields grouped into one, root field does not have a value)
 * 	repeat fields (observation field that can repeat a number of times)
 * 	observation fields with or without children, depending on parent values
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
const ObservationRootField = (props) => {
	const {
		line,
		fieldSchema,
		fields,
		setFields,
		isChildShown,
		setTimeError,
		driveLog,
		observationFields,
		observationFiles,
		setObservationFiles,
		observationId = '',
	} = props;

	const { fieldName, children, mandatory, lines, group, validator, repeat, readOnly } = fieldSchema;

	// show field only for certain lines
	if (lines && !lines.includes(line)) {
		return null;
	}

	if (readOnly) {
		return (
			<ObservationReadOnly
				key={fieldName}
				fieldName={fieldName}
				driveLog={driveLog}
				fields={fields}
				observationFields={observationFields}
			/>
		);
	}

	if (repeat) {
		return (
			<ObservationRepeatField
				key={fieldName}
				line={line}
				fieldSchema={fieldSchema}
				fields={fields}
				setFields={setFields}
				driveLog={driveLog}
			/>
		);
	}

	// if it's a grouped field, show all the group children
	if (group) {
		return (
			<ObservationGroupedField
				key={fieldName}
				line={line}
				fieldSchema={fieldSchema}
				fields={fields}
				setFields={setFields}
				setTimeError={setTimeError}
				driveLog={driveLog}
				observationFiles={observationFiles}
				setObservationFiles={setObservationFiles}
				observationId={observationId}
			/>
		);
	}

	/**
	 * handler for primary form fields
	 * @param {string} fieldToUpdate
	 * @return {function(*): void}
	 */
	const handleChange = (fieldToUpdate) => (newValue) => {
		if (!children) {
			setFields({ ...fields, [fieldToUpdate]: newValue });
		} else {
			setFields({ ...fields, [fieldToUpdate]: { value: newValue, children: {} } });
		}
	};

	/**
	 * handler for child form fields
	 * @param {string} childToUpdate
	 * @return {function(*): void}
	 */
	const handleChildChange = (childToUpdate) => (newValue) => {
		const { value, children: stateChildren } = fields[fieldName];
		const newChildrenValue = { ...stateChildren, [childToUpdate]: newValue };
		setFields({ ...fields, [fieldName]: { value, children: newChildrenValue } });
	};

	/**
	 * get child observation field
	 * @param {Object} childSchema
	 * @return {JSX.Element|null}
	 */
	const getChildField = (childSchema) => {
		const {
			fieldName: childName,
			mandatory: childMandatory,
			validator: childValidator,
		} = childSchema;

		if (!isChildShown(fieldName, childSchema)) {
			return null;
		}

		return (
			<ObservationFields
				key={`${fieldName}-${childName}`}
				line={line}
				fieldName={childName}
				state={fields[fieldName].children[childName]}
				handleChange={handleChildChange(childName)}
				mandatory={childMandatory}
				validator={childValidator}
				setTimeError={setTimeError}
				fieldSchema={fieldSchema}
				driveLog={driveLog}
			/>
		);
	};

	/**
	 * for fields with children, return all children
	 * @return {JSX.Element|null}
	 */
	const getChildren = () => {
		// get all the child fields and filter out null ones
		const childFields = children.map(getChildField).filter((field) => field);

		if (childFields?.length > 0) {
			return <div className="observation__subfields">{childFields}</div>;
		}
		return null;
	};

	const state = children ? fields[fieldName]?.value : fields[fieldName];

	return (
		<>
			<ObservationFields
				line={line}
				fieldName={fieldName}
				state={state}
				handleChange={handleChange(fieldName)}
				mandatory={mandatory}
				validator={validator}
				setTimeError={setTimeError}
				fieldSchema={fieldSchema}
				driveLog={driveLog}
				observationFiles={observationFiles}
				setObservationFiles={setObservationFiles}
				observationId={observationId}
			/>
			{children && getChildren()}
		</>
	);
};

ObservationRootField.propTypes = {
	line: PropTypes.string,
	fieldSchema: PropTypes.object.isRequired,
	fields: PropTypes.object.isRequired,
	setFields: PropTypes.func.isRequired,
	isChildShown: PropTypes.func.isRequired,
	setTimeError: PropTypes.func,
	driveLog: PropTypes.object.isRequired,
	observationFiles: PropTypes.array,
	setObservationFiles: PropTypes.func,
	observationId: PropTypes.string,
};

export default ObservationRootField;
