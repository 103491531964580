import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from '../../../core';
import { deleteAttentionAlertById } from '../../attention-alert.services';
import './attention-alert-list-row.scss';

/**
 * AttentionAlertListRow component display a row for each attention-alert
 * @param props
 * @param {object} props.attentionAlert
 * @param {string} props.className
 * @return {JSX.Element}
 * @constructor
 */
const AttentionAlertListRow = (props) => {
	const { attentionAlert, className = '', refreshAttentionAlerts } = props;
	const updatedAttentionAlert = { ...attentionAlert };
	if (updatedAttentionAlert.line === 'B') {
		updatedAttentionAlert.tracks = updatedAttentionAlert.tracks.map((track) => {
			if (track === 1) return 'Nord - Sud (Voie 1 RATP)';
			if (track === 2) return 'Sud - Nord (Voie 2 RATP)';
			return track;
		});
	}

	const { t } = useTranslation('attention-alert');
	const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { id, station, tracks, type, other_type } = updatedAttentionAlert;

	const handleDelete = (e) => {
		e.stopPropagation();
		setShowDeleteAlertModal(!showDeleteAlertModal);
	};

	const deleteAttentionAlert = () => {
		setIsLoading(true);
		deleteAttentionAlertById(id)
			.then((res) => {
				setShowDeleteAlertModal(false);
				refreshAttentionAlerts();
			})
			.catch((error) => console.error(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const renderStation = () => {
		return (
			<>
				<span className="attention-alert-list-row__cell-station">{station}</span>
				{tracks && (
					<span>
						{attentionAlert.line === 'A'
							? t('attention-alert.list.cells.track', {
									track: tracks.join(` ${t('attention-alert.list.cells.and')} `),
							  })
							: tracks.join(` ${t('attention-alert.list.cells.and')} `)}
					</span>
				)}
			</>
		);
	};

	//TODO LEO V7: afficher les icones
	const renderTypes = () => {
		return (
			<div className="attention-alert-list-row__cell-type">
				{type[0] === 'other' ? other_type : t(`attention-alert.list.cells.${type[0]}`)}
			</div>
		);
	};

	const renderAction = () => {
		return (
			<>
				<Link to={`/attention-alert/${id}/edit`}>
					<Button className="button--outline attention-alert-list-row__cell-action">
						{t('attention-alert.forms.buttons.update')}
					</Button>
				</Link>
				<Button
					className="button--outline attention-alert-list-row__cell-action"
					onClick={(e) => handleDelete(e)}
				>
					{t('attention-alert.forms.buttons.delete')}
				</Button>
			</>
		);
	};

	return (
		<>
			<div className={`${className} attention-alert-list-row`}>
				<div className="attention-alert-list-row__cell">{renderStation()}</div>
				<div className="attention-alert-list-row__cell">{renderTypes()}</div>
				<div className="attention-alert-list-row__cell">{renderAction()}</div>
			</div>

			{showDeleteAlertModal && (
				<Modal
					type="confirm"
					title={t('attention-alert.modals.delete.title')}
					text="attention-alert:attention-alert.modals.delete.content"
					cancelText={t('attention-alert.modals.delete.cancel')}
					confirmText={t('attention-alert.modals.delete.validate')}
					confirmCallback={() => deleteAttentionAlert()}
					cancelCallback={() => setShowDeleteAlertModal(false)}
					closeCallback={() => setShowDeleteAlertModal(false)}
					isLoading={isLoading}
					iconName="warning-triangle"
				/>
			)}
		</>
	);
};

export default AttentionAlertListRow;
