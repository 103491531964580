import http from '../axios';
import localForage from '../local-forage';
import checkNetwork from './check-network';
import { storePutPending } from './synchronize-storage';

/**
 * put data to API and remove from local storage
 * @param {string} url - url to call
 * @param {Object} data - data to put
 * @return {Promise<Object>}
 */
const putDataToApi = async (url, data) => await http.put(url, data);

/**
 * Dispatch put request to either local storage or to API
 * @param {string} key
 * @param {string} url
 * @param {Object} data
 * @param {string} listKey
 * @param {string} id
 * @return {Promise<Object|void>}
 */
const dispatchPutRequest = async (key, url, data, id) => {
	try {
		const storedData = await localForage.getItem(key);
		if (!storedData || !storedData.data) {
			throw new Error('No data found in localForage');
		}
		const updatedData = storedData.data.map((item) =>
			item.id === id ? { ...item, ...data } : item,
		);
		updatedData.sort((a, b) => new Date(a.hour_start) - new Date(b.hour_start));
		await localForage.setItem(key, { ...storedData, data: updatedData });
	} catch (err) {
		console.error('Error updating localForage:', err);
	}

	const online = await checkNetwork();

	if (online) return await putDataToApi(url, data);
	else await storePutPending(key);
};

export { putDataToApi };
export default dispatchPutRequest;
