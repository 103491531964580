/**
 * Display station name according to the location where the observation happened : gare, intergare or faisceau
 * @param {Object} content
 * @returns {String} station name
 */
const displayStationNameFromObservation = (content) => {
	if (content?.location.value === 'Intergare') {
		return `${content?.location.children.interStationStart.name} / ${content?.location.children.interStationEnd.name}`;
	} else if (content?.location.value === 'Gare') {
		return content?.location.children.station.name;
	}
	return content?.location.children.trainStorageChoice.name;
};

export default displayStationNameFromObservation;
