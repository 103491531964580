import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './identity-cell.scss';

const IdentityCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation('dl');

	const getName = (firstname, lastname) => {
		const fn = firstname ? firstname : '';
		const ln = lastname ? lastname : '';
		return `${fn} ${ln}`;
	};

	const buildHeader = () => <div className="identity-cell">{t('dl-table.headers.name')}</div>;

	const buildCell = () => (
		<div className="identity-cell">
			<div className="identity-cell__identity__name">
				{getName(driveLog?.first_name, driveLog?.last_name)}
			</div>
			<div className="identity-cell__identity__corporate-id">{driveLog?.user_id}</div>
		</div>
	);

	return header ? buildHeader() : buildCell();
};

IdentityCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool,
};

export default IdentityCell;
