const MATOMO_CATEGORIES = {
	AUTH: 'authentification',
	DRIVELOG: 'bulletin',
	SURVEY: 'questionnaire-sf',
}; // e_c
const MATOMO_ACTIONS = {
	LOGIN: 'connexion',
	SIGN: 'signature',
	VALIDATE_MULTI: 'validation en masse',
	PASS_SURVEY: 'passe questionnaire',
	VALIDATE_SURVEY: 'validation questionnaire',
	PROCESSING_TIME: 'temps de traitement',
	VALIDATE_MANUAL: 'validation manuelle',
}; // e_a

export { MATOMO_CATEGORIES, MATOMO_ACTIONS };
