import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../../../../core/components/toggle-switch/toggle-switch';
import './drive-log-delay-details-table-cell.scss';

const DriveLogDelayDetailsTableCell = (props) => {
	const { t } = useTranslation('dl');
	const {
		label,
		value,
		valueKey,
		valueType = 'number',
		theme,
		isEditable = false,
		handleChange,
		comment = '',
	} = props;
	const [containerTheme, setContainerTheme] = useState(
		'drive-log-delay-details-table-cell__container',
	);

	useEffect(() => {
		if (theme === 'pink') {
			setContainerTheme(
				'drive-log-delay-details-table-cell__container drive-log-delay-details-table-cell__container__pink-theme',
			);
		} else if (theme === 'transparent') {
			setContainerTheme(
				'drive-log-delay-details-table-cell__container drive-log-delay-details-table-cell__container__transparent-theme',
			);
		}
	}, [theme]);

	return (
		<div className={containerTheme}>
			<div className={'drive-log-delay-details-table-cell__label'}>{label}</div>
			<div className={'drive-log-delay-details-table-cell__comment'}>{comment}</div>
			{valueType === 'number' && !isEditable && (
				<div className={'drive-log-delay-details-table-cell__value'}>{value}’</div>
			)}
			{valueType === 'number' && isEditable && (
				<div className={'drive-log-delay-details-table-cell__value-editable'}>
					<input
						value={value}
						type={'number'}
						onChange={(e) => handleChange(e.target.value, valueKey, valueType)}
					/>
					’
				</div>
			)}
			{valueType === 'bool' && (
				<div className={'drive-log-delay-details-table-cell__switch'}>
					{value === true ? t('delay-list-page.yes') : t('delay-list-page.no')}
					{isEditable && (
						<ToggleSwitch
							className="toggle-status"
							isChecked={value}
							sliderClassName="drive-log-delay-details-table-cell_toggle-status__slider"
							handleOnChange={(e) => handleChange(e.target.checked, valueKey, valueType)}
						/>
					)}
				</div>
			)}
		</div>
	);
};

DriveLogDelayDetailsTableCell.propTypes = {
	label: PropTypes.string,
	valueKey: PropTypes.string,
	valueType: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	theme: PropTypes.string,
	comment: PropTypes.string,
	isEditable: PropTypes.bool,
	handleChange: PropTypes.func,
};

export default DriveLogDelayDetailsTableCell;
