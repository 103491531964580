import { dispatchGetRequest, http } from '../../config';
import { getExpiredDataFromApi } from '../../config/offline/dispatch-get-request';

/**
 * get list of missions from digiref
 * @returns {Promise<Object>} response
 */
const getMissionsFromRef = (key, url, config = {}) => dispatchGetRequest(key, url, config);

/**
 * get list of station passages of one mission from digiref
 * @returns {Promise<Object>} response
 */
const getMissionPassageFromRef = async (url, config = {}) => {
	const apiData = await http.get(url, config);
	return apiData?.data || null;
};

/**
 * get drive log mission list from api
 * @param {String} driveLogId drive log uuid
 * @returns {Promise} response
 */
const getDriveLogMissions = async (driveLogId) => {
	const payload = await dispatchGetRequest(
		`dl-${driveLogId}-missions`,
		`/drive-log/${driveLogId}/mission`,
	);

	// if individual missions have been modified offline, the changes won't show up on the list. Get mission info from local storage instead
	const getIndividualMissionInfo = async (mission) => {
		const { id } = mission;
		if (!id) {
			return mission;
		}
		const missionPayload = await dispatchGetRequest(
			`dl-${driveLogId}-mission-${id}`,
			`/drive-log/${driveLogId}/mission/${id}`,
		);
		return missionPayload?.data;
	};

	const missionList = await Promise.all(payload?.data.map(getIndividualMissionInfo));

	return { data: missionList };
};

/**
 * get mission information from api
 * @param {string} driveLogId - drive-log tech_id (uuid)
 * @param {string} missionId - mission tech_id (uuid)
 * @returns {Promise<Object>} response
 */
const getMissionInfo = (driveLogId, missionId) => {
	return getExpiredDataFromApi(
		`dl-${driveLogId}-mission-${missionId}`,
		`/drive-log/${driveLogId}/mission/${missionId}`,
		8,
	);
};

export { getDriveLogMissions, getMissionInfo, getMissionPassageFromRef, getMissionsFromRef };
