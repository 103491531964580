import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import AdminHeader from '../../headers/admin-header/admin-header';
import PageWrapper from '../page-wrapper/page-wrapper';
import './admin-page.scss';

/**
 * Renders a <AdminPage /> component
 * @param {object} props
 * @param {string} props.className - classnames to add to the root of this component
 * @param {Object} props.children - the content to render in the page

 */
const AdminPage = (props) => {
	const { className = '', children, selectedLink = 0 } = props;
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();

	const allowedRoles = ['admin', 'localAdmin'];

	if (!user?.role || !allowedRoles.includes(user.role)) {
		return navigate('/error-access');
	}

	return (
		<PageWrapper className="admin-page">
			<AdminHeader selectedLink={selectedLink} />
			<main className={`admin-page__content ${className}`}>{children}</main>
		</PageWrapper>
	);
};

AdminPage.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	overrideRoles: PropTypes.array,
};

export default AdminPage;
