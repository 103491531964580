import React from 'react';
import { useTranslation } from 'react-i18next';
import { setHours, setMinutes, getHours, getMinutes, addDays } from 'date-fns';
import PropTypes from 'prop-types';
import { TimePicker, DatePickerComponent } from '../../../../core';
import './dl-full-date-picker.scss';

/**
 * Renders a <DLFullDatePicker /> component
 * @param {Object} props
 * @param {function} props.setFullDate - the setFullDate function derived from provided props
 * @param {string} props.title - the title of the hour part derived from provided props
 */
const DLFullDatePicker = (props) => {
	const { dlDateTime = new Date(), setDlDateTime, setTimeError, title } = props;

	const { t } = useTranslation();

	const handleHourChange = (newValue) => {
		const newTime = setHours(dlDateTime, newValue);
		setDlDateTime(newTime);
	};

	const handleMinuteChange = (newValue) => {
		const newTime = setMinutes(dlDateTime, newValue);
		setDlDateTime(newTime);
	};

	/**
	 * @function selectFutureDays - exclude future dates from being selected
	 * @param {Date} startDate - the selected date from where days won't be available
	 * @returns {Date} Returns a new Date that adds the specified number of days to the value of this instance
	 */
	const selectFutureDays = (startDate) => addDays(startDate, 1);

	return (
		<div className="full-date__cells-wrapper">
			<div className="full-date__cells-wrapper__date">
				<span className="full-date__cells-wrapper__date__label">
					{t('dl:schedule-page.label.date')}
				</span>
				<DatePickerComponent
					date={dlDateTime}
					onChange={setDlDateTime}
					maxDate={selectFutureDays(new Date())}
				/>
			</div>
			<div className="full-date__cells-wrapper__time">
				<span className="full-date__cells-wrapper__time__label">{title}</span>
				<TimePicker
					className="full-date__cells-wrapper__time__cells"
					hours={getHours(dlDateTime)}
					setHours={handleHourChange}
					minutes={getMinutes(dlDateTime)}
					setMinutes={handleMinuteChange}
					setTimeError={setTimeError}
				/>
			</div>
		</div>
	);
};

DLFullDatePicker.propTypes = {
	setFullDate: PropTypes.func,
	title: PropTypes.string,
};

export default DLFullDatePicker;
