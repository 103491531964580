import React, { useEffect, useState } from 'react';
import { checkArrayEmpty, DriverPage, Loader } from '../../../../core';
import { useTranslation } from 'react-i18next';
import { getDriveLogs } from '../../../drive-log.services';
import ReturnedDriveLogTableRow from '../../../components/drive-log-table/returned-drive-log-table-row/returned-drive-log-table-row';
import ReturnedDriveLogTableHeaders from '../../../components/drive-log-table/returned-drive-log-table-headers/returned-drive-log-table-headers';
import './returned-dl-list-page.scss';

const ReturnedDlListPage = () => {
	const { t } = useTranslation('dl');

	const [returnedDriveLogs, setReturnedDriveLogs] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getDriveLogs({
			dateSortOrder: 'desc',
			onlyMe: true,
			numberResults: 40,
			status: ['returned'],
		})
			.then((response) => {
				const driveLogs = response.data;
				setReturnedDriveLogs(driveLogs?.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const generateLogTable = () => {
		const driveLogListEmpty = checkArrayEmpty(returnedDriveLogs);
		if (driveLogListEmpty) {
			return (
				<div className="returned-dl__empty-table-warning">
					{t('dl-list-page.no-returned-drive-logs')}
				</div>
			);
		}

		const generateRows = (driveLog) => (
			<ReturnedDriveLogTableRow driveLog={driveLog} key={driveLog.id} />
		);

		return (
			<ul className="returned-dl-table">
				<ReturnedDriveLogTableHeaders />
				{Array.isArray(returnedDriveLogs) &&
					returnedDriveLogs?.length > 0 &&
					returnedDriveLogs.map(generateRows)}
			</ul>
		);
	};

	return (
		<DriverPage selectedLink={1} className="returned-dl-list-page">
			<Loader isLoading={!!isLoading}>{generateLogTable()}</Loader>
		</DriverPage>
	);
};

export default ReturnedDlListPage;
