import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAppEnv } from '../../../../../config';
import { DriverPage, useDriveLog } from '../../../../core';
import HappyMouse from '../../../../core/assets/images/happy-mouse.svg';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import {
	UserSatisfactionSurveyModal,
	UserSatisfactionSurveyThxModal,
} from '../../../components/user-satisfaction-survey';
import { getDriveLogs } from '../../../drive-log.services';
import './signed-dl.scss';

const SignedDl = (props) => {
	const { params = {} } = props;
	const { redirectUrl = '' } = params;

	const { t } = useTranslation('dl');
	const { id } = useParams();
	const navigate = useNavigate();

	const { GFORM_USER_SATISFACTION_DRIVELOGS_COUNT = '' } = useAppEnv();
	// turn env var to array
	const DRIVELOGS_COUNT = useMemo(() => {
		return (
			GFORM_USER_SATISFACTION_DRIVELOGS_COUNT && JSON.parse(GFORM_USER_SATISFACTION_DRIVELOGS_COUNT)
		);
	}, [GFORM_USER_SATISFACTION_DRIVELOGS_COUNT]);

	const {
		user: { role },
	} = useContext(AuthContext);

	// user satisfaction form modal related
	const [displayUserSatisfactionModal, setDisplayUserSatisactionModal] = useState(false);
	const toggleUserSatisfactionModal = () =>
		setDisplayUserSatisactionModal((prevState) => !prevState);

	// thanks modal related
	const [displayThxModal, setDisplayThxModal] = useState(false);
	const toggleThxModal = useCallback(() => setDisplayThxModal(!displayThxModal), [displayThxModal]);

	const { driveLog } = useDriveLog(id);
	const createdForPsg = !!driveLog.is_psg;

	const generateTitle = () => {
		const text = createdForPsg
			? t('signed-dl.transmitted-psg-title')
			: t('signed-dl.transmitted-title');
		return <h1 className="signed-dl-page__title">{text}</h1>;
	};

	const generateMainControl = () => {
		const homePage = redirectUrl === '/drive-log' ? '/daily-drive-log' : '/drive-logs/returned';

		return (
			<Link className="button" to={homePage}>
				{t('signed-dl.return-home')}
			</Link>
		);
	};

	const handleRedirect = () => navigate(`${redirectUrl}/${driveLog.id}/details`);

	// only fetch signed drivers' drive logs
	const fetchDriverDrivelogs = useCallback(() => {
		if (role === 'driver') {
			getDriveLogs({ status: ['signed', 'validated', 'archived'], onlyMe: true })
				.then((response) => {
					if (response?.data) {
						const totalDriveLogs = response?.data?.meta?.totalCount;
						const showUserSatisfactionModal = DRIVELOGS_COUNT.includes(totalDriveLogs);
						if (showUserSatisfactionModal) {
							toggleUserSatisfactionModal();
						}
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [role, DRIVELOGS_COUNT]);

	const handleUserSatisfactionModal = () => {
		toggleUserSatisfactionModal();
		toggleThxModal();
	};

	useEffect(fetchDriverDrivelogs, [fetchDriverDrivelogs]);

	return (
		<DriverPage selectedLink={redirectUrl === '/drive-log' ? 0 : 1} className="signed-dl-page">
			{generateTitle()}
			<div
				className="signed-dl-page__empty-splash"
				style={{ backgroundImage: `url(${HappyMouse})` }}
			/>
			<div className="signed-dl-page__controls">
				<button className="button button--outline" onClick={handleRedirect}>
					{t('signed-dl.generate-pdf')}
				</button>
				{generateMainControl()}
			</div>
			{displayUserSatisfactionModal && (
				<UserSatisfactionSurveyModal
					handleUserSatisfactionModal={handleUserSatisfactionModal}
					closeModal={toggleUserSatisfactionModal}
				/>
			)}
			{displayThxModal && <UserSatisfactionSurveyThxModal onClose={toggleThxModal} />}
		</DriverPage>
	);
};

export default SignedDl;
