import { http } from '../../config';

/**
 * get all special notices
 * @param {object} config Configuration for the get special notices request
 * @returns {Promise} response
 */
const getSpecialNotices = async (config) => {
	const { status, search, searchOptions, sortBy } = config;
	const params = { status, search, sortBy, searchOptions: searchOptions };
	return http.get('/special-notice', { params });
};

/**
 * get a special notice by its id
 * @param {String} id the id of the special notice to get
 * @returns {Promise} response
 */
const getSpecialNoticeById = async (id) => {
	return http.get(`/special-notice/${id}`);
};

/**
 * create a new special notice
 * @param {object} specialNotice the special-notice object
 * @returns {Promise} response
 */
const createSpecialNotice = async (specialNotice) => http.post('/special-notice', specialNotice);

/**
 * update the special notice of the given id
 * @param {string} id the id of the special notice to update
 * @param {object} specialNotice the special-notice object
 * @param {object} params parameters
 * @returns {Promise} response
 */
const updateSpecialNotice = async (id, specialNotice, params = {}) =>
	http.put(`/special-notice/${id}`, specialNotice, { params });

export { getSpecialNotices, getSpecialNoticeById, createSpecialNotice, updateSpecialNotice };
