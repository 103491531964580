import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../core';
import surveySfStructure from '../../forms/survey-forms-structure/survey-sf-form-structure';
import SurveyGroupedField from './survey-grouped-fields/survey-grouped-fields';
import { isChildrenGroupValid, isFieldValid } from './survey-utils';
import '../../../core/components/button/button.scss';
import './survey-form.scss';

const SurveyForm = (props) => {
	const {
		surveyToEdit = {},
		submitForm,
		handleCancelForm = null,
		action = 'pass',
		readOnly = false,
		displayButton = true,
	} = props;
	const { id: surveyId } = surveyToEdit;
	const { t } = useTranslation();
	const { driveLogId, observationId } = useParams();

	const [driveLog] = useState({});
	const [formData, setFormData] = useState(surveyToEdit?.content || {});
	const [formValid, setFormValid] = useState(false);

	const resetForm = () => {
		setFormData({});
		setFormValid(false);
	};

	/**
	 * check if Form is Valid
	 * @return {boolean}
	 */
	const isGroupFormValid = useCallback(
		(schemaStructure) => {
			const { fieldName, group } = schemaStructure;
			const groupContentFieldsNotValid = group.some(
				(content) => !isFieldValid(formData[fieldName], fieldName, content),
			);
			const isChildrenFieldNotValid = isChildrenGroupValid(formData[fieldName], schemaStructure);
			return !groupContentFieldsNotValid && !isChildrenFieldNotValid;
		},
		[formData],
	);

	/**
	 * Handle form data change
	 * TODO separete children and mainGroup change values for lisibility, second function needed
	 */
	const handleChangeFormData = (value, groupName, fieldName, childrenName) => {
		const dataToUpdate = { ...formData };

		// get group value from formData
		const groupValues = dataToUpdate[groupName] || {};

		// get subGroup value from group
		if (childrenName) {
			const childrenGroupValues = groupValues[childrenName] || {};
			childrenGroupValues[fieldName] = value;
			// update subgroup values
			groupValues[childrenName] = childrenGroupValues;
		} else {
			// update group values
			groupValues[fieldName] = value;
		}
		dataToUpdate[groupName] = groupValues;
		setFormData(dataToUpdate);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const config = { driveLogId, observationId, surveyId, formData };
		resetForm();
		submitForm(config);
	};

	const renderSurveyStructureForm = (fieldSchema) => {
		const { fieldName } = fieldSchema;
		return (
			<SurveyGroupedField
				key={fieldName}
				fieldSchema={fieldSchema}
				fields={formData[fieldName]}
				setFields={handleChangeFormData}
				driveLog={driveLog}
				readOnly={readOnly}
			/>
		);
	};

	/**
	 * Launch form validator when form changed
	 */
	useEffect(() => {
		const fieldsNotValid = surveySfStructure.map(isGroupFormValid);
		setFormValid(fieldsNotValid.every(Boolean));
	}, [formData, isGroupFormValid]);

	return (
		<form className="survey-form" onSubmit={handleSubmit}>
			{surveySfStructure.map(renderSurveyStructureForm)}
			<div className="survey-form__button-container">
				<div className="survey-form__button-block">
					{displayButton && (
						<Button className="button--outline" type="button" onClick={handleCancelForm}>
							{t(`survey:survey-fields.button.${action}`)}
						</Button>
					)}
				</div>
				{!readOnly && (
					<div className="survey-form__button-block">
						<Button className="button" disabled={!formValid} type="submit">
							{t('survey:survey-fields.button.validate')}
						</Button>
					</div>
				)}
			</div>
		</form>
	);
};

export default SurveyForm;
