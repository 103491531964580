import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../../../../core';
import './simple-search-bar.scss';

/**
 * Search worksheet component
 * @param props
 * @param {function} props.handleSearch
 * @return {JSX.Element}
 * @constructor
 */

const SimpleSearchBar = (props) => {
	const { handleSearch } = props;

	const { t } = useTranslation();

	const [search, setSearch] = useState('');

	const handleSearchChange = (e) => {
		setSearch(e.target.value);
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSearch(search);
		}
	};

	const resetSearch = () => {
		setSearch('');
		handleSearch('');
	};

	return (
		<div className="simple-search-bar">
			<Icon className="simple-search-bar__icon" name="search" />
			<input
				aria-label={t('dl:simple-search-bar.search')}
				placeholder={t('dl:simple-search-bar.search')}
				name={t('dl:simple-search-bar.search')}
				type="text"
				className="simple-search-bar__input"
				value={search}
				onChange={handleSearchChange}
				onKeyPress={handleKeyPress}
			/>
			{search && (
				<button className="simple-search-bar__button-reset" onClick={resetSearch}>
					<Icon className="simple-search-bar__button-reset__icon" name="close" />
				</button>
			)}
		</div>
	);
};

SimpleSearchBar.propTypes = {
	handleSearch: PropTypes.func.isRequired,
};

export default SimpleSearchBar;
