import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Modal } from '../../../../../core';
import Icon from '../../../../../core/components/icon-svg/icon';
import './user-satisfaction-thanks.scss';

/**
 * Renders an UserSatisfactionSurveyThxModal component
 * it renders the UserSatisfactionSurveyThxModal component within custom modal component
 * @param {object} props
 * @param {function} props.onClose - onClose event handler
 * @returns {JSX.Element}
 */
const UserSatisfactionSurveyThxModal = (props) => {
	const { onClose = () => {} } = props;

	const { t } = useTranslation('dl');

	return (
		<Modal
			type="bottom-modal"
			overlay={false}
			className="satisfaction-survey-thx-modal"
			hideAnimation
		>
			<div className="satisfaction-survey-thx-modal__container">
				<span className="satisfaction-survey-thx-modal__container__icon-close">
					<Icon className="icon-close" aria-label="Close" name="close" onClick={onClose} />
				</span>
				<div className="satisfaction-survey-thx-modal__content-wrapper">
					<div className="satisfaction-survey-thx-modal__content-wrapper__content">
						<Icon
							className="satisfaction-survey-thx-modal__content-wrapper__icon-thx"
							aria-label="Thumbs up"
							name="thumbs_up"
						/>
						<p className="satisfaction-survey-thx-modal__content-wrapper__text">
							{t('signed-dl.satisfaction-survey.thx-feedback')}
						</p>
					</div>
				</div>
			</div>
		</Modal>
	);
};

UserSatisfactionSurveyThxModal.propTypes = {
	onClose: PropTypes.func,
};

export default UserSatisfactionSurveyThxModal;
