import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import emptySplash from '../../../core/assets/images/empty-splash.svg';
import GenericHeader from '../../../core/components/headers/generic-header/generic-header';
import PageWrapper from '../../../core/components/page-wrappers/page-wrapper/page-wrapper';
import './error-page.scss';

const ErrorPage = ({ handleOnExit = () => {}, errorMessage }) => {
	const { t } = useTranslation();
	return (
		<PageWrapper className="error-page">
			<GenericHeader className="error-page__header" hideNav hideUser />
			<div className="error-page__content">
				<div className="error-page__heading">{t('error:error-page.heading')}</div>
				<div className="error-page__message">{t('error:error-page.message')}</div>
				<img className="error-page__image" src={emptySplash} alt={t('error:error-page.message')} />
				{errorMessage && <div className="error-page__error">Error : {errorMessage}</div>}
				<Link className="error-page__button" to="/" onClick={handleOnExit}>
					{t('error:error-page.button')}
				</Link>
			</div>
		</PageWrapper>
	);
};

export default ErrorPage;
