import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkNetwork } from '../../../../../../config';
import { submitFeedbackToBackend } from '../../../../../user/save-feedback.service';
import { getCurrentUser } from '../../../../../user/user.services';
import { Button, Comment, Dropdown, Icon, Modal } from '../../../../index';
import { validateComment, validateDropdownChoice } from '../../utils/feedback-fields-validators';
import './feedback-modal.scss';

const FeedbackModal = (props) => {
	const { showModal, toggleModal, confirmCallback, cancelCallback = () => null, readOnly } = props;
	const { t } = useTranslation();
	const [ratpAccountId, setRatpAccountId] = useState(null);

	useEffect(() => {
		const fetchCurrentUser = async () => {
			try {
				const online = await checkNetwork();
				if (online) {
					const response = await getCurrentUser();
					const user = response.data;
					if (user && user.id) {
						setRatpAccountId(user.id);
					}
				}
			} catch (error) {
				console.error('Error fetching current user:', error);
			}
		};

		fetchCurrentUser();
	}, []);

	const feedbackTypes = {
		suggestion: t('core:feedback.form-modal.dropdown.suggestion'),
		technicalProblem: t('core:feedback.form-modal.dropdown.technicalProblem'),
		appreciation: t('core:feedback.form-modal.dropdown.appreciation'),
	};

	const commentMaxLength = 240;
	const [feedbackValid, setFeedbackValid] = useState(false);

	const [newFeedback, setNewFeedback] = useState({
		feedback_type: '',
		comment: '',
	});

	const [isUserIdAccepted, setIsUserIdAccepted] = useState(false);

	const validateFeedback = () => {
		const fieldChoiceValid =
			newFeedback.feedback_type && validateDropdownChoice(newFeedback.feedback_type);
		const fieldCommentValid = newFeedback.comment && validateComment(newFeedback.comment);

		if (fieldCommentValid && fieldChoiceValid) {
			return setFeedbackValid(true);
		}
	};

	const resetFeedback = () => {
		setNewFeedback({ feedback_type: '', comment: '' });
		setIsUserIdAccepted(false);
		setFeedbackValid(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const feedbackTypeValue = t(`core:feedback.form-modal.dropdown.${newFeedback.feedback_type}`);
		let userInputs = { feedback_type: feedbackTypeValue, comment: newFeedback.comment };
		if (isUserIdAccepted && ratpAccountId) {
			userInputs.created_by = ratpAccountId;
		}

		try {
			await submitFeedbackToBackend(userInputs);
		} catch (error) {
			console.error(error);
		} finally {
			toggleModal(!showModal);
			resetFeedback();
			confirmCallback();
		}
	};

	const handleCancel = () => {
		toggleModal(!showModal);
		resetFeedback();
		cancelCallback();
	};

	const handleChangeData = (name) => (value) => {
		setNewFeedback((previousState) => ({ ...previousState, [name]: value }));
	};

	useEffect(validateFeedback, [newFeedback, isUserIdAccepted]);

	return (
		showModal && (
			<Modal type="custom">
				<div className="feedback-modal__form">
					<button className="feedback-modal__form__icon" onClick={handleCancel}>
						<Icon name="close" />
					</button>
					<div className="feedback-modal__form__container">
						<div className="feedback-modal__form__container__header">
							<p className="feedback-modal__form__container__header__title">
								{t('core:feedback.form-modal.title')}
							</p>
						</div>
						<div className="feedback-modal__form__container__content">
							<p className="feedback-modal__form__container__content__sub-title">
								{t('core:feedback.form-modal.dropdown.title')}
							</p>
							<Dropdown
								value={newFeedback.feedback_type}
								onChange={handleChangeData('feedback_type')}
								className={'feedback-modal__form__content__dropdown'}
								dropdownList={feedbackTypes}
								placeholder={t('core:feedback.form-modal.dropdown.placeholder')}
							/>
						</div>
						<div className="feedback-modal__form__container__content">
							<p className="feedback-modal__form__container__content__sub-title">
								{t('core:feedback.form-modal.comment.title')}
							</p>
							<Comment
								value={newFeedback.comment}
								handleChange={handleChangeData('comment')}
								className={'feedback-modal__form__content__comment'}
								placeholder={t('core:feedback.form-modal.comment.placeholder')}
								maxLength={commentMaxLength}
								readOnly={readOnly}
							/>
						</div>

						<div className="feedback-modal__form__container__content__checkbox">
							<input
								type="checkbox"
								checked={isUserIdAccepted}
								onChange={() => setIsUserIdAccepted(!isUserIdAccepted)}
							/>
							<span>{t('core:feedback.form-modal.user-id')}</span>
						</div>
					</div>

					<div className="feedback-modal__form__button">
						<Button
							className="button"
							disabled={!feedbackValid}
							type="submit"
							onClick={handleSubmit}
						>
							{t('core:feedback.form-modal.send')}
						</Button>
					</div>
				</div>
			</Modal>
		)
	);
};

FeedbackModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
	readOnly: PropTypes.bool,
	ratpAccountId: PropTypes.string,
};

export default FeedbackModal;
