import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './delays-cells.scss';
import { useTranslation } from 'react-i18next';
import { validateDelay } from '../../../../../core/utils/validation-utils/validation-utils';
import { updateDriveLog } from '../../../../drive-log.services';
import computeTime from '../../../../../core/utils/compute-time/compute-time';

const DelaysCells = (props) => {
	const { t } = useTranslation('dl');
	const { driveLog = {}, header = false, hrToArchiveView = false, refresh } = props;

	const {
		hr_total_additional_time: totalAdditionalTime,
		hr_end_of_service_delay: endOfServiceDelay,
		hr_daily_legal_time_exceed: hrDailyLegalTimeExceed,
		hr_rest_time_reduction: hrRestTimeReduction,
		realized_date_end: hrRealizedDateEnd,
		realized_date_start: hrRealizedDateStart,
	} = driveLog;

	const [dailyLegalTimeExceed, setDailyLegalTimeExceed] = useState(hrDailyLegalTimeExceed);
	const [restTimeReduction, setRestTimeReduction] = useState(hrRestTimeReduction);
	const [total, setTotal] = useState(totalAdditionalTime);

	useEffect(() => {
		const parsedDailyLegalTimeExceed = parseInt(dailyLegalTimeExceed || 0);
		const parsedRestTimeReduction = parseInt(restTimeReduction || 0);
		const computedTime = computeTime(
			{
				shiftStartDate: hrRealizedDateStart,
				shiftEndDate: hrRealizedDateEnd,
				endOfShiftDelay: endOfServiceDelay,
				dailyLegalTimeExceed: parsedDailyLegalTimeExceed,
				restTimeReduction: parsedRestTimeReduction,
			},
			driveLog,
		);
		setTotal(computedTime.total);
	}, [
		driveLog,
		dailyLegalTimeExceed,
		endOfServiceDelay,
		hrRealizedDateEnd,
		hrRealizedDateStart,
		restTimeReduction,
	]);

	const getSuperiorTenMin = () => {
		if (endOfServiceDelay && endOfServiceDelay > 10) {
			return endOfServiceDelay - 10;
		}
		return 0;
	};

	const buildHeader = () => (
		<>
			<div className="delay-header"> {t('dl-table.headers.additionnal-time')}</div>
			<div className="delay-header"> {t('dl-table.headers.superior-10-min')}</div>
			<div className="delay-header"> {t('dl-table.headers.exceed-legal-time')}</div>
			<div className="delay-header"> {t('dl-table.headers.shortened-rest')}</div>
			<div className="delay-header"> {t('dl-table.headers.total')}</div>
			{hrToArchiveView && <div className="archived-controls" />}
		</>
	);

	const handleArchive = (e) => {
		e.stopPropagation();
		updateDriveLog(
			{
				id: driveLog.id,
				hr_daily_legal_time_exceed: dailyLegalTimeExceed,
				hr_rest_time_reduction: restTimeReduction,
			},
			{ action: 'archive' },
		)
			.then(refresh)
			.catch((err) => {
				console.error(err);
			});
	};

	const generateSwitchableCell = (initialVal, updater) => {
		const handleChange = (e) => {
			const { value } = e.target;
			if (validateDelay(value)) {
				const parsedValue = value === '' ? value : parseInt(value);
				updater(parsedValue);
			}
		};

		const handleClick = (e) => {
			e.stopPropagation();
		};

		const handleBlur = (e) => {
			e.stopPropagation();
			updateDriveLog(
				{
					id: driveLog.id,
					hr_daily_legal_time_exceed: dailyLegalTimeExceed,
					hr_rest_time_reduction: restTimeReduction,
				},
				{ action: 'modify-delay' },
			)
				.then(refresh)
				.catch((err) => {
					console.error(err);
				});
		};

		if (hrToArchiveView) {
			return (
				<input
					className="delay-cell input-delay-cell"
					type="text"
					value={initialVal}
					onChange={handleChange}
					size={4}
					onClick={handleClick}
					onBlur={handleBlur}
				/>
			);
		}
		return <div className="delay-cell"> {`${initialVal}'`} </div>;
	};

	const buildCell = () => (
		<>
			<div className="delay-cell"> {`${endOfServiceDelay}'`}</div>
			<div className="delay-cell"> {getSuperiorTenMin()}</div>
			<div className="delay-cell">
				{generateSwitchableCell(dailyLegalTimeExceed, setDailyLegalTimeExceed)}
			</div>
			<div className="delay-cell">
				{generateSwitchableCell(restTimeReduction, setRestTimeReduction)}
			</div>
			<div className="delay-cell"> {`${total}'`}</div>
			{hrToArchiveView && (
				<div className="archived-controls">
					<button className="button" onClick={handleArchive}>
						{t('dl-table.archive')}
					</button>
				</div>
			)}
		</>
	);

	return header ? buildHeader() : buildCell();
};

DelaysCells.propTypes = {
	header: PropTypes.bool,
	refresh: PropTypes.any,
	driveLog: PropTypes.object,
};

export default DelaysCells;
