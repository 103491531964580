import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './error-message.scss';

const ErrorMessage = (props) => {
	const { t } = useTranslation('core');
	const { message = t('core:error.default') } = props;

	return <div className="error-message">{message}</div>;
};

ErrorMessage.propTypes = {
	message: PropTypes.string,
};

export default ErrorMessage;
