import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import SurveyPrecisionComment from '../survey-comment/survey-precision-comment';
import './survey-checkbox-comment.scss';
import PropTypes from 'prop-types';

const SurveyCheckboxComment = (props) => {
	const {
		className = '',
		onChange,
		value = {},
		label,
		title,
		subLabel,
		labelId,
		checkboxAttribute = 'checkbox',
		precisionAttribute = 'precision',
		readOnly = false,
	} = props;
	const [checklistCommentData, setChecklistCommentData] = useState({});
	const { t } = useTranslation();

	const checked = value[checkboxAttribute] || false;
	const precision = value[precisionAttribute] || '';

	const classes = classnames(
		'survey-check-list-button',
		{ 'survey-check-list-button--checked': checked },
		className,
	);

	const handleChange = (val, name, data) => {
		data[name] = val;
		setChecklistCommentData(data);
		onChange(data);
	};

	return (
		<React.Fragment>
			<div className={classes}>
				<label className={'survey-check-list-button__choices'} htmlFor={labelId}>
					<input
						id={labelId}
						className="survey-check-list-button__input"
						onChange={() => handleChange(!checked, checkboxAttribute, {})}
						checked={checked}
						type="checkbox"
						disabled={readOnly}
					/>
					<div>
						<span>{title}</span>
						<div className="survey-check-list-button__label-container">
							<span className="survey-check-list-button__label">{label}</span>
							<span className="survey-check-list-button__sub-label">{subLabel}</span>
						</div>
					</div>
				</label>
				{checked && (
					<SurveyPrecisionComment
						className={'survey-check-list-button__comment'}
						value={precision}
						handleChange={(e) => handleChange(e, precisionAttribute, checklistCommentData)}
						placeholder={t('survey:survey-fields.precision.placeholder')}
						readOnly={readOnly}
					/>
				)}
			</div>
		</React.Fragment>
	);
};

SurveyCheckboxComment.propTypes = {
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	value: PropTypes.object,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	placeholder: PropTypes.string,
	validator: PropTypes.func,
};

export default SurveyCheckboxComment;
