/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, InputField, InputTextarea, Loader, ValidateUtils } from '../../../core';
import {
	ErrorMessage,
	MultiCheckboxField,
	MultiInputField,
	MultiRadioField,
	MultiSearchField,
	ScheduleField,
} from '../../../core/components';
import { getReferentialList } from '../../../core/core.services';
import { AuthContext } from '../../../user/auth/context/auth-context';
import { Card } from '../../components';
import {
	createSpecialNotice,
	getSpecialNoticeById,
	updateSpecialNotice,
} from '../../special-notice.services';
import { Modes } from '../../utils/constants';
import specialNoticeFormConfig from './special-notice-form.json';
import './special-notice-form.scss';

const translationEntity = 'special-notice';
const broadcastTypes = {
	Station: 'station',
	Zone: 'zone',
	Line: 'line',
};

const {
	broadcastType: broadcastTypeConfig,
	broadcastStation: broadcastStationConfig,
	broadcastZone: broadcastZoneConfig,
	tracks: tracksConfig,
	isAllMissions: isAllMissionsConfig,
	missions: missionsConfig,
	isNotScheduled: isNotScheduledConfig,
	schedule: scheduleConfig,
	title: titleConfig,
	message: messageConfig,
} = specialNoticeFormConfig;

const SpecialNoticeForm = (props) => {
	const { t } = useTranslation(translationEntity);
	const navigate = useNavigate();
	const { id: specialNoticeId } = useParams();

	const { mode = Modes.Create } = props;
	const editMode = mode === Modes.Update;
	const readOnly = mode === Modes.Read;
	const republishMode = mode === Modes.Republish;

	const { user = {} } = useContext(AuthContext);
	const [stations, setStations] = useState([]);
	// The state of the form components
	const [values, setValues] = useState({
		broadcast_type: broadcastTypes.Line,
		is_all_missions: true,
		is_not_scheduled: true,
		tracks: [1, 2],
	});
	// An invalid property has a true value
	const [validator, setValidator] = useState({});
	const [isFormValid, setIsFormValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isReady, setIsReady] = useState(false);

	const fillForm = () => {
		getLineStations().then((lineStations) => {
			setStations(lineStations);

			if (editMode || readOnly || republishMode) {
				// Get the special-notice entity and populate state values
				getSpecialNoticeById(specialNoticeId).then((res) => {
					const {
						broadcast_type,
						broadcast_station_trigram,
						broadcast_station_start_trigram,
						broadcast_station_end_trigram,
						tracks,
						is_all_missions,
						missions,
						is_not_scheduled,
						schedule_start,
						schedule_end,
						title,
						message,
					} = res.data;

					setValues((prev) => ({
						...prev,
						status: 'active',
						broadcast_type,
						tracks,
						is_all_missions,
						missions,
						is_not_scheduled: republishMode ? true : is_not_scheduled,
						schedule_start: republishMode ? null : schedule_start,
						schedule_end: republishMode ? null : schedule_end,
						title,
						message,
					}));

					if (broadcast_type === broadcastTypes.Station && broadcast_station_trigram != null) {
						setValues((prev) => ({
							...prev,
							broadcast_station: getStationByTrigram(lineStations, broadcast_station_trigram),
						}));
					}

					if (
						broadcast_type === broadcastTypes.Zone &&
						broadcast_station_start_trigram != null &&
						broadcast_station_end_trigram != null
					) {
						setValues((prev) => ({
							...prev,
							broadcast_station_start: getStationByTrigram(
								lineStations,
								broadcast_station_start_trigram,
							),
							broadcast_station_end: getStationByTrigram(
								lineStations,
								broadcast_station_end_trigram,
							),
						}));
					}

					setIsReady(true);
				});
			} else {
				setIsReady(true);
			}
		});
	};

	const getLineStations = () => {
		return getReferentialList('station').then((res) => {
			return res.data.filter((station) => {
				const { line: stationLine } = station;
				return stationLine === user.line;
			});
		});
	};

	const getStationByTrigram = (data, trigram) => {
		return data.find((station) => {
			const { trigram: stationTrigram } = station;
			return stationTrigram === trigram;
		});
	};

	const handleValidation = () => {
		setValidator((prev) => ({
			...prev,
			broadcast_station:
				values.broadcast_type === broadcastTypes.Station && values.broadcast_station == null,
			broadcast_station_start:
				values.broadcast_type === broadcastTypes.Zone && values.broadcast_station_start == null,
			broadcast_station_end:
				values.broadcast_type === broadcastTypes.Zone && values.broadcast_station_end == null,
			tracks: values.tracks == null || values.tracks?.length === 0,
			missions:
				!values.is_all_missions && (values.missions == null || values.missions?.length === 0),
			schedule:
				!values.is_not_scheduled &&
				(values.schedule_start == null ||
					values.schedule_end == null ||
					values.schedule_start > values.schedule_end),
			title: values.title == null || values.title === '',
			message: values.message == null || values.message === '',
		}));
	};

	const handleChange = (fieldName, value) => {
		setValues((prev) => ({ ...prev, [fieldName]: value }));
	};

	// Get data from form state & handle it before submit
	const handleSubmit = () => {
		const formData = {
			broadcast_type: values.broadcast_type,
			tracks: values.tracks,
			is_all_missions: values.is_all_missions,
			is_not_scheduled: values.is_not_scheduled,
			title: values.title,
			message: values.message,
		};

		if (values.broadcast_type === broadcastTypes.Station) {
			formData.broadcast_station = values.broadcast_station.name;
			formData.broadcast_station_trigram = values.broadcast_station.trigram;
		}

		if (values.broadcast_type === broadcastTypes.Zone) {
			formData.broadcast_station_start = values.broadcast_station_start.name;
			formData.broadcast_station_start_trigram = values.broadcast_station_start.trigram;
			formData.broadcast_station_end = values.broadcast_station_end.name;
			formData.broadcast_station_end_trigram = values.broadcast_station_end.trigram;
		}

		if (!values.is_all_missions) {
			formData.missions = values.missions;
		}

		if (!values.is_not_scheduled) {
			formData.is_not_scheduled = false;
			formData.schedule_start = values.schedule_start;
			formData.schedule_end = values.schedule_end;
		}

		setIsLoading(true);

		const handleData = editMode
			? () => updateSpecialNotice(specialNoticeId, formData)
			: () => createSpecialNotice(formData);

		handleData()
			.then((res) => {
				setIsLoading(false);
				navigate('/special-notices');
			})
			.catch(() => {
				// TODO: Display an error message
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fillForm();
	}, []);

	useEffect(() => {
		handleValidation();
	}, [values]);

	// Check if there are any invalid fields
	useEffect(() => {
		setIsFormValid(Object.keys(validator).filter((key) => validator[key]).length === 0);
	}, [validator]);

	return (
		<div className="special-notice__form__container">
			{!readOnly && (
				<Loader isLoading={!isReady}>
					<Card
						index={1}
						title={t(`special-notice.forms.cards.missions.${readOnly ? 'read' : 'write'}`)}
						translationEntity={translationEntity}
					>
						<MultiRadioField
							className="special-notice__form"
							translation={translationEntity}
							fieldName={isAllMissionsConfig.fieldName}
							options={isAllMissionsConfig.options}
							value={values.is_all_missions == null ? false : values.is_all_missions}
							handleChange={handleChange}
							disabled={readOnly}
						/>
						{!values.is_all_missions && (
							<>
								<MultiInputField
									className="special-notice__form"
									translation={translationEntity}
									fieldName={missionsConfig.fieldName}
									inputLength={6}
									maxItems={5}
									values={values.missions}
									handleChange={handleChange}
									validator={(item) => ValidateUtils.validateMissionId(item)}
									disabled={readOnly}
								/>

								{validator.missions && (
									<ErrorMessage message={t('special-notice.forms.errors.missions.empty')} />
								)}
							</>
						)}
					</Card>
					<Card index={2} title={t('special-notice.forms.cards.broadcast-zone.write')}>
						<MultiRadioField
							className="special-notice__form"
							translation={translationEntity}
							fieldName={broadcastTypeConfig.fieldName}
							options={broadcastTypeConfig.options}
							value={values.broadcast_type == null ? broadcastTypes.Station : values.broadcast_type}
							handleChange={handleChange}
							disabled={readOnly}
						/>
						{(values?.broadcast_type === broadcastTypes.Station ||
							values.broadcast_type == null) && (
							<>
								<MultiSearchField
									className="special-notice__form"
									translation={translationEntity}
									placeholders="special-notice.forms.fields.placeholders"
									groupName="broadcast_station"
									fieldNames={[broadcastStationConfig.fieldName]}
									options={stations}
									values={{
										broadcast_station: values.broadcast_station,
									}}
									handleChange={handleChange}
									disabled={readOnly}
								/>

								{validator.broadcast_station && (
									<ErrorMessage
										message={t('special-notice.forms.errors.broadcast_station.empty')}
									/>
								)}
							</>
						)}
						{values?.broadcast_type === broadcastTypes.Zone && (
							<>
								<MultiSearchField
									className="special-notice__form"
									translation={translationEntity}
									placeholders="special-notice.forms.fields.placeholders"
									groupName="broadcast_zone"
									fieldNames={broadcastZoneConfig.fieldNames}
									options={stations}
									values={{
										broadcast_station_start: values.broadcast_station_start,
										broadcast_station_end: values.broadcast_station_end,
									}}
									handleChange={handleChange}
									disabled={readOnly}
								/>

								<div className="special-notice__form__error-row">
									<span className="special-notice__form__error-column">
										{validator.broadcast_station_start && (
											<ErrorMessage
												message={t('special-notice.forms.errors.broadcast_station_start.empty')}
											/>
										)}
									</span>
									<span className="special-notice__form__error-column">
										{validator.broadcast_station_end && (
											<ErrorMessage
												message={t('special-notice.forms.errors.broadcast_station_end.empty')}
											/>
										)}
									</span>
								</div>
							</>
						)}
						<MultiCheckboxField
							className="special-notice__form"
							translation={translationEntity}
							fieldName={tracksConfig.fieldName}
							options={tracksConfig.options}
							values={values.tracks}
							handleChange={handleChange}
							disabled={readOnly}
						/>
						{validator.tracks && (
							<ErrorMessage message={t('special-notice.forms.errors.tracks.empty')} />
						)}
					</Card>

					<Card
						index={3}
						title={t(`special-notice.forms.cards.schedule.${readOnly ? 'read' : 'write'}`)}
					>
						<MultiRadioField
							className="special-notice__form"
							translation={translationEntity}
							fieldName={isNotScheduledConfig.fieldName}
							options={isNotScheduledConfig.options}
							value={values.is_not_scheduled == null ? false : values.is_not_scheduled}
							handleChange={handleChange}
							disabled={readOnly}
						/>
						{!values.is_not_scheduled && (
							<>
								<ScheduleField
									className="special-notice__form"
									translation={translationEntity}
									placeholders="special-notice.forms.fields.placeholders"
									fieldNames={scheduleConfig.fieldNames}
									values={{
										schedule_start: values.schedule_start,
										schedule_end: values.schedule_end,
									}}
									handleChange={handleChange}
									disabled={readOnly}
								/>

								{validator.schedule && (
									<ErrorMessage message={t('special-notice.forms.errors.schedule.chronology')} />
								)}
							</>
						)}
					</Card>
					<Card
						index={4}
						title={t(`special-notice.forms.cards.content.${readOnly ? 'read' : 'write'}`)}
					>
						<div className="special-notice__form__field">
							<div className="special-notice__form__field__label no-margin-top">
								{t('special-notice.forms.fields.labels.title')}
							</div>

							<div className="special-notice__form__field__container">
								<InputField
									className="special-notice__form__field__input"
									value={values.title}
									onChange={(value) => handleChange(titleConfig.fieldName, value)}
									maxLength={titleConfig.maxLength}
									placeholder={t(
										`special-notice.forms.fields.placeholders.${titleConfig.fieldName}`,
									)}
									disabled={readOnly}
								/>
							</div>
						</div>

						{validator.title && (
							<ErrorMessage message={t('special-notice.forms.errors.title.empty')} />
						)}

						<div className="special-notice__form__field">
							<div className="special-notice__form__field__label">
								{t('special-notice.forms.fields.labels.message')}
							</div>
							<div className="special-notice__form__field__container">
								<InputTextarea
									className="special-notice__form__field__textarea"
									placeholder={t(
										`special-notice.forms.fields.placeholders.${messageConfig.fieldName}`,
									)}
									value={values.message}
									rows={6}
									maxLength={messageConfig.maxLength}
									onChange={(value) => handleChange(messageConfig.fieldName, value)}
									disabled={readOnly}
								/>
							</div>
						</div>

						{validator.message && (
							<ErrorMessage message={t('special-notice.forms.errors.message.empty')} />
						)}
					</Card>

					<div className="special-notice__form__submit-block">
						<Button
							className="special-notice__form__button--cancel button button--outline"
							onClick={() => navigate(-1)}
						>
							{t(`special-notice.forms.buttons.${readOnly ? 'back' : 'cancel'}`)}
						</Button>
						<Button
							className="special-notice__form__button--submit button"
							type="submit"
							disabled={!isFormValid || isLoading}
							onClick={() => handleSubmit()}
						>
							{editMode || republishMode
								? t(`special-notice.forms.buttons.${mode}`)
								: t('special-notice.forms.buttons.submit')}
						</Button>
					</div>
				</Loader>
			)}
		</div>
	);
};

export default SpecialNoticeForm;
