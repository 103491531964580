import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FinalizeDl from '../finalize-dl/finalize-dl';
import EmptySplash from '../../../../../../core/assets/images/empty-splash.svg';
import AddObservationWithoutMission from '../add-observation-without-mission/add-observation-without-mission';
import DlAddMission from '../dl-add-mission/dl-add-mission';
import NoMissionObservations from '../no-mission-observations/no-mission-observations';
import FinalizeMessage from '../finalize-message/finalize-message';
import './empty-dl-list.scss';

/**
 * Renders an empty mission list on the drive log page
 * @param {object} props
 * @param {string} props.className - the className of the list
 */
const EmptyDlList = (props) => {
	const { t } = useTranslation();
	const {
		className = '',
		driveLogId,
		observations = [],
		retrieveObservationsList,
		setUncompletedDl,
		uncompletedDl,
		redirectUrl = '',
	} = props;

	return (
		<div className={`empty-dl-list ${className}`}>
			<div className="empty-dl-list__content">
				{uncompletedDl && <FinalizeMessage />}
				<h1 className="empty-dl-list__content__title"> {t('dl:dl-detail-page.no-mission')} </h1>
				<div
					className="empty-dl-list__empty-splash"
					style={{ backgroundImage: `url(${EmptySplash})` }}
				/>
			</div>
			<div className="empty-dl-list__controls">
				<DlAddMission displayButtonForm={true} redirectUrl={redirectUrl} />
				<AddObservationWithoutMission driveLogId={driveLogId} redirectUrl={redirectUrl} />
				<FinalizeDl
					setUncompletedDl={setUncompletedDl}
					observations={observations}
					redirectUrl={redirectUrl}
				/>
			</div>
			<NoMissionObservations
				observations={observations}
				retrieveObservationsList={retrieveObservationsList}
				uncompletedDl={uncompletedDl}
				redirectUrl={redirectUrl}
			/>
		</div>
	);
};

EmptyDlList.propTypes = {
	className: PropTypes.string,
	driveLogId: PropTypes.string,
	observations: PropTypes.array.isRequired,
	retrieveObservationsList: PropTypes.func.isRequired,
};

export default EmptyDlList;
