import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoadMore = (props) => {
	const { onClick, className = '' } = props;
	const { t } = useTranslation('core');

	return (
		<button className={`button ${className}`} onClick={onClick}>
			{t('load-more.label')}
		</button>
	);
};

LoadMore.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default LoadMore;
