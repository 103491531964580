import i18next from '../../config/i18next';
import i18nMission from './i18n/fr.json';
import AddMission from './pages/add-mission/add-mission';
import AddNoMissionCode from './pages/add-no-mission-code/add-no-mission-code';
import AddTrainNumber from './pages/add-train-number/add-train-number';
import ModifyStations from './pages/modify-stations/modify-stations';
import MissionDetails from './pages/mission-details/mission-details';

i18next.addResourceBundle('fr', 'mission', i18nMission);

export { AddMission, AddNoMissionCode, AddTrainNumber, ModifyStations, MissionDetails };
