import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateUtils, InputCell, RegexUtils } from '../../../index';
import './time-picker.scss';

/**
 * Renders an <TimePicker /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {number} props.hours - display the hours
 * @param {function} props.setHours - set the hours
 * @param {number} props.minutes - display the minutes
 * @param {function} props.setMinutes - set the setMinutes
 * @param {number} props.seconds - display the seconds
 * @param {function} props.setSeconds - set the setSeconds
 * @param {function} props.setTimeError - set the setTimeError
 * @param {function} props.resetToCurrentTime - reset to current time
 * @param {boolean} props.displaySeconds - display the input seconds
 */
const TimePicker = (props) => {
	const {
		className = '',
		hours,
		setHours,
		minutes,
		setMinutes,
		seconds,
		setSeconds,
		setTimeError,
		resetToCurrentTime,
		disabled = false,
		displaySeconds = false,
	} = props;
	const { t } = useTranslation();

	const [emptyHours, setEmptyHours] = useState(false);
	const [emptyMinutes, setEmptyMinutes] = useState(false);
	const [emptySeconds, setEmptySeconds] = useState(false);

	const [hoursBlurred, setHoursBlurred] = useState(true);
	const [minutesBlurred, setMinutesBlurred] = useState(true);
	const [secondsBlurred, setSecondsBlurred] = useState(true);

	const addPadding = (newValue) => {
		if (newValue >= 0 && newValue < 10) {
			return DateUtils.formatPadStart(newValue);
		}
		return newValue;
	};

	/**
	 * test if the value exist and under the max number provided, then save it
	 * @param {function} setter - the function which set the value
	 * @param {number} max - the maximal value to not exceed
	 */
	const saveUnderMaxValue = (setter, max) => (newValue) => {
		const parsedNewValue = parseInt(newValue, 10);
		if (parsedNewValue <= max) {
			setter(parsedNewValue);
		}
	};

	const handleClearHour = () => {
		setHours(0);
		setEmptyHours(true);
		setTimeError(true);
		setHoursBlurred(false);
	};

	const handleClearMinute = () => {
		setMinutes(0);
		setEmptyMinutes(true);
		setTimeError(true);
		setMinutesBlurred(false);
	};

	const handleClearSecond = () => {
		setSeconds(0);
		setEmptySeconds(true);
		setTimeError(true);
		setSecondsBlurred(false);
	};

	const handleHourChange = (newValue) => {
		if (!newValue) {
			handleClearHour();
		} else {
			saveUnderMaxValue(setHours, 23)(newValue);
			setEmptyHours(false);
			if (!emptyMinutes) {
				setTimeError(false);
			}
			setHoursBlurred(false);
		}
	};
	const handleMinutesChange = (newValue) => {
		if (!newValue) {
			handleClearMinute();
		} else {
			saveUnderMaxValue(setMinutes, 59)(newValue);
			setEmptyMinutes(false);
			if (!emptyHours) {
				setTimeError(false);
			}
			setMinutesBlurred(false);
		}
	};

	const handleSecondChange = (newValue) => {
		if (!newValue) {
			handleClearSecond();
		} else {
			saveUnderMaxValue(setSeconds, 59)(newValue);
			setEmptySeconds(false);
			if (!emptyHours && !emptyMinutes) {
				setTimeError(false);
			}
			setSecondsBlurred(false);
		}
	};

	const getHourValue = () => {
		if (emptyHours) {
			return undefined;
		}
		if (hoursBlurred) {
			return addPadding(hours);
		}
		return hours;
	};

	const getMinuteValue = () => {
		if (emptyMinutes) {
			return undefined;
		}
		if (minutesBlurred) {
			return addPadding(minutes);
		}
		return minutes;
	};

	const getSecondValue = () => {
		if (emptySeconds) {
			return undefined;
		}
		if (secondsBlurred) {
			return addPadding(seconds);
		}
		return seconds;
	};

	const handleResetToCurrentTime = () => {
		resetToCurrentTime();
		setEmptyHours(false);
		setHoursBlurred(true);
		setEmptyMinutes(false);
		setMinutesBlurred(true);
		setEmptySeconds(false);
		setSecondsBlurred(true);
		setTimeError(false);
	};

	return (
		<>
			{!displaySeconds && (
				<div className={`time-picker ${className}`}>
					<InputCell
						className="input__hours"
						type="tel"
						pattern={RegexUtils.sequenceOfDigits}
						onChange={handleHourChange}
						value={getHourValue()}
						regex={RegexUtils.twoDigitNumber}
						onClick={handleClearHour}
						onBlur={() => setHoursBlurred(true)}
						disabled={disabled}
					/>
					<span className="time__cells__separator">:</span>
					<InputCell
						className="input__minutes"
						type="tel"
						pattern={RegexUtils.sequenceOfDigits}
						onChange={handleMinutesChange}
						value={getMinuteValue()}
						regex={RegexUtils.twoDigitNumber}
						onClick={handleClearMinute}
						onBlur={() => setMinutesBlurred(true)}
						disabled={disabled}
					/>
				</div>
			)}
			{displaySeconds && (
				<div className={`time-picker ${className}`}>
					<InputCell
						className="input__duration__minutes"
						type="tel"
						pattern={RegexUtils.sequenceOfDigits}
						onChange={handleMinutesChange}
						value={getMinuteValue()}
						regex={RegexUtils.twoDigitNumber}
						onClick={handleClearMinute}
						onBlur={() => setMinutesBlurred(true)}
						disabled={disabled}
					/>
					<span className="duration__cells__separator">minute(s)</span>
					<InputCell
						className="input__duration__seconds"
						type="tel"
						pattern={RegexUtils.sequenceOfDigits}
						onChange={handleSecondChange}
						value={getSecondValue()}
						regex={RegexUtils.twoDigitNumber}
						onClick={handleClearSecond}
						onBlur={() => setSecondsBlurred(true)}
						disabled={disabled}
					/>
					<span className="duration__cells__separator">seconde(s)</span>
				</div>
			)}
			{resetToCurrentTime && (
				<button className="time-picker__reset" type="button" onClick={handleResetToCurrentTime}>
					{t('mission:modify-stations.now')}
				</button>
			)}
		</>
	);
};

TimePicker.propTypes = {
	className: PropTypes.string,
	hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setHours: PropTypes.func,
	minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setMinutes: PropTypes.func,
	seconds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setSeconds: PropTypes.func,
	displaySeconds: PropTypes.bool,
	disabled: PropTypes.bool,
	setTimeError: PropTypes.func,
	resetToCurrentTime: PropTypes.func,
	showSeconds: PropTypes.bool,
};

export default TimePicker;
