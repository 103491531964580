import { differenceInMonths } from 'date-fns';
import localForage from '../local-forage';

// versioning system for cache
const { REACT_APP_CURRENT_VERSION = 'V0.8.0.202107271837' } = process.env;
const { REACT_APP_MAX_CACHE_AGE = 3 } = process.env;

/**
 * Purge cache older than CACHE_EXPIRATION interval
 * @return {Promise<void>}
 */
const purgeOldStorage = async () => {
	const keys = await localForage.keys();

	const purgeKey = async (key) => {
		const cache = await localForage.getItem(key);
		if (
			cache?.data &&
			cache?.cacheDate &&
			differenceInMonths(new Date(), new Date(cache.cacheDate.toString())) > REACT_APP_MAX_CACHE_AGE
		) {
			await localForage.removeItem(key);
		}
	};
	await Promise.allSettled(keys.map(purgeKey));
};

/**
 * Purge cache from older app versions
 * @return {Promise<void>}
 */
const purgeExpiredStorage = async () => {
	const keys = await localForage.keys();
	await Promise.allSettled(keys.map(async (key) => localForage.removeItem(key)));
	await localForage.setItem('cache-version', REACT_APP_CURRENT_VERSION);
};

/**
 * Purge all cache older than 3 months
 * @return {Promise<void>}
 */
const purgeStorage = async () => {
	const checkVersion = await localForage.getItem('cache-version');

	if (!checkVersion || checkVersion < REACT_APP_CURRENT_VERSION) {
		// purge everything
		await purgeExpiredStorage();
	} else {
		// purge old storage only
		await purgeOldStorage();
	}
};

export { purgeStorage };
