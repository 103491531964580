import React from 'react';
import './feedbacks-list-row.scss';
import { extractNumbers } from '../../../nudges/utils';
import { getColorByFeedbackType } from '../../utils';

/**
 * FeedbacksListRow component to display a row for each feedback
 * @param {object} props
 * @param {object} props.feedback - feedback data
 * @returns {JSX.Element}
 */
const FeedbacksListRow = ({ feedback }) => {
	const { feedback_type, created_at, comment, created_by } = feedback;

	const formattedDate = new Date(created_at).toLocaleString([], {
		dateStyle: 'short',
		timeStyle: 'short',
	});

	return (
		<div className="feedbacks-list-row">
			<div
				className={`feedbacks-list-row__cell feedbacks-list-row__cell__${getColorByFeedbackType(
					feedback_type,
				)} bold`}
			>
				{feedback_type}
			</div>
			<div className="feedbacks-list-row__cell">{formattedDate}</div>
			<div className="feedbacks-list-row__cell">{comment}</div>
			<div className="feedbacks-list-row__cell">{created_by && extractNumbers(created_by)}</div>
		</div>
	);
};

export default FeedbacksListRow;
