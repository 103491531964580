import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../input-field/input-field';
import './input-dropdown.scss';

/**
 * Renders an <InputDropdown /> component
 * @param {Object} props
 * @param {string} props.value - input value
 * @param {Array} props.results - array of { key, name } elements
 * @param {function} props.onChange - function to handle change events for the search input
 * @param {function} props.onSelect - function to handle select events for the drop-down list
 * @param {boolean} props.hasError - boolean on whether or not form has errors
 * @param {boolean} props.required - input field is required or not
 */
const InputDropdown = (props) => {
	const { value = '', results, onChange, onSelect, hasError, required, placeholder = '' } = props;

	const showResults = (result) => {
		const { key, name: resultName } = result;
		return (
			<button key={key} className="input-dropdown__results__field" onClick={() => onSelect(key)}>
				{resultName}
			</button>
		);
	};

	return (
		<div className="input-dropdown">
			<InputField
				className="input-dropdown__field"
				icon="search"
				value={value}
				onChange={onChange}
				hasError={hasError}
				required={required}
				placeholder={placeholder}
			/>

			{results && results.length > 0 && (
				<div className="input-dropdown__results">{results.map(showResults)}</div>
			)}
		</div>
	);
};

InputDropdown.propTypes = {
	value: PropTypes.string,
	results: PropTypes.array,
	onChange: PropTypes.func,
	onSelect: PropTypes.func,
	hasError: PropTypes.bool,
	required: PropTypes.bool,
};

export default InputDropdown;
