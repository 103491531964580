import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import localForage from '../../../../config/local-forage';
import { Icon, Loader, PopupFullPage } from '../../../core';
import EmptySplash from '../../../core/assets/images/empty-splash.svg';
import { getReferentialList } from '../../../core/core.services';
import sortedStationsList from '../../../drive-log/utils/sorted-stations-list/sorted-stations-list.json';
import { filterListStationsMission } from '../../utils';
import StationRow from './components/station-row/station-row';
import './mission-details.scss';

const MissionDetails = () => {
	const { id: driveLogId, missionId } = useParams();
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const serviceNumber = searchParams.get('serviceNumber');
	const attachmentBigram = searchParams.get('attachmentBigram');
	const line = searchParams.get('line');
	const station_start = searchParams.get('station_start');
	const station_end = searchParams.get('station_end');

	const { t } = useTranslation();

	// states declaration
	const [isLoading, setIsLoading] = useState(true);
	const [stationsList, setStationsList] = useState([]);
	const [stationsRefList, setStationsRefList] = useState([]);

	useEffect(() => {
		const fetchStationsRefList = async () => {
			const savedCache = await localForage.getItem('stationsRefList');
			if (savedCache) {
				const { date, data } = JSON.parse(savedCache);
				const today = new Date().toISOString().split('T')[0];
				if (date === today) {
					setStationsRefList(data);
					return;
				}
			}
			try {
				const res = await getReferentialList('station');
				setStationsRefList(res.data);
				const cacheData = {
					date: new Date().toISOString().split('T')[0], // Date au format YYYY-MM-DD
					data: res.data,
				};
				localForage.setItem('stationsRefList', JSON.stringify(cacheData));
			} catch (error) {
				console.error('Error fetching stations reference list:', error);
			}
		};

		fetchStationsRefList();
	}, []);

	const filterList = useCallback(
		(list) => {
			if (list && Object.keys(list).length > 0) {
				const dataArray = Object.keys(list).map((key) => {
					return list[key];
				});
				// Step 1: Transform the list of stations
				const transformedStationList = dataArray.map((station) => {
					// Find the corresponding station in the reference list in order to retrieve the name and trigram
					const correspondingStation = stationsRefList.find(
						(st) => st.bigram === station.station_bigram,
					);
					// Get the position of the station
					const stationPosition = sortedStationsList[line][correspondingStation?.trigram];
					// Return the station object with new properties
					return {
						...station,
						station_name: correspondingStation?.name,
						station_trigram: correspondingStation?.trigram,
						station_position: stationPosition,
					};
				});

				// Step 2: Sort the stations based on their position on the line and the direction
				const sortedStationList = transformedStationList.sort((a, b) => {
					if (a.direction === 2) {
						return a.station_position - b.station_position;
					} else if (a.direction === 1) {
						return b.station_position - a.station_position;
					}
					return 0;
				});

				const filteredDisabledStationsMission = filterListStationsMission(
					sortedStationList,
					station_start,
					station_end,
				);

				// Step 4: Update the sorted stations list
				return filteredDisabledStationsMission;
			}
		},
		[stationsRefList, line, station_start, station_end],
	);

	useEffect(() => {
		const fetchData = async () => {
			const savedCache = await localForage.getItem('mission-details');
			if (savedCache && savedCache.data && savedCache.data[code]) {
				const list = filterList(savedCache.data[code]);
				setStationsList(list);
				setIsLoading(false);
				return;
			} else setIsLoading(false);
		};

		if (stationsRefList.length > 0) {
			fetchData();
		}
	}, [
		attachmentBigram,
		driveLogId,
		filterList,
		line,
		code,
		missionId,
		serviceNumber,
		stationsRefList,
	]);

	const generateStationRow = (stationData) => {
		return (
			<StationRow
				key={stationData.station_trigram}
				stationData={stationData}
				isDeparture={stationData.station_name === station_start}
				isArrival={stationData.station_name === station_end}
			/>
		);
	};

	return (
		<PopupFullPage className="mission-details-page" title={t('mission:mission-details.title')}>
			<div className={'mission-details-page__content'}>
				<Loader isLoading={isLoading}>
					<div className="mission-details-page__header">
						<div className="mission-details-page__header__item">
							<span className="mission-details-page__header__line">{'RER'}</span>
							<Icon
								name={line === 'A' ? 'line_A_square' : 'line_B_square'}
								className="mission-details-page__header__line__icon"
							/>
						</div>

						<div className="mission-details-page__header__code">
							<span>{code}</span>
						</div>

						<div className="mission-details-page__header__item">
							<span className="mission-details-page__header__station">{station_start}</span>
							<Icon
								name={line === 'A' ? 'toA' : 'to'}
								className="mission-details-page__header__station__icon"
							/>
							<span className="mission-details-page__header__station">{station_end}</span>
						</div>
					</div>
					<div className="mission-details-page__content">
						{!isLoading && (!stationsList || stationsList.length === 0) ? (
							<div className="empty-dl-list">
								<h1 className="empty-dl-list__content__title">
									{t('dl:dl-detail-page.no-details-mission')}
								</h1>
								<div
									className="empty-dl-list__empty-splash"
									style={{ backgroundImage: `url(${EmptySplash})` }}
								/>
							</div>
						) : (
							<ul>{Array.isArray(stationsList) && stationsList.map(generateStationRow)}</ul>
						)}
					</div>
				</Loader>
			</div>
		</PopupFullPage>
	);
};

export default MissionDetails;
