import React from 'react';
import PropTypes from 'prop-types';
import './drive-log-subheader.scss';

/**
 * Renders a <DriveLogSubHeader /> component
 * @param {object} props
 * @param {string} props.subHeader - the page subtitle / sub header
 */
const DriveLogSubHeader = (props) => {
	const { children, subHeader, className = '' } = props;

	return (
		<div className={`dl-subheader ${className}`}>
			{subHeader}
			{children}
		</div>
	);
};

DriveLogSubHeader.propTypes = {
	subHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	className: PropTypes.string,
};

export default DriveLogSubHeader;
