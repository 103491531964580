import { differenceInHours } from 'date-fns';
import http from '../axios';
import localForage from '../local-forage';
import checkNetwork from './check-network';
import { synchronizeAllStorage } from './synchronize-storage';

/**
 * Retrieves data from API and refresh local storage
 * @param {string} key - local storage key
 * @param {string} url - url to call
 * @param config
 * @return {Promise<Object>}
 */
const getDataFromApi = async (key, url, config = {}) => {
	try {
		// check if there's pending data in local storage and synchronize
		await synchronizeAllStorage();
		const apiData = await http.get(url, config);
		const { data } = apiData;

		// refresh local storage
		localForage.setItem(key, { data, cacheDate: new Date() }).then();
		return apiData;
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

/**
 * Retrieves data from API and refresh local storage
 * @param {string} key - local storage key
 * @param {string} url - url to call
 * @param config
 * @return {Promise<Object>}
 */
const refreshDataFromApi = async (key, url, config = {}) => {
	try {
		// check if there's pending data in local storage and synchronize
		await synchronizeAllStorage();
		const value = localForage.getItem(key);
		if (value?.data && value.cacheDate) {
			const isLessThan12Hours =
				differenceInHours(new Date(), new Date(value.cacheDate.toString())) < 12;
			if (isLessThan12Hours) {
				return;
			}
		}
		const apiData = await http.get(url, config);
		const { data } = apiData;
		// refresh local storage
		localForage.setItem(key, { data, cacheDate: new Date() }).then();
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

/**
 * Retrieves data from local storage
 * @param {string} key - local storage key
 * @return {Promise<{data: Object}>}
 */
const getDataFromLocalStorage = async (key) => {
	const response = await localForage.getItem(key);
	if (response && response.data) {
		const { data } = response;
		return { data };
	}
	return null;
};

/**
 * Retrieve expired data from API
 * @param {string} key
 * @param {string} url
 * @param {number} maxCacheAge - cache age limit in hours
 * @param config
 * @return {Promise<void>}
 */
const getExpiredDataFromApi = async (key, url, maxCacheAge, config) => {
	try {
		const cache = await localForage.getItem(key);
		if (!cache || (cache.data instanceof Blob && cache.data.size === 0)) {
			return getDataFromApi(key, url, config);
		}
		const { cacheDate } = cache;
		// cache date is over the cache age limit.
		const isExpired = differenceInHours(new Date(), new Date(cacheDate.toString())) > maxCacheAge;

		// cache no longer valid if there's no max age, no date or is expired
		const cacheNotValid = !maxCacheAge || !cacheDate || isExpired;

		// renew cache if not valid
		if (cacheNotValid) {
			return getDataFromApi(key, url, config);
		}

		return getDataFromLocalStorage(key);
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

const checkDataWithIdFromApi = async (key, url, id, config = {}) => {
	try {
		const cache = await localForage.getItem(key);
		const online = await checkNetwork();
		if (
			online &&
			(!cache || (cache.data instanceof Blob && cache.data.size === 0) || cache.id !== id)
		) {
			await synchronizeAllStorage();
			const apiData = await http.get(url, config);
			const { data } = apiData;
			if (data && !(data instanceof Blob && data.size === 0)) {
				localForage.setItem(key, { data, id });
				return data;
			}
			return null;
		}
		return cache?.data;
	} catch (err) {
		console.log('Data with id fetch failed', err);
		return null;
	}
};

/**
 * Dispatch request to either api (if app is online) or local storage (if app is offline)
 * @param {string} key - local storage key
 * @param {string} url - url to call (GET request)
 * @param config
 * @return {Promise<{data: Object}>}
 */
const dispatchGetRequest = async (key, url, config = {}) => {
	const online = await checkNetwork();
	if (online) {
		return await getDataFromApi(key, url, config);
	}

	return getDataFromLocalStorage(key);
};

export {
	checkDataWithIdFromApi,
	getDataFromApi,
	getDataFromLocalStorage,
	getExpiredDataFromApi,
	refreshDataFromApi,
};
export default dispatchGetRequest;
