import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, subYears } from 'date-fns';

import { DatePickerComponent, ResponsiveText } from '../../../core';
import { ExportSectionTitle } from '../../';
import {
	verifyDaysRange,
	validateStartDate,
	validateEndDate,
} from '../../utils/validate-export-date-picker';
import './export-date-picker.scss';

const ExportDatePicker = (props) => {
	const { onChange = () => {} } = props;
	// current start date
	const [startFullDate, setStartFullDate] = useState(new Date());
	// current end date
	const [endFullDate, setEndFullDate] = useState(new Date());

	const [validDate, setValidDate] = useState(true);

	const [validStartDate, setValidStartDate] = useState(true);
	const [validEndDate, setValidEndDate] = useState(true);

	const { t } = useTranslation('exp');

	useEffect(() => {
		const isStartDateValid = validateStartDate(startFullDate, endFullDate);
		setValidStartDate(isStartDateValid);
	}, [startFullDate, endFullDate]);

	useEffect(() => {
		const isEndDateValid = validateEndDate(startFullDate, endFullDate);
		setValidEndDate(isEndDateValid);
	}, [startFullDate, endFullDate]);

	useEffect(() => {
		const isDaysRangeValid = verifyDaysRange(startFullDate, endFullDate);
		setValidDate(isDaysRangeValid);
	}, [endFullDate, startFullDate]);

	useEffect(() => {
		if (!validDate) {
			onChange({ target: { name: 'from', value: null } });
			onChange({ target: { name: 'to', value: null } });
		} else {
			onChange({ target: { name: 'from', value: startFullDate } });
			onChange({ target: { name: 'to', value: endFullDate } });
		}
	}, [validDate, startFullDate, endFullDate, onChange]);

	return (
		<div className="date-picker__section">
			<ExportSectionTitle
				sectionNumber={t('exp:sections.dates-picker.section-number')}
				sectionTitle={t('exp:sections.dates-picker.section-title')}
			/>
			<div className="date-picker__section__content">
				<div className="date-picker__section__content__picker">
					<div className="date-picker__section__content__picker__date">
						<ResponsiveText
							text={t('exp:sections.dates-picker.from')}
							mobileText={t('exp:sections.dates-picker.from')}
							className="date-picker__section__content__picker__text"
						/>
						<DatePickerComponent
							date={startFullDate}
							dateFormat="dd/MM/yyyy"
							isError={!validDate || !validStartDate}
							large
							maxDate={addDays(new Date(), 0)}
							minDate={subYears(new Date(), 5)}
							onChange={setStartFullDate}
						/>
					</div>
					<div className="date-picker__section__content__picker__date">
						<ResponsiveText
							text={t('exp:sections.dates-picker.to')}
							mobileText={t('exp:sections.dates-picker.to')}
							className="date-picker__section__content__picker__text"
						/>
						<DatePickerComponent
							date={endFullDate}
							dateFormat="dd/MM/yyyy"
							isError={!validDate || !validEndDate}
							large
							maxDate={addDays(new Date(), 0)}
							minDate={subYears(new Date(), 5)}
							onChange={setEndFullDate}
						/>
					</div>
				</div>
				{!validDate && (
					<div className="date-picker__section__content__picker__error">
						<span className="date-picker__section__content__picker__error__text">
							{t('exp:sections.dates-picker.error-msg')}
						</span>
					</div>
				)}
			</div>
		</div>
	);
};

export default ExportDatePicker;
