import http from '../../config/axios';
import localForage from '../../config/local-forage';
import { getLineSheetDataFromLocalStorage } from '../../config/offline/dispatch-line-sheet-get-request';
import retrieveFileName from '../export/utils/retrieve-filename';

/**
 * get line sheet
 * @param {Object} params
 * @return {Promise} response
 */
const getLineSheet = async (params = {}) => {
	const { line, status } = params;
	const key = `line-sheet-${line}-${status}`;
	const val = await getLineSheetDataFromLocalStorage(key);
	if (val && val.cacheDate && new Date(val.cacheDate).toDateString() === new Date().toDateString())
		return val;
	const config = {
		responseType: 'blob',
		params: { line, status },
	};
	const apiData = await http.get('/line-sheet', config);
	const { data } = apiData;
	// if no line sheet retrieve, return null
	if (data.size === 0) {
		return null;
	}

	const fileName = retrieveFileName(apiData);
	// refresh local storage
	await localForage.setItem(key, { data, fileName, cacheDate: new Date() });
	return { ...apiData, fileName };
};

export { getLineSheet };
