import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import GenericHeader from '../generic-header/generic-header';

/**
 * Renders a <NudgesHeader /> component
 * @param {object} props
 * @param {string} props.className - className to add to the Supervisor header Component
 * @param {string} props.selectedLink - the link-related number
 * @param {bool} props.hideNav - hide or not the nav links
 */
const NudgesHeader = props => {
	const { className = '', selectedLink = 0, hideNav = false } = props;
	const { t } = useTranslation();

	const links = [
		{ target: '/nudges', text: t('core:header.nudges-distributed') },
		{ target: '/nudges/enclosed', text: t('core:header.nudges-archived') },
	];

	return (
		<GenericHeader className={className} links={links} selectedLink={selectedLink} hideNav={hideNav} />
	);
};

NudgesHeader.propTypes = {
	classname: PropTypes.string,
	selectedLink: PropTypes.number,
	hideNav: PropTypes.bool,
};

export default NudgesHeader;


