import { addHours, differenceInMinutes, endOfDay } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import localForage from '../../../../../config/local-forage';
import { refreshDataFromApi } from '../../../../../config/offline/dispatch-get-request';
import { DateUtils, DriverPage, Loader, Modal, ResponsiveText } from '../../../../core';
import { getMissionsFromRef } from '../../../../mission/mission.services';
import { deleteDriveLog, getDailyDriveLog } from '../../../drive-log.services';
import './daily-dl-page.scss';

const DailyDlPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { formatHourMinutes, formatFullDate, formatDuration } = DateUtils;

	const [currentDriveLog, setCurrentDriveLog] = useState(null);
	const [showDLExistsModal, setShowDLExistsModal] = useState(false);
	const [loading, setLoading] = useState(true);
	const [returnUrl, setReturnUrl] = useState('');

	// const { checkDriveLogType } = useContext(NotificationContext);

	const validateDriveLog = (driveLog) => {
		const { attachment_name, realized_date_start, realized_date_end, realized_service_number } =
			driveLog;
		return !!(
			attachment_name &&
			realized_date_start &&
			realized_date_end &&
			realized_service_number
		);
	};

	const formatDriveLog = useCallback((driveLog) => {
		const {
			id,
			attachment_name: attachmentName,
			realized_date_start,
			realized_date_end,
			realized_service_number,
			sheet_line_number,
		} = driveLog;
		const realizedDateStart = new Date(realized_date_start);
		const realizedDateEnd = new Date(realized_date_end);
		const duration = differenceInMinutes(realizedDateEnd, realizedDateStart);
		const valid = validateDriveLog(driveLog);

		return {
			id,
			attachmentName,
			realizedDateStart,
			realizedDateEnd,
			realized_service_number,
			duration,
			valid,
			sheet_line_number,
		};
	}, []);

	useEffect(() => {
		refreshDataFromApi('stations', '/referential/station');
		refreshDataFromApi('train-storage', '/referential/train-storage');
	}, []);

	const getDailyDL = useCallback(
		(type) => {
			getDailyDriveLog({ type })
				.then((payload) => {
					const driveLog = payload.data;
					if (driveLog?.id) {
						localForage.setItem(`dl-${driveLog.id}`, { data: driveLog });
						const formattedLastDriveLog = formatDriveLog(driveLog);
						setCurrentDriveLog(formattedLastDriveLog);
						const { realized_date_start: dlDate } = driveLog;
						const now = new Date();
						localForage.getItem('mission-details').then(async (data) => {
							if (!data || data.id !== driveLog.id) {
								const params = {
									attachmentBigram: driveLog.attachment_bigram,
									line: driveLog.line_number,
									service: driveLog.realized_service_number,
								};
								const stationResponse = await getMissionsFromRef(
									'missions-schedule-info',
									'/mission/schedule-info',
									{ params },
								);
								if (stationResponse) {
									localForage.setItem('mission-details', {
										data: stationResponse.data,
										id: driveLog.id,
									});
								}
							}
						});
						// the modal to replace existing dl should pop the day after the dlDate at 2am
						const triggerModalDate = addHours(endOfDay(new Date(dlDate)), 2);
						if (now > triggerModalDate) {
							setShowDLExistsModal(true);
						}
						//checkDriveLogType(driveLog);
					}
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => setLoading(false));
		},
		[formatDriveLog],
	);

	useEffect(() => {
		getDailyDL('daily');
	}, [getDailyDL]);

	const handleCompletePreviousDL = () => {
		const { id } = currentDriveLog;
		navigate(`/drive-log/${id}`);
		setShowDLExistsModal(false);
	};

	const handleDeletePreviousDL = () => {
		const { id } = currentDriveLog;
		deleteDriveLog(id).then(() => {
			setShowDLExistsModal(false);
			localForage.removeItem('daily-drive-log').then(() => {
				const retrieveDailyDl = getDailyDL('daily-import');
				setCurrentDriveLog(retrieveDailyDl);
			});
		});
	};

	useEffect(() => {
		const generateLink = async () => {
			if (loading) return;
			if (!currentDriveLog?.id) {
				setReturnUrl('/drive-log/create');
			} else {
				const data = await localForage.getItem('daily-lineSheet-seen');
				if (!data || data !== currentDriveLog.id)
					setReturnUrl(`/line-sheet?driveLogId=${currentDriveLog.id}`);
				else setReturnUrl(`/drive-log/${currentDriveLog.id}`);
			}
		};
		generateLink();
	}, [loading, currentDriveLog]);

	return (
		<DriverPage
			className={`daily-dl-page ${
				currentDriveLog && currentDriveLog.valid ? '' : 'daily-dl-page--invalid'
			}`}
		>
			<h1 className="daily-dl-page__title">{t('dl:daily-dl-page.my-daily-dl')}</h1>
			<Loader isLoading={loading}>
				<Link to={returnUrl} className="daily-dl-page__dl-information">
					<div className="daily-dl-page__service-information">
						<div className="daily-dl-page__service-number">
							<h3 className="daily-dl-page__service-number__title">
								{t('dl:daily-dl-page.service-number')}
							</h3>
							<div className="daily-dl-page__service-number__number">
								{currentDriveLog?.realized_service_number !== '-1'
									? currentDriveLog?.realized_service_number
									: 'PV'}
							</div>
						</div>
						<div className="daily-dl-page__attachment">
							<div className="daily-dl-page__attachment__title">
								{t('dl:daily-dl-page.attachment')}
							</div>
							<div className="daily-dl-page__attachment__name">
								{currentDriveLog?.attachmentName}
							</div>
						</div>
					</div>
					<div className="daily-dl-page__date-information">
						<div className="daily-dl-page__date-information__day">
							<h3 className="daily-dl-page__date-information__day__title">
								{t('dl:daily-dl-page.today')}
							</h3>
							<div className="daily-dl-page__date-information__day__service-date">
								{currentDriveLog && formatFullDate(currentDriveLog.realizedDateStart)}
							</div>
						</div>
						<div className="daily-dl-page__date-information__timetable">
							<h3 className="daily-dl-page__date-information__timetable__title">
								{t('dl:daily-dl-page.timetable')}
							</h3>
							<div className="daily-dl-page__date-information__timetable__hours">
								<span className="from">{t('dl:daily-dl-page.from')}</span>
								<span className="daily-dl-page__date-information__timetable__hours__start">
									{currentDriveLog && formatHourMinutes(currentDriveLog.realizedDateStart)}
								</span>
								<span className="to">{t('dl:daily-dl-page.to')}</span>
								<span className="separator">-</span>
								<span className="daily-dl-page__date-information__timetable__hours__end">
									{currentDriveLog && formatHourMinutes(currentDriveLog.realizedDateEnd)}
								</span>

								<div className="daily-dl-page__date-information__timetable__hours__duration">
									{currentDriveLog && formatDuration(currentDriveLog.duration)}
								</div>
							</div>
						</div>
					</div>
				</Link>
			</Loader>

			<div className="daily-dl-page__controls">
				<button className="button button--outline">
					<Link to={`/drive-log/${currentDriveLog?.id}/modify-service?action=dailyDl`}>
						<ResponsiveText
							text={t('dl:daily-dl-page.modify-service')}
							mobileText={t('dl:daily-dl-page.modify-service-short')}
						/>
					</Link>
				</button>

				<Link to={returnUrl} className="button">
					<ResponsiveText
						text={t('dl:daily-dl-page.fill-dl')}
						mobileText={t('dl:daily-dl-page.fill-dl-short')}
					/>
				</Link>
			</div>

			<div className="daily-dl-page__empty-dl">
				<p className="daily-dl-page__empty-dl__description">{t('dl:daily-dl-page.no-service')}</p>
				<div className="daily-dl-page__empty-dl__bottom-wrapper">
					{!(currentDriveLog && currentDriveLog.valid) && (
						<button className="button button--outline">
							<Link to={'/drive-log/choose-service?action=dailyDl'}>
								<ResponsiveText
									text={t('dl:daily-dl-page.choose-service')}
									mobileText={t('dl:daily-dl-page.choose-service-short')}
								/>
							</Link>
						</button>
					)}
					<Link to="/drive-log/create?new-drivelog=true" className="daily-dl-page__empty-dl__link">
						{t('dl:daily-dl-page.create-empty-dl')}
					</Link>
				</div>
			</div>

			{showDLExistsModal && (
				<Modal
					type="confirm"
					title={t('dl:daily-dl-page.dl-exists-modal.title')}
					confirmText={t('dl:daily-dl-page.dl-exists-modal.confirm')}
					cancelText={t('dl:daily-dl-page.dl-exists-modal.cancel')}
					confirmCallback={handleDeletePreviousDL}
					cancelCallback={handleCompletePreviousDL}
					closeCallback={handleCompletePreviousDL}
				/>
			)}
		</DriverPage>
	);
};

export default DailyDlPage;
