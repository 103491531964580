const alphaNumeric = /^[a-z0-9]+$/i;

const alphaNumericWithFifteenCharacters = /^[a-zA-Z0-9]{0,15}$/i;

const alphaNumericWithFiveCharacters = /^[a-zA-Z0-9]{0,5}$/i;

const alphaNumericSpecialCharactersWithTwenty = /^[a-z0-9!-%,-.@^_~\sàâäéèêëîïôöùûüç]{0,20}$/i;

const numeric = /^[0-9]+$/i;

const sequenceOfDigits = '[0-9]*';

const numericOrEmpty = /^(\s*|\d+)$/;

const twoDigitNumber = /^\d{0,2}$/;

const fourDigitNumber = /^\d{0,4}$/i;

const sixDigitNumber = /^\d{0,6}$/i;

const twentyDigitNumber = /^\d{0,20}$/i;

const alphabeticAndSpecialCharacters = /^[ a-zA-Z._^%$#!~@,-]+$/i;

const alphaNumericAndSpecialCharacters = /^[ A-Za-z0-9_@./#&+-,;ù¨^!?]*$/;

const alphaNumericAndSpecialCharactersWithFifteen =
	/^[ A-Za-z0-9_@./#&+-,-;ù¨^%$!?*àçé"{}()'\s]{0,15}$/i;

export {
	alphaNumeric,
	alphaNumericAndSpecialCharacters,
	alphaNumericAndSpecialCharactersWithFifteen,
	alphaNumericSpecialCharactersWithTwenty,
	alphaNumericWithFifteenCharacters,
	alphaNumericWithFiveCharacters,
	alphabeticAndSpecialCharacters,
	fourDigitNumber,
	numeric,
	numericOrEmpty,
	sequenceOfDigits,
	sixDigitNumber,
	twentyDigitNumber,
	twoDigitNumber,
};
