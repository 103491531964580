import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, InputTextarea, RadioButton } from '../../../../../core';
import { validateComment, validateRating } from '../../utils/satisfaction-survey-form-validators';

import './user-satisfaction-form.scss';

const satisfactionData = [
	{ id: 0, value: '0' },
	{ id: 1, value: '1' },
	{ id: 2, value: '2' },
	{ id: 3, value: '3' },
	{ id: 4, value: '4' },
	{ id: 5, value: '5' },
	{ id: 6, value: '6' },
	{ id: 7, value: '7' },
	{ id: 8, value: '8' },
	{ id: 9, value: '9' },
	{ id: 10, value: '10' },
];

/**
 * Renders an UserSatisfactionForm component
 * @param {object} props
 * @param {function} props.onConfirm - onConfirm event handler
 * @returns {JSX.Element}
 */

const UserSatisfactionForm = () => {
	/*const { onConfirm = () => {} } = props;*/
	const { t } = useTranslation('dl');

	const [comment, setComment] = useState('');
	const [rating, setRating] = useState('');
	const [formValid, setFormValid] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		// const userInputs = { rating, comment };
		// try {
		// 	await sendSatisfactionSurveyData(userInputs);
		// } catch (error) {
		// 	console.error(error);
		// } finally {
		// 	onConfirm();
		// }
	};

	const isFormValid = useCallback(
		() => setFormValid(validateRating(rating) && validateComment(comment)),
		[comment, rating],
	);

	useEffect(isFormValid, [isFormValid]);

	const renderRadioList = (item) => {
		return (
			<RadioButton
				key={item.id}
				name={item.value}
				label={item.value}
				onChange={() => setRating(item.value)}
				checked={item.value === rating}
				className={classNames('satisfaction-survey__list-item', {
					[`satisfaction-survey__list-item--${item.value}`]: item.value,
					[`satisfaction-survey__list-item--${item.value} satisfaction-survey__list-item--${item.value}--checked`]:
						item.value === rating,
				})}
				radioLabelClassName={classNames('satisfaction-survey__label', {
					[`satisfaction-survey__label--${item.value} selected`]: item.value === rating,
				})}
			/>
		);
	};

	return (
		<div className="satisfaction-survey">
			<div className="satisfaction-survey__header">
				<h1 className="satisfaction-survey__header__title">
					{t('signed-dl.satisfaction-survey.gratitude')}
				</h1>
				<h2 className="satisfaction-survey__header__title--secondary">
					{t('signed-dl.satisfaction-survey.evaluation')}
				</h2>
			</div>
			<form className="satisfaction-survey__form" onSubmit={handleSubmit}>
				<div className="satisfaction-survey__form__container">
					<div className="satisfaction-survey__form__radio-list">
						{satisfactionData.map(renderRadioList)}
					</div>
					<div className="satisfaction-survey__form__legend">
						<span className="satisfaction-survey__form__legend__text">
							{t('signed-dl.satisfaction-survey.not-at-all')}
						</span>
						<span className="satisfaction-survey__form__legend__text">
							{t('signed-dl.satisfaction-survey.very')}
						</span>
					</div>
				</div>
				<div className="satisfaction-survey__comment">
					<span className="satisfaction-survey__comment__text satisfaction-survey__comment__text--bold">
						{t('signed-dl.satisfaction-survey.comment')}
					</span>
					<InputTextarea
						className="satisfaction-survey__comment__text-area"
						placeholder={t('signed-dl.satisfaction-survey.comment-placeholder')}
						onChange={(text) => setComment(text)}
						value={comment}
					/>
				</div>
				<Button disabled={!formValid} type="submit">
					{t('signed-dl.satisfaction-survey.cta')}
				</Button>
			</form>
		</div>
	);
};

UserSatisfactionForm.propTypes = {
	onConfirm: PropTypes.func,
};

export default UserSatisfactionForm;
