import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../core';
import { MATOMO_ACTIONS, MATOMO_CATEGORIES } from '../../../core/matomo/matomo-constants';
import useMatomoTracker from '../../../core/matomo/matomo-tracker';
import { getObservationById } from '../../../observation/observation.services';
import { AuthContext } from '../../../user/auth/context/auth-context';
import SurveyForm from '../../components/survey-form/survey-form';
import SurveyHeader from '../../components/survey-form/survey-header/survey-header';
import SurveyModal from '../../components/survey-form/survey-modal/survey-modal';
import { addSurvey } from '../../survey.services';
import retrieveNextObservationWithoutSurvey from '../../utils/retrieve-next-observation-id-for-survey';
import storeObservationsPassed from '../../utils/store-observations-passed';

const AddSurvey = () => {
	const { driveLogId, observationId } = useParams();
	const { trackEvent } = useMatomoTracker();
	const { user = {} } = useContext(AuthContext);

	const trackSurveyAction = (action = '') => {
		const { attachment = '' } = user;
		if (attachment !== 'PSG') {
			trackEvent({ category: MATOMO_CATEGORIES.SURVEY, action: MATOMO_ACTIONS[action], value: 1 });
		}
	};

	const [showModal, setShowModal] = useState(false);
	const [observation, setObservation] = useState({});
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const retrieveObservationInfo = useCallback(() => {
		getObservationById(driveLogId, observationId)
			.then((res) => {
				setObservation(res?.data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [driveLogId, observationId]);

	const getBackLink = () => {
		return `/drive-log/${driveLogId}`;
	};

	const displayCancelFormModal = () => {
		setShowModal(true);
	};

	const handleRedirection = async () => {
		const nextObservationId = await retrieveNextObservationWithoutSurvey(driveLogId);
		if (nextObservationId) {
			return navigate(`/drive-log/${driveLogId}/observation/${nextObservationId}/add-survey`);
		}
		return navigate(`/drive-log/${driveLogId}/sign`);
	};

	const handleNextSurvey = async () => {
		await storeObservationsPassed(driveLogId, observationId);
		await handleRedirection();
		trackSurveyAction('PASS_SURVEY');
	};

	const handleSubmit = async (config = {}) => {
		await addSurvey(config);
		await handleRedirection();
		trackSurveyAction('VALIDATE_SURVEY');
	};

	useEffect(retrieveObservationInfo, [retrieveObservationInfo]);

	return (
		<PopupFullPage title={t('survey:survey.title')} backLink={getBackLink()}>
			<Loader isLoading={loading}>
				<SurveyHeader
					observationType={observation.observation_type}
					observationContent={observation.content}
					missionCode={observation.mission_code}
				/>
				<SurveyForm
					submitForm={handleSubmit}
					handleCancelForm={displayCancelFormModal}
					action="pass"
				/>
				<SurveyModal
					showModal={showModal}
					toggleModal={setShowModal}
					confirmCallback={() => handleNextSurvey()}
					modalText={t('survey:survey-modal.content')}
					modalSubText={t('survey:survey-modal.sub-content')}
				/>
			</Loader>
		</PopupFullPage>
	);
};

export default AddSurvey;
