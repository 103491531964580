import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './hours-cell.scss';
import { DateUtils, Icon } from '../../../../../core';

const HoursCell = (props) => {
	const { driveLog, header = false } = props;
	const { t } = useTranslation('dl');

	const getHourStart = (dl) => {
		return DateUtils.formatHourMinutes(dl.realized_date_start, ':');
	};

	const getHourEnd = (dl) => {
		return DateUtils.formatHourMinutes(dl.realized_date_end, ':');
	};

	const buildHeader = () => (
		<div className="hours-cell__hours">
			<div className="hours-cell__hours__header">{t('dl-table.headers.hour-start')}</div>
			<Icon name="arrow-right" className="arrow-right" />
			<div className="hours-cell__hours__header">{t('dl-table.headers.hour-end')}</div>
		</div>
	);

	const buildCell = () => (
		<div className="hours-cell__hours hours-cell__cell">
			<div className="hours-cell__hour-start">{getHourStart(driveLog)}</div>
			<Icon name="arrow-right" className="arrow-right" />
			<div className="hours-cell__hour-end">{getHourEnd(driveLog)}</div>
		</div>
	);

	return header ? buildHeader() : buildCell();
};

HoursCell.propTypes = {
	driveLog: PropTypes.object,
	header: PropTypes.bool,
};

export default HoursCell;
