import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeAttachmentBanner } from '../../../../attachment';
import { DateUtils, Loader, SearchBar, SupervisorPage } from '../../../../core';
import useDrivelogList from '../../../../core/custom-hooks/use-drive-log-list';
import { AuthContext } from '../../../../user/auth/context/auth-context';
import DriveLogTable from '../../../components/drive-log-table/drive-log-table';
import SearchDriveLogsByDates from '../../../components/search-drive-logs-by-dates/search-drive-logs-by-dates';
import './validated-drive-logs-page.scss';

const ValidatedDriveLogsPage = () => {
	const { t } = useTranslation('dl');
	const { getAttachment, user = {} } = useContext(AuthContext);
	const [currentAttachment, setCurrentAttachment] = useState('');
	const attachmentName = user?.attachment;

	const storageName = 'supervisorDlValidated';
	const columnsToSearch = ['name', 'service', 'id', 'mission', 'train-number'];

	const {
		isLoading,
		driveLogs,
		handleResetDriveLogs,
		handleSearchDriveLogs,
		handleDateFilter,
		filter,
	} = useDrivelogList({
		status: ['validated', 'archived'],
		attachmentCode: currentAttachment?.code,
		storageName,
		columnsToSearch: columnsToSearch[0],
		numberResults: 40,
		dateSortOrder: 'desc',
		mandatoryForRequest: ['attachmentCode'],
	});

	const formattedDate = DateUtils.formatFullDate(filter?.searchDate, 'dd/MM/yyyy');

	useEffect(() => {
		getAttachment()
			.then((attachmentInfo) => {
				setCurrentAttachment(attachmentInfo);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getAttachment]);

	return (
		<SupervisorPage selectedLink={1} className="validated-drive-log-page">
			{currentAttachment && (
				<ChangeAttachmentBanner
					attachment={currentAttachment}
					userAttachment={attachmentName}
					className="validated-drive-log-page__attachment-banner"
					originPage="/supervisor/validated-drive-logs"
					text="change-attachment-banner.drive-log-from"
				/>
			)}
			<div className="validated-drive-log-page__filters">
				<SearchBar
					handleSearch={handleSearchDriveLogs}
					handleReset={handleResetDriveLogs}
					columnsToSearch={columnsToSearch}
					filter={filter}
				/>
				<SearchDriveLogsByDates handleDateFilter={handleDateFilter} date={filter?.searchDate} />
			</div>
			<h1 className="validated-drive-log-page__title">
				{formattedDate
					? `${t('validated-drive-log-page.title')} du ${formattedDate}`
					: t('validated-drive-log-page.title')}
			</h1>
			<Loader isLoading={!!isLoading}>
				<DriveLogTable driveLogs={driveLogs} displayDriveLogStatus={true} ampValidatedView={true} />
			</Loader>
		</SupervisorPage>
	);
};

export default ValidatedDriveLogsPage;
