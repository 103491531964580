import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import localForage from '../../../../../config/local-forage';
import { Icon, Loader } from '../../../../core';
import EmptySplash from '../../../../core/assets/images/empty-splash.svg';
import { getReferentialList } from '../../../../core/core.services';
import { getMissionsFromRef } from '../../../../mission/mission.services';
import sortedStationsList from '../../../utils/sorted-stations-list/sorted-stations-list.json';
import DlScheduleSheetCell from './dl-schedule-sheet-cell/dl-schedule-sheet-cell';
import './dl-shedule-sheet.scss';

/**
 * Renders a <DlScheduleSheet /> component
 * @param {object} props
 * @param {object} props.currentDriveLog - the current drivelog of the user
 * @param {object} props.temporaryWorkSheet - the temporary worksheet selected bu the user if any
 */
const DlScheduleSheet = (props) => {
	const { currentDriveLog = {}, temporaryWorkSheet = null } = props;

	const { t } = useTranslation();

	// states declaration
	const [stationsList, setStationsList] = useState([]);
	const [scheduleSheetInfo, setScheduleSheetInfo] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const userLine = currentDriveLog?.line_number;

	// filter stations list by user's line
	const stationsListByLine = stationsList.filter((station) => station.line === userLine);

	// add to each station of the list the key "position" and its value based on the referential "sortedStationsList"
	const formattedStationsListByLine = stationsListByLine.map((station) => {
		station.position = sortedStationsList[userLine][station.trigram];
		return station;
	});

	// sort stations by it's position in the line
	const orderedStationsListByLine = formattedStationsListByLine.sort(
		(a, b) => a.position - b.position,
	);

	// delete duplicate station Châtelet
	const chateletIndex = orderedStationsListByLine.findIndex((el) => el.trigram === 'CHL');
	const uniqueOrderdStationListByLine =
		chateletIndex >= 0
			? orderedStationsListByLine.filter((el, index) => index !== chateletIndex)
			: orderedStationsListByLine;

	// display the header of the table with the mission's code
	// with 2 empty cells above station's trigram and station's name columns
	const displayScheduleTableHeader = () => {
		return (
			<tr>
				<th className="schedule-sheet-table-header-cell" colSpan="2">
					{}
				</th>
				{scheduleSheetInfo &&
					Object.entries(scheduleSheetInfo).map(([code, value]) => {
						const bigramList = Object.keys(value);
						const { direction, composition } = value[bigramList[0]];
						const isMissionW = code[0] === 'W';
						const isColumnReverse = direction === 1;
						const trainType = composition === 2 ? 'UM' : 'US';

						const cellClassNames = classNames('schedule-sheet-table-header-cell', {
							'schedule-sheet-table-header-cell--red': isMissionW,
						});
						const cellContentClassNames = classNames('schedule-sheet-table-header-cell-content', {
							'schedule-sheet-table-header-cell-content--reverse': isColumnReverse,
						});
						const iconClassNames = classNames('schedule-sheet-table-header-cell-icon', {
							'schedule-sheet-table-header-cell-icon--reverse': isColumnReverse,
						});
						const trainTypeClassNames = classNames('schedule-sheet-table-header-train-type', {
							'schedule-sheet-table-header-train-type--red': isMissionW,
						});

						return (
							<th className={cellClassNames} key={code}>
								<div className={cellContentClassNames}>
									<Icon name="arrow-right" className={iconClassNames} />
									<span className={'schedule-sheet-table-header-cell-code'}>{code}</span>
									<span className={trainTypeClassNames}>{trainType}</span>
								</div>
							</th>
						);
					})}
			</tr>
		);
	};

	useEffect(() => {
		getReferentialList('station').then((res) => {
			setStationsList(res.data);
		});
	}, []);

	useEffect(() => {
		const getScheduleSheetInfo = async () => {
			let params;
			if (!temporaryWorkSheet || Object.keys(temporaryWorkSheet).length === 0) {
				const savedCache = await localForage.getItem('mission-details');
				if (savedCache && savedCache.data) {
					setScheduleSheetInfo(savedCache.data);
					setIsLoading(false);
					return;
				}
				const {
					attachment_bigram: attachmentBigram,
					line_number: line,
					realized_service_number: service,
				} = currentDriveLog || {};
				params = { attachmentBigram, line, service };
			} else {
				const { attachment_bigram: attachmentBigram, line, service } = temporaryWorkSheet || {};
				params = { attachmentBigram, line, service };
			}
			getMissionsFromRef('missions-schedule-info', '/mission/schedule-info', { params })
				.then((response) => {
					setScheduleSheetInfo(response?.data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		};
		getScheduleSheetInfo();
	}, [currentDriveLog, temporaryWorkSheet]);

	if (scheduleSheetInfo && Object.keys(scheduleSheetInfo).length !== 0) {
		return (
			<Loader isLoading={isLoading}>
				<div className="schedule-sheet">
					<table className="schedule-sheet-table">
						<thead className="schedule-sheet-table-header">{displayScheduleTableHeader()}</thead>
						<tbody>
							{uniqueOrderdStationListByLine.map((station, index) => {
								return (
									<tr key={index}>
										<td className="schedule-sheet-table-content-cell">{station.trigram}</td>
										<td className="schedule-sheet-table-content-cell">{station.name}</td>
										<DlScheduleSheetCell
											scheduleSheetInfo={scheduleSheetInfo}
											bigram={station.bigram}
										/>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</Loader>
		);
	} else {
		return (
			<Loader isLoading={isLoading}>
				<div className={'dl-schedule-document-page__content__no-result'}>
					<h1 className="no-result__title">{t('dl:dl-documents.no-assigned-schedule-document')}</h1>
					<div
						className="no-result__searching-mouse-img"
						style={{ backgroundImage: `url(${EmptySplash})` }}
					/>
				</div>
			</Loader>
		);
	}
};

DlScheduleSheet.propTypes = {
	currentDriveLog: PropTypes.object,
	temporaryWorkSheet: PropTypes.object,
};

export default DlScheduleSheet;
