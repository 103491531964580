import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Loader, Overlay, PageWrapper, ResponsiveText } from '../../..';
import GenericHeader from '../../headers/generic-header/generic-header';
import './export-page-wrapper.scss';

const ExportPageWrapper = (props) => {
	const { children, className = '', loading = false } = props;

	const { t } = useTranslation();

	return (
		<PageWrapper className="export-page">
			{loading && (
				<Overlay className="export-page__loader">
					<Loader isLoading={loading} />
				</Overlay>
			)}
			<GenericHeader />
			<main className={`export-page__content ${className}`}>
				<ResponsiveText
					text={t('exp:page.title')}
					mobileText={t('exp:page.title')}
					className="export-page__content__title"
				/>
				{children}
			</main>
		</PageWrapper>
	);
};

ExportPageWrapper.propTypes = {
	children: PropTypes.node,
};

export default ExportPageWrapper;
