import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, ResponsiveText } from '../../../../../../core';
import ToggleSwitch from '../../../../../../core/components/toggle-switch/toggle-switch';
import './delay-line.scss';

const DelayLine = (props) => {
	const {
		valueType = 'number',
		editable = false,
		handleEditClick = () => {},
		description = '',
		details = '',
		shortDescription = '',
		value = 0,
		editedValue,
		loading = true,
	} = props;
	const { t } = useTranslation();

	// Use the short description on mobile if provided else the regular text is used
	const shortDesc = shortDescription ? shortDescription : description;

	const shortDetails = details ? `${details.substring(0, 21)}...` : '';

	const displayPreviousValue = () => {
		return (
			<>
				<span className="delay-line__previous-value">{editedValue}</span>
				<Icon name="arrow-right" className="delay-line__arrow-right" />
			</>
		);
	};

	const displayEditButton = () => {
		return (
			<div className="delay-line__link" onClick={handleEditClick}>
				{t('dl:delay-list-page.modify')}
			</div>
		);
	};

	// check also if value === 0 but has been edited
	const isValueZeroButEdited = editedValue === 0 && value !== editedValue;

	return (
		<li className="delay-line">
			<div className="delay-line__description">
				<ResponsiveText
					text={description}
					mobileText={shortDesc}
					className={'delay-line__description-title'}
				/>
				{details && (
					<ResponsiveText
						text={details}
						mobileText={shortDetails}
						className={'delay-line__description-details'}
					/>
				)}
			</div>
			<div className="delay-line__recap">
				{valueType === 'number' && (
					<>
						{editable && displayEditButton()}
						{(isValueZeroButEdited || editedValue) && displayPreviousValue()}
						<span className="delay-line__time">{!loading ? `${value}' ` : ' '}</span>
					</>
				)}
				{valueType === 'boolean' && (
					<>
						{!loading && (
							<span className={'delay-line__boolean_value'}>
								{value ? t('dl:delay-list-page:yes') : t('dl:delay-list-page:no')}
							</span>
						)}
						{!loading && editable && (
							<ToggleSwitch
								isChecked={value}
								className={'toggle-status'}
								sliderClassName={'toggle-status__slider'}
								handleOnChange={handleEditClick}
							/>
						)}
					</>
				)}
			</div>
		</li>
	);
};

DelayLine.propTypes = {
	valueType: PropTypes.string,
	editable: PropTypes.bool,
	getLink: PropTypes.func,
	description: PropTypes.string,
	details: PropTypes.string,
	shortDescription: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	editedValue: PropTypes.number,
	loading: PropTypes.bool,
	isDriveLogSigned: PropTypes.bool,
};

export default DelayLine;
