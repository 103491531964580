import React from 'react';
import { useTranslation } from 'react-i18next';

import { PopupFullPage } from '../../../core';
import { AttentionAlertForm } from '../../forms';

import './create-attention-alert-page.scss';

const CreateAttentionAlertPage = (props) => {
	const { t } = useTranslation('attention-alert');

	return (
		<PopupFullPage title={t('attention-alert.forms.titles.create')} backLink={'/attention-alerts'}>
			<AttentionAlertForm mode="create"/>
		</PopupFullPage>
	);
};

export default CreateAttentionAlertPage;
