import React from 'react';
import classnames from 'classnames';
import { addSeconds, format, startOfDay } from 'date-fns';
import './dl-schedule-sheet-cell.scss';

const DlScheduleSheetCell = (props) => {
	const { scheduleSheetInfo = {}, bigram } = props;

	const formatTimeFromSeconds = (seconds) => {
		if (seconds) {
			const helperDate = addSeconds(startOfDay(new Date()), seconds);
			return format(helperDate, 'HH:mm:ss');
		}
		return null;
	};

	return Object.entries(scheduleSheetInfo)?.map(([code, value]) => {
		const {
			is_served: isServed,
			isInDriverScope,
			isDriverDeparture,
			isDriverArrival,
			departure_time_seconds: departureTimeStation,
		} = value[bigram] || {};
		const isMissionW = code[0] === 'W';
		const scheduleCellClasses = classnames(
			'schedule-sheet-table-content-cell',
			{ 'schedule-cell-red': !isServed && departureTimeStation },
			{ 'schedule-cell-yellow': isDriverDeparture && isInDriverScope },
		);
		// if the station is served by another driver, display blue dot
		// else if the station is served or if it is the departure or the arrival of mission code starting by W, display departure time
		// else display empty cell
		if (!isInDriverScope && departureTimeStation && (isServed || isMissionW)) {
			return (
				<td key={code} className="schedule-sheet-table-content-cell">
					<div className="schedule-cell-blue-dot" />
				</td>
			);
		} else if (
			(isServed || (isMissionW && (isDriverDeparture || isDriverArrival))) &&
			departureTimeStation
		) {
			return (
				<td key={code} className={scheduleCellClasses}>
					{formatTimeFromSeconds(departureTimeStation)}
				</td>
			);
		} else {
			return <td key={code} className={scheduleCellClasses} />;
		}
	});
};

export default DlScheduleSheetCell;
