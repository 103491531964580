/**
 * @typedef {import("../utils/typedefs")} MissionCode
 */

/**
 * Decode mission information based on mission id format
 * @param {String} id mission id
 * @returns {MissionCode} mission code information arrival and departure station letter codes, and mission type
 */
const decodeMission = (id) => {
	// hlp missions are always of the format W[letter]W[letter][digit][digit]
	const hlpMission = /W[A-Z]W[A-Z]\d{2}/i;

	// passenger missions are always of the format [letter][letter]ZZ[digit][digit]
	const passengerMission = /[A-Z]{2}Z{2}\d{2}/i;

	if (hlpMission.test(id)) {
		return {
			departureLetter: id.slice(3, 4),
			arrivalLetter: id.slice(1, 2),
			missionType: 'hlp',
		};
	}

	if (passengerMission.test(id)) {
		return {
			departureLetter: id.slice(1, 2),
			arrivalLetter: id.slice(0, 1),
			missionType: 'voy',
		};
	}

	return undefined;
};

export default decodeMission;
