import React from 'react';
import { useTranslation } from 'react-i18next';
import displayStationNameFromObservation from '../../../../core/utils/display-station-name-from-observation/display-station-name-from-observation';
import PropTypes from 'prop-types';

/**
 * Survey header which display the observation type, the observation content and the mission code of the related survey
 * @param props
 * @param {Array<Object>} props.observationType
 * @param {Array<Object>} props.observationContent
 * @param {Array<Object>} props.missionCode
 * @constructor
 */
const SurveyHeader = (props) => {
	const { observationType, observationContent, missionCode } = props;
	const { t } = useTranslation();

	return (
		<div className="survey-form__header">
			<div className="survey-form__header__title-container">
				<h2 className="survey-form__header__title">
					{t(`observation:observation-titles.${observationType}`)}
				</h2>
				<span className="survey-form__header__details">
					<span>{`${displayStationNameFromObservation(observationContent)}/${observationContent?.pattern.value}`}</span>
				</span>
			</div>
			<div className="survey-form__mission_code">{missionCode}</div>
		</div>
	);
};

SurveyHeader.propTypes = {
	observation_type: PropTypes.string,
	observation_content: PropTypes.string,
	mission_code: PropTypes.string,
};

export default SurveyHeader;
