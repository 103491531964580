import React from 'react';
import Icon from '../../../../../../core/components/icon-svg/icon';
import './finalize-message.scss';
import { useTranslation } from 'react-i18next';

/**
 * Render the finalize error message if the drive-log is not completed
 * @component
 */
const FinalizeMessage = ({ message }) => {
	const { t } = useTranslation();

	return (
		<div className="finalize-message">
			<Icon aria-label="finalize-message" className="finalize-message__icon" name="alert" />
			<span className="finalize-message__text">{t(`dl:dl-detail-page.${message}`)}</span>
		</div>
	);
};

export default FinalizeMessage;
