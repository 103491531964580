import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, DateUtils, Icon, ResponsiveText } from '../../../../../../../../core';
import { AuthContext } from '../../../../../../../../user/auth/context/auth-context';
import MissionObservations from '../../../mission-observations/mission-observations';
import ConfirmTrainCompositionModal from '../../../modals/confirm-train-composition-modal/confirm-train-composition-modal';
import UpdateTrainCompositionModal from '../../../modals/update-train-composition-modal/update-train-composition-modal';
import DlDeleteMission from '../../components/dl-delete-mission/dl-delete-mission';

/**
 * Component for display the mission list
 *
 * @component
 * @param {Object} props
 * @param {array} props.missionList - the missions array
 * @param {string} props.className - the class name
 * @param {array} props.observations - the observations array
 * @param {function} props.retrieveObservationsList - the function which retrieve all observations
 * @param {object} props.missionData - the mission data object
 * @param {boolean} props.readOnly - the boolean that define if the observation is on readonly mode
 * @param {boolean} props.uncompletedDl - the boolean that define if the driveLog is uncompleted
 */
const DlMissionRow = (props) => {
	const {
		observations,
		retrieveObservationsList,
		missionData,
		readOnly = false,
		redirectUrl = '',
		uncompletedDl,
		setUncompletedDl,
		emptyTrainComposition,
		setEmptyTrainComposition,
		dailyDriveLog,
	} = props;
	const { t } = useTranslation();
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const { formatHourMinutes } = DateUtils;
	const { id: driveLogId } = useParams();
	const {
		id,
		code,
		train_number,
		station_start,
		station_end,
		hour_start,
		hour_end,
		train_composition,
	} = missionData;
	const linkToObsList = `${redirectUrl}/${driveLogId}/mission/${id}/add-observation/${code}`;
	const startMissionLink = `${redirectUrl}/${driveLogId}/mission/${id}/station-start/edit?start=launch-mission`;
	const linkToMissionDetails = `${redirectUrl}/${driveLogId}/mission/${id}/details`;
	const hasDigiPlanAccess = user.digiplan_access;

	const [showUpdateTrainCompoModal, setShowUpdateTrainCompoModal] = useState(false);
	const [showConfirmTrainCompoModal, setShowConfirmTrainCompoModal] = useState(false);
	const [animationCount, setAnimationCount] = useState(null);

	const handleShowUpdateModal = () => {
		setShowUpdateTrainCompoModal(true);
	};

	const getAddTrainNumberLink = (missionId, train) => {
		if (!readOnly) {
			return (
				<Link
					className={`dl-mission-list__mission__train-number__add ${
						uncompletedDl && !train ? 'uncompleted' : ''
					} ${train ? '' : 'bordered'}`}
					to={`${redirectUrl}/${driveLogId}/mission/${missionId}/train-number`}
				>
					{train ? (
						<span className="train-number__added">{Number(train) !== -1 ? train : 'NC'}</span>
					) : (
						<>
							<Icon aria-label="add-train" className="train-number__add__icon" name="add-plus" />
							<span>{t('dl:dl-detail-page.add')}</span>
						</>
					)}
				</Link>
			);
		} else {
			return (
				<span className="dl-add-mission--readonly">{Number(train) !== -1 ? train : 'NC'}</span>
			);
		}
	};

	const getTrainComposition = (missionId, composition) => {
		if (composition) {
			const compositionLetter = composition === 1 ? 'US' : 'UM';
			return (
				<Button
					className="dl-mission-list__mission__composition__value"
					light
					onClick={() => handleShowUpdateModal()}
				>
					{compositionLetter}
				</Button>
			);
		} else {
			const missingInfo = emptyTrainComposition || uncompletedDl;
			return (
				<>
					{!readOnly && (
						<div
							onClick={() => handleShowUpdateModal()}
							className={`dl-mission-list__mission__composition__add ${
								missingInfo && !composition ? 'uncompleted' : ''
							} ${composition ? '' : 'bordered'}`}
						>
							<Icon
								aria-label="add-composition"
								className="composition__add__icon"
								name="add-plus"
							/>
							<span>{t('dl:dl-detail-page.add')}</span>
						</div>
					)}
					{readOnly && <span>{'NC'}</span>}
				</>
			);
		}
	};

	const stationStartRedirection = `${redirectUrl}/${driveLogId}/mission/${id}/station-start/edit`;
	const stationEndRedirection = `${redirectUrl}/${driveLogId}/mission/${id}/station-end/edit`;

	const missionReadOnly = (moment, station, hour) => (
		<div className={`dl-mission-list__mission__${moment}`}>
			<div className={`dl-mission-list__mission__${moment}__station`}>{station}</div>
			<div className={`dl-mission-list__mission__${moment}__modify`}>
				<div className={`dl-mission-list__mission__${moment}__modify__text`}>
					{formatHourMinutes(hour, ':')}
				</div>
			</div>
		</div>
	);

	const hasHourError = (mission) => {
		const missingHours = !mission.hour_start || !mission.hour_end;
		const errorHours = mission.hour_start > mission.hour_end;
		return missingHours || errorHours;
	};

	const missionEditable = (moment, station, hour, redirection) => (
		<div
			className={`dl-mission-list__mission__${moment} ${
				uncompletedDl && hasHourError(missionData) ? 'uncompleted' : ''
			}`}
		>
			<Link to={redirection}>
				<div className={`dl-mission-list__mission__${moment}__station`}>{station}</div>
				<div className={`dl-mission-list__mission__${moment}__modify`}>
					<div className={`dl-mission-list__mission__${moment}__modify__text`}>
						{formatHourMinutes(hour, ':')}
					</div>
					<div className={`dl-mission-list__mission__${moment}__modify__link`}>
						<ResponsiveText
							text={t('dl:dl-detail-page.edit')}
							mobileText={<Icon name="pen" className="modify__link__icon" />}
						/>
					</div>
				</div>
			</Link>
		</div>
	);

	const startMission = () => {
		if (!train_composition) {
			setEmptyTrainComposition(true);
			setUncompletedDl(false);
		} else if (train_composition === 1) {
			setShowConfirmTrainCompoModal(true);
			setAnimationCount(0);
		} else {
			navigate(startMissionLink);
		}
	};

	const handleRedirect = () => {
		if (dailyDriveLog && Object.keys(dailyDriveLog).length > 0) {
			const params = new URLSearchParams({
				attachmentBigram: dailyDriveLog.attachment_bigram,
				serviceNumber: dailyDriveLog.realized_service_number,
				line: dailyDriveLog.line_number,
				code,
				station_start,
				station_end,
			}).toString();
			return navigate(`${linkToMissionDetails}?${params}`);
		}
	};

	return (
		<li key={code} className="dl-mission-list__mission">
			<div className="dl-mission-list__mission__code" onClick={handleRedirect}>
				{code}
			</div>
			<div className="dl-mission-list__mission__composition">
				{getTrainComposition(id, train_composition)}
			</div>
			<div className="dl-mission-list__mission__train-number">
				<ResponsiveText
					text=""
					mobileText={t('dl:dl-detail-page.motor_number-short')}
					className="dl-mission-list__mission__train-number__title"
				/>
				{getAddTrainNumberLink(id, train_number)}
			</div>
			{!readOnly
				? missionEditable('start', station_start, hour_start, stationStartRedirection)
				: missionReadOnly('start', station_start, hour_start)}
			{!readOnly
				? missionEditable('end', station_end, hour_end, stationEndRedirection)
				: missionReadOnly('end', station_end, hour_end)}
			{!readOnly && (
				<div className="dl-mission-list__mission__actions">
					<Link to={linkToObsList} className="actions__link">
						<ResponsiveText
							text={
								<Button className="actions__add-obs" light>
									{t('dl:dl-detail-page.add-observation')}
								</Button>
							}
							mobileText={t('dl:dl-detail-page.add-observation')}
						/>
					</Link>
					{code && (
						<Button
							className="actions__launch-mission"
							light
							onClick={startMission}
							disabled={!hasDigiPlanAccess}
						>
							<Icon name="play" className="actions__launch-mission__icon" />
						</Button>
					)}
					<DlDeleteMission
						hasObservations={observations.filter((obs) => obs.mission_id === id).length > 0}
						driveLogId={driveLogId}
						missionCode={code}
						missionId={id}
					/>
				</div>
			)}

			<MissionObservations
				retrieveObservationsList={retrieveObservationsList}
				observations={observations}
				missionId={id}
				readOnly={readOnly}
				redirectUrl={redirectUrl}
				uncompletedDl={uncompletedDl}
			/>

			{showUpdateTrainCompoModal && (
				<UpdateTrainCompositionModal
					trainComposition={train_composition}
					setShowModal={setShowUpdateTrainCompoModal}
					setEmptyTrainComposition={setEmptyTrainComposition}
					driveLogId={driveLogId}
					missionId={missionData.id}
				/>
			)}

			{showConfirmTrainCompoModal && (
				<ConfirmTrainCompositionModal
					setShowModal={setShowConfirmTrainCompoModal}
					setShowUpdateTrainCompoModal={setShowUpdateTrainCompoModal}
					redirectUrl={startMissionLink}
					animationCount={animationCount}
					setAnimationCount={setAnimationCount}
				/>
			)}
		</li>
	);
};

DlMissionRow.propTypes = {
	missionList: PropTypes.array,
	className: PropTypes.string,
	observations: PropTypes.array,
	retrieveObservationsList: PropTypes.func,
	readOnly: PropTypes.bool,
	uncompletedDl: PropTypes.bool,
	setUncompletedDl: PropTypes.func,
	emptyTrainComposition: PropTypes.bool,
	setEmptyTrainComposition: PropTypes.func,
};

export default DlMissionRow;
