import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ObservationDetails from '../observation-details/observation-details';
import './observation-details-list.scss';

/**
 * Observations list with full observation content
 * @param props
 * @param {Array<Object>} props.observationsList
 * @param {Array<Object>} props.observationsList
 * @constructor
 */
const ObservationDetailsList = (props) => {
	const { observationsList, missionList } = props;
	const { t } = useTranslation();

	const getMissionCode = (id) => {
		const missionFound = missionList.find((mission) => mission.id === id);
		return missionFound?.code;
	};

	const getObservationDetails = (observation) => {
		const { id, observation_type, mission_id } = observation;
		return (
			<div key={id} className="observation-list__item">
				<div className="observation-list__item__heading">
					<div className="observation-list__item__heading__title">
						{t(`observation:observation-titles.${observation_type}`)}
					</div>
					{mission_id && (
						<div className="observation-list__item__heading__mission">
							{t('observation:observation-list.heading.mission')}
							<span>{getMissionCode(mission_id)}</span>
						</div>
					)}
				</div>
				<ObservationDetails className="observation-list__item__content" observation={observation} />
			</div>
		);
	};

	return (
		<div className="observation-details-list">{observationsList.map(getObservationDetails)}</div>
	);
};

ObservationDetailsList.propTypes = {
	observationsList: PropTypes.array,
	missionList: PropTypes.array,
};

export default ObservationDetailsList;
