import axios from 'axios';

const noTimeoutUrls = ['/status'];

/**
 * Axios timeout from config is a response timeout not connection timeout.
 * If there's no internet connection or server not reachable, axios timeout will not work
 * Interceptor uses CancellationToken to manually cancel axios request
 * @param config
 * @return {{cancelToken: CancelToken}|*}
 */
const timeoutInterceptor = (config) => {
	if (noTimeoutUrls.includes(config.url) || config.timeout === undefined || config.timeout === 0) {
		return { ...config, timeout: 0 };
	}

	const source = axios.CancelToken.source();

	setTimeout(() => {
		source.cancel(
			`Cancelled request. Took longer than ${config.timeout}ms to get complete response.`,
		);
	}, config.timeout);

	// If caller configures cancelToken, preserve cancelToken behaviour.
	if (config.cancelToken) {
		config.cancelToken.promise.then((cancel) => {
			source.cancel(cancel.message);
		});
	}

	return { ...config, cancelToken: source.token };
};

export default timeoutInterceptor;
