import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TwoChoicesButtons } from '../../../../core';
import ObservationCarSelector from './observation-car-selector/observation-car-selector';
import ObservationSearch from './observation-search/observation-search';
import ObservationDelay from './observation-delay/observation-delay';
import ObservationText from './observation-text/observation-text';
import ObservationComment from './observation-comment/observation-comment';
import ObservationTime from './observation-time/observation-time';
import ObservationWithFileAttachment from './observation-with-file-attachment/observation-with-file-attachment';
import ObservationReminderText from './observation-reminder-text/observation-reminder-text';
import ObservationCheckbox from './observation-checkbox/observation-checkbox';
import {
	carSelector,
	stationSearch,
	trainStorageSearch,
	text,
	comment,
	yesNo,
	dateTime,
	radio,
	carSelectorWithInput,
	delay,
	fileAttachment,
	checkbox,
	reminderText,
} from './observation-fields-list';
import './observation-fields.scss';

const ObservationFields = (props) => {
	const { t } = useTranslation();
	const {
		line,
		fieldSchema,
		fieldName,
		state,
		handleChange,
		mandatory,
		validator,
		repeat,
		setTimeError,
		driveLog,
		observationFiles,
		setObservationFiles,
		observationId,
		maxLength,
	} = props;
	const labelBase = repeat
		? t(`observation:observation-fields.${fieldName}-repeat`)
		: t(`observation:observation-fields.${fieldName}`);
	const optionalLabel = mandatory ? '' : t('observation:observation-fields.optional');
	const label = `${labelBase} ${optionalLabel}`;

	const className = `observation__field observation__field--${fieldName}`;

	const getListType = (name) => {
		if (name === 'car') {
			return line;
		}
		if (name === 'equipment') {
			if (line === 'A') {
				return 'equipmentA';
			}
			if (line === 'B') {
				return 'equipmentB';
			}
			return 'equipmentNight';
		}
		return name;
	};

	if (carSelector.includes(fieldName)) {
		return (
			<ObservationCarSelector
				className={className}
				label={label}
				value={state}
				handleChange={handleChange}
				listType={getListType(fieldName)}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (carSelectorWithInput.includes(fieldName)) {
		return (
			<ObservationCarSelector
				className={className}
				label={label}
				value={state}
				handleChange={handleChange}
				listType={getListType(fieldName)}
				hasInput
				inputPlaceholder={t(`observation:observation-fields.${fieldName}-placeholder`)}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (stationSearch.includes(fieldName) || trainStorageSearch.includes(fieldName)) {
		const referential =
			(stationSearch.includes(fieldName) && 'station') ||
			(trainStorageSearch.includes(fieldName) && 'train-storage');
		return (
			<ObservationSearch
				className={className}
				searchLabel={label}
				referential={referential}
				selectedStation={state}
				setSelectedStation={handleChange}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (text.includes(fieldName)) {
		return (
			<ObservationText
				className={className}
				label={label}
				value={state}
				onChange={handleChange}
				placeholder={t(`observation:observation-fields.${fieldName}-placeholder`)}
				validator={validator}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (comment.includes(fieldName)) {
		return (
			<ObservationComment
				className={className}
				value={state}
				title={label}
				handleChange={handleChange}
				placeholder={t(`observation:observation-fields.${fieldName}-placeholder`)}
				validator={validator}
				maxLength={maxLength}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (fileAttachment.includes(fieldName)) {
		return (
			<ObservationWithFileAttachment
				driveLogId={driveLog?.id}
				observationFiles={observationFiles}
				setObservationFiles={setObservationFiles}
				observationId={observationId}
			/>
		);
	}

	if (yesNo.includes(fieldName)) {
		return (
			<TwoChoicesButtons
				className={className}
				name={fieldName}
				value={state}
				onChange={handleChange}
				label={label}
				firstOptionLabel={t('observation:observation-fields.radio-button.yes')}
				firstOptionValue="yes"
				secondOptionLabel={t('observation:observation-fields.radio-button.no')}
				secondOptionValue="no"
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (radio.includes(fieldName)) {
		const firstOption =
			(fieldName === 'garageAction' && 'garage') ||
			(fieldName === 'pcc' && 'sncf') ||
			(fieldName === 'authorizationType' && 'resume-running');
		const secondOption =
			(fieldName === 'garageAction' && 'deGarage') ||
			(fieldName === 'pcc' && 'creg') ||
			(fieldName === 'authorizationType' && 'crossing-signal');
		return (
			<TwoChoicesButtons
				className={className}
				name={fieldName}
				value={state}
				onChange={handleChange}
				label={label}
				firstOptionLabel={t(`observation:observation-fields.radio-button.${firstOption}`)}
				firstOptionValue={firstOption}
				secondOptionLabel={t(`observation:observation-fields.radio-button.${secondOption}`)}
				secondOptionValue={secondOption}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (dateTime.includes(fieldName)) {
		return (
			<ObservationTime
				className="observation__field"
				label={label}
				dateTime={state}
				handleChange={handleChange}
				setTimeError={setTimeError}
				fieldSchema={fieldSchema}
				startServiceDateTime={driveLog?.realized_date_start}
			/>
		);
	}

	if (fieldName === 'duration') {
		return (
			<ObservationTime
				className="observation__field"
				label={label}
				dateTime={state}
				handleChange={handleChange}
				setTimeError={setTimeError}
				fieldSchema={fieldSchema}
				startServiceDateTime={driveLog?.realized_date_start}
				displaySeconds={true}
			/>
		);
	}

	if (delay.includes(fieldName)) {
		return (
			<ObservationDelay
				className={className}
				value={state}
				title={label}
				handleChange={handleChange}
				validator={validator}
				fieldSchema={fieldSchema}
			/>
		);
	}

	if (checkbox.includes(fieldName)) {
		return <ObservationCheckbox className={className} value={state} handleChange={handleChange} />;
	}

	if (reminderText.includes(fieldName)) {
		return <ObservationReminderText className={className} text={label} />;
	}

	return null;
};

ObservationFields.propTypes = {
	line: PropTypes.string,
	fieldName: PropTypes.string.isRequired,
	state: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object]),
	handleChange: PropTypes.func,
	mandatory: PropTypes.bool,
	validator: PropTypes.func,
	repeat: PropTypes.bool,
	setTimeError: PropTypes.func,
	driveLog: PropTypes.object.isRequired,
	observationFiles: PropTypes.array,
	setObservationFiles: PropTypes.func,
	observationId: PropTypes.string,
};

export default ObservationFields;
