import PropTypes from 'prop-types';
import React from 'react';

import './loader.scss';

const Loader = ({ isLoading, children, spinnerClass = 'dbl-spinner' }) => {
	if (!isLoading && children) {
		return <>{children}</>;
	}

	return <div className={spinnerClass} />;
};

Loader.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	children: PropTypes.node,
};

export default Loader;
