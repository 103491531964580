import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Comment, Loader } from '../../../core';
import { useTranslation } from 'react-i18next';
import {
	deleteFileAttachmentByIdInDl,
	getFilesAttachmentByDl,
	insertDriveLogFileAttachment,
} from '../../file-attachment.services';
import FileAttachmentCard from './file-attachment-card/file-attachment-card';
import checkAddFile from '../../utils/file-attachment/file-attachment';
import classNames from 'classnames';
import './comment-file-attachment.scss';

const commentMaxLength = 144;

/**
 * Renders component to write a comment and add file(s) to a signed dl
 * and to view the comment and files added or not
 */
const CommentWithFileAttachment = (props) => {
	const { formData = {}, setFormData = () => {}, currentDriveLog, readOnly = false, title } = props;
	const { id: driveLogId, comment: currentDlComment } = currentDriveLog;
	const { comment } = formData;
	const { t } = useTranslation();
	const [disableAddFileBtn, setDisableAddFileBtn] = useState(false);
	const [filesData, setFilesData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const displayComponent = currentDlComment || filesData.length > 0 || !readOnly;

	const getFilesByDrivelog = () => {
		if (driveLogId) {
			setIsLoading(true);
			getFilesAttachmentByDl(driveLogId)
				.then((files) => {
					const { data } = files;
					setFilesData(data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					clearLoadingTimer();
				});
		}
	};

	const addFileAttachment = (newFile) => {
		setIsLoading(true);
		setErrorMessage('');
		insertDriveLogFileAttachment(newFile, driveLogId)
			.then((res) => {
				getFilesByDrivelog();
			})
			.catch((err) => {
				console.error(err);
				setErrorMessage(t('dl:signature-page.file-attachment.error-message.insert'));
				clearLoadingTimer();
			});
	};

	/**
	 * Verification of the size and the file type before to add it
	 * @param newFile
	 */
	const handleAddFileAttachment = (newFile) => {
		const { type: fileType, size: fileSize } = newFile;
		try {
			checkAddFile(fileType, fileSize);

			setIsLoading(true);
			addFileAttachment(newFile);
		} catch (error) {
			console.error(error);
			setErrorMessage(t(`${error}`));
		}
	};

	/**
	 * Verification of the user permissions before to delete the file
	 * @param fileInfos
	 */
	const handleDeleteFile = (fileInfos) => {
		const { id: fileAttachmentId } = fileInfos;
		setIsLoading(true);
		setErrorMessage('');
		deleteFileAttachmentByIdInDl(driveLogId, fileAttachmentId)
			.then(() => {
				getFilesByDrivelog();
			})
			.catch((err) => {
				console.error(err);
				setErrorMessage(t('dl:signature-page.file-attachment.error-message.delete'));
				clearLoadingTimer();
			});
	};

	const handleChangeFile = (e) => {
		if (e.target.files[0] !== undefined) {
			try {
				handleAddFileAttachment(e.target.files[0]);
			} catch (error) {
				console.error(error);
			} finally {
				e.target.value = null;
			}
		}
	};

	const handleChangeComment = (fieldToUpdate) => (value) => {
		const newFields = { ...formData };
		newFields[fieldToUpdate] = value;
		setFormData(newFields);
	};

	const isNumberOfFileOver = () => {
		const disableButton = filesData.length >= 4;
		setDisableAddFileBtn(disableButton);
	};

	const displayFileCard = (file) => {
		const { file_name: fileName } = file;

		return (
			<React.Fragment key={`${fileName}`}>
				<FileAttachmentCard
					handleDeleteFile={handleDeleteFile}
					file={file}
					readOnly={readOnly}
					driveLogId={driveLogId}
				/>
			</React.Fragment>
		);
	};

	const clearLoadingTimer = () => {
		const timer = setTimeout(() => setIsLoading(false), 1000);
		return () => {
			clearTimeout(timer);
		};
	};

	const displayReadOnlyComponent = () => {
		return (
			<>
				{currentDlComment && (
					<div className="comment-file-attachment__content__comment__read">
						<h3 className="comment-file-attachment__content__comment__read-title">
							{title || t('dl:dl-details.comment.title')}
						</h3>
						<div className="comment-file-attachment__content__comment__read-text">
							{currentDriveLog.comment}
						</div>
					</div>
				)}
				<Loader isLoading={!!isLoading}>
					<div
						className={
							readOnly
								? classNames(
										'comment-file-attachment__content__card',
										'comment-file-attachment__content__card--read-only',
									)
								: 'comment-file-attachment__content__card'
						}
					>
						{renderFileCard}
					</div>
				</Loader>
				{errorMessage.length > 0 && (
					<label className="comment-file-attachment__content__error-message">{errorMessage}</label>
				)}
			</>
		);
	};

	const displayEditableComponent = () => {
		return (
			<>
				<Comment
					className="comment-file-attachment__content__comment"
					value={comment}
					handleChange={handleChangeComment('comment')}
					title={t('dl:dl-details.comment.title')}
					placeholder={t('dl:dl-details.comment.placeholder')}
					maxLength={commentMaxLength}
				/>
				<label className="comment-file-attachment__content__instruction">
					{t('dl:signature-page.file-attachment.instruction')}
				</label>
				<Loader isLoading={!!isLoading}>
					<div
						className={
							readOnly
								? classNames(
										'comment-file-attachment__content__card',
										'comment-file-attachment__content__card--read-only',
									)
								: 'comment-file-attachment__content__card'
						}
					>
						{renderFileCard}
					</div>
				</Loader>
				{errorMessage.length > 0 && (
					<label className="comment-file-attachment__content__error-message">{errorMessage}</label>
				)}
				<input
					type="file"
					id="input-file"
					accept="application/pdf"
					onChange={handleChangeFile}
					name="file"
					className="comment-file-attachment__content__input"
				/>
				<div className={'comment-file-attachment__content__button-group'}>
					{!disableAddFileBtn && (
						<label className="comment-file-attachment__content__button" htmlFor="input-file">
							{t('dl:signature-page.file-attachment.add-file-attachment')}
						</label>
					)}
					{disableAddFileBtn && (
						<Button className="button" disabled>
							{t('dl:signature-page.file-attachment.add-file-attachment')}
						</Button>
					)}
				</div>
			</>
		);
	};

	const renderFileCard = filesData.map((res) => displayFileCard(res));
	useEffect(isNumberOfFileOver, [filesData]);
	useEffect(getFilesByDrivelog, [driveLogId]);

	return (
		<>
			{displayComponent && (
				<div className="comment-file-attachment">
					{readOnly && displayReadOnlyComponent()}
					{!readOnly && displayEditableComponent()}
				</div>
			)}
		</>
	);
};

CommentWithFileAttachment.propTypes = {
	setFormData: PropTypes.func,
	formData: PropTypes.object,
	currentDriveLog: PropTypes.object,
	readOnly: PropTypes.bool,
};

export default CommentWithFileAttachment;
