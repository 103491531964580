import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader, PopupFullPage } from '../../../core';
import { AuthContext } from '../../../user/auth/context/auth-context';
import { useAttachmentList } from '../../attachment.services';
import './modify-attachment-global-scope-page.scss';

const ModifyAttachmentGlobalScopePage = () => {
	const { getAttachment, setTemporaryAttachment } = useContext(AuthContext);

	const { state: stateLocation } = useLocation();
	const navigate = useNavigate();

	const [currentAttachment, setCurrentAttachment] = useState();

	const { attachments, loading } = useAttachmentList(true);
	const { t } = useTranslation('at');

	const validateAttachment = () => {
		setTemporaryAttachment(currentAttachment)
			.then(() => {
				navigate(stateLocation.originPage);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	useEffect(() => {
		getAttachment()
			.then((attachmentInfo) => {
				setCurrentAttachment(attachmentInfo);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getAttachment]);

	const generateAttachment = (attachment) => {
		const attachmentSelected = `${
			currentAttachment?.code === attachment.code
				? 'modify-attachment-page__attachment-card--selected'
				: ''
		}`;
		return (
			<li
				key={attachment.code}
				className={`modify-attachment-page__attachment-card ${attachmentSelected}`}
				onClick={() =>
					setCurrentAttachment({
						code: attachment.code,
						name: attachment.name,
					})
				}
			>
				<div className="modify-attachment-page__attachment-card__content">{attachment.name}</div>
				<div className="modify-attachment-page__attachment-card__select">
					{attachmentSelected
						? t('modify-attachment-page.deselect')
						: t('modify-attachment-page.choose')}
				</div>
			</li>
		);
	};

	const generateAttachmentList = (attachmentList) => {
		if (Array.isArray(attachmentList) && attachmentList.length > 0) {
			return (
				<ul className="modify-attachment-page__attachment-list">
					{attachmentList.map(generateAttachment)}
				</ul>
			);
		}
		return <ul className="modify-attachment-page__attachment-list" />;
	};

	return (
		<PopupFullPage
			overrideRoles={['admin', 'amp', 'consultant', 'localAdmin']}
			title={t('modify-attachment-page.title')}
			backLink={stateLocation.originPage}
			className="modify-attachment-page"
		>
			<div className="modify-attachment-page__content">
				<h2 className="modify-attachment-page__title">{t('modify-attachment-page.sub-title')}</h2>
				<Loader isLoading={!!loading}>{generateAttachmentList(attachments)}</Loader>
				<button className="button" onClick={validateAttachment}>
					{t('modify-attachment-page.validate')}
				</button>
			</div>
		</PopupFullPage>
	);
};

export default ModifyAttachmentGlobalScopePage;
