import React from 'react';
import { useTranslation } from 'react-i18next';
import './attention-alert-list-header.scss';

const AttentionAlertListHeader = (props) => {
	const { className = '' } = props;

	const { t } = useTranslation('attention-alert');

	return (
		<li className={`${className} attention-alert-list-header`}>
			<div className="attention-alert-list-header__cell">{t('attention-alert.list.headers.broadcast-zone')}</div>
			<div className="attention-alert-list-header__cell">{t('attention-alert.list.headers.type')}</div>
			<div className="attention-alert-list-header__cell">{t('attention-alert.list.headers.actions')}</div>
		</li>
	);
};

export default AttentionAlertListHeader;
