import {
	regexValidator,
	validateDelay,
} from '../../components/observation-form/observation-validators';
import { RegexUtils } from '../../../core';
import { setDateTimeWithService } from '../../observation.services';

const maneuverSignalAuthorization = [
	{
		fieldName: 'authorizationType',
		mandatory: true,
	},
	{
		fieldName: 'post',
		mandatory: true,
	},
	{
		fieldName: 'missionCode',
		readOnly: true,
		visibleOnSide: ['mission'],
	},
	{
		fieldName: 'addresseeName',
		mandatory: true,
		visibleOnSide: ['drive-log'],
	},
	{
		fieldName: 'signal',
		mandatory: true,
		validator: regexValidator(RegexUtils.alphaNumericSpecialCharactersWithTwenty),
	},
	{
		fieldName: 'currentDate',
		readOnly: true,
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
	{
		fieldName: 'orderNumber',
		mandatory: true,
		validator: regexValidator(RegexUtils.alphaNumericSpecialCharactersWithTwenty),
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
	},
];

export default maneuverSignalAuthorization;
