import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../core';
import './feedbacks-export-btn.js.scss';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const FeedbacksExportBtn = ({ filteredFeedbacks }) => {
	const { t } = useTranslation('users-feedback');
	const formattedFeedbacks = filteredFeedbacks.map((feedback) => ({
		...feedback,
		created_at: format(new Date(feedback.created_at), 'dd/MM/yyyy HH:mm'),
	}));

	const formattedFileName = `feedback_utilisateurs_${format(new Date(), 'dd/MM/yyyy')}.csv`;
	const headers = [
		{ label: 'Catégorie', key: 'feedback_type' },
		{ label: 'Date&Heure', key: 'created_at' },
		{ label: 'Commentaire', key: 'comment' },
		{ label: 'Id utilisateur', key: 'created_by' },
	];

	return (
		<CSVLink data={formattedFeedbacks} headers={headers} filename={formattedFileName} separator=";">
			<button className="feedbacks-export-btn">
				<Icon className="fa-upload-icon" name="upload" />
				{t('feedback-page.filter.export-button')}
			</button>
		</CSVLink>
	);
};

FeedbacksExportBtn.propTypes = {
	filteredFeedbacks: PropTypes.object.isRequired,
};

export default FeedbacksExportBtn;
