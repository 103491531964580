import { http } from '../../config';

/**
 * add a disabled line sheet
 * @param {Object} lineSheet
 * @returns {Promise} response
 */
const addDisabledLineSheet = (lineSheet) => {
	return http.post('/disabled-line-sheet', { line_sheet: lineSheet });
};

/**
 * get the list of disabled line sheets
 * @returns {Promise} response
 */
const getDisabledLineSheets = async () => {
	return http.get('/disabled-line-sheet');
};

/**
 * get today disabled line sheet
 *  * @param {Object} lineToRetrieve
 * @returns {Promise} response
 */
const getTodayDisabledLineSheet = async (lineToRetrieve) => {
	const params = {
		line: lineToRetrieve,
	};

	return http.get('/disabled-line-sheet/today', { params });
};

export { addDisabledLineSheet, getDisabledLineSheets, getTodayDisabledLineSheet };
