/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, InputField, Loader } from '../../../core';
import { ErrorMessage, MultiCheckboxField, MultiSearchField } from '../../../core/components';
import { getReferentialList } from '../../../core/core.services';
import { AuthContext } from '../../../user/auth/context/auth-context';
import { Card } from '../../components';
import { createNudge, getNudgeById, updateNudge } from '../../services';
import { ModesNudge } from '../../utils/constants';
import nudgeFormConfig from './nudge-form.json';
import './nudge-form.scss';

const {
	broadcastStation: broadcastStationConfig,
	tracks: tracksConfig,
	title: titleConfig,
} = nudgeFormConfig;

const translationEntity = 'nudges';
const NudgeForm = (props) => {
	const { t } = useTranslation(translationEntity);
	const navigate = useNavigate();
	const { id: nudgeId } = useParams();
	const { mode = ModesNudge.Create } = props;
	const editModeNudge = mode === ModesNudge.Update;
	const readOnlyNudge = mode === ModesNudge.Read;
	const republishModeNudge = mode === ModesNudge.Republish;

	const { user = {} } = useContext(AuthContext);
	const [stations, setStations] = useState([]);
	// The state of the form components
	const [values, setValues] = useState({ broadcast_station: null, tracks: [1, 2], title: null });
	// An invalid property has a true value
	const [validators, setValidators] = useState({});
	const [isFormValid, setIsFormValid] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isReady, setIsReady] = useState(false);

	const nudgeByIdService = (lineStations) => {
		getNudgeById(nudgeId).then((res) => {
			const { broadcast_station_trigram, tracks, title } = res.data;

			setValues((prev) => ({
				...prev,
				tracks,
				title,
			}));

			if (broadcast_station_trigram != null) {
				setValues((prev) => ({
					...prev,
					broadcast_station: getStationByTrigram(lineStations, broadcast_station_trigram),
				}));
			}
		});
	};
	const fillForm = () => {
		getLineStations().then((lineStations) => {
			setStations(lineStations);

			if (editModeNudge || readOnlyNudge || republishModeNudge) {
				nudgeByIdService(lineStations);
				setIsReady(true);
			} else {
				setIsReady(true);
			}
		});
	};

	const getLineStations = () => {
		return getReferentialList('station').then((res) => {
			return res.data.filter((station) => {
				const { line: stationLine } = station;
				return stationLine === user.line;
			});
		});
	};

	const getStationByTrigram = (data, trigram) => {
		return data.find((station) => {
			const { trigram: stationTrigram } = station;
			return stationTrigram === trigram;
		});
	};

	const handleValidation = () => {
		setValidators((prev) => ({
			...prev,
			broadcast_station: values.broadcast_station == null,
			tracks: values.tracks === null || values.tracks?.length === 0,
			title: values.title === null || values.title === '',
		}));
	};

	const handleChange = (fieldName, value) => {
		setValues((prev) => ({ ...prev, [fieldName]: value }));
	};

	// Get data from form state & handle it before submit
	const handleSubmit = () => {
		const formData = {
			tracks: values.tracks,
			title: values.title,
		};

		formData.broadcast_station = values.broadcast_station.name;
		formData.broadcast_station_trigram = values.broadcast_station.trigram;

		setIsLoading(true);

		const params = (editModeNudge || republishModeNudge) && { action: mode };

		const handleData =
			editModeNudge || republishModeNudge
				? () => updateNudge(nudgeId, formData, params)
				: () => createNudge(formData);

		handleData()
			.then((res) => {
				setIsLoading(false);
				navigate('/nudges');
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		fillForm();
	}, []);

	useEffect(() => {
		handleValidation();
	}, [values]);

	// Check if there are any invalid fields
	useEffect(() => {
		setIsFormValid(Object.keys(validators).filter((key) => validators[key]).length === 0);
	}, [validators]);

	return (
		<div className="nudge__form__container">
			{!readOnlyNudge && (
				<Loader isLoading={!isReady}>
					<Card
						index={1}
						title={t('nudges.forms.cards.broadcast-zone')}
						translationEntity={translationEntity}
					>
						<MultiSearchField
							className="nudge__form"
							translation={translationEntity}
							placeholders="nudges.forms.fields.placeholders"
							groupName="broadcast_station"
							fieldNames={[broadcastStationConfig.fieldName]}
							options={stations}
							values={{
								broadcast_station: values.broadcast_station,
							}}
							handleChange={handleChange}
							disabled={readOnlyNudge}
						/>

						{validators.broadcast_station && (
							<ErrorMessage message={t('nudges.forms.errors.broadcast_station.empty')} />
						)}

						<MultiCheckboxField
							className="nudge__form"
							translation={translationEntity}
							fieldName={tracksConfig.fieldName}
							options={tracksConfig.options}
							values={values.tracks}
							handleChange={handleChange}
							disabled={readOnlyNudge}
						/>
						{validators.tracks && <ErrorMessage message={t('nudges.forms.errors.tracks.empty')} />}
					</Card>
					<Card
						index={2}
						title={t(`nudges.forms.cards.content.${readOnlyNudge ? 'read' : 'write'}`)}
					>
						<div className="nudge__form__field">
							<div className="nudge__form__field__label no-margin-top">
								{t('nudges.forms.fields.labels.title')}
							</div>

							<div className="nudge__form__field__container">
								<InputField
									className="nudge__form__field__input"
									value={values.title}
									onChange={(value) => handleChange(titleConfig.fieldName, value)}
									maxLength={titleConfig.maxLength}
									placeholder={t(`nudges.forms.fields.placeholders.${titleConfig.fieldName}`)}
									disabled={readOnlyNudge}
								/>
							</div>
						</div>

						{validators.title && <ErrorMessage message={t('nudges.forms.errors.title.empty')} />}
					</Card>

					<div className="nudge__form__submit-block">
						<Button
							className="nudge__form__button--cancel button button--outline"
							onClick={() => navigate(-1)}
						>
							{t(`nudges.forms.buttons.${readOnlyNudge ? 'back' : 'cancel'}`)}
						</Button>
						<Button
							className="nudge__form__button--submit button"
							type="submit"
							disabled={!isFormValid || isLoading}
							onClick={() => handleSubmit()}
						>
							{editModeNudge || republishModeNudge
								? t(`nudges.forms.buttons.${mode}`)
								: t('nudges.forms.buttons.submit')}
						</Button>
					</div>
				</Loader>
			)}
		</div>
	);
};

export default NudgeForm;
