import {
	getDataFromLocalStorage,
	getExpiredDataFromApi,
} from '../../config/offline/dispatch-get-request';

/**
 * get list of all referential from api
 * @param {string} referential - referential name
 * @returns {Promise} response
 */
const getReferentialList = (referential) => {
	return getDataFromLocalStorage(referential);
};

/**
 * get list of all station codes
 * @return {Promise<Object>} response
 */
const getStationCodes = () => {
	return getExpiredDataFromApi('station-codes', '/referential/station-code', 8);
};

export { getReferentialList, getStationCodes };
