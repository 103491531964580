import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './observation-card.scss';

/**
 * Renders an <ObservationCard /> component
 * @param {object} props
 * @param {object} props.type - the observation type object
 * @param {string} props.driveLogId - the drive log id
 * @param {string} props.missionId - the mission id
 **/
const ObservationCard = (props) => {
	const { type, driveLogId, missionId, redirectUrl = '' } = props;

	const { t } = useTranslation();
	const typeHasLabel = type?.label !== 'none';
	const missionURL = missionId ? `/mission/${missionId}` : '';

	const getUrl = () => {
		return `${redirectUrl}/${driveLogId}${missionURL}/observation/${type?.url}`;
	};

	return (
		<li>
			<Link to={getUrl()} className="observation-card">
				{typeHasLabel && (
					<div className="observation-card__label">
						{t(`observation:observation-list.types.subtypes.${type.label}`)}
					</div>
				)}
				<div className="observation-card__type">
					{t(`observation:observation-list.types.${type.name}`)}
				</div>
			</Link>
		</li>
	);
};

ObservationCard.propTypes = {
	type: PropTypes.object,
	driveLogId: PropTypes.string,
	missionId: PropTypes.string,
};

export default ObservationCard;
