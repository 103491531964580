import React from 'react';
import classNames from 'classnames';

const CustomDatePickerInput = React.forwardRef((props, ref) => {
	const { date, value, onClick, large, isError = false } = props;

	const customDatePickerInputClassNames = classNames('datePicker', {
		'datePicker--filled': date !== null,
		'datePicker--large': large,
		'datePicker--error': isError,
	});

	return (
		<button ref={ref} className={customDatePickerInputClassNames} onClick={onClick}>
			{value}
		</button>
	);
});

export default CustomDatePickerInput;
