import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../../../../../../core';
import retrieveNextObservationWithoutSurvey from '../../../../../../../survey/utils/retrieve-next-observation-id-for-survey';

/*
	Modal to display when the 'Finalize Drive log' button is clicked
	The user is either routed to the add delay page if the answer is yes
	or to the sign drive log page if the answer is no
 */
const FinalizeDlModal = ({ showModal, hideModal, redirectUrl = '' }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { id } = useParams();

	const modalText = t('dl:dl-detail-page.modals.add-delay');

	const confirmCallback = () => {
		navigate(`${redirectUrl}/${id}/add-delay`);
	};
	const cancelCallback = async () => {
		const observationId = await retrieveNextObservationWithoutSurvey(id);
		if (observationId) {
			navigate(`${redirectUrl}/${id}/observation/${observationId}/add-survey`);
		} else {
			navigate(`${redirectUrl}/${id}/sign`);
		}
	};

	// handle click to simply close modal
	const closeCallback = () => hideModal();

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={confirmCallback}
				cancelCallback={cancelCallback}
				closeCallback={closeCallback}
			/>
		)
	);
};

FinalizeDlModal.propTypes = {
	showModal: PropTypes.bool,
	hideModal: PropTypes.func,
};

export default FinalizeDlModal;
