import React from 'react';
import PropTypes from 'prop-types';
import DriveLogTable from '../../../../../components/drive-log-table/drive-log-table';
import { useTranslation } from 'react-i18next';
import { checkArrayEmpty, LoadMore } from '../../../../../../core';

import './complex-drive-logs.scss';

const ComplexDriveLogs = (props) => {
	const { driveLogs, loadMore, className, totalResultCount } = props;
	const { t } = useTranslation('dl');

	const renderDriveLogsList = () => {
		const complexDriveLogsEmpty = checkArrayEmpty(driveLogs);

		if (!driveLogs || complexDriveLogsEmpty) {
			return null;
		}

		return (
			<>
				<h2 className="complex-dls__title">
					{t('supervisor-dl-list-page.number-dl-to-be-validated', { count: driveLogs?.length })}
				</h2>
				<DriveLogTable driveLogs={driveLogs} />
				<div className="complex-dls__controls">
					{driveLogs.length < totalResultCount && <LoadMore onClick={() => loadMore(20)} />}
				</div>
			</>
		);
	};

	return <section className={`${className} complex-dls`}>{renderDriveLogsList()}</section>;
};

ComplexDriveLogs.props = {
	driveLogs: PropTypes.array,
	refreshDriveLogs: PropTypes.func,
	className: PropTypes.string,
	totalResultCount: PropTypes.number,
};

export default ComplexDriveLogs;
