import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '../../../../../../core';
import './document-card.scss';

/**
 * Renders an <DocumentCard /> component
 * @param {object} props
 * @param {string} props.mainText - the type of document : schedule-documents or line-sheet
 * @param {string} props.secondaryText - the drivelog's attachment station
 * @param {string} props.url - the url displaying the document image according to the type of document selected
 */
const DocumentCard = (props) => {
	const { mainText, secondaryText, url, disabled = false } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleRedirect = () => {
		if (disabled) {
			return;
		}
		return navigate(url);
	};

	const cardContainerClassNames = classNames('dl-document-card__container', {
		'dl-document-card__container--disabled': disabled,
	});
	const documentCardButtonClassNames = classNames('dl-document-card__button', {
		'dl-document-card__button--disabled': disabled,
	});

	return (
		<div className={cardContainerClassNames} onClick={handleRedirect}>
			<div className="dl-document-card__content">
				<div className="dl-document-card__icon-wrapper">
					<Icon name="document" className="dl-document-card__icon" />
				</div>

				<div className="dl-document-card__text">
					<span className="dl-document-card__main">{mainText}</span>
					<span className="dl-document-card__secondary">{secondaryText}</span>
				</div>
			</div>
			<Button className={documentCardButtonClassNames}>{t('dl:dl-documents.consult')} </Button>
		</div>
	);
};

DocumentCard.propTypes = {
	mainText: PropTypes.string.isRequired,
	secondaryText: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

export default DocumentCard;
