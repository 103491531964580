import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../../../core/components/icon-svg/icon';
import { Button } from '../../../core';
import Modal from '../../../core/components/modal/modal';
import { addDisabledLineSheet } from '../../../drive-log/disable-sheets-line.service';

import './disable-line-sheet-modal.scss';

const DisableLineSheetsModal = (props) => {
	const { setShowDisableModal, lineSheet, refreshDisabledSheetsList, showDisabledModal } = props;
	const { line } = lineSheet || {};
	const { t } = useTranslation('disable-line-sheets');
	const [countdown, setCountdown] = useState(3);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const handleCloseModal = () => {
		setShowDisableModal(false);
		setCountdown(3);
		setIsButtonDisabled(true);
	};

	const handleDisableLineSheets = () => {
		setCountdown(3);
		setIsButtonDisabled(true);
		setShowDisableModal(false);
		addDisabledLineSheet({ line })
			.then(() => {
				refreshDisabledSheetsList();
			})
			.catch((err) => console.error(err));
	};

	useEffect(() => {
		let countdownInterval;

		if (showDisabledModal) {
			countdownInterval = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown === 1) {
						clearInterval(countdownInterval);
						setIsButtonDisabled(false);
						return 0;
					} else {
						return prevCountdown - 1;
					}
				});
			}, 1000);
		}

		return () => {
			clearInterval(countdownInterval);
		};
	}, [showDisabledModal]);

	return (
		<Modal type={'custom'}>
			<div className={'disable-line-sheets-page__modal'}>
				<div onClick={handleCloseModal}>
					<Icon name="close" className="disable-line-sheets-page__modal__close" />
				</div>
			</div>
			<div className={'disable-line-sheets-page__modal__content'}>
				<span className={'disable-line-sheets-page__modal__content__title'}>
					{t('sheets-state.modal.title')}
				</span>
				<span className={'disable-line-sheets-page__modal__content__text'}>
					{t('sheets-state.modal.text')}
				</span>
			</div>
			<div className={'disable-line-sheets-page__modal__button-group'}>
				<Button
					className={'disable-line-sheets-page__modal__button'}
					onClick={handleCloseModal}
					outline
				>
					{t('sheets-state.modal.no')}
				</Button>
				<Button
					className={'disable-line-sheets-page__modal__button'}
					onClick={handleDisableLineSheets}
					disabled={isButtonDisabled}
				>
					{t('sheets-state.modal.yes')}
				</Button>
			</div>
			<div className={'signature-page-sign-modal__instruction'}>
				{countdown > 0 && (
					<p>
						<span>{t('sheets-state.modal.instruction-1')}</span>
						<span className={'signature-page-sign-modal__instruction-counter'}>{countdown}</span>
						<span>{t('sheets-state.modal.instruction-2')}</span>
					</p>
				)}
				{countdown === 0 && <p>{t('sheets-state.modal.instruction-ok')}</p>}
			</div>
		</Modal>
	);
};

export default DisableLineSheetsModal;
