import { http } from '../../config';

/**
 * post to the database
 * @param {Object} userInputs - user satisfaction form data
 @param {String} userInputs.choice - choice between given selection
 * @param {String} userInputs.comment - user comment
 @param {String} [userInputs.matricule] - optional matricule (ratpAccountId)
 * @returns {Promise} response
 */
const submitFeedbackToBackend = async (userInputs) => {
	await http.post('/feedback/create', userInputs);
};
export { submitFeedbackToBackend };
