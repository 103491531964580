import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Modal } from '../../../core';
import { updateNudge } from '../../services/index';
import { extractNumbers } from '../../utils';
import './nudges-list-row.scss';

/**
 * NudgesListRow component display a row for each nudge
 * @param props
 * @param {object} props.nudge
 * @param {string} props.className
 * @return {JSX.Element}
 * @constructor
 */
const NudgesListRow = (props) => {
	const { nudge, className = '', enclosed = false, refreshNudges } = props;

	const { t } = useTranslation('nudges');
	const [showEncloseNoticeModal, setShowEncloseNoticeModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { id, broadcast_station, title, created_at, created_by, last_updated_at, last_updated_by } =
		nudge;

	const handleEnclose = (e) => {
		e.stopPropagation();
		setShowEncloseNoticeModal(!showEncloseNoticeModal);
	};

	const updateNoticeStatus = (action) => {
		const params = { action: action };
		setIsLoading(true);
		updateNudge(id, nudge, params)
			.then((res) => {
				setShowEncloseNoticeModal(false);
				refreshNudges();
			})
			.catch((error) => console.error(error))
			.finally(() => {
				setIsLoading(false);
			});
	};

	const renderCreatedBy = (createdByNudge) => {
		return <div className="nudges-list-row__cell">{extractNumbers(createdByNudge)}</div>;
	};

	const renderAction = () => {
		if (enclosed) {
			return (
				<Link to={`/nudges/${id}/republish`}>
					<Button className="button--outline nudges-list-row__cell-action">
						{t('nudges.forms.buttons.republish')}
					</Button>
				</Link>
			);
		}
		return (
			<>
				<Link to={`/nudges/${id}/edit`}>
					<Button className="button--outline nudges-list-row__cell-action">
						{t('nudges.forms.buttons.update')}
					</Button>
				</Link>

				<Button
					className="button--outline nudges-list-row__cell-action"
					onClick={(e) => handleEnclose(e)}
				>
					{t('nudges.forms.buttons.enclose')}
				</Button>
			</>
		);
	};

	const renderCreatedDate = () => {
		if (enclosed && last_updated_by) {
			return renderCreatedBy(last_updated_by);
		}
		const creationDate = last_updated_at ? new Date(last_updated_at) : new Date(created_at);

		return (
			<div className="nudges-list-row__cell">
				{creationDate.toLocaleString([], { dateStyle: 'short' })}
			</div>
		);
	};

	return (
		<>
			<div
				className={`${className} nudges-list-row  ${enclosed ? 'nudges-list-row--enclosed' : ''}`}
			>
				<div className="nudges-list-row__cell">
					<span className="nudges-list-row__cell-station">{broadcast_station}</span>
				</div>
				<div className="nudges-list-row__cell">{title}</div>
				{renderCreatedBy(created_by)}
				<div className="nudges-list-row__cell">{renderCreatedDate()}</div>
				<div className="nudges-list-row__cell">{renderAction()}</div>
			</div>

			{showEncloseNoticeModal && (
				<Modal
					type="confirm"
					title={t('nudges.modals.enclose.title')}
					text="nudges:nudges.modals.enclose.content"
					cancelText={t('nudges.modals.enclose.cancel')}
					confirmText={t('nudges.modals.enclose.validate')}
					confirmCallback={() => updateNoticeStatus('enclosed')}
					cancelCallback={() => setShowEncloseNoticeModal(false)}
					closeCallback={() => setShowEncloseNoticeModal(false)}
					isLoading={isLoading}
					iconName="warning-triangle"
				/>
			)}
		</>
	);
};

export default NudgesListRow;
