/**
 * validate comment
 * @param comment
 * @return {boolean}
 */
const validateComment = (comment) => {
	return comment?.length > 0 && comment?.length < 240;
};

/**
 * validate dropdown choice
 * @param choice
 * @returns {boolean}
 */
const validateDropdownChoice = (choice) => {
	const feedbackChoices = ['suggestion', 'technicalProblem', 'appreciation'];
	return feedbackChoices.includes(choice);
};

export { validateComment, validateDropdownChoice };
