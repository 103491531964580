import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.scss';

/**
 * Renders an <Button /> component
 * @param {object} props
 * @param {string} props.type - the button type
 * @param {string} props.className - the className of the button
 * @param {boolean} props.outline - define if the button has outline or not
 * @param {boolean} props.light - define if the button has outline or not
 * @param {boolean} props.link - define if the button is a link or not
 * @param {boolean} props.disabled - define if the button is disabled or not
 * @param {string} props.children - the content (children) of the button
 */
const Button = (props) => {
	const {
		type = 'button',
		className = '',
		outline,
		light,
		link,
		children,
		disabled = false,
		handleClick = () => {},
		...rest
	} = props;

	const buttonClassNames = classNames('button', className, {
		'button--outline': outline,
		'button--light': light,
		'button--link': link,
	});

	return (
		<button
			onClick={handleClick}
			disabled={disabled}
			className={buttonClassNames}
			type={type}
			{...rest}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	outline: PropTypes.bool,
	light: PropTypes.bool,
	link: PropTypes.bool,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	handleClick: PropTypes.func,
};

export default Button;
