import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ResponsiveText } from '../../../../../../core';
import PropTypes from 'prop-types';

const AddObservationWithoutMission = (props) => {
	const { driveLogId, redirectUrl = '' } = props;

	const { t } = useTranslation();

	return (
		<button className="add-observation-without-mission-button button button--outline">
			<Link
				className="add-observation-without-mission"
				to={`${redirectUrl}/${driveLogId}/add-observation`}
			>
				<ResponsiveText
					text={t('dl:dl-detail-page.add-observation-without-mission')}
					mobileText={t('dl:dl-detail-page.add-observation-without-mission-short')}
				/>
			</Link>
		</button>
	);
};

AddObservationWithoutMission.propTypes = {
	driveLogId: PropTypes.string,
};

export default AddObservationWithoutMission;
