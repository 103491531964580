import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupFullPage } from '../../../core';
import { SpecialNoticeForm } from '../../forms';
import { Modes } from '../../utils/constants';
import './update-special-notice-page.scss';

const UpdateSpecialNoticePage = (props) => {
	const { t } = useTranslation('special-notice');
	const { params } = props;
	const { action } = params;
	const backLink = action === 'republish' ? '/special-notices/enclosed' : '/special-notices';
	const mode = action === 'republish' ? 'Republish' : 'Update';

	return (
		<PopupFullPage title={t(`special-notice.forms.titles.${action}`)} backLink={() => backLink()}>
			<SpecialNoticeForm mode={Modes[mode]} />
		</PopupFullPage>
	);
};

export default UpdateSpecialNoticePage;
