import http from '../axios';
import localForage from '../local-forage';
import checkNetwork from './check-network';
import { storeDeletePending } from './synchronize-storage';

/**
 * put data to API and remove from local storage
 * @param {string} url - url to call
 * @return {Promise<Object>}
 */
const deleteDataFromApi = async (url) => await http.delete(url);

/**
 * Dispatch put request to either local storage or to API
 * @param {string} id - item tech id
 * @param {string} itemKey - item to delete
 * @param {string} listKey - list to delete from
 * @param {string} url
 * @return {Promise<Object|void>}
 */
const dispatchDeleteRequest = async (key, id, url) => {
	try {
		const storedData = await localForage.getItem(key);
		if (!storedData || !storedData.data) {
			throw new Error('No data found in localForage');
		}
		const updatedData = storedData.data.filter((item) => item.id !== id);
		await localForage.setItem(key, { ...storedData, data: updatedData });
	} catch (err) {
		console.error('Error updating localForage:', err);
	}

	const online = await checkNetwork();

	if (online) return await deleteDataFromApi(url);
	else await storeDeletePending(url);
};

export { deleteDataFromApi };
export default dispatchDeleteRequest;
