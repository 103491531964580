import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders FileAttachment image component
 */
const FileAttachmentImage = (props) => {
	const { files } = props;

	const renderFileImage = (file) => {
		const { file_image_url: fileImageUrl } = file;

		return (
			<div className="file-attachment-image">
				<div className={'file-attachment-image__content'}>
					<img src={fileImageUrl} alt="Observation file attached" />
				</div>
			</div>
		);
	};

	return <>{files.map((file) => renderFileImage(file))}</>;
};

FileAttachmentImage.propTypes = {
	files: PropTypes.object,
	handleDeleteFile: PropTypes.func,
	readOnly: PropTypes.bool,
	driveLogId: PropTypes.string,
};

export default FileAttachmentImage;
