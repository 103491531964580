import React from 'react';
import { useTranslation } from 'react-i18next';
import './nudges-distributed-list-header.scss';

const NudgesDistributedListHeader = (props) => {
	const { className = '', enclosed = false } = props;
	const { t } = useTranslation('nudges');

	const nudgeIfEnclosed = enclosed ? 'enclosed-by' : 'creation-date';
	return (
		<li
			className={`${className} nudge-list-header ${enclosed ? 'nudge-list-header--enclosed' : ''}`}
		>
			<div className="nudge-list-header__cell">{t('nudges.list.headers.broadcast-zone')}</div>
			<div className="nudge-list-header__cell">{t('nudges.list.headers.title')}</div>
			<div className="nudge-list-header__cell">{t('nudges.list.headers.author')}</div>
			<div className="nudge-list-header__cell">{t(`nudges.list.headers.${nudgeIfEnclosed}`)}</div>
			<div className="nudge-list-header__cell">{t('nudges.list.headers.actions')}</div>
		</li>
	);
};

export default NudgesDistributedListHeader;
