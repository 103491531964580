import {
	regexValidator,
	validateDelay,
	validateReason,
} from '../../components/observation-form/observation-validators';
import { RegexUtils } from '../../../core';
import { setDateTimeWithService } from '../../observation.services';

const maneuver = [
	{
		fieldName: 'trainStorage',
		mandatory: true,
	},
	{
		fieldName: 'lanes',
		mandatory: true,
		group: [
			{
				fieldName: 'laneStart',
				validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
				mandatory: true,
			},
			{
				fieldName: 'laneEnd',
				validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
				mandatory: true,
			},
		],
	},
	{
		fieldName: 'drivingCab',
		mandatory: true,
		validator: regexValidator(RegexUtils.fourDigitNumber),
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
	{
		fieldName: 'trafficLane',
		validator: regexValidator(RegexUtils.alphaNumericWithFifteenCharacters),
	},
	{
		fieldName: 'comment',
		validator: validateReason,
	},
	{
		fieldName: 'delay',
		validator: validateDelay,
	},
];

export default maneuver;
