import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../../../../core';

/*
	Modal to display when the 'Delete' button is clicked on a mission Item
 */
const DeleteMissionModal = ({
	showModal,
	setShowModal,
	missionToDelete,
	handleDeleteMission,
	handleCancelDeleteMission,
}) => {
	const { t } = useTranslation();

	const modalText = t('dl:dl-detail-page.delete-mission-popup', {
		missionCode: missionToDelete?.missionCode,
	});

	const closeCallback = () => {
		setShowModal(false);
	};

	return (
		showModal && (
			<Modal
				type="confirm"
				title={modalText}
				confirmCallback={handleDeleteMission}
				cancelCallback={handleCancelDeleteMission}
				confirmText={t('core:modal.yes')}
				cancelText={t('core:modal.no')}
				closeCallback={closeCallback}
			/>
		)
	);
};

DeleteMissionModal.propTypes = {
	showModal: PropTypes.bool,
};

export default DeleteMissionModal;
