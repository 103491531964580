import {
	arrayValidator,
	booleanValidator,
	dateValidator,
	requiredFieldValidator,
} from './export-form-utils';

/**
 * Validate ExportForm form data
 * @param {ExportFormData} form form data to validate
 * @param exportFormData
 * @returns {exportFormData}
 */
const validateExportForm = (exportFormData) => {
	const { attachments, from, hasDriveLog, hasObservation, hasSurvey, lines, observationTypes, to } =
		exportFormData;

	return {
		attachments: requiredFieldValidator(attachments) && arrayValidator(attachments),
		from: requiredFieldValidator(from) && dateValidator(from),
		hasDriveLog: requiredFieldValidator(hasDriveLog) && booleanValidator(hasDriveLog),
		hasObservation: requiredFieldValidator(hasDriveLog) && booleanValidator(hasObservation),
		hasSurvey: requiredFieldValidator(hasDriveLog) && booleanValidator(hasSurvey),
		lines:
			requiredFieldValidator(lines) && arrayValidator(lines) && lines?.length > 0 ? [] : ['falsy'],
		observationTypes:
			hasObservation && arrayValidator(observationTypes) && observationTypes?.length > 0
				? []
				: arrayValidator(observationTypes),
		to: requiredFieldValidator(to) && dateValidator(to),
	};
};

export default validateExportForm;
