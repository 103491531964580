import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '../../../core';
import './change-attachment-banner.scss';

const ChangeAttachmentBanner = (props) => {
	const {
		attachment = {},
		className = '',
		originPage,
		text,
		redirectUrl = '/modify-attachment',
		query = '',
		userAttachment = '',
	} = props;

	const { t } = useTranslation('at');

	return (
		<div className={`change-attachment-banner ${className}`}>
			{t(text)}
			{userAttachment !== 'PSG' ? (
				<Link
					to={redirectUrl}
					search={query}
					state={{ originPage }}
					className="change-attachment-banner__link"
				>
					<span className="change-attachment-banner__attachment-name">{attachment?.name}</span>
					<Icon className="change-attachment-banner__icon" name="pen" />
				</Link>
			) : (
				<span className="change-attachment-banner__attachment-name"> {userAttachment} </span>
			)}
		</div>
	);
};

ChangeAttachmentBanner.propTypes = {
	attachment: PropTypes.object.isRequired,
	className: PropTypes.string,
	originPage: PropTypes.string,
	text: PropTypes.string,
};

export default ChangeAttachmentBanner;
