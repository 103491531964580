import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Assets, DriverPage } from '../../../core';
import GroupedArrows from '../../../core/assets/images/grouped-arrows.svg';
import HomeAmp from '../../../core/assets/images/home-amp.svg';
import HomeDriver from '../../../core/assets/images/home-driver.svg';
import Leaves from '../../../core/assets/images/leaves.svg';
import { AuthContext } from '../../auth/context/auth-context';
import { HomeProfileCard } from '../../index';
import './user-home.scss';

/**
 * Main menu Dbul
 * @param props
 * @returns {*}
 * @constructor
 */
const UserHome = (props) => {
	const { overrideRoles } = props;
	const { user } = useContext(AuthContext);
	const { t } = useTranslation();
	const {
		role,
		attachment,
		firstName,
		lastName,
		special_notice_access: specialNoticeAccess,
		mobile_access: mobileAccess,
	} = user;
	const isAdminRole = role === 'admin' || role === 'localAdmin';
	const isPSGRole = role === 'amp' && attachment === 'PSG';

	const { availWidth: width, availHeight: height } = window.screen;
	const isOnSmartphone = width <= 480 || height <= 480;

	if (role === 'hr') return <Navigate to="/hr/drive-log-list" />;
	if (role === 'consultant') return <Navigate to="/consultant/drive-logs" />;
	if (role === 'driver') return <Navigate to="/daily-drive-log" />;

	const renderHomePageFunctionRole = () => {
		if (!mobileAccess && isOnSmartphone) return displayNoSmartphoneAccess();
		if (isAdminRole || role === 'amp') return displayHomeSelection();
	};

	const displayNoSmartphoneAccess = () => (
		<DriverPage
			className="user-home-page"
			hideNav={true}
			overrideRoles={overrideRoles}
			hideButton={true}
		>
			<div className="user-home-page__no-smartphone-access">
				<h1 className="user-home-page__no-smartphone-access__title">
					{t('user:home-page.no-smartphone-access')}
				</h1>
				<h3 className="user-home-page__no-smartphone-access__subtitle">
					{t('user:home-page.connect-on-tablet')}
				</h3>
			</div>
			<div className="user-home-page__no-smartphone-access__img">
				<img
					className="user-home-page__tablet-mouse-img"
					src={Assets.TabletMouse}
					alt="No access from smartphone"
				/>
			</div>
		</DriverPage>
	);

	const displayHomeSelection = () => (
		<DriverPage
			className="user-home-page"
			hideNav={true}
			overrideRoles={overrideRoles}
			hideButton={true}
		>
			<div className="user-home-page__wrapper">
				<h2 className="user-home-page__title">
					{t('user:home-page.welcoming', { firstName: firstName, lastName: lastName })}
				</h2>
				<nav className="user-home-page__links">
					<div className="user-home-page__links__container">
						<HomeProfileCard
							className={'card-supervisor'}
							link={'/supervisor/drive-log-list'}
							iconName={'home-amp'}
							description={t('user:home-page.amp-view.description')}
							title={t('user:home-page.amp-view.title')}
							imgLink={HomeAmp}
						/>
						<HomeProfileCard
							className={'card-driver'}
							link={'/daily-drive-log'}
							iconName={'home-driver'}
							description={t('user:home-page.driver-view.description')}
							title={t('user:home-page.driver-view.title')}
							imgLink={HomeDriver}
						/>
						<img className="user-home-page__img img--leaves" src={Leaves} alt="leaves" />
					</div>

					{isAdminRole && (
						<div className="user-home-page__links__container">
							<HomeProfileCard
								link={'/hr/drive-log-list'}
								title={t('user:home-page.hr-view.title')}
							/>
							<HomeProfileCard
								link={'/consultant/drive-logs'}
								title={t('user:home-page.reader-view.title')}
							/>
							<HomeProfileCard
								link={'/admin/users-list'}
								title={t('user:home-page.back-office-view.title')}
							/>
							<img
								className="user-home-page__img img--grouped-arrows"
								src={GroupedArrows}
								alt="grouped-arrows"
							/>
						</div>
					)}

					{isPSGRole && (
						<div className="user-home-page__links__container">
							<HomeProfileCard
								link={'/consultant/drive-logs'}
								title={t('user:home-page.reader-view.title')}
							/>
						</div>
					)}

					<div className="user-home-page__links__container">
						{(isAdminRole || (role === 'amp' && specialNoticeAccess)) && (
							<HomeProfileCard
								className={'card-notice'}
								link={'/special-notices'}
								description={t('user:home-page.notice.description')}
								title={t('user:home-page.notice.title')}
							/>
						)}
						{isAdminRole && (
							<HomeProfileCard
								className={'card-notice'}
								link={'/nudges'}
								description={t('user:home-page.nudges.description')}
								title={t('user:home-page.nudges.title')}
							/>
						)}
					</div>
				</nav>
			</div>
		</DriverPage>
	);

	return renderHomePageFunctionRole();
};

export default UserHome;
