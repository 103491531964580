import { getHours, getMinutes, getSeconds, isDate, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { TimePicker } from '../../../../../core';
import { setDateTimeWithService } from '../../../../observation.services';
import './observation-time.scss';

/***
 * Renders an <ObservationTime /> component
 * @param {Object} props
 * @param {string} props.className - the className derived from provided props
 * @param {string} props.label
 * @param {any} props.dateTime
 * @param {function} props.handleChange
 * @param {function} props.setTimeError
 * @param {boolean} props.hasResetToCurrentTime
 * @param {string} props.startServiceDateTime
 * @param {boolean} props.displaySeconds
 * @return {JSX.Element}
 * @constructor
 */
const ObservationTime = (props) => {
	const {
		className = '',
		label,
		dateTime,
		handleChange,
		setTimeError = () => {},
		hasResetToCurrentTime,
		startServiceDateTime,
		fieldSchema,
		displaySeconds,
	} = props;
	const formatDate = isDate(dateTime) ? dateTime : parseISO(dateTime);

	const handleTimeChange = (newValue, type) => {
		const currentDate = dateTime ? formatDate : null;
		const newTime = setDateTimeWithService(
			startServiceDateTime,
			fieldSchema?.startOfDayHour,
			currentDate,
			type,
			newValue,
		);
		handleChange(newTime);
	};

	const handleHourChange = (newValue) => handleTimeChange(newValue, 'hours');

	const handleMinuteChange = (newValue) => handleTimeChange(newValue, 'minutes');

	const handleSecondChange = (newValue) => handleTimeChange(newValue, 'seconds');

	const handleResetToCurrentTime = () => {
		handleChange(
			setDateTimeWithService(startServiceDateTime, fieldSchema?.startOfDayHour, new Date()),
		);
	};

	return (
		<div className={`observation-time ${className}`}>
			<label className="observation-time__label">{label}</label>
			<TimePicker
				className={displaySeconds ? '' : 'observation-time__input'}
				hours={dateTime ? getHours(formatDate) : undefined}
				setHours={handleHourChange}
				minutes={dateTime ? getMinutes(formatDate) : undefined}
				setMinutes={handleMinuteChange}
				seconds={dateTime ? getSeconds(formatDate) : undefined}
				setSeconds={handleSecondChange}
				setTimeError={setTimeError}
				showSeconds={true}
				resetToCurrentTime={hasResetToCurrentTime && handleResetToCurrentTime}
				displaySeconds={displaySeconds}
			/>
		</div>
	);
};

ObservationTime.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	dateTime: PropTypes.any,
	handleChange: PropTypes.func,
	setTimeError: PropTypes.func,
	hasResetToCurrentTime: PropTypes.bool,
	startServiceDateTime: PropTypes.any,
	displaySeconds: PropTypes.bool,
	fieldSchema: PropTypes.object,
};

export default ObservationTime;
