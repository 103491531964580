import ModifyAttachmentGlobalScopePage from './pages/modify-attachment-global-scope-page/modify-attachment-global-scope-page';
import ChangeAttachmentBanner from './components/change-attachment-banner/change-attachment-banner';
import ModifyAttachmentScheduleDocumentPage from './pages/modify-attachment-schedule-document-page/modify-attachment-schedule-document-page';
import ModifyAttachmentConsultationPage from './pages/modify-attachment-consultation-page/modify-attachment-consultation-page';
import i18next from '../../config/i18next';
import i18nAttachement from './i18n/fr.json';

i18next.addResourceBundle('fr', 'at', i18nAttachement);

export {
	ModifyAttachmentGlobalScopePage,
	ChangeAttachmentBanner,
	ModifyAttachmentScheduleDocumentPage,
	ModifyAttachmentConsultationPage,
};
