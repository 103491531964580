import { validateReason } from '../../components/observation-form/observation-validators';
import { setDateTimeWithService } from '../../observation.services';

const travellerInformation = [
	{
		fieldName: 'stationLocation',
		mandatory: true,
		children: [
			{
				fieldName: 'station',
				mandatory: true,
				parentValue: 'station',
				parentTranslated: true,
			},
			{
				fieldName: 'interStationStart',
				mandatory: true,
				parentValue: 'inter-station',
				parentTranslated: true,
			},
			{
				fieldName: 'interStationEnd',
				mandatory: true,
				parentValue: 'inter-station',
				parentTranslated: true,
			},
		],
	},
	{
		fieldName: 'communicationMode',
		mandatory: true,
	},
	{
		fieldName: 'messageType',
		mandatory: true,
	},
	{
		fieldName: 'dateTime',
		mandatory: true,
		defaultValue: ({ driveLog }) =>
			setDateTimeWithService(driveLog?.realized_date_start, new Date()),
	},
	{
		fieldName: 'reason',
		validator: validateReason,
	},
];

export default travellerInformation;
