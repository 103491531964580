import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getStoredFilter } from '../utils/stored-filter/get-stored-filter';

/**
 * Query Config for filter
 * @typedef {Object} Filter Config
 * @property  {Array} [status] - the status to search for ( enclosed / active)
 * @property  {string} [type] - the type of drivelog to get ( simple or complex)
 * @property  {string} [dateSortOrder] - the order to sort the drivelogs in (asc or desc). asc by default
 * @property  {number} [numberResults] - number of initial results to load
 * @property  {Array} [sortBy] - sort specialNotices by criteria
 * @property  {string} [storageName] - key where is stored the filters
 */

/**
 * Generic features around drive log list management
 * @param {{storageName: string}} [config] - config for the hook
 */
const useStoredFilter = (config) => {
	const { status } = config;
	const { storageName, getData, columnsToSearch } = config;
	const [data, setData] = useState();
	const [numberResults, setNumberResults] = useState(40);
	const [searchData, setSearchData] = useState({ search: '', searchOptions: '' });
	const [isLoading, setIsLoading] = useState(false);

	const sendRequest = () => {
		setIsLoading(true);
		getData()
			.then((response) => {
				const result = response.data;
				setData(result);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const loadData = () => {
		const filter = getStoredFilter(storageName);
		if (filter) {
			setSearchData({
				search: filter?.search,
				searchOptions: filter?.searchOptions,
				searchDate: filter.searchDate,
			});
		}
		sendRequest();
	};

	const loadMore = (numberToAdd = 40) => {
		setNumberResults((oldNumberResult) => oldNumberResult + numberToAdd);
	};

	const handleSearchData = (search, searchOptions) => {
		setSearchData({ search, searchOptions });
		localStorage.setItem(
			storageName,
			JSON.stringify({ search, searchOptions, dateStored: new Date() }),
		);
	};

	const handleResetData = () => {
		setSearchData({ search: '', searchOptions: columnsToSearch });
		localStorage.removeItem(storageName);
	};

	useDeepCompareEffect(() => {
		loadData();
	}, [status, searchData, numberResults]);

	return {
		data,
		loadMore,
		refresh: loadData,
		handleResetData,
		handleSearchData,
		filter: searchData,
		isLoading,
		numberResults,
	};
};

export default useStoredFilter;
