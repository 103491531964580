import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { subYears, addDays } from 'date-fns';
import { DateUtils } from '../../../core';
import Icon from '../../../core/components/icon-svg/icon';
import { useEffect } from 'react';
import './search-drive-logs-by-dates.scss';

/**
 * Search drive log by dates component
 * @param props
 * @param {function} props.handleDateFilter
 * @return {JSX.Element}
 * @constructor
 */
const SearchDriveLogsByDates = (props) => {
	const { t } = useTranslation();
	const { handleDateFilter, date = '' } = props;
	const refReact = React.useRef();
	const fiveYearsFromCurrentDate = subYears(new Date(), 5);
	const currentDay = addDays(new Date(), 0);

	const [searchDlDate, setSearchDlDate] = useState(new Date());
	const [display, setDisplay] = useState(false);
	const [isOpenCalendar, setIsOpenCalendar] = useState(false);

	useEffect(() => {
		setSearchDlDate(date ? new Date(date) : new Date());
		setDisplay(!!date);
	}, [date]);

	const handleDisplayCalendar = () => {
		setDisplay(true);
		setIsOpenCalendar(true);
		handleDateFilter(DateUtils.formatFullDate(new Date(), 'yyyy/MM/dd'));
	};

	const handleDateChange = (selectedDate) => {
		setSearchDlDate(selectedDate);
		handleDateFilter(DateUtils.formatFullDate(selectedDate, 'yyyy/MM/dd'));
	};

	const resetDatePicker = () => {
		setSearchDlDate(new Date());
		handleDateFilter('');
		setDisplay(false);
	};

	const CustomFilterPicker = forwardRef(({ value, onClick }, ref) => (
		<button
			className="custom-date-picker-filter"
			onClick={() => {
				setIsOpenCalendar(!isOpenCalendar);
			}}
			ref={ref}
		>
			{value}
			<Icon
				onClick={resetDatePicker}
				aria-label="Close"
				className="custom-date-picker-filter__icon-close"
				name="close"
			/>
		</button>
	));

	const handleCloseCalendar = () => {
		setIsOpenCalendar(false);
	};

	return (
		<div className="search-dl-dates">
			{!display && (
				<button className="search-dl-dates__label" onClick={handleDisplayCalendar}>
					<Icon
						aria-label="Calendar"
						className="custom-date-picker-filter__icon-calendar"
						name="calendar"
					/>
					{t('dl:search-dl-dates.label')}
				</button>
			)}

			{display && (
				<DatePicker
					dateFormat="dd/MM/yyyy"
					selected={searchDlDate}
					onChange={handleDateChange}
					customInput={<CustomFilterPicker ref={refReact} />}
					minDate={fiveYearsFromCurrentDate}
					maxDate={currentDay}
					locale="fr"
					onClickOutside={handleCloseCalendar}
					open={isOpenCalendar}
				/>
			)}
		</div>
	);
};

SearchDriveLogsByDates.propTypes = {
	handleDateFilter: PropTypes.func,
};

export default SearchDriveLogsByDates;
