import React from 'react';
import PropTypes from 'prop-types';
import { Comment } from '../../../../../../../core';

const SurveyPrecisionComment = (props) => {
	const {
		handleChange,
		value,
		className = '',
		title,
		placeholder,
		validator,
		readOnly = false,
	} = props;
	const precisionMaxLength = 240;

	const onChange = (newValue) => {
		if (!validator || validator(newValue) || newValue === '') {
			handleChange(newValue);
		}
	};

	return (
		<Comment
			value={value}
			title={title}
			handleChange={onChange}
			className={className}
			placeholder={placeholder}
			readOnly={readOnly}
			maxLength={precisionMaxLength}
		/>
	);
};

SurveyPrecisionComment.propTypes = {
	handleChange: PropTypes.func.isRequired,
	className: PropTypes.string,
	value: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	placeholder: PropTypes.string,
	validator: PropTypes.func,
};

export default SurveyPrecisionComment;
