import { differenceInHours, getHours, isSameDay } from 'date-fns';
import retrieveFileName from '../../domains/export/utils/retrieve-filename';
import http from '../axios';
import localForage from '../local-forage';
import { synchronizeAllStorage } from './synchronize-storage';

/**
 * Retrieves data from API and refresh local storage
 * @param {string} key - local storage key
 * @param {string} url - url to call
 * @param config
 * @return {Promise<Object>}
 */
const getLineSheetDataFromApi = async (key, url, config = {}) => {
	try {
		// check if there's pending data in local storage and synchronize
		await synchronizeAllStorage();
		const apiData = await http.get(url, config);
		const { data } = apiData;

		// if no line sheet retrieve, return null
		if (data.size === 0) {
			return null;
		}

		const fileName = retrieveFileName(apiData);

		// refresh local storage
		localForage.setItem(key, { data, fileName, cacheDate: new Date() }).then();
		return { ...apiData, fileName };
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

/**
 * Retrieves data from API and refresh local storage
 * @param {string} key - local storage key
 * @param {string} url - url to call
 * @return {Promise<Object>} - Returns an object containing the blob and file name, or null
 */
const getDocumentDataFromApi = async (key, url) => {
	try {
		// Synchronize local storage before fetching data
		await synchronizeAllStorage();

		const apiData = await http.get(url, { responseType: 'arraybuffer' });
		const { data } = apiData;

		// If no data retrieved, return null
		if (!data || data.size === 0) {
			console.warn('No data received or data is empty');
			return null;
		}

		const fileName = retrieveFileName(apiData);

		// Create a blob from the data
		const blob = new Blob([data], { type: 'application/pdf' });

		// Store the blob and additional information in localForage
		await localForage.setItem(key, { data: blob, fileName, cacheDate: new Date() });

		return { data: blob, fileName };
	} catch (err) {
		console.error('Data fetch failed:', err);
		return null; // Handle the error case
	}
};

/**
 * Retrieves linesheet data from local storage
 * @param {string} key - local storage key
 * @return {Promise<{data: Object}>}
 */
const getLineSheetDataFromLocalStorage = async (key) => {
	// return the entire response : data + fileName + cacheDate
	return localForage.getItem(key);
};

/**
 * Retrieve expired data from API
 * @param {string} key
 * @param {string} url
 * @param {number} maxCacheAge - cache age limit in hours
 * @param config
 * @return {Promise<void>}
 */
const getExpiredLineSheetDataFromApi = async (key, url, maxCacheAge, config) => {
	try {
		const cache = await localForage.getItem(key);

		if (!cache) {
			return getLineSheetDataFromApi(key, url, config);
		}

		const { cacheDate } = cache;
		// cache date is over the cache age limit.
		const isExpired = differenceInHours(new Date(), new Date(cacheDate.toString())) >= maxCacheAge;

		// cache no longer valid if there's no max age, no date or is expired
		const cacheNotValid = !maxCacheAge || !cacheDate || isExpired;

		// renew cache if not valid
		if (cacheNotValid) {
			await localForage.removeItem(key);
			return getLineSheetDataFromApi(key, url, config);
		}

		return getLineSheetDataFromLocalStorage(key);
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

/**
 * Retrieve expired data from API
 * @param {string} key
 * @param {string} url
 * @param {number} maxCacheAge - cache age limit in hours
 * @return {Promise<void>}
 */
const getExpiredDocumentDataFromApi = async (key, url) => {
	try {
		const cache = await localForage.getItem(key);
		if (!cache) {
			return getDocumentDataFromApi(key, url);
		}

		const { cacheDate } = cache;
		const currentDate = new Date();

		// Check if the documents are not from the same day
		const notSameDay = !isSameDay(currentDate, new Date(cacheDate.toString()));

		// Check if the current time is after 4 AM
		const after4AM = getHours(currentDate) >= 4;

		// Check if the cache date is before 4 AM
		const cacheBefore4AM = getHours(new Date(cacheDate.toString())) < 4;

		// Consider the document expired if:
		// 1. The documents are not from the same day
		// 2. The cache date is from the same day but before 4 AM, and the current time is after 4 AM
		const isExpired =
			notSameDay ||
			(isSameDay(currentDate, new Date(cacheDate.toString())) && cacheBefore4AM && after4AM);

		// Cache is no longer valid if there's no date or it is expired
		const cacheNotValid = !cacheDate || isExpired;

		// renew cache if not valid
		if (cacheNotValid) {
			await localForage.removeItem(key);
			return getDocumentDataFromApi(key, url);
		}

		// return the entire response : data + fileName + cacheDate
		return localForage.getItem(key);
	} catch (err) {
		console.info('Data fetch failed', err);
	}
};

export {
	getExpiredDocumentDataFromApi,
	getExpiredLineSheetDataFromApi,
	getLineSheetDataFromApi,
	getLineSheetDataFromLocalStorage,
};
