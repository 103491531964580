import i18next from '../../config/i18next';
import i18nUser from './i18n/fr.json';
import { AuthProvider } from './auth/context/auth-context';
import HomeProfileCard from './pages/user-home/components/home-profile-card/home-profile-card';
import UserHome from './pages/user-home/user-home';
import UsersList from './pages/users-list/users-list';
import UserTableRow from './pages/users-list/components/user-table-row/user-table-row';
import UserTableHeader from './pages/users-list/components/user-table-header/user-table-header';
import UpdateUserModal from './pages/users-list/components/modals/update-user-modal/update-user-modal';
import CreateUserModal from './pages/users-list/components/modals/create-user-modal/create-user-modal';
import DeleteUserModal from './pages/users-list/components/modals/delete-user-modal/delete-user-modal';
import * as FeedbackPage from '../users-feedback'; 
		
i18next.addResourceBundle('fr', 'user', i18nUser);

export {
	AuthProvider,
	HomeProfileCard,
	UserHome,
	UsersList,
	UserTableRow,
	UserTableHeader,
	UpdateUserModal,
	CreateUserModal,
	DeleteUserModal,
	FeedbackPage,
};
