import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Icon, Loader, Modal } from '../../../core';
import { NotificationContext } from '../../../core/notification/notification-context';
import './notification-modal.scss';

const initialState = { releve: false, degarage: false };

const NotificationModal = (props) => {
	const {
		showModal,
		toggleModal,
		cancelCallback = () => null,
		confirmCallback = () => null,
	} = props;
	const { t } = useTranslation('dl');

	const {
		isNotificationActive: removeMode,
		notificationConfig,
		saveNotificationConfig,
		removeAlert,
		activateAlert,
	} = useContext(NotificationContext);

	const [formData, setFormData] = useState(notificationConfig || initialState);
	const [isSubmitLoading, setSubmitLoading] = useState(false);
	const formValid = formData?.releve || formData?.degarage;

	const handleCancel = () => {
		setFormData(initialState);
		toggleModal(!showModal);
		cancelCallback();
	};

	const handleClick = (value, name) => {
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		const notification = { ...formData, active: true, date: new Date() };
		try {
			setSubmitLoading(true);
			await activateAlert({ ...formData });
			saveNotificationConfig(notification);
			toggleModal(!showModal);
			confirmCallback();
			setSubmitLoading(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleRemove = () => {
		saveNotificationConfig({ active: false, date: new Date() });
		removeAlert();
		toggleModal(!showModal);
	};

	const askPermission = () => {
		if (showModal) {
			Notification.requestPermission();
		}
	};

	useEffect(askPermission, [showModal]);

	const renderCheckboxInput = (name) => {
		const className = classnames('notification-modal__field__item', {
			'notification-modal__field__item--checked': !removeMode && formData[name],
			'notification-modal__field__item--readonly': removeMode,
		});
		if ((removeMode && formData[name]) || !removeMode) {
			return (
				<li key={name} className={className} onClick={() => handleClick(!formData[name], name)}>
					{!removeMode && <input type="checkbox" name={name} checked={formData[name]} />}
					<label htmlFor={name} className="notification-modal__field__text">
						{t(`dl-notification-modal.${name}`)}
					</label>
				</li>
			);
		}
		return null;
	};

	return (
		showModal && (
			<Modal type="custom">
				<div className="notification-modal__form">
					<button className="notification-modal__form__icon" onClick={handleCancel}>
						<Icon name="close" />
					</button>
					<div className="notification-modal__form__container">
						<div className="notification-modal__form__container__header">
							<p className="notification-modal__form__container__header__title">
								{t('dl-notification-modal.title')}
							</p>
							<Trans
								className="notification-modal__form__container__header__subtitle"
								i18nKey="dl:dl-notification-modal.subtitle"
								parent="p"
							>
								<strong />
							</Trans>
						</div>
						<div className="notification-modal__form__container__content">
							<span className="notification-modal__form__container__content__label">
								{removeMode
									? t('dl-notification-modal.label-remove')
									: t('dl-notification-modal.label')}
							</span>
							<div className="notification-modal__form__container__content__form">
								{['releve', 'degarage'].map(renderCheckboxInput)}
							</div>
						</div>
					</div>
					<div className="notification-modal__form__button">
						<Loader isLoading={isSubmitLoading}>
							<Button
								className="button"
								disabled={!removeMode ? !formValid : false}
								type="submit"
								onClick={!removeMode ? handleSubmit : handleRemove}
							>
								{!removeMode
									? t('dl-notification-modal.submit')
									: t('dl-notification-modal.remove')}
							</Button>
						</Loader>
					</div>
				</div>
			</Modal>
		)
	);
};

NotificationModal.propTypes = {
	showModal: PropTypes.bool,
	toggleModal: PropTypes.func,
	confirmCallback: PropTypes.func,
	cancelCallback: PropTypes.func,
	readOnly: PropTypes.bool,
};

export default NotificationModal;
