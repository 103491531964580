import { numeric } from '../../../core/utils/regex-utils/regex-utils';

/**
 * validate reason
 * @param reason
 * @return {boolean}
 */
const validateReason = (reason) => {
	return reason?.length > 0 && reason?.length <= 500;
};

/**
 * validate delay
 * @param delay
 * @return {boolean}
 */
const validateDelay = (delay) => {
	// delay is valid if it is made only of numbers or if it is empty
	// if delay is empty, its value is a boolean equal to "true"
	const isValidDelay = numeric.test(delay) || typeof delay === 'boolean';
	if (isValidDelay) {
		const castedDelay = typeof delay === 'string' ? parseInt(delay, 10) : delay;
		return castedDelay <= 1440 && castedDelay >= 0;
	}
	return false;
};

/**
 * generic regex validator function
 * @param regex
 * @return {function(*=): *}
 */
const regexValidator = (regex) => (value) => {
	return regex.test(value);
};

export { validateReason, validateDelay, regexValidator };
